<template>
    <section>
        <div class="content">
            <base-header class="pb-6">
                <b-row align-v="center" class="py-4">
                    <b-col cols="7" lg="6">
                        <h6 class="h2 text-white d-inline-block mb-0">
                            Site
                        </h6>
                        <nav
                                aria-label="breadcrumb"
                                class="d-none d-md-inline-block ml-md-4"
                        >
                        </nav>
                    </b-col>
                </b-row>
            </base-header>
            <b-container fluid class="mt--6">
                <div>
                    <card
                            class="card-border-diagonal p-0"
                            style="border: 1px solid black;"
                    >
                        <b-row class="my--4">
                            <b-col md="2" class="b-col-centered">
                                <div class="scroll-follower">
                                    <base-button size="md" class="p-2 m-2 button-css" @click="redirectToProjectDashboard">Project Profile</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToSite">Site Profile</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToGanttChart">Gantt Chart</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="scrollToUploadMsProTask">Upload Project File</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="redirectToDocRepo">Document Tracking</base-button><br>
                                    <base-button v-if="myUserID.role_id==3" size="md" class="p-2 m-2 button-css" @click="redirectToUtilitiesDataManipulation">Utilities Data Manipulation</base-button><br>
                                    <base-button size="md" class="p-2 m-2 button-css" @click="redirectToProjectUploadHistory">Historical Updates</base-button>
                                </div>
                            </b-col>

                            <b-col md="8" class="bg-custom">
                                <div id="site-profile">
                                    <h3 class="mt-4">Site Profile</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <b-row>
                                            <b-col>
                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <p class="form-label">Site Name</p>
                                                        <p class="form-content">{{ model.site_name }}</p>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Site Status"
                                                                    name="Site Status"
                                                                    v-model="model.status_id">
                                                            <select class="form-control" v-model="model.status_id">
                                                                <option Selected disabled value="x">-- Please Select Site Status --</option>
                                                                <option v-for="item in site_status" :key="item.status_id" v-bind:value="item.status_id" :disabled="item.disabled">{{ item.status_code }}</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <p class="form-label">Address</p>
                                                        <p class="form-content">{{ model.site_address }}</p>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <p class="form-label">Postal Code</p>
                                                        <p class="form-content">{{ model.site_postal_code }}</p>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Latitude"
                                                                    name="Latitude"
                                                                    v-model="model.site_latitude">
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Longitude"
                                                                    name="Longitude"
                                                                    v-model="model.site_longitude">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="EPC"
                                                                    name="EPC"
                                                                    v-model="model.contractor_id">
                                                            <select class="form-control" v-model="model.contractor_id">
                                                                <option Selected disabled value="x">-- Please Select A Contractor --</option>
                                                                <option v-for="item in contractors" :key="item.contractor_id" v-bind:value="item.contractor_id">{{ item.contractor_code }}</option>
                                                            </select>
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="Site Code"
                                                                    name="Site Code"
                                                                    v-model="model.site_code">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <p class="form-label">Roof Handover Date</p>
                                                        <p class="form-content">{{ additional_model.roof_handover_date | formatDate }}</p>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="COD"
                                                                    name="COD"
                                                                    type="date"
                                                                    v-model="model.site_cod_date">
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <!--<p class="form-label">Planned Turn On</p>-->
                                                        <!--<p class="form-content">{{ model.site_target_turn_on_date | formatDate }}</p>-->
                                                        <base-input label="Planned Turn On"
                                                                    name="Planned Turn On"
                                                                    type="date"
                                                                    v-model="model.site_target_turn_on_date">
                                                        </base-input>
                                                    </b-col>
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Actual Turn On Date"
                                                                    name="Actual Turn On Date"
                                                                    type="date"
                                                                    v-model="model.site_actual_turn_on_date">
                                                        </base-input>
                                                    </b-col>

                                                    <!-- <b-col md="6">
                                                        <base-input label="As Built Capacity (kWp)"
                                                                    name="As Built Capacity (kWp)"
                                                                    v-model="model.site_as_built_capacity">
                                                        </base-input>
                                                    </b-col> -->
                                                </div>

                                                <div class="form-row">
                                                    <b-col md="6">
                                                        <base-input label="Site PPA Total Capacity"
                                                                    name="Site PPA Total Capacity"
                                                                    v-model="model.site_total_capacity">
                                                        </base-input>
                                                    </b-col>

                                                    <b-col md="6">
                                                        <base-input label="As Built Capacity (kWp)"
                                                                    name="As Built Capacity (kWp)"
                                                                    v-model="model.site_as_built_capacity">
                                                        </base-input>
                                                    </b-col>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="3">
                                                <p class="form-label">Attachment:</p>
                                            </b-col>

                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click.prevent="$refs.file.click()">Select File</base-button>
                                                <input
                                                        type="file"
                                                        ref="file"
                                                        style="display:none"
                                                        @change="onChange"
                                                />
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="3">
                                                <div v-if="!site_attachment.file">No file selected.</div>
                                                <div v-else>{{ site_attachment.file.name }}</div>
                                            </b-col>

                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadFile">Upload File</base-button>
                                            </b-col>
                                        </b-row>

                                        <p class="form-label" v-if="site_attachments.length > 0">File Name</p>

                                        <hr v-if="site_attachments.length > 0" style="margin-top: 5px; margin-bottom: 5px; border-top: 2px solid black;">

                                        <b-row v-if="site_attachments.length > 0">
                                            <b-col>
                                                <div v-for="attachment in site_attachments" :key="attachment.site_file_id">
                                                    <b-row>
                                                        <b-col md="6">{{ attachment.site_file_name }}</b-col>
                                                        <b-col md="2"><base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 80%;" @click="downloadFile(attachment.file.url, attachment.site_file_name)">Download</base-button></b-col>
                                                        <b-col md="2"><base-button size="md" class="p-1 m-2" style="background-color: #C00000; width: 80%;" @click="deleteFile(attachment.site_file_id)">Delete</base-button></b-col>
                                                    </b-row>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <hr v-if="site_attachments.length > 0" style="margin-top: 5px; margin-bottom: 5px; border-top: 2px solid black;">

                                        <!-- <b-row>
                                            <b-col md="10" class="text-right">
                                                set active epc
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="setActiveSiteContractor()">Save EPC</base-button>
                                            </b-col>
                                        </b-row> -->

                                        <b-row>
                                            <b-col md="10" class="text-right">
                                                <!-- save site data -->
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteData(); setActiveSiteContractor();">Save</base-button>
                                                <!-- <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteData()">Save</base-button> -->
                                            </b-col>
                                        </b-row>
                                    </card>
                                </div>


                                <div id="gantt-chart">
                                    <h3>Gantt Chart</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <div id="chart" style="border: 1px solid #375623;">
                                            <apexchart type="rangeBar" height="350" :options="chartOptions" :series="series" @dataPointSelection="dataPointSelectionHandler"></apexchart>
                                        </div>

                                        <!-- <b-row>
                                            <b-col md="10" class="text-right">
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteMilestoneData()">Save</base-button>
                                            </b-col>
                                        </b-row> -->
                                    </card>
                                </div>

                                <div id="upload-mspt">
                                    <h3>Upload Project File</h3>
                                    <card class="card-border-inner bg-transparent form-custom-css">
                                        <b-row>
                                            <b-col md="3">
                                                Select the file to upload:
                                            </b-col>

                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="$refs.file1.click()">Select File</base-button>
                                                <!--<base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="onChangeProjectSiteTask($event,1)">Select File</base-button>-->
                                                <input
                                                        type="file"
                                                        ref="file1"
                                                        style="display:none"
                                                        @change="onChangeProjectSiteTask"
                                                />
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col md="3">
                                                <div v-if="!project_task_file">No file selected.</div>
                                                <div v-else>
                                                    <base-input label="Sheet To Import"
                                                                name="Sheet To Import"
                                                                v-model="selected_project_task_sheet">
                                                        <select class="form-control" v-model="selected_project_task_sheet" @click="loadProjectTaskSheetNames" @change="updateProjectTaskRangeToSelect(); showUploadingProjectTaskData();">
                                                            <option Selected disabled value="x">-- Please Select A Sheet To Import --</option>
                                                            <option v-for="sheet1 in projectTaskSheets" :key="sheet1.sheetId" v-bind:value="sheet1.name">{{ sheet1.name }}</option>
                                                        </select>
                                                    </base-input>
                                                </div>
                                            </b-col>
                                            <b-col md="2">
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadProjectSiteTask" v-if="projectTaskTempData"
                                                >Upload File</base-button
                                                >
                                                <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;" @click="uploadProjectSiteTask" v-else disabled
                                                >Upload File</base-button
                                                >
                                            </b-col>
                                        </b-row>

                                        <div>
                                            <xlsx-read ref="excelRead1" :file="project_task_file">
                                                <xlsx-json ref="excelJson1" :sheet="selected_project_task_sheet" :options="{ range: selected_project_task_range, blankrows: false }">
                                                </xlsx-json>
                                            </xlsx-read>
                                        </div>
                                    </card>
                                </div>

                                <div id="display_documents"></div>
                                <div id="history"></div>
                            </b-col>

                            <b-col md="2">
                                <!-- empty for side key contents -->
                                <div class="scroll-follower-right form-custom-css">
                                    <h2 class="h2-key-contacts">Key Contacts</h2>
                                    <br>
                                    <div class="form-row">
                                        <b-col md="12">
                                            <p class="form-label-key-contacts">Project Engineer</p>
                                            <p class="form-content-key-contacts">{{ contacts_model.project_manager }}</p>
                                        </b-col>
                                    </div>

                                    <div class="form-row">
                                        <b-col md="12">
                                            <p class="form-label-key-contacts">Engineer</p>
                                            <p class="form-content-key-contacts">{{ contacts_model.project_engineer }}</p>
                                        </b-col>
                                    </div>

                                    <div class="form-row">
                                        <b-col md="12">
                                            <p class="form-label-key-contacts">Business Development</p>
                                            <p class="form-content-key-contacts">{{ contacts_model.project_business_development }}</p>
                                        </b-col>
                                    </div>

                                    <div class="form-row">
                                        <b-col md="12">
                                            <p class="form-label-key-contacts">Safety Officer</p>
                                            <p class="form-content-key-contacts">{{ contacts_model.project_safety }}</p>
                                        </b-col>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </card>
                </div>
            </b-container
            >
        </div>

        <!--<modal-->
                <!--id="gantt_modal"-->
                <!--:show.sync="isSiteMilestoneVisible"-->
                <!--size="xl"-->
                <!--body-classes="p-0"-->
                <!--title="Site Milestone">-->
            <!--<card>-->
                <!--<template v-if="site_milestones.length > 0">-->
                    <!--<h1 class="text-center">Milestone: {{ site_milestones[selectedDataPointIndex].site_milestone_code }}</h1>-->
                    <!--<div class="form-row">-->
                        <!--<b-col md="4">-->
                            <!--<base-input label="Planned Start Date"-->
                                        <!--name="Planned Start Date"-->
                                        <!--type="date"-->
                                        <!--v-model="site_milestones[selectedDataPointIndex].planned_start_date"-->
                                        <!--disabled>-->
                            <!--</base-input>-->
                        <!--</b-col>-->

                        <!--<b-col md="4">-->
                            <!--<base-input label="Planned End Date"-->
                                        <!--name="Planned End Date"-->
                                        <!--type="date"-->
                                        <!--v-model="site_milestones[selectedDataPointIndex].planned_end_date"-->
                                        <!--disabled>-->
                            <!--</base-input>-->
                        <!--</b-col>-->
                        <!--<b-col md="4">-->
                            <!--<base-input label="Total Days"-->
                                        <!--name="Total Days"-->
                                        <!--type="number"-->
                                        <!--v-model="site_milestones[selectedDataPointIndex].total_planned_day"-->
                                        <!--disabled>-->
                            <!--</base-input>-->
                        <!--</b-col>-->
                    <!--</div>-->
                    <!--<div class="form-row">-->
                        <!--<b-col md="4">-->
                            <!--<base-input label="Actual Start Date"-->
                                        <!--name="Actual Start Date"-->
                                        <!--type="date"-->
                                        <!--v-model="site_milestones[selectedDataPointIndex].actual_start_date"-->
                                        <!--:max="site_milestones[selectedDataPointIndex].actual_end_date"-->
                                        <!--@change="calculateTotalPlannedDay(selectedDataPointIndex,'actual_start_date')">-->

                            <!--</base-input>-->
                        <!--</b-col>-->

                        <!--<b-col md="4">-->
                            <!--<base-input label="Actual End Date"-->
                                        <!--name="Actual End Date"-->
                                        <!--type="date"-->
                                        <!--v-model="site_milestones[selectedDataPointIndex].actual_end_date"-->
                                        <!--:min="site_milestones[selectedDataPointIndex].actual_start_date"-->
                                        <!--@change="calculateTotalPlannedDay(selectedDataPointIndex,'actual_end_date')">-->

                            <!--</base-input>-->
                        <!--</b-col>-->

                        <!--<b-col md="4">-->
                            <!--<base-input label="Total Days"-->
                                        <!--name="Total Days"-->
                                        <!--type="number"-->
                                        <!--v-model="site_milestones[selectedDataPointIndex].total_actual_day"-->
                                        <!--:min="0"-->
                                        <!--@input="specificCalculateActualDate">-->
                            <!--</base-input>-->
                        <!--</b-col>-->
                    <!--</div>-->
                <!--</template>-->
                <!--&lt;!&ndash; <div style="float: right;">-->
                  <!--<base-button size="md" class="p-2" style="background-color: #375623;" @click="closeAndUpdateGanttChart">Update Gantt Chart</base-button>-->
                <!--</div> &ndash;&gt;-->

                <!--<b-row>-->
                    <!--<b-col md="10" class="text-right">-->
                        <!--&lt;!&ndash; update gantt chart, still need to click on save site milestone data to store data &ndash;&gt;-->
                    <!--</b-col>-->
                    <!--<b-col md="2">-->
                        <!--&lt;!&ndash; <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="saveSiteData(); setActiveSiteContractor();">Save</base-button> &ndash;&gt;-->
                        <!--&lt;!&ndash; <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="closeAndUpdateGanttChart()">Update Gantt Chart</base-button> &ndash;&gt;-->
                        <!--<base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%" @click="closeAndUpdateGanttChart()">Save</base-button>-->
                    <!--</b-col>-->
                <!--</b-row>-->
            <!--</card>-->
        <!--</modal>-->
    </section>
</template>

<script>
    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
        getcurrentDate,
    } from "@/assets/js/config.js";
    import axios from "axios";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
    import jsonFormData from 'json-form-data';
    import VueApexCharts from "vue-apexcharts";
    import moment from "moment";
    import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
    import XlsxJson from "vue-xlsx/dist/components/XlsxJson";


    export default {
        mixins: [ErrorFunctionMixin],
        components: {
            apexchart: VueApexCharts,
            XlsxRead,
            XlsxJson,
        },
        created() {
            this.currentDate = getcurrentDate();

            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;

            this.getProjectSiteStatusListing();
            this.getContractorListing();
            this.getModuleBrandListing();
            this.getMilestoneTemplateListing();
            this.getDashboardData();
            this.getDashboardAdditionalInfo();
            this.getProjectSiteMilestoneData();
            this.getContractorFromSite();
            this.getSiteAttachmentListing();
            this.getGanttChartData();
        },
        data() {
            return {
                model: {
                    site_id: null,
                    project_id: null,
                    group_id: null,
                    site_name: null,
                    status_id: 'x',
                    site_address: null,
                    site_postal_code: null,
                    contractor_id: 'x',
                    site_code: null,
                    site_latitude: null,
                    site_longitude: null,
                    roof_handover_date: null, // CHECK WITH ANDREW
                    site_cod_date: null,
                    site_target_turn_on_date: null,
                    site_actual_turn_on_date: null,
                    site_total_capacity: null, // CHECK WITH ANDREW
                    site_as_built_capacity: null,
                    site_drawing_module_brand_id: 'x',
                    site_drawing_module_quantity: 0,
                    site_drawing_module_capacity: 0,
                    site_drawing_total_capacity: 0,
                    site_drawing_ifa_date: null,
                    site_drawing_ifc_date: null,
                    site_module_brand_id: 'x',
                    site_module_quantity: 0,
                    site_module_capacity: 0,
                    site_module_allocation_total_capacity: 0,
                    site_ifc_ifa_module_remarks: null,
                    // site_name: null,
                    // status_code: null,
                    // site_leader: null,
                    // site_total_installation: 0,
                    // site_total_task: 0,
                    // site_completed_task: 0,
                    // site_total_document: 0,
                    // site_completed_document: 0,
                    // site_progress: 0,
                    // site_total_capacity: 0,
                    // site_completed_capacity: 0,
                    // group_engineer: {
                    // name: null,
                    // email: null,
                    // rows: [
                    //     { milestone: "Applicaton to Authorities", startDate: "", endDate: "", remarks: "" },
                    //     { milestone: "Mobilization to Site", startDate: "", endDate: "", remarks: "" },
                    //     { milestone: "Hoisting", startDate: "", endDate: "", remarks: "" },
                    //     // MAKE DYNAMIC BASED ON API CALL
                    // ],
                    milestone_template_id: 'x',
                },
                site_status: [],
                contractors: [],
                module_brands: [],
                milestone_template: [],
                site_attachment: {
                    file: null,
                },
                cif_file: null,
                project_task_file: null,
                tempData: null,
                projectTaskTempData: null,
                converted_temp_data: null,
                converted_temp_project_task_data: null,
                sheets: [
                    // {
                    //     name: "Stage_1 Technical Info (ENG)",
                    // },
                    // {
                    //     name: "Stage_1 Project Info (BD)",
                    // },
                ],
                projectTaskSheets:[],
                selected_sheet: 'x',
                selected_range: '',
                selected_project_task_sheet:'x',
                selected_project_task_range:'',
                postResponse: null,
                site_attachments: [],
                additional_model: {
                    project_site_additional_info_id: null,
                    site_id: null,
                    ntp_applicable: 0,
                    ntp_issued: 0,
                    ntp_issue_date: null,
                    internal_project_handover_completed: 0,
                    internal_project_handover_date: null,
                    kick_off_meeting_completed: 0,
                    kick_off_meeting_date: null,
                    roof_handover_completed: 0,
                    roof_handover_date: null,
                    cops_tnc_checked: 0,
                    cops_tnc_issue: 0,
                    cops_tnc_remarks: null,
                    onm_handover_checked: 0,
                    onm_handover_issue: 0,
                    onm_handover_remarks: null,
                    turn_on_checking_approved: 0,
                    turn_on_checking_approval_date: null,
                    turn_on_checking_approver: null,
                    cif_technical_info: null,
                    cif_project_info: null,
                },
                site_milestones: [],
                ms_upload_task: [],
                series: [
                ],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'rangeBar',
                        background: "#DDEED2",
                    },
                    // colors: ["#2E75B5", "#00B050"], // #FF0000
                    colors: [
                        "#2E75B5",
                        ({ value, dataPointIndex }) => {
                            if (this.ms_upload_task.length > 0 ) {
                                if (this.ms_upload_task[dataPointIndex].mspt_planned_end_date) {
                                    const plannedEndDate = new Date(this.ms_upload_task[dataPointIndex].mspt_planned_end_date).getTime();
                                    console.log(plannedEndDate);
                                    if (value > plannedEndDate) {
                                        return '#FF0000'
                                    } else {
                                        return '#00B050'
                                    }
                                } else {
                                    return '#00B050'
                                }
                            } else {
                                return '#00B050'
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        //   enabled: true,
                        //   formatter: function(val) {
                        //     var a = moment(val[0])
                        //     var b = moment(val[1])
                        //     var diff = b.diff(a, 'days')
                        //     return diff + (diff > 1 ? ' days' : ' day')
                        //   }
                    },
                    fill: {
                        //   type: 'gradient',
                        //   gradient: {
                        //     shade: 'light',
                        //     type: 'vertical',
                        //     shadeIntensity: 0.25,
                        //     gradientToColors: undefined,
                        //     inverseColors: true,
                        //     opacityFrom: 1,
                        //     opacityTo: 1,
                        //     stops: [50, 0, 100, 100]
                        //   }
                    },
                    xaxis: {
                        type: 'datetime',
                        // labels: {
                        //     format: 'MMM yyyy', // Format for month labels
                        // },
                        // min: new Date('2023-01-01').getTime(),
                        // max: new Date('2023-12-31').getTime(),
                    },
                    legend: {
                        position: 'top',
                        markers: {
                            fillColors: ["#2E75B5", "#00B050"],
                        },
                    }
                },
                isSiteMilestoneVisible: false,
                selectedDataPointIndex: 0,
                actual_total_day : 0,
                contacts_model: {
                    project_manager: null,
                    project_engineer: null,
                    project_business_development: null,
                    project_safety: null,
                },
                projects_model: {
                    project_ppa_start_date: null,
                    project_ppa_approved_capacity: null,
                },
            };
        },
        filters: {
            formatDate(value) {
                if (value) {
                    const dateParts = value.split("-");
                    const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                    return formattedDate;
                }
                return "N/A"; // Return an empty string if the date is not available or invalid
            },
        },
        watch: {
            'additional_model.ntp_applicable': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.ntp_issued = "0";
                    this.additional_model.ntp_issue_date = null;
                }
            },
            'additional_model.ntp_issued': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.ntp_issue_date = null;
                } else if (newVal === "1") {
                    this.additional_model.ntp_issue_date = this.currentDate;
                }
            },
            'additional_model.internal_project_handover_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.internal_project_handover_date = null;
                } else if (newVal === "1") {
                    this.additional_model.internal_project_handover_date = this.currentDate;
                }
            },
            'additional_model.kick_off_meeting_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.kick_off_meeting_date = null;
                } else if (newVal === "1") {
                    this.additional_model.kick_off_meeting_date = this.currentDate;
                }
            },
            'additional_model.roof_handover_completed': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.roof_handover_date = null;
                } else if (newVal === "1") {
                    this.additional_model.roof_handover_date = this.currentDate;
                }
            },
            'additional_model.cops_tnc_checked': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.cops_tnc_issue = "0";
                    this.additional_model.cops_tnc_remarks = null;
                }
            },
            'additional_model.onm_handover_checked': function (newVal) {
                if (newVal === "0") {
                    this.additional_model.onm_handover_issue = "0";
                    this.additional_model.onm_handover_remarks = null;
                }
            },
        },
        computed:{

        },
        methods: {
            redirectToProjectDashboard(){
                this.$router.push({path: '/projectDashboardV2/' + this.model.project_id})
            },
            scrollToSite() {
                const siteSection = document.getElementById("site-profile");
                siteSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToGanttChart() {
                const ganttChartSection = document.getElementById("gantt-chart");
                ganttChartSection.scrollIntoView({ behavior: "smooth" });
            },
            scrollToUploadMsProTask(){
                const uploadMsProTaskSection = document.getElementById('upload-mspt');
                uploadMsProTaskSection.scrollIntoView({ behavior: "smooth" });
            },
            redirectToDocRepo() {
              const { project_id, site_id } = this.model
              this.$router.push({ path: `/DocRepo/P/${project_id}/S/${site_id}/Home` })
            },
            scrollToDisplayHistory(){
                const displayHistorySection = document.getElementById('history');
                displayHistorySection.scrollIntoView({ behavior: "smooth" });
            },
            redirectToProjectUploadHistory(){
                this.$router.push({path: '/ProjectSiteHistoricalUpdates/' + this.model.site_id})
            },
            redirectToUtilitiesDataManipulation(){
                this.$router.push({path: '/ProjectSiteUtilitiesDashboardData/'+this.model.site_id})
            },

            getProjectSiteStatusListing() {
                // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.site_status = response.data.data;
                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
            },
            updateAvailableProjectSiteStatus() {
                // 20240109 andrew request to enable all site status selection
                // // set all to disabled initially, slowly allow selection based on validation
                // this.site_status = this.site_status.map(item => ({
                //     ...item,
                //     disabled: true
                // }));

                // const objIndex = this.site_status.findIndex((obj => obj.status_sequence == 1));
                // this.site_status[objIndex].disabled = false;

                // const conditions = [
                //     {
                //         condition: (this.projects_model.project_ppa_start_date != null
                //             && this.projects_model.project_ppa_approved_capacity != null
                //             && (this.model.contractor_id != null && this.model.contractor_id != "x")),
                //         statusSequence: 2
                //     },
                //     {
                //         condition: ((this.model.site_module_brand_id != null && this.model.site_module_brand_id != "x")
                //             && (this.model.site_module_quantity != null && this.model.site_module_quantity != 0)),
                //         statusSequence: 3
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 4
                //     },
                //     {
                //         condition: (this.site_milestones.length > 0
                //             && this.site_milestones.every(obj => obj.planned_start_date !== null && obj.planned_end_date !== null)),
                //         statusSequence: 5
                //     },
                //     {
                //         condition: this.getSiteMilestoneActualDates("Installation"),
                //         statusSequence: 6
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 7
                //     },
                //     {
                //         condition: true,
                //         statusSequence: 8
                //     },
                //     {
                //         condition: this.getSiteMilestoneActualDates("Turn On"),
                //         statusSequence: 9
                //     },
                //     {
                //         condition: true, // TO BE DONE, HOW TO CHECK IF ALL FIELDS ARE FILLED?
                //         statusSequence: 10
                //     },
                // ];

                // conditions.every(({ condition, statusSequence }) => {
                //     if (!condition) {
                //         return false; // Exit the loop if the condition is false
                //     }

                //     const objIndex = this.site_status.findIndex(obj => obj.status_sequence === statusSequence);
                //     this.site_status[objIndex].disabled = false;

                //     return true; // Continue to the next iteration if the condition is true
                // });
            },
            getSiteMilestoneActualDates(milestone_name) {
                if (this.site_milestones.length == 0) {
                    return false;
                }

                const site_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === milestone_name);

                if (typeof site_milestone_data !== "undefined") {
                    if (site_milestone_data.actual_start_date != null && site_milestone_data.actual_end_date != null) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }

            },
            getContractorListing() {
                axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.contractors = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                    } );
            },
            getModuleBrandListing(){
                axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.module_brands = response.data.data;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Module Brand Information");
                    });
            },

            getMilestoneTemplateListing() {
                axios.get(apiDomain + 'template/milestone_template', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.milestone_template = response.data.data;
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Milestone Template Information")
                    } );
            },
            getDashboardData() {
                this.model.site_id = this.$route.params.id;
                axios
                    .get(apiDomain + "project/project_site/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.model.project_id = response.data.data[0].project.project_id;
                            this.model.group_id = response.data.data[0].group.group_id;
                            this.model.site_name = response.data.data[0].site_name;
                            this.model.status_id = response.data.data[0].status.status_id;
                            this.model.site_address = response.data.data[0].site_address;
                            this.model.site_postal_code = response.data.data[0].site_postal_code;
                            this.model.site_code = response.data.data[0].site_code;
                            this.model.site_latitude = response.data.data[0].site_latitude;
                            this.model.site_longitude = response.data.data[0].site_longitude;
                            this.model.site_cod_date = response.data.data[0].site_cod_date;
                            this.getProjectData();
                            this.model.site_target_turn_on_date = response.data.data[0].site_target_turn_on_date;
                            this.model.site_actual_turn_on_date = response.data.data[0].site_actual_turn_on_date;
                            // this.model.site_total_capacity = response.data.data[0].site_total_capacity;
                            this.model.site_total_capacity = parseFloat(response.data.data[0].site_total_capacity.replace(/,/g, ""));
                            this.model.site_as_built_capacity = parseFloat(response.data.data[0].site_as_built_capacity.replace(/,/g, ""));

                            this.model.site_drawing_module_brand_id = response.data.data[0].site_drawing_module_brand.module_brand_id;
                            this.model.site_drawing_module_quantity = parseFloat(response.data.data[0].site_drawing_module_quantity.replace(/,/g, ""));
                            this.model.site_drawing_module_capacity = parseFloat(response.data.data[0].site_drawing_module_capacity.replace(/,/g, ""));
                            this.model.site_drawing_total_capacity = parseFloat(response.data.data[0].site_drawing_total_capacity.replace(/,/g, ""));
                            this.model.site_drawing_ifa_date = response.data.data[0].site_drawing_ifa_date;
                            this.model.site_drawing_ifc_date = response.data.data[0].site_drawing_ifc_date;
                            this.model.site_module_brand_id = response.data.data[0].site_module_brand.module_brand_id;
                            this.model.site_module_quantity = parseFloat(response.data.data[0].site_module_quantity.replace(/,/g, ""));
                            this.model.site_module_capacity = parseFloat(response.data.data[0].site_module_capacity.replace(/,/g, ""));
                            this.model.site_module_allocation_total_capacity = parseFloat(response.data.data[0].site_module_allocation_total_capacity.replace(/,/g, ""));
                            this.model.site_ifc_ifa_module_remarks = response.data.data[0].site_ifc_ifa_module_remarks;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Information");
                    });
            },
            getProjectData() {
                axios
                    .get(apiDomain + "project/project/" + this.model.project_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.contacts_model.project_manager = response.data.data[0].project_manager.name;
                            this.contacts_model.project_engineer = response.data.data[0].project_engineer.name;
                            this.contacts_model.project_business_development = response.data.data[0].project_business_development.name;
                            this.contacts_model.project_safety = response.data.data[0].project_safety.name;

                            this.projects_model.project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            this.projects_model.project_ppa_approved_capacity = response.data.data[0].project_ppa_approved_capacity;
                            // this.updateAvailableProjectSiteStatus();

                            // default cod date = ppa signed date + 180 days
                            const project_ppa_start_date = response.data.data[0].project_ppa_start_date;
                            if (project_ppa_start_date && this.model.site_cod_date == null) {
                                const ppaCod = new Date(project_ppa_start_date);
                                ppaCod.setDate(ppaCod.getDate() + 180);
                                const ppaCodMonth = String((ppaCod.getMonth()+1)).padStart(2, '0');
                                const ppaCodDay = String(ppaCod.getDate()).padStart(2, '0');
                                const ppaCodDate = ppaCod.getFullYear() + '-' + ppaCodMonth + '-' + ppaCodDay;
                                this.model.site_cod_date = ppaCodDate;
                            }

                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch((error) => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Project Information");
                        }
                    });
            },
            getDashboardAdditionalInfo() {
                axios
                    .get(apiDomain + "project/project_site_additional_info/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.additional_model.project_site_additional_info_id = response.data.data[0].project_site_additional_info_id;
                            this.additional_model.site_id = response.data.data[0].site_id;
                            this.additional_model.ntp_applicable = response.data.data[0].ntp_applicable;
                            this.additional_model.ntp_issued = response.data.data[0].ntp_issued;
                            this.additional_model.ntp_issue_date = response.data.data[0].ntp_issue_date;
                            this.additional_model.internal_project_handover_completed = response.data.data[0].internal_project_handover_completed;
                            this.additional_model.internal_project_handover_date = response.data.data[0].internal_project_handover_date;
                            this.additional_model.kick_off_meeting_completed = response.data.data[0].kick_off_meeting_completed;
                            this.additional_model.kick_off_meeting_date = response.data.data[0].kick_off_meeting_date;
                            this.additional_model.roof_handover_completed = response.data.data[0].roof_handover_completed;
                            this.additional_model.roof_handover_date = response.data.data[0].roof_handover_date;
                            this.additional_model.cops_tnc_checked = response.data.data[0].cops_tnc_checked;
                            this.additional_model.cops_tnc_issue = response.data.data[0].cops_tnc_issue;
                            this.additional_model.cops_tnc_remarks = response.data.data[0].cops_tnc_remarks;
                            this.additional_model.onm_handover_checked = response.data.data[0].onm_handover_checked;
                            this.additional_model.onm_handover_issue = response.data.data[0].onm_handover_issue;
                            this.additional_model.onm_handover_remarks = response.data.data[0].onm_handover_remarks;
                            this.additional_model.turn_on_checking_approved = response.data.data[0].turn_on_checking_approved;
                            this.additional_model.turn_on_checking_approval_date = response.data.data[0].turn_on_checking_approval_date;
                            this.additional_model.turn_on_checking_approver = response.data.data[0].turn_on_checking_approver;
                            this.additional_model.cif_technical_info = JSON.parse(response.data.data[0].cif_technical_info);
                            this.additional_model.cif_project_info = JSON.parse(response.data.data[0].cif_project_info);
                            if (this.additional_model.cif_project_info) {
                                this.addIsEmailAttribute(this.additional_model.cif_project_info);
                            }
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Additional Information");
                    });
            },
            addIsEmailAttribute(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].column_4 === 'Email / Phone Number') {
                        for (let j = i + 1; j <= i + 4 && j < data.length; j++) {
                            data[j].isEmail = true;
                        }
                    }
                }
            },
            getProjectSiteMilestoneData() {
                axios
                    .get(
                        apiDomain +
                        "project/project_site_milestone.show_by_site/" +
                        this.model.site_id,
                        { headers: getHeader() }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            let data = response.data.data;
                            data.forEach(item => {
                                item.total_planned_day = 0;
                                item.total_actual_day = 0;
                                if(item.planned_start_date !== null && item.planned_end_date !== null){
                                    item.total_planned_day  = this.getDaysDifference(item.planned_start_date,item.planned_end_date)
                                }

                                if(item.actual_start_date !== null && item.actual_end_date !== null){
                                    item.total_actual_day  = this.getDaysDifference(item.actual_start_date,item.actual_end_date)
                                }
                            });
                            this.site_milestones = data;
                            //this.updateGanttChartSeries();

                            // get and update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                            // get and update Actual Turn On based on "Turn On" actual_end_date if value is null
                            // ONE WAY!!!
                            // changing planned_end_date of "Turn On" will change site_target_turn_on_date
                            // changing site_target_turn_on_date will not change planned_end_date of "Turn On"

                            const turn_on_milestone_data = this.site_milestones.find(obj => obj.site_milestone_code === "Turn On");

                            if (turn_on_milestone_data != undefined) {
                                // update Planned Turn On (site_target_turn_on_date) based on "Turn On" planned_end_date
                                if (turn_on_milestone_data.planned_end_date) {
                                    this.model.site_target_turn_on_date = turn_on_milestone_data.planned_end_date;
                                }
                                // update Actual Turn On based on "Turn On" actual_end_date if value is null
                                if (turn_on_milestone_data.actual_end_date && (this.model.site_actual_turn_on_date == null)) {
                                    this.model.site_actual_turn_on_date = turn_on_milestone_data.actual_end_date;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                    });
            },
            updateGanttChartSeries() {
                const temp_series = [
                    {
                        name: 'Planned',
                        data: this.ms_upload_task.map(item => ({
                            x: item.mspt_task_name,
                            y: [
                                item.mspt_planned_start_date ? new Date(item.mspt_planned_start_date).getTime() : null,
                                item.mspt_planned_end_date ? new Date(item.mspt_planned_end_date).getTime() : null
                            ]
                        }))
                    },
                    {
                        name: 'Actual',
                        data: this.ms_upload_task.map(item => ({
                            x: item.mspt_task_name,
                            y: [
                                item.mspt_actual_start_date ? new Date(item.mspt_actual_start_date).getTime() : null,
                                item.mspt_actual_end_date ? new Date(item.mspt_actual_end_date).getTime() : null
                            ]
                        }))
                    }
                ];

                this.series = [];
                this.series = temp_series;

                // this.chartOptions.xaxis.min = new Date('2023-07-01').getTime();
                // this.chartOptions.xaxis.max = new Date('2023-12-01').getTime();
            },
            getContractorFromSite()
            {
                axios.get(apiDomain + 'project/site_contractor.show_by_site/' + this.model.site_id + '?client=mobile', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.model.contractor_id = response.data.data[0].contractor.contractor_id;
                            this.updateAvailableProjectSiteStatus();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Contractor Information")
                        }
                    } );
            },
            saveSiteData() {
                // CAREFUL WITH PUT METHOD, MIGHT NEED TO CHANGE TO POST WITH _put

                axios.put(apiDomain + 'project/project_site/' + this.model.site_id, this.model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            // this.$router.back()
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Information")
                    });
            },
            setActiveSiteContractor() {
                if ( this.model.contractor_id == 'x' ) {
                    this.$notify({
                        message:
                            '<b>Invalid Contractor : </b> - Please Select A Contractor to proceed.',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                } else {
                    const site_contractor_model = {
                        site_id: this.model.site_id,
                        contractor_id: this.model.contractor_id,
                        active_status: 1
                    };

                    const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                    const headers = {
                        Authorization: "Bearer " + tokenData.access_token,
                    };

                    axios
                        .post(
                            apiDomain + "project/site_contractor.set_active_contractor/" + this.model.site_id,
                            site_contractor_model,
                            { headers }
                        )
                        .then((response) => {
                            this.$notify({
                                message:
                                    '<b>Site Contractor Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        })
                        .catch((error) => {
                            this.errorFunction(error, "Set Active Site Contractor")
                        });
                }
            },
            saveSiteAdditionalInfo() {
                // CAREFUL WITH PUT METHOD, MIGHT NEED TO CHANGE TO POST WITH _put
                axios.put(apiDomain + 'project/project_site_additional_info/' + this.model.site_id, this.additional_model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                            this.getDashboardAdditionalInfo();
                            // this.$router.back()
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Additional Information")
                    });

            },
            approveToCloseTurnOnChecking()
            {
                const dummy_model = {
                    dummy_data: 0
                };

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_additional_info.approve_to_close/" + this.model.site_id,
                        dummy_model,
                        { headers }
                    )
                    .then((response) => {
                        this.$notify({
                            message:
                                '<b>Turn On Checking Approval To Close Successfully Approved.</b>',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                        //   this.$router.back()
                        this.getDashboardAdditionalInfo();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Approve To Close Turn On Checking")
                    });
            },
            saveSiteMilestoneData() {
                // this.site_milestones

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site_milestone.update_site_milestones/" + this.model.site_id,
                        this.site_milestones,
                        { headers }
                    )
                    .then((response) => {
                        this.getProjectSiteMilestoneData();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Update Site Milestones Attachment");
                    });

            },
            dataPointSelectionHandler(e, chartContext, config) {
                //   this.tempPieDataCNI = [config.seriesIndex, config.dataPointIndex];
                this.selectedDataPointIndex = config.dataPointIndex;
                this.total_actual_date = this.site_milestones[config.dataPointIndex].total_actual_day;
                this.isSiteMilestoneVisible = true;
            },
            closeAndUpdateGanttChart() {
                this.updateGanttChartSeries();
               // this.saveSiteMilestoneData();
                this.isSiteMilestoneVisible = false;
            },
            getSiteAttachmentListing() {
                axios
                    .get(apiDomain + "project/project_site.site_attachment/" + this.model.site_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.site_attachments = response.data.data;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Site Attachment Listing");
                    });
            },
            onChange(event) {
                this.site_attachment.file = event.target.files ? event.target.files[0] : null;
            },
            uploadFile() {
                let formData = jsonFormData(this.site_attachment);

                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/project_site.upload_site_attachment/" + this.model.site_id,
                        formData,
                        { headers }
                    )
                    .then((response) => {
                        // NOTIFY SUCCESSFUL UPLOAD HERE
                        this.getSiteAttachmentListing();
                        this.site_attachment.file = null;
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Upload Site Attachment");
                    });
            },
            downloadFile(apiEndpoint, filename) {
                axios.get(apiDomain + apiEndpoint, { headers: getHeader(),  responseType: 'blob'})
                    .then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', filename);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                    });
            },
            deleteFile(site_file_id) {
                axios.get(apiDomain  + "project/project_site.delete_site_attachment/" + site_file_id, { headers: getHeader()})
                    .then((response) => {
                        this.getSiteAttachmentListing();
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Delete Site Attachment");
                    });
            },
            onChangeProjectSiteTask(event){
                this.project_task_file = event.target.files ? event.target.files[0] : null;

            },
            loadProjectTaskSheetNames() {
                if (typeof(this.$refs.excelRead1._workbook) !== "undefined") {
                    // this.sheets = this.$refs.excelRead1._workbook.Workbook.Sheets;
                    this.projectTaskSheets = this.$refs.excelRead1._workbook.Workbook.Sheets.filter(item => item.name == "Task_Table1");
                }
            },
            updateProjectTaskRangeToSelect () {
                if (typeof(this.$refs.excelRead1._workbook) !== "undefined" && this.selected_project_task_sheet != 'x') {
                    const original_range = this.$refs.excelRead1._workbook.Sheets[this.selected_project_task_sheet]['!ref'];
                    this.selected_project_task_range = 'A1:' + original_range.substring(3);
                    this.selected_project_task_range = this.selected_project_task_range.replace(/:(.*?)(?=\d)/, ':J');
                }

            },
            showUploadingProjectTaskData(){
                this.projectTaskTempData = this.$refs.excelJson1.$data.collection;
            },
            uploadProjectSiteTask() {
                this.updateProjectTaskRangeToSelect();
                this.showUploadingProjectTaskData();
               // this.convertExcelDateTime();

                const post_data = {
                    data: this.projectTaskTempData
                }


                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));
                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };

                axios
                    .post(
                        apiDomain + "project/ms_project_import_from_upload.from_json/"+ this.model.site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        if (response.status === 200) {

                            this.getGanttChartData();

                            this.$notify({
                                message:
                                '<b>Update Project Site Task : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        }

                    })
                    .catch((error) => {

                        this.$notify({
                            message: "</b>Import New Project File",
                            timeout: 10000,
                            icon: "ni ni-bulb-61",
                            type: "danger",
                        });
                        this.errorFunction(error);
                    });

            },
            getDaysDifference(startDate, endDate) {

                if(startDate != "N/A" || endDate != "N/A"){
                    // Convert the date strings to Date objects
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    // Calculate the difference in milliseconds between the two dates
                    const differenceInMilliseconds = endDateObj - startDateObj;

                    // Convert milliseconds to days
                    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                    // Round the difference to remove decimal places
                    return Math.round(differenceInDays)+1;
                }else{
                    return 0;
                }
            },
            calculateTotalPlannedDay(index,field,inputValue = null){

                let plannedStartDate = this.site_milestones[index].planned_start_date;
                let plannedEndDate = this.site_milestones[index].planned_end_date;
                let totalPlannedDate = this.site_milestones[index].total_planned_day;

                let actualStartDate = this.site_milestones[index].actual_start_date;
                let actualEndDate = this.site_milestones[index].actual_end_date;
                let totalActualDate =  (inputValue !== null) ? inputValue : this.total_actual_date;

                let newDate = null;
                let convertDate = null;

                const millisecondsInOneDay = 1000 * 60 * 60 * 24;

                if(field == 'total_planned_day'){
                    if(plannedStartDate !== null && totalPlannedDate !== null){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate == null && plannedEndDate !== null && totalPlannedDate !== null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }

                }else if(field == 'planned_start_date'){
                    if(plannedStartDate !== null && totalPlannedDate > 0){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'planned_end_date'){
                    if(plannedEndDate !== null && totalPlannedDate > 0 && plannedStartDate == null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'total_actual_day'){
                    console.log(actualStartDate);
                    console.log(totalActualDate);
                    console.log(actualEndDate);
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate !== null){
                        console.log('is here')
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        console.log(convertDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate  == '<empty string>' && actualStartDate == null && actualEndDate !== null && totalActualDate !== null){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }

                }else if(field == 'actual_start_date'){
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate > 0){
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }else if(field == 'actual_end_date'){
                    if(actualEndDate !== null && totalActualDate > 0 && (actualStartDate == null  || actualStartDate  == '<empty string>')){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }else if(actualStartDate !== '<empty string>' && actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }

            },
            specificCalculateActualDate(event){
                this.site_milestones[this.selectedDataPointIndex].total_actual_day = event;
                this.calculateTotalPlannedDay(this.selectedDataPointIndex,'total_actual_day',event);
            },
            convertDateTime(date){
                let convertDate = new Date(date);
                const year = convertDate.getFullYear();
                const month = convertDate.getMonth();
                const day = convertDate.getDate();

                return convertDate.toISOString().slice(0,10);
            },
            excelDateToJSDate(serial) {
                var utc_days = Math.floor(serial - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);

                var fractional_day = serial - Math.floor(serial) + 0.0000001;

                var total_seconds = Math.floor(86400 * fractional_day);

                var seconds = total_seconds % 60;

                total_seconds -= seconds;

                var hours = Math.floor(total_seconds / (60 * 60));
                var minutes = Math.floor(total_seconds / 60) % 60;

                return new Date(
                    date_info.getFullYear(),
                    date_info.getMonth(),
                    date_info.getDate(),
                    hours,
                    minutes,
                    seconds
                );
            },
            convertToDateOnly(inputDateTime) {
                const current = inputDateTime;
                const currentMonth = String(current.getMonth() + 1).padStart(2, "0");
                const currentDay = String(current.getDate()).padStart(2, "0");
                const currentDate =
                    current.getFullYear() + "-" + currentMonth + "-" + currentDay;
                return currentDate;
            },
            convertExcelDateTime() {
                this.projectTaskTempData.forEach((e) => {
                    e["Start_Date"] = this.excelDateToJSDate(e["Start_Date"]);
                    e["Start_Date"] = this.convertToDateOnly(e["Start_Date"]);

                    e["Finish_Date"] = this.excelDateToJSDate(e["Finish_Date"]);
                    e["Finish_Date"] = this.convertToDateOnly(e["Finish_Date"]);

                    e["Actual_Start"] = (e["Actual_Start"] != "NA") ? this.excelDateToJSDate(e["Actual_Start"]) : e["Actual_Start"];
                    e["Actual_Start"] = (e["Actual_Start"] != "NA") ? this.convertToDateOnly(e["Actual_Start"]) : e["Actual_Start"];

                    e["Actual_Finish"] = (e["Actual_Finish"] != "NA") ? this.excelDateToJSDate(e["Actual_Finish"]) : e["Actual_Finish"];
                    e["Actual_Finish"] = (e["Actual_Finish"] != "NA") ? this.convertToDateOnly(e["Actual_Finish"]) : e["Actual_Finish"];
                });
            },
            getGanttChartData(){
                axios.get(apiDomain + 'project/ms_task_main_level/'+this.model.site_id, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.ms_upload_task = response.data.data;
                            this.updateGanttChartSeries();
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
            }

        }
    };
</script>
<style>

    .bg-custom {
        background-color: #EFF7EA;
    }

    .card-border-diagonal {
        /* border: 2px solid #BDDBA9; */
        border-radius: 100px 0 100px 0;
        /* margin: 20px; */
        padding: 10px;
        /* max-width: 300px; */
    }

    .card-border-inner {
        border: 1px solid #ADB5BD;
        border-radius: 50px 50px 50px 50px;
        /* margin: 20px; */
        padding: 10px;
        /* max-width: 300px; */
    }

    .form-custom-css .form-control:disabled {
        border: none !important;
        font-weight: bold;
        background-color: transparent !important;
        appearance: none !important;
        -webkit-appearance: none;
        background-image: none !important;
    }

    .form-custom-css .form-control {
        border: 2px solid #BDDBA9 !important;
        border-radius: 0 !important;
        color: black !important;
    }

    .form-custom-css .form-control-label {
        color: #525f7f !important;
        font-size: .875rem !important;
        font-weight: 600 !important;
    }

    /* .form-custom-css-grey .form-control:disabled { */
    /* border: none !important; */
    /* font-weight: bold; */
    /* background-color: transparent !important; */
    /* appearance: none !important; */
    /* -webkit-appearance: none; */
    /* background-image: none !important; */
    /* } */

    .form-custom-css-grey .form-control {
        border: 2px solid #BDDBA9 !important;
        border-radius: 0 !important;
        color: black !important;
    }

    .form-custom-css-grey .form-control-label {
        color: #525f7f !important;
        font-size: .875rem !important;
        font-weight: 600 !important;
    }

    .form-label {
        color: #525f7f !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        margin-bottom: 5px !important;
    }

    .form-content {
        font-size: 16px !important;
        font-weight: bold !important;
        color: black !important;
        margin-top: 5px !important;
    }

    .b-col-centered {
        display: flex;
        justify-content: center;
    }

    .scroll-follower{
        position: fixed;
        transform: translateY(20%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .scroll-follower .base-button {
        width: 100%;
        text-align: center;
    }

    .scroll-follower-right{
        position: fixed;
        width: 13%;
        transform: translateY(5%);
        /* display: flex; */
        /* flex-direction: column; */
        /* align-items: center; */
    }

    .h2-key-contacts {
        font-weight: 700 !important;
        color: black !important;
    }

    .form-label-key-contacts {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: black !important;
        margin-bottom: 5px !important;
    }

    .form-content-key-contacts {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: black !important;
        margin-top: 5px !important;
    }

    .button-css {
        border-radius: 10px;
        background: transparent;
        color: #3E5C2B;
    }

</style>
