<template>
  <div class="bg-default-dashboard" >
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <!-- <div class="header-body "  > -->
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">C&I Project Site List</h1>
          </b-col>
        </b-row>

        <!--NEW GRID START HERE-->
        <b-row class="mt-0" style="width: 100%;">
          <b-col md="12">
            <div class="row" style="width: 100%;">
              <dataset style="width: 100%;" v-slot="{ ds }" :ds-data="tableData"
                       :ds-sortby="['project.project_name', 'site_name', 'site_address', 'site_postal_code', 'site_total_capacity']"
                       :ds-search-in="['project.project_name', 'site_name', 'site_address', 'site_postal_code', 'site_total_capacity']">
                <div class="row" style="padding-bottom: 15px;" :data-page-count="ds.dsPagecount">
                  <div class="col-md-6 mb-2 mb-md-0"
                       style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                    <dataset-show class="dataset-style"/>
                  </div>
                  <div class="col-md-6">
                    <dataset-search ds-search-placeholder="Search..." />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table style="width: 100%;" class="table table-striped d-md-table">
                        <thead
                                style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid #082A2F;">
                        <tr>
                          <!-- <th scope="col">#</th> -->
                          <th v-for="(th, index) in obj_pagination.cols" :key="th.field" :class="['sort', th.sort]"
                              @click="click($event, index)">
                            {{ th.name }} <i class="gg-select float-right"></i>
                          </th>
                        </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr
                                    style="background-color: #23454A; color: white; font-size: smaller; border-bottom:  5px solid #082A2F;">
                              <!-- <th scope="row">{{ rowIndex + 1 }}</th> -->
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.project.project_name }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.site_name }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.site_address }}</td>
                              <td class="tr-td-table-new" style="min-width: 50px;">{{ row.site_postal_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.site_total_capacity }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #46D275;" v-if="row.status.status_id == 16 || row.status.status_id == 17 || row.status.status_id == 5 || row.status.status_id == 7"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF3232;" v-if="row.status.status_id == 8 || row.status.status_id == 9 || row.status.status_id == 10 || row.status.status_id == 11 || row.status.status_id == 3 || row.status.status_id == 6"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF9A3D;" v-if="row.status.status_id == 12 || row.status.status_id == 13 || row.status.status_id == 14 || row.status.status_id == 15 || row.status.status_id == 2"></i>
                                {{ row.status.status_code }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 50px;">
                                <div  style="margin-left: 40px">
                                  <b-link :to="`/ReviewerDocumentDetail/${row.site_id}`" class="btn btn-sm btn-secondary rounded-pill text-white" target="_blank">
                                    <div class="icon-file-line-cs ml-1 mr-1"></div>
                                  </b-link>
                                  <b-link :to="`/projectSiteDashboardV2/${row.site_id}`" class="btn btn-sm btn-secondary rounded-pill text-white" target="_blank">
                                    <div class="icon-pencil-cs ml-1 mr-1"></div>
                                  </b-link>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                     style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                  <dataset-info class="mb-2 mb-md-0" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>
          </b-col>
        </b-row>

        <!--NEW GRID END HERE-->




      </b-container>

    </div>


  </div>
</template>
<script>
    import { Select, Option,  Table, TableColumn, } from 'element-ui'
    import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
    import jsonFormData from 'json-form-data';
    import { BasePagination } from "@/components";
    import standardPaginationMix from "@/assets/js/standardPaginationMix.js";
    import Fuse from "fuse.js";
    import axios from "axios";
    import XlsxRead from "vue-xlsx/dist/components/XlsxRead";
    import XlsxTable from "vue-xlsx/dist/components/XlsxTable";
    import XlsxJson from "vue-xlsx/dist/components/XlsxJson";
    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
    } from "@/assets/js/config.js";

    import {
        Dataset,
        DatasetItem,
        DatasetInfo,
        DatasetPager,
        DatasetSearch,
        DatasetShow
    } from 'vue-dataset';

    export default {
        mixins: [standardPaginationMix],
        name :"Contractor Landing",
        components: {
            BasePagination,
            RouteBreadCrumb,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Dataset,
            DatasetItem,
            DatasetInfo,
            DatasetPager,
            DatasetSearch,
            DatasetShow,
            XlsxRead,
            XlsxTable,
            XlsxJson,
        },
        created() {
            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;
            var project_site_access = JSON.parse(
                window.localStorage.getItem("project_site_access")
            );
            this.tempChecker = project_site_access;
            this.button_access.new_button = project_site_access.access_create;
            this.button_access.view_button = project_site_access.access_show;
            if (this.myUserID.role_id == 12) {
                this.button_access.view_button = "YES";
            }
            this.getStartUpData();
        },
        data() {
            return {
                fields: [
                    { key: 'project_name', label: 'Project Name' },
                    { key: 'site_name', label: 'Site Name' },
                    { key: 'address', label: 'Address' },
                    { key: 'capacity', label: 'Capacity' },
                    { key: 'action', label: 'Action' },

                ],
                items: [
                    {
                        id:0,
                        project_name:"PLTS IKN",
                        site_name : "Site 01",
                        address : "Desa Pemaluan, Kecamatan Sepaku, Kabupaten Penajam Paser Utara, Provinsi Kalimantan Timur-76147",
                        capacity:"50000 kWp",
                    },
                    {
                        id:1,
                        project_name:"PLTS IKN 02",
                        site_name : "Site 02",
                        address : "Desa Pemaluan, Kecamatan Sepaku, Kabupaten Penajam Paser Utara, Provinsi Kalimantan Timur-76147",
                        capacity:"50000 kWp",
                    }
                ],
                filterOption :[
                    {
                        label: 5,
                        value: 5
                    },
                    {
                        label: 10,
                        value: 10
                    },
                    {
                        label: 25,
                        value: 25
                    },
                    {
                        label: 50,
                        value: 50
                    },
                ],
                obj_pagination: {
                    cols: [
                        {
                            name: 'Project Name',
                            field: 'project.project_name',
                            sort: ''
                        },
                        {
                            name: 'Site Name',
                            field: 'site_name',
                            sort: ''
                        },
                        {
                            name: 'Address',
                            field: 'site_address',
                            sort: ''
                        },
                        {
                            name: 'Postal Code',
                            field: 'site_postal_code',
                            sort: ''
                        },
                        {
                            name: 'Capacity',
                            field: 'site_total_capacity',
                            sort: ''
                        },
                        {
                            name: 'Status',
                            field: 'status.status_code',
                            sort: ''
                        },
                        {}
                    ],
                },
                selectedFilter:5,
                file_uploads:false,
                tempChecker: null,
                myUserID: null,
                propsToSearch: [
                    "site_code",
                    "site_name",
                    "project.project_code",
                    "group.group_code",
                    "site_leader.name",
                    "site_progress",
                    "status.status_code",
                ],
                tableColumns: [],
                tableData: [],
                selectedRows: [],
                button_access: {
                    new_button: null,
                    view_button: null,
                },
                indexToBeDeleted: [],
                buttonClicked: false,
                clickedRow: null,
                model: {
                    site_id: null,
                    project_id: null,
                    group_id: null,
                    site_name: null,
                    status_id: 'x',
                    site_address: null,
                    site_postal_code: null,
                    contractor_id: 'x',
                    site_code: null,
                    site_latitude: null,
                    site_longitude: null,
                    roof_handover_date: null, // CHECK WITH ANDREW
                    site_cod_date: null,
                    site_target_turn_on_date: null,
                    site_actual_turn_on_date: null,
                    site_total_capacity: null, // CHECK WITH ANDREW
                    site_as_built_capacity: null,
                    site_drawing_module_brand_id: 'x',
                    site_drawing_module_quantity: 0,
                    site_drawing_module_capacity: 0,
                    site_drawing_total_capacity: 0,
                    site_drawing_ifa_date: null,
                    site_drawing_ifc_date: null,
                    site_module_brand_id: 'x',
                    site_module_quantity: 0,
                    site_module_capacity: 0,
                    site_module_allocation_total_capacity: 0,
                    site_ifc_ifa_module_remarks: null,
                    milestone_template_id: 'x',
                },
                filter: {
                    developers: [],
                    project_name: null,
                    project_ppa_sign_date_from_date: null,
                    project_ppa_sign_date_to_date: null,
                    project_ppa_capacity_greater_equal: null,
                    project_ppa_capacity_lesser_equal: null,

                    project_type: [],
                    project_managers: [],
                    project_engineers: [],
                    project_business_development: [],
                    project_safety: [],

                    site_name: null,
                    project_site_contractors: [],
                    project_site_statuses: [],
                    site_postal_code: null,
                    site_code: null,
                    roof_handover_date_from_date: null,
                    roof_handover_date_to_date: null,
                    site_cod_date_from_date: null,
                    site_cod_date_to_date: null,
                    site_target_turn_on_date_from_date: null,
                    site_target_turn_on_date_to_date: null,
                    site_actual_turn_on_date_from_date: null,
                    site_actual_turn_on_date_to_date: null,
                    site_total_capacity_greater_equal: null,
                    site_total_capacity_lesser_equal: null,
                    site_as_built_capacity_greater_equal: null,
                    site_as_built_capacity_lesser_equal: null,
                    site_module_brand_ids: [],
                    site_module_quantity_greater_equal: null,
                    site_module_quantity_lesser_equal: null,
                    site_module_capacity_greater_equal: null,
                    site_module_capacity_lesser_equal: null,
                    site_module_allocation_total_capacity_greater_equal: null,
                    site_module_allocation_total_capacity_lesser_equal: null,
                    site_drawing_module_brand_ids: [],
                    site_drawing_module_quantity_greater_equal: null,
                    site_drawing_module_quantity_lesser_equal: null,
                    site_drawing_module_capacity_greater_equal: null,
                    site_drawing_module_capacity_lesser_equal: null,
                    site_drawing_total_capacity_greater_equal: null,
                    site_drawing_total_capacity_lesser_equal: null,
                    site_drawing_ifa_date_from_date: null,
                    site_drawing_ifa_date_to_date: null,
                    site_drawing_ifc_date_from_date: null,
                    site_drawing_ifc_date_to_date: null,
                    ntp_applicable: null,
                    ntp_issued: null,
                    ntp_issue_date_from_date: null,
                    ntp_issue_date_to_date: null,
                    internal_project_handover_completed: null,
                    internal_project_handover_date_from_date: null,
                    internal_project_handover_date_to_date: null,
                    kick_off_meeting_completed: null,
                    kick_off_meeting_date_from_date: null,
                    kick_off_meeting_date_to_date: null,
                },
                project_task_file: null,
                tempData: null,
                projectTaskTempData: null,
                converted_temp_data: null,
                converted_temp_project_task_data: null,
                sheets: [
                    // {
                    //     name: "Stage_1 Technical Info (ENG)",
                    // },
                    // {
                    //     name: "Stage_1 Project Info (BD)",
                    // },
                ],
                projectTaskSheets:[],
                selected_project_task_sheet:'x',
                selected_project_task_range:'',
                selected_sheet: 'x',
                selected_range: '',
                postResponse: null,
                site_attachments: [],
                selected_site_id : '0',
                selected_site_name : '',
                ms_upload_task: [],
            };
        },
        filters: {
            formatDate(value) {
                if (value) {
                    const dateParts = value.split("-");
                    const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                    return formattedDate;
                }
                return "N/A"; // Return an empty string if the date is not available or invalid
            },
        },
        methods: {
            showModal(paramID,paramSiteName) {
                this.selected_site_id = paramID;
                this.selected_site_name = paramSiteName;
                this.$refs.myModalRef.show();
                //this.$refs['my-modal'].show()
            },
            triggerFileInput() {
                // Trigger a click on the file input element
                this.$refs.file1.click();
            },
            getStartUpData() {
                this.filter.project_type.push(1);
                axios.post(apiDomain + "project/project_site.show_by_filter", this.filter, {
                    headers: getHeader(),
                })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.tableData = response.data.data;
                            this.fuseData();
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Search Project Site Listing")
                    });
            },
            fuseData() {
                this.fuseSearch = new Fuse(this.tableData, {
                    keys: this.propsToSearch,
                    threshold: 0.3,
                });
            },
            paginationChanged(page) {
                this.pagination.currentPage = page;
            },
            handleDetails(index, row) {
                this.$router.push({
                    path: "projectSiteDashboardContractor/" + row.site_id,
                });
            },
            rowClicked(row) {
                if (this.button_access.view_button == "YES") {
                    this.buttonClicked = true;
                    this.clickedRow = row;
                    this.$router.push({
                        path: "projectSiteDashboardContractor/" + row.site_id,
                    });
                }
            },
            selectionChange(selectedRows) {
                this.selectedRows = selectedRows;
            },
            deleteApprovedListing() {
                this.tableData.forEach((item) => {
                    if (item.status.status_id == 7) {
                        this.indexToBeDeleted.push(this.tableData.indexOf(item));
                    }
                });
                this.indexToBeDeleted.reverse();
                this.indexToBeDeleted.forEach((item) => {
                    this.tableData.splice(item, 1);
                });
            },
            getDaysDifference(startDate, endDate) {
                if(startDate != "N/A" || endDate != "N/A"){
                    // Convert the date strings to Date objects
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    // Calculate the difference in milliseconds between the two dates
                    const differenceInMilliseconds = endDateObj - startDateObj;

                    // Convert milliseconds to days
                    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                    // Round the difference to remove decimal places
                    return Math.round(differenceInDays)+1;
                }else{
                    return 0;
                }
            },
            calculateTotalPlannedDay(index,field,inputValue = null){
                let plannedStartDate = this.site_milestones[index].planned_start_date;
                let plannedEndDate = this.site_milestones[index].planned_end_date;
                let totalPlannedDate = this.site_milestones[index].total_planned_day;

                let actualStartDate = this.site_milestones[index].actual_start_date;
                let actualEndDate = this.site_milestones[index].actual_end_date;
                let totalActualDate =  (inputValue !== null) ? inputValue : this.total_actual_date;

                let newDate = null;
                let convertDate = null;

                console.log(plannedStartDate)
                console.log(plannedEndDate)
                console.log(totalPlannedDate)

                console.log(actualStartDate)
                console.log(actualEndDate)
                console.log(totalActualDate)

                const millisecondsInOneDay = 1000 * 60 * 60 * 24;

                if(field == 'total_planned_day'){
                    if(plannedStartDate !== null && totalPlannedDate !== null){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate == null && plannedEndDate !== null && totalPlannedDate !== null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }

                }else if(field == 'planned_start_date'){
                    if(plannedStartDate !== null && totalPlannedDate > 0){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'planned_end_date'){
                    if(plannedEndDate !== null && totalPlannedDate > 0 && plannedStartDate == null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'total_actual_day'){
                    console.log(actualStartDate);
                    console.log(totalActualDate);
                    console.log(actualEndDate);
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate !== null){
                        console.log('is here')
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        console.log(convertDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate  == '<empty string>' && actualStartDate == null && actualEndDate !== null && totalActualDate !== null){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }

                }else if(field == 'actual_start_date'){
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate > 0){
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }else if(field == 'actual_end_date'){
                    if(actualEndDate !== null && totalActualDate > 0 && (actualStartDate == null  || actualStartDate  == '<empty string>')){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }else if(actualStartDate !== '<empty string>' && actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }
            },
            specificCalculateActualDate(event){
                this.site_milestones[this.selectedDataPointIndex].total_actual_day = event;
                this.calculateTotalPlannedDay(this.selectedDataPointIndex,'total_actual_day',event);
            },
            convertDateTime(date){
                let convertDate = new Date(date);
                const year = convertDate.getFullYear();
                const month = convertDate.getMonth();
                const day = convertDate.getDate();

                return convertDate.toISOString().slice(0,10);
            },
            onChangeProjectSiteTask(event){
                this.project_task_file = event.target.files ? event.target.files[0] : null;

            },
            loadProjectTaskSheetNames() {
                if (typeof(this.$refs.excelRead1._workbook) !== "undefined") {
                    this.projectTaskSheets = this.$refs.excelRead1._workbook.Workbook.Sheets;
                    //this.projectTaskSheets = this.$refs.excelRead1._workbook.Workbook.Sheets.filter(item => item.name == "Task_Table1");
                }
            },
            updateProjectTaskRangeToSelect () {
                if (typeof(this.$refs.excelRead1._workbook) !== "undefined" && this.selected_project_task_sheet != 'x') {
                    const original_range = this.$refs.excelRead1._workbook.Sheets[this.selected_project_task_sheet]['!ref'];
                    this.selected_project_task_range = 'A1:' + original_range.substring(3);
                    this.selected_project_task_range = this.selected_project_task_range.replace(/:(.*?)(?=\d)/, ':J');
                }
            },
            showUploadingProjectTaskData(){
                this.projectTaskTempData = this.$refs.excelJson1.$data.collection;
            },
            uploadProjectSiteTask() {
                this.updateProjectTaskRangeToSelect();
                this.showUploadingProjectTaskData();
                const post_data = {
                    data: this.projectTaskTempData
                }


                const tokenData = JSON.parse(window.localStorage.getItem("authUser"));

                const headers = {
                    Authorization: "Bearer " + tokenData.access_token,
                };
                axios
                    .post(
                        apiDomain + "project/ms_project_import_from_upload.from_json/"+ this.selected_site_id,
                        post_data,
                        { headers }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$refs.myModalRef.hide();
                            this.$notify({
                                message:
                                '<b>Update Project Site Task : ' + this.selected_site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        }

                    })
                    .catch((error) => {
                        this.$notify({
                            message: "</b>Update is failed",
                            timeout: 10000,
                            icon: "ni ni-bulb-61",
                            type: "danger",
                        });
                        this.errorFunction(error);
                    });
            },
            getDaysDifference(startDate, endDate) {

                if(startDate != "N/A" || endDate != "N/A"){
                    // Convert the date strings to Date objects
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    // Calculate the difference in milliseconds between the two dates
                    const differenceInMilliseconds = endDateObj - startDateObj;

                    // Convert milliseconds to days
                    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
                    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

                    // Round the difference to remove decimal places
                    return Math.round(differenceInDays)+1;
                }else{
                    return 0;
                }
            },
            calculateTotalPlannedDay(index,field,inputValue = null){

                let plannedStartDate = this.site_milestones[index].planned_start_date;
                let plannedEndDate = this.site_milestones[index].planned_end_date;
                let totalPlannedDate = this.site_milestones[index].total_planned_day;

                let actualStartDate = this.site_milestones[index].actual_start_date;
                let actualEndDate = this.site_milestones[index].actual_end_date;
                let totalActualDate =  (inputValue !== null) ? inputValue : this.total_actual_date;

                let newDate = null;
                let convertDate = null;

                const millisecondsInOneDay = 1000 * 60 * 60 * 24;

                if(field == 'total_planned_day'){
                    if(plannedStartDate !== null && totalPlannedDate !== null){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate == null && plannedEndDate !== null && totalPlannedDate !== null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }

                }else if(field == 'planned_start_date'){
                    if(plannedStartDate !== null && totalPlannedDate > 0){
                        plannedStartDate  = new Date(plannedStartDate);
                        newDate = new Date(plannedStartDate.getTime() + totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_end_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'planned_end_date'){
                    if(plannedEndDate !== null && totalPlannedDate > 0 && plannedStartDate == null){
                        plannedEndDate  = new Date(plannedEndDate);
                        newDate = new Date(plannedEndDate.getTime() - totalPlannedDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].planned_start_date = convertDate;
                    }else if(plannedStartDate !== null && plannedEndDate !== null){
                        totalPlannedDate = this.getDaysDifference(plannedStartDate,plannedEndDate);
                        this.site_milestones[index].total_planned_day = totalPlannedDate;
                    }
                }else if(field == 'total_actual_day'){
                    console.log(actualStartDate);
                    console.log(totalActualDate);
                    console.log(actualEndDate);
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate !== null){
                        console.log('is here')
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        console.log(convertDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate  == '<empty string>' && actualStartDate == null && actualEndDate !== null && totalActualDate !== null){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }

                }else if(field == 'actual_start_date'){
                    if(actualStartDate  != '<empty string>' && actualStartDate !== null && totalActualDate > 0){
                        actualStartDate  = new Date(actualStartDate);
                        newDate = new Date(actualStartDate.getTime() + totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_end_date = convertDate;
                    }else if(actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }else if(field == 'actual_end_date'){
                    if(actualEndDate !== null && totalActualDate > 0 && (actualStartDate == null  || actualStartDate  == '<empty string>')){
                        actualEndDate  = new Date(actualEndDate);
                        newDate = new Date(actualEndDate.getTime() - totalActualDate*millisecondsInOneDay);
                        convertDate = this.convertDateTime(newDate);
                        this.site_milestones[index].actual_start_date = convertDate;
                    }else if(actualStartDate !== '<empty string>' && actualStartDate !== null && actualEndDate !== null){
                        totalActualDate = this.getDaysDifference(actualStartDate,actualEndDate);
                        this.site_milestones[index].total_actual_day = totalActualDate;
                    }
                }
            },
            specificCalculateActualDate(event){
                this.site_milestones[this.selectedDataPointIndex].total_actual_day = event;
                this.calculateTotalPlannedDay(this.selectedDataPointIndex,'total_actual_day',event);
            },
            excelDateToJSDate(serial) {
                var utc_days = Math.floor(serial - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);

                var fractional_day = serial - Math.floor(serial) + 0.0000001;

                var total_seconds = Math.floor(86400 * fractional_day);

                var seconds = total_seconds % 60;

                total_seconds -= seconds;

                var hours = Math.floor(total_seconds / (60 * 60));
                var minutes = Math.floor(total_seconds / 60) % 60;

                return new Date(
                    date_info.getFullYear(),
                    date_info.getMonth(),
                    date_info.getDate(),
                    hours,
                    minutes,
                    seconds
                );
            },
            convertToDateOnly(inputDateTime) {
                const current = inputDateTime;
                const currentMonth = String(current.getMonth() + 1).padStart(2, "0");
                const currentDay = String(current.getDate()).padStart(2, "0");
                const currentDate =
                    current.getFullYear() + "-" + currentMonth + "-" + currentDay;
                return currentDate;
            },
            convertExcelDateTime() {
                this.projectTaskTempData.forEach((e) => {
                    e["Start_Date"] = this.excelDateToJSDate(e["Start_Date"]);
                    e["Start_Date"] = this.convertToDateOnly(e["Start_Date"]);

                    e["Finish_Date"] = this.excelDateToJSDate(e["Finish_Date"]);
                    e["Finish_Date"] = this.convertToDateOnly(e["Finish_Date"]);

                    e["Actual_Start"] = (e["Actual_Start"] != "NA") ? this.excelDateToJSDate(e["Actual_Start"]) : e["Actual_Start"];
                    e["Actual_Start"] = (e["Actual_Start"] != "NA") ? this.convertToDateOnly(e["Actual_Start"]) : e["Actual_Start"];

                    e["Actual_Finish"] = (e["Actual_Finish"] != "NA") ? this.excelDateToJSDate(e["Actual_Finish"]) : e["Actual_Finish"];
                    e["Actual_Finish"] = (e["Actual_Finish"] != "NA") ? this.convertToDateOnly(e["Actual_Finish"]) : e["Actual_Finish"];
                });
            },
        },
        //mounted() {
        // this.generateData()
        // console.table(this.data)

        //}
    };
</script>
<style scoped>
  /* Class For Modal */
  .modal-content {
    background-color: #306b74;
  }

  table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #3a656b;
    color: white;
    padding: 20px;
    border-bottom: 0px solid #082A2F;
    border-top: 0px solid #e9ecef;
  }


  .th-table-new {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 20px;
  }
  .tr-td-table-new{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
  }
  .form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  &:focus {
     background-color: #2C545A;
     border-color: #2C545A;
     color: white;
   }
  &::placeholder {
     color: white;
   }
  }
  .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }

  .input-group-text {
    background-color: #2C545A;;
    color: white;
  &:focus {
     background-color: #2C545A;;
     border-color: #1D4147;
     color: white;
   }
  }

  .focused {
  .input-group-text {
    background-color: #2C545A;
  }
  }
  .navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search .input-group {
    border-radius: 7px;
  }


  .el-table th.el-table__cell {

    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
    /* border-bottom: 20px solid #082A2F; */
  }
  .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
  }
  div.el-table .el-table__row {
    background: #3a656b;
    border-bottom: 0 solid #082A2F;


  }
  div.el-table .el-table__row:hover {
    background: #3a656b;
  }

  .el-table--border::after, .el-table--group::after, .el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
  }

</style>

