<template>
    <div class="bg-default-dashboard">
        <div class=" py-lg-8 pt-lg-5">
            <b-container fluid style="width: 90%; position: relative;">
                <!-- filters -->
                <!-- maps -->
                <b-row>
                    <b-col>
                        <b-card no-body class="border-0">
                            <div id="map-custom" class="map-canvas" style="height: 800px;" ref="mapRef"></div>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div class="text-right ">
                            <label class="text-white mr-2 h3 custom-control-label-tmp">Display SKPT</label>
                            <base-switch v-model="display_SKPT" :value="true" :unchecked-value="false" class="mr-2 " on-text="" off-text="" @change="toggleSKPT(display_SKPT)"></base-switch>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="text-right ">
                            <label class="text-white mr-2 h3 custom-control-label-tmp">Display Solar Layout</label>
                            <base-switch v-model="display_solarlayout" :value="true" :unchecked-value="false" class="mr-2 " on-text="" off-text=""></base-switch>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="text-right ">
                            <label class="text-white mr-2 h3 custom-control-label-tmp">Display Land Owner</label>
                            <base-switch v-model="display_owner" :value="true" :unchecked-value="false" class="mr-2 " on-text="" off-text=""></base-switch>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="text-right ">
                            <label class="text-white mr-2 h3 custom-control-label-tmp">Display TL Route</label>
                            <base-switch v-model="display_TLRoute" :value="true" :unchecked-value="false" class="mr-2 " on-text="" off-text=""></base-switch>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="text-right ">
                            <label class="text-white mr-2 h3 custom-control-label-tmp">Display Contour Line</label>
                            <base-switch v-model="display_contour" :value="true" :unchecked-value="false" class="mr-2 " on-text="" off-text=""></base-switch>
                        </div>
                    </b-col>
                </b-row>
        <!-- filter -->
        <b-row>
          <b-col md="1" class="mt-1">
            <base-input>
              <select class="form-control" v-model="selectedCategory1">
                <option v-for="item of categories1" :key="item.id" :value="item.id" :label="item.code"></option>
              </select>
            </base-input>
          </b-col>
          <b-col md="1" class="mt-1">
            <base-input>
              <select class="form-control" v-model="selectedCategory2">
                <option v-for="item of categories2" :key="item.id" :value="item.id" :label="item.code"></option>
              </select>
            </base-input>
          </b-col>

          <b-col md="1" class="mt-1">
            <template v-if="setting.category3_enabled">
              <base-input>
                <select class="form-control" v-model="selectedCategory3">
                  <option v-for="item of categories3" :key="item.id" :value="item.id" :label="item.code"></option>
                </select>
              </base-input>
            </template>
          </b-col>
        </b-row>
        <!--Filter end here-->

        <!--NEW GRID STARTS HERE-->
        <b-row class="mt-0" style="width: 100%;">
          <b-col md="12">
            <div class="row" style="width: 100%;">
              <!-- <dataset v-slot="{ ds }" :ds-data="documents" :ds-sortby="sortBy" :ds-sort-as="{ birthdate: isoDateToDate }" -->
              <!-- <dataset v-slot="{ ds }" :ds-data="documents"
                :ds-sortby="['category1_code', 'category2_code', 'category3_code', 'number', 'title']" :ds-sort-as="[]"
                :ds-search-in="['category1_code', 'category2_code', 'category3_code', 'number', 'title', 'status']"
                :ds-search-as="[]"> -->
              <dataset style="width: 100%;" v-slot="{ ds }" :ds-data="documents"
                :ds-sortby="['category1_code', 'category2_code', 'category3_code', 'number', 'title']"
                :ds-search-in="['category1_code', 'category2_code', 'category3_code', 'number', 'title', 'status']">
                <!-- :ds-search-as="{ birthdate: searchAsEuroDate }"> -->
                <div class="row" style="padding-bottom: 15px;" :data-page-count="ds.dsPagecount">
                  <div class="col-md-6 mb-2 mb-md-0"
                    style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                    <dataset-show class="dataset-style"/>
                  </div>
                  <div class="col-md-6">
                    <dataset-search ds-search-placeholder="Search..."
                    :value="searchValue"
                    @input="updateSearchValue"
                    ref="myTextBox"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table style="width: 100%;" class="table table-striped d-md-table">
                        <thead
                          style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid #082A2F;">
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th v-for="(th, index) in obj_pagination.cols" :key="th.field" :class="['sort', th.sort]"
                              @click="click($event, index)">
                              {{ th.name }} <i class="gg-select float-right"></i>
                            </th>
                          </tr>
                        </thead>
                        <dataset-item tag="tbody">
                          <template #default="{ row, rowIndex }">
                            <tr
                              style="background-color: #23454A; color: white; font-size: smaller; border-bottom:  5px solid #082A2F;">
                              <!-- <th scope="row">{{ rowIndex + 1 }}</th> -->
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category1_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category2_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.category3_code }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.number }}</td>
                              <td class="tr-td-table-new" style="min-width: 150px;">{{ row.title }}</td>
                              <td class="tr-td-table-new" style="min-width: 100px;">
                                <i class="fa fa-xs fa-circle mr-1" style="color: #46D275;"
                                  v-if="row.status == 'APPROVED'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF9A3D;"
                                  v-if="row.status == 'PENDING_REVIEW'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #11CDEF;"
                                  v-if="row.status == 'NEW_REVIEW'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FF3232;"
                                  v-if="row.status == 'REJECTED'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: #FFD43D;"
                                  v-if="row.status == 'NEW_REVISION'"></i>
                                <i class="fa fa-xs fa-circle mr-1" style="color: white;"
                                  v-if="row.status == 'EMPTY'"></i>
                                <!-- <i class="fa fa-xs fa-circle mr-1"  v-else></i> -->
                                {{ row.status }}
                              </td>
                              <td class="tr-td-table-new" style="min-width: 100px;">
                                <div>
                      <!--
                          <b-link v-if="role_user=='contractor'" to="tracking" style="color: #FF5C00; font-size:large;" >
                            <svg width="23" height="25" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518" stroke="#FF5C00" stroke-width="1.5"/>
                              <path d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332" stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                          </b-link>-->
                      <template v-if="row.status == 'NEW_REVISION' || row.status == 'EMPTY'"><b-link
                          @click="showModal(row.id, row.status)" style="color: #FF5C00; font-size:large;">
                          <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                              stroke="#FF5C00" stroke-width="1.5" />
                            <path
                              d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                              stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round" />
                          </svg>
                        </b-link></template>
                        <template v-else-if="row.status == 'REJECTED' || row.status == 'APPROVED'">
                                    <b-link @click="showCompleteModal(row.id, row.status)"
                                      style="color: #FF5C00; font-size:large;">
                                      <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                                          stroke="#FF5C00" stroke-width="1.5" />
                                        <path
                                          d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                                          stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round" />
                                      </svg>
                                    </b-link>
                        </template>
                      <template v-else><b-link @click="showPasifModal(row.id, row.status)"
                          style="color: #FF5C00; font-size:large;">
                          <svg width="23" height="25" viewBox="0 0 28 30" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.2396 9.71982L21.857 9.10116C22.3485 8.60944 23.0152 8.33313 23.7105 8.33301C24.4058 8.33288 25.0726 8.60895 25.5643 9.10049C26.056 9.59202 26.3323 10.2588 26.3325 10.954C26.3326 11.6493 26.0565 12.3161 25.565 12.8078L24.9476 13.4265M21.2396 9.71982C21.2396 9.71982 21.317 11.0318 22.4756 12.1905C23.6343 13.3492 24.9476 13.4265 24.9476 13.4265M21.2396 9.71982L15.5596 15.3998C15.173 15.7838 14.981 15.9772 14.8156 16.1892C14.621 16.4398 14.4543 16.7092 14.317 16.9972C14.201 17.2398 14.1156 17.4972 13.9436 18.0132L13.393 19.6665L13.2143 20.2012M24.9476 13.4265L19.2676 19.1065C18.881 19.4932 18.689 19.6852 18.477 19.8505C18.2263 20.0452 17.957 20.2118 17.669 20.3492C17.4263 20.4652 17.169 20.5505 16.653 20.7225L14.9996 21.2732L14.465 21.4518M13.2143 20.2012L13.037 20.7372C12.9957 20.8616 12.9898 20.995 13.02 21.1226C13.0502 21.2502 13.1153 21.3668 13.208 21.4595C13.3007 21.5522 13.4173 21.6173 13.5449 21.6475C13.6724 21.6777 13.8059 21.6718 13.9303 21.6305L14.465 21.4518M13.2143 20.2012L14.465 21.4518"
                              stroke="#FF5C00" stroke-width="1.5" />
                            <path
                              d="M7.66667 16.3332H11M7.66667 10.9998H16.3333M7.66667 21.6665H9.66667M23.4373 3.22917C21.876 1.6665 19.3613 1.6665 14.3333 1.6665H11.6667C6.63867 1.6665 4.124 1.6665 2.56267 3.22917C1 4.7905 1 7.30517 1 12.3332V17.6665C1 22.6945 1 25.2092 2.56267 26.7705C4.124 28.3332 6.63867 28.3332 11.6667 28.3332H14.3333C19.3613 28.3332 21.876 28.3332 23.4373 26.7705C24.696 25.5132 24.94 23.6398 24.988 20.3332"
                              stroke="#FF5C00" stroke-width="1.5" stroke-linecap="round" />
                          </svg>
                        </b-link></template>
                    </div>
                              </td>
                            </tr>
                          </template>
                        </dataset-item>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                  style="color: #ffffff; font-family: Open Sans, sans-serif !important; font-size: 0.875rem;">
                  <dataset-info class="mb-2 mb-md-0" />
                  <dataset-pager />
                </div>
              </dataset>
            </div>
          </b-col>
        </b-row>

        <!--MODAL NEW REVISIONS-->
        <b-modal ref="contractor-modal" size="xl" centered hide-footer title="Revision" class="text-white">
          <b-row>
            <b-col>
              <label class="text-white" for="">Comment</label>
              <b-form-textarea v-model="revision_comment" id="textarea-no-resize" placeholder="" rows="3"
                no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col>

              <table style="width: 100%;">
                <template v-if="files.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">File to upload </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of files"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"> <i class="fa fa-file mr-1"></i> {{ file.name }}</td>
                      <td class="tr-td-table-modal-new"><a href="#" @click.prevent="downloadFile(file.id)">{{
                        formatSize(file.size) }}</a></td>

                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <b-link>
                            <i class="fa fa-trash" style="color: #FF3232; font-size:small;"
                              @click="removeFile(index)"></i>
                          </b-link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-if="uploadedFiles.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">Uploaded File </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of uploadedFiles"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1" @click="downloadFile(file.id)"></i>{{
                        file.filename }}</td>
                      <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <b-link>
                            <i class="fa fa-trash" style="color: #FF3232; font-size:small;"></i>
                          </b-link>
                        </div>
                      </td>
                    </tr>


                  </tbody>
                </template>
              </table>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="text-right">
                  <!--
                     <span class="text-white "><i class="fa fa-file mr-2" style="font-size: large;"></i>Testing.jpg <sup style="padding-left: 3px;">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1457_587)">
                        <path d="M13.6541 2.34121C12.9119 1.59736 12.03 1.00749 11.0591 0.605461C10.0883 0.203434 9.04757 -0.00282109 7.99676 -0.00145813C3.57809 -0.00145813 -0.00390625 3.58054 -0.00390625 7.99921C-0.00390625 10.2085 0.892094 12.2092 2.34009 13.6572C3.08231 14.4011 3.96418 14.9909 4.93504 15.393C5.9059 15.795 6.94662 16.0012 7.99743 15.9999C12.4161 15.9999 15.9981 12.4179 15.9981 7.99921C15.9981 5.78988 15.1021 3.78921 13.6541 2.34121ZM12.6261 12.6259C12.0189 13.2346 11.2974 13.7174 10.5031 14.0464C9.70875 14.3755 8.85722 14.5443 7.99743 14.5432C4.38209 14.5432 1.45143 11.6125 1.45143 7.99721C1.45035 7.13742 1.61918 6.28589 1.94822 5.49155C2.27726 4.69722 2.76002 3.97573 3.36876 3.36854C3.9758 2.75979 4.69717 2.27702 5.4914 1.94798C6.28563 1.61893 7.13707 1.45011 7.99676 1.45121C11.6114 1.45121 14.5421 4.38188 14.5421 7.99654C14.5432 8.85624 14.3744 9.70769 14.0454 10.5019C13.7163 11.2962 13.2335 12.0175 12.6248 12.6245L12.6261 12.6259Z" fill="#FF6060"/>
                        <path d="M9.02503 7.99983L11.595 5.42983C11.7218 5.29165 11.7903 5.10984 11.7862 4.92236C11.7822 4.73488 11.7059 4.55622 11.5732 4.42366C11.4406 4.2911 11.2619 4.2149 11.0744 4.21096C10.8869 4.20701 10.7051 4.27564 10.567 4.4025L10.5677 4.40183L7.99769 6.97183L5.42769 4.40183C5.28951 4.27506 5.10771 4.20656 4.92023 4.21062C4.73275 4.21469 4.55408 4.291 4.42152 4.42365C4.28897 4.55629 4.21276 4.73501 4.20882 4.92249C4.20488 5.10997 4.2735 5.29173 4.40036 5.42983L4.39969 5.42916L6.9697 7.99916L4.39969 10.5692C4.32739 10.6355 4.26925 10.7158 4.22878 10.8052C4.18832 10.8946 4.16636 10.9912 4.16423 11.0893C4.16211 11.1875 4.17985 11.285 4.21641 11.376C4.25296 11.4671 4.30756 11.5498 4.37693 11.6193C4.44629 11.6887 4.52899 11.7433 4.62003 11.7799C4.71107 11.8165 4.80858 11.8344 4.90669 11.8323C5.0048 11.8302 5.10147 11.8083 5.1909 11.7679C5.28032 11.7275 5.36064 11.6694 5.42703 11.5972L5.42769 11.5965L7.99769 9.0265L10.5677 11.5965C10.634 11.6688 10.7143 11.7269 10.8037 11.7674C10.8931 11.8079 10.9898 11.8298 11.0879 11.832C11.186 11.8341 11.2835 11.8163 11.3746 11.7798C11.4656 11.7432 11.5484 11.6886 11.6178 11.6193C11.6872 11.5499 11.7418 11.4672 11.7785 11.3762C11.8151 11.2851 11.8329 11.1876 11.8308 11.0895C11.8288 10.9914 11.8069 10.8947 11.7665 10.8053C11.726 10.7159 11.668 10.6356 11.5957 10.5692L11.595 10.5685L9.02503 7.99983Z" fill="#FF6060"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1457_587">
                        <rect width="16" height="16" fill="white"/>
                        </clipPath>
                        </defs>
                      </svg>
                      </sup>
                    </span>-->
                </b-col>
                <b-col class="text-right">
                  <br>
                  <input type="file" class="d-none" ref="file_upload" multiple @change="currentFileChanged($event)">
                  <b-button size="sm" variant="primary" @click="showFileDialog()">Choose File</b-button>
                  <!--
                    <b-button size="sm" variant="primary" @click="uploadFiles()">Upload File</b-button>
                    -->
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-right">
              <b-button size="md" variant="outline-danger" @click="hideModal">Cancel</b-button>
              <b-button size="md" variant="primary" @click="uploadFiles()">Submit for Review</b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!--END MODAL NEW REVISIONS-->

        <!--MODAL PASIF PENDING WITH SEMBCORP REVIEW-->
        <b-modal ref="contractor-pasif-modal" size="xl" centered hide-footer title="Revision" class="text-white">
          <b-row>
            <b-col>
              <label class="text-white" for="">Comment</label>
              <b-form-textarea v-model="revision_comment" :disabled="!isActive" id="textarea-no-resize" placeholder=""
                rows="3" no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col>

              <table style="width: 100%;">

                <template v-if="uploadedFiles.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">Uploaded File </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of uploadedFiles"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1"></i> {{ file.filename }}</td>
                      <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <a href="#" @click.prevent="downloadFile(file.id)">Download</a>
                        </div>
                      </td>
                    </tr>


                  </tbody>
                </template>
              </table>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="text-right">

                </b-col>
                <b-col class="text-right">
                  <br>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-right">
              <b-button size="md" variant="outline-danger" @click="hidePasifModal">Cancel</b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!--END MODAL PASIF PENDING WITH SEMBCORP REVIEW-->

        <!--MODAL PASIF COMPLETE-->
        <b-modal ref="complete-pasif-modal" size="xl" centered hide-footer title="Revision - Review" class="text-white">
          <b-row>
            <b-col>
              <label class="text-white" for="">{{this.review_detail}}</label>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label class="text-white" for="">Comment</label>
              <b-form-textarea v-model="review_comment" :disabled="!isActive" id="textarea-no-resize" placeholder=""
                rows="3" no-resize></b-form-textarea>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col>

              <table style="width: 100%;">

                <template v-if="uploadedFiles.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">REVISION File(s) </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of uploadedFiles"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1"></i> {{ file.filename }}</td>
                      <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <a href="#" @click.prevent="downloadFile(file.id)">Download</a>
                        </div>
                      </td>
                    </tr>

                    


                  </tbody>
                </template>

                <template v-if="review_uploadedFiles.length">
                  <thead
                    style="background-color: #23454A; color: white; font-size: small; border-bottom: 10px solid  #306b74;">
                    <th class="th-table-modal-new ">REVIEW File(s) </th>
                    <th class="th-table-modal-new ">Size</th>
                    <th class="th-table-modal-new "></th>
                  </thead>
                  <tbody>
                    <tr v-for="(file, index) of review_uploadedFiles"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  5px solid  #306b74;">
                      <td class="tr-td-table-modal-new"><i class="fa fa-file mr-1"></i> {{ file.filename }}</td>
                      <td class="tr-td-table-modal-new">{{ formatSize(file.size) }}</td>
                      <td class="tr-td-table-modal-new">
                        <div style="">
                          <a href="#" @click.prevent="downloadFile(file.id)">Download</a>
                        </div>
                      </td>
                    </tr>

                    


                  </tbody>
                </template>

              </table>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="text-right">

                </b-col>
                <b-col class="text-right">
                  <br>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-right">
              <b-button size="md" variant="primary" @click="showModalAfterComplete()">Create New Revision</b-button>
              <b-button size="md" variant="outline-danger" @click="hideCompleteModal()">Cancel</b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!--END MODAL PASIF COMPLETE-->
            </b-container>
        </div>
    </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';


import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
// import Card from "../../../components/Cards/Card.vue";

import { API_KEY } from '@/views/Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['geometry'];

import VueHighcharts from 'vue2-highcharts'
import proj4 from "proj4"

import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";

import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from 'vue-dataset';

//import axios from "axios";
import axios from '@/util/axios';
import { getAccessByModule } from '@/util/access';
import { formatBytesInUnits, documentStatusToText, revisionStatusToText } from './util'


export default {
    mixins: [ErrorFunctionMixin],
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        VueHighcharts,
        Dataset,
        DatasetItem,
        DatasetInfo,
        DatasetPager,
        DatasetSearch,
        DatasetShow,
    },

    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval);
        clearInterval(this.dataDateInterval);
    },
    created() {
        window.vm = this;
        // update the time every second
        this.interval = setInterval(() => {
            // Concise way to format time according to system locale.
            // In my case this returns "3:48:00 am"
            this.time = Intl.DateTimeFormat('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            }).format().replace(', ', ' | ');
        }, 1000)

        // Schedule checkAndUpdateDataDate every 10 minutes
        this.dataDateInterval = setInterval(() => {
            this.checkAndUpdateDataDate();
        }, 600000); // 10 minutes in milliseconds

        //this.getDeveloperOptions();
        var currentDate = getcurrentDate();
        this.model.data_date = currentDate;
        //this.changeAllChart();

        const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
        this.myUserID = LoginUser;
        this.getDashboardData();
    },
    computed: {
    },
    data() {
        return {
            obj_pagination: {
        cols: [
          {
            name: 'Cat1',
            field: 'category1_code',
            sort: ''
          },
          {
            name: 'Cat2',
            field: 'category2_code',
            sort: ''
          },
          {
            name: 'Cat3',
            field: 'category3_code',
            sort: ''
          },
          {
            name: 'Number',
            field: 'number',
            sort: ''
          },
          {
            name: 'Title',
            field: 'title',
            sort: ''
          },
          {
            name: 'Status',
            field: 'status',
            sort: ''
          },
          {}
        ],
            },
            interval: null,
            time: null,
            dataDateInterval: null,
            developers: [],
            infoWindow: null,
            lastInfoWindow: null,
            model: {
                // developer_id: null,
                developer_id: 1,
                data_date: null,
            },
            // modalTitle: "On-Going C&I Projects Detailed",
            gmapMarkers: [],
            gmapLastInfoWindow: null,
            total_data: {
                total_capacity_mwp: null,
                total_turn_on_capacity_mwp: null,
                total_site: null,
            },
            chartOptionObservations: null,
            chartOptionsFinding: null,
            selectedFilter: 5,
            file_uploads: true,
            polygon: null,
            polygonCoords: [
                { lat: 21.291, lng: -157.821 },
                { lat: 37.772, lng: -122.214 },
                { lat: -18.142, lng: 178.431 },
                { lat: -27.467, lng: 153.027 },
            ],
            contourLinesData : [
            [
                {lat:-2.844615288030678,lng:122.0808162215605},
                {lat:-2.843626065557394,lng:122.0808581604368},
                {lat:-2.842561916832862,lng:122.0835809703182},
                {lat:-2.841683869208673,lng:122.0870592412189},
                {lat:-2.842930810426423,lng:122.0954786352646},
                {lat:-2.837476413586116,lng:122.1013152926669},
                {lat:-2.828609591574998,lng:122.108910416868},
                {lat:-2.827088504528595,lng:122.1202308164921},
                {lat:-2.829230982549955,lng:122.1328643341851},
                {lat:-2.829537642082105,lng:122.1429807751129},
                {lat:-2.838550870239782,lng:122.1429912846695},
                {lat:-2.842895570101015,lng:122.1519798356979},    
            ],
            ],
            sesnamap: null,
            polygon_info:'',
            display_SKPT : true,
            display_solarlayout: true,
            display_substation: true,
            display_TLRoute:true,
            display_contour:true,
            display_owner:false,
            contourData:[             
{ID:1,coord:[{lat:-2.83069443333333,lng:122.063964160472},
{lat:-2.83083332222222,lng:122.063964160472},
{lat:-2.83084170332599,lng:122.064166655556},
{lat:-2.83083332222222,lng:122.06417911353},
{lat:-2.83069443333333,lng:122.06417911353},]},
{ID:2,coord:[{lat:-2.83069443333333,lng:122.065224536587},
{lat:-2.83083332222222,lng:122.065224536587},
{lat:-2.83084285087289,lng:122.065277766667},
{lat:-2.83086157184931,lng:122.065555544444},
{lat:-2.83083332222222,lng:122.065607178914},
{lat:-2.83069443333333,lng:122.065607178914},]},
{ID:3,coord:[{lat:-2.83069443333333,lng:122.063669921795},
{lat:-2.83083332222222,lng:122.063669921795},
{lat:-2.83089813963942,lng:122.0636111},
{lat:-2.83089813963942,lng:122.063472211111},]},
{ID:4,coord:[{lat:-2.83069443333333,lng:122.083431730662},
{lat:-2.83083332222222,lng:122.083431730662},
{lat:-2.83100137996594,lng:122.0836111},
{lat:-2.8309462260532,lng:122.083888877778},
{lat:-2.83083332222222,lng:122.083996981391},
{lat:-2.83069443333333,lng:122.083996981391},]},
{ID:5,coord:[{lat:-2.83069443333333,lng:122.067384952784},
{lat:-2.83083332222222,lng:122.067384952784},
{lat:-2.83090989838376,lng:122.067499988889},
{lat:-2.8310531230965,lng:122.067777766667},
{lat:-2.8311111,lng:122.067905976848},
{lat:-2.83119436621036,lng:122.068055544444},
{lat:-2.83118034073489,lng:122.068333322222},
{lat:-2.83120878849816,lng:122.0686111},
{lat:-2.83123762988189,lng:122.068888877778},
{lat:-2.8311493955165,lng:122.069166655556},
{lat:-2.83114419142868,lng:122.069444433333},
{lat:-2.8311111,lng:122.069694709434},
{lat:-2.83110602314905,lng:122.069722211111},
{lat:-2.83093510466966,lng:122.069999988889},
{lat:-2.83083332222222,lng:122.070130155365},
{lat:-2.83069443333333,lng:122.070130155365},]},
{ID:6,coord:[{lat:-2.83069443333333,lng:122.080616675075},
{lat:-2.83083332222222,lng:122.080616675075},
{lat:-2.83099197046661,lng:122.080833322222},
{lat:-2.83109555453336,lng:122.0811111},
{lat:-2.8311111,lng:122.081280411467},
{lat:-2.8311401362706,lng:122.081388877778},
{lat:-2.8311111,lng:122.081413738701},
{lat:-2.83102818257809,lng:122.081666655556},
{lat:-2.83095982502643,lng:122.081944433333},
{lat:-2.83088377531599,lng:122.082222211111},
{lat:-2.83083332222222,lng:122.082380479914},
{lat:-2.83069443333333,lng:122.082380479914},]},
{ID:7,coord:[{lat:-2.83069443333333,lng:122.071177699258},
{lat:-2.83083332222222,lng:122.071177699258},
{lat:-2.8311111,lng:122.071376084116},
{lat:-2.83113330361739,lng:122.071388877778},
{lat:-2.83138887777778,lng:122.071624858648},
{lat:-2.83142632674451,lng:122.071666655556},
{lat:-2.83138887777778,lng:122.071711514552},
{lat:-2.8311111,lng:122.071768191261},
{lat:-2.83083332222222,lng:122.071739793241},
{lat:-2.83069443333333,lng:122.071739793241},]},
{ID:8,coord:[{lat:-2.83139381684066,lng:122.063472211111},
{lat:-2.83139381684066,lng:122.0636111},
{lat:-2.83138887777778,lng:122.063617630037},
{lat:-2.83135800327545,lng:122.0636111},
{lat:-2.83135800327545,lng:122.063472211111},]},
{ID:9,coord:[{lat:-2.8314009382287,lng:122.084166655556},
{lat:-2.83138887777778,lng:122.084229718961},
{lat:-2.83137370399671,lng:122.084166655556},
{lat:-2.83135196392445,lng:122.083888877778},
{lat:-2.83138887777778,lng:122.08387364605},
{lat:-2.83141093259049,lng:122.083888877778},
{lat:-2.8314009382287,lng:122.084166655556},]},
{ID:10,coord:[{lat:-2.83069443333333,lng:122.07584038754},
{lat:-2.83083332222222,lng:122.07584038754},
{lat:-2.8311111,lng:122.076088651751},
{lat:-2.83138887777778,lng:122.075958114851},
{lat:-2.83142828375953,lng:122.0761111},
{lat:-2.83138887777778,lng:122.076167503376},
{lat:-2.8311111,lng:122.0761318726},
{lat:-2.83103549800305,lng:122.076388877778},
{lat:-2.83089233867891,lng:122.076666655556},
{lat:-2.83083332222222,lng:122.076825009251},
{lat:-2.83069443333333,lng:122.076825009251},]},
{ID:11,coord:[{lat:-2.83171584206572,lng:122.063472211111},
{lat:-2.83171584206572,lng:122.0636111},
{lat:-2.83166665555556,lng:122.063690099668},
{lat:-2.83153158162099,lng:122.063888877778},
{lat:-2.83138887777778,lng:122.06409836374},
{lat:-2.83127388835274,lng:122.064166655556},
{lat:-2.8311111,lng:122.064368470922},
{lat:-2.83107793739383,lng:122.064444433333},
{lat:-2.8309994763591,lng:122.064722211111},
{lat:-2.83105722799847,lng:122.064999988889},
{lat:-2.83108032792275,lng:122.065277766667},
{lat:-2.83108121185366,lng:122.065555544444},
{lat:-2.83098664903655,lng:122.065833322222},
{lat:-2.83089751449274,lng:122.0661111},
{lat:-2.83083332222222,lng:122.066204455248},
{lat:-2.83069443333333,lng:122.066204455248},]},
{ID:12,coord:[{lat:-2.83069443333333,lng:122.087589934316},
{lat:-2.83083332222222,lng:122.087589934316},
{lat:-2.8311111,lng:122.087617260266},
{lat:-2.83123995789241,lng:122.087499988889},
{lat:-2.83138887777778,lng:122.087356506609},
{lat:-2.83149622689669,lng:122.087222211111},
{lat:-2.83166665555556,lng:122.087054697086},
{lat:-2.83174063695664,lng:122.086944433333},
{lat:-2.83169630963896,lng:122.086666655556},
{lat:-2.83166665555556,lng:122.086555464568},
{lat:-2.83162651798221,lng:122.086666655556},
{lat:-2.83138887777778,lng:122.086881909282},
{lat:-2.83127710094842,lng:122.086944433333},
{lat:-2.8311111,lng:122.087098210838},
{lat:-2.83102741543331,lng:122.086944433333},
{lat:-2.83095185099058,lng:122.086666655556},
{lat:-2.83098298784257,lng:122.086388877778},
{lat:-2.83104366655364,lng:122.0861111},
{lat:-2.83091608764917,lng:122.085833322222},
{lat:-2.83083332222222,lng:122.085716015735},
{lat:-2.83069443333333,lng:122.085716015735},]},
{ID:13,coord:[{lat:-2.83166665555556,lng:122.074352209744},
{lat:-2.83168689668998,lng:122.074166655556},
{lat:-2.83166665555556,lng:122.074145744752},
{lat:-2.83143695893037,lng:122.074166655556},
{lat:-2.83166665555556,lng:122.074352209744},]},
{ID:14,coord:[{lat:-2.83069443333333,lng:122.067064109384},
{lat:-2.83083332222222,lng:122.067064109384},
{lat:-2.8309687765009,lng:122.067222211111},
{lat:-2.8311111,lng:122.067434711849},
{lat:-2.83115382283401,lng:122.067499988889},
{lat:-2.83128735903669,lng:122.067777766667},
{lat:-2.83138887777778,lng:122.067947212693},
{lat:-2.83153704219879,lng:122.068055544444},
{lat:-2.83156714464362,lng:122.068333322222},
{lat:-2.83147880209688,lng:122.0686111},
{lat:-2.83144771049852,lng:122.068888877778},
{lat:-2.83143908244358,lng:122.069166655556},
{lat:-2.83139247915625,lng:122.069444433333},
{lat:-2.83138887777778,lng:122.069466215388},
{lat:-2.83135931396255,lng:122.069722211111},
{lat:-2.83132805112255,lng:122.069999988889},
{lat:-2.83122184632636,lng:122.070277766667},
{lat:-2.8311111,lng:122.070507757673},
{lat:-2.83108691226665,lng:122.070555544444},
{lat:-2.83104305735215,lng:122.070833322222},
{lat:-2.83109836982598,lng:122.0711111},
{lat:-2.8311111,lng:122.07112116775},
{lat:-2.83138887777778,lng:122.071267294484},
{lat:-2.83151201073427,lng:122.071388877778},
{lat:-2.83166665555556,lng:122.07155749588},
{lat:-2.83184054959571,lng:122.071666655556},
{lat:-2.83194443333334,lng:122.07175237988},
{lat:-2.83205920841801,lng:122.071944433333},
{lat:-2.83222221111111,lng:122.072197341579},
{lat:-2.83224754698309,lng:122.072222211111},
{lat:-2.83225260141469,lng:122.072499988889},
{lat:-2.83222221111111,lng:122.072505088704},
{lat:-2.83220694981075,lng:122.072499988889},
{lat:-2.83194443333334,lng:122.072355465302},
{lat:-2.83166665555556,lng:122.072280324255},
{lat:-2.83158373625303,lng:122.072222211111},
{lat:-2.83138887777778,lng:122.072044250005},
{lat:-2.8311111,lng:122.071997167246},
{lat:-2.83083332222222,lng:122.072090567019},
{lat:-2.83069443333333,lng:122.072090567019},]},
{ID:15,coord:[{lat:-2.83069443333333,lng:122.075232499385},
{lat:-2.83083332222222,lng:122.075232499385},
{lat:-2.83101772361804,lng:122.075277766667},
{lat:-2.8311111,lng:122.075315429488},
{lat:-2.83138887777778,lng:122.075354750823},
{lat:-2.83166665555556,lng:122.075342416096},
{lat:-2.83189584411329,lng:122.075555544444},
{lat:-2.83194443333334,lng:122.075593296932},
{lat:-2.83205906719797,lng:122.075555544444},
{lat:-2.83222221111111,lng:122.075499748991},
{lat:-2.83237215813619,lng:122.075277766667},
{lat:-2.83240407211484,lng:122.074999988889},
{lat:-2.83234339561788,lng:122.074722211111},
{lat:-2.83227858214007,lng:122.074444433333},
{lat:-2.83222221111111,lng:122.074255493531},
{lat:-2.83219545606787,lng:122.074166655556},
{lat:-2.83207767052481,lng:122.073888877778},
{lat:-2.83194443333334,lng:122.073828100195},
{lat:-2.83170512229261,lng:122.0736111},
{lat:-2.83166665555556,lng:122.073582880776},
{lat:-2.83138887777778,lng:122.073495449197},
{lat:-2.83127843404914,lng:122.0736111},
{lat:-2.8311111,lng:122.073810319337},
{lat:-2.83104010710477,lng:122.073888877778},
{lat:-2.83083332222222,lng:122.074147332072},
{lat:-2.83069443333333,lng:122.074147332072},]},
{ID:16,coord:[{lat:-2.83181091698487,lng:122.089583322222},
{lat:-2.83181091698487,lng:122.089444433333},
{lat:-2.83194443333334,lng:122.089360887746},
{lat:-2.83222221111111,lng:122.089299868583},
{lat:-2.83242400854357,lng:122.089166655556},
{lat:-2.83249998888889,lng:122.089105860502},
{lat:-2.83257184850265,lng:122.089166655556},
{lat:-2.83277776666667,lng:122.089379309193},
{lat:-2.83287131854092,lng:122.089444433333},
{lat:-2.83287131854092,lng:122.089583322222},]},
{ID:17,coord:[{lat:-2.83208923714482,lng:122.063472211111},
{lat:-2.83208923714482,lng:122.0636111},
{lat:-2.83194443333334,lng:122.063819881224},
{lat:-2.83189925722291,lng:122.063888877778},
{lat:-2.83173975375017,lng:122.064166655556},
{lat:-2.83166665555556,lng:122.064287100511},
{lat:-2.83159516710962,lng:122.064444433333},
{lat:-2.83152705471092,lng:122.064722211111},
{lat:-2.83163829780062,lng:122.064999988889},
{lat:-2.83156301638758,lng:122.065277766667},
{lat:-2.83147802664508,lng:122.065555544444},
{lat:-2.83138887777778,lng:122.065647744502},
{lat:-2.83132733952069,lng:122.065833322222},
{lat:-2.83136990988547,lng:122.0661111},
{lat:-2.83134167728674,lng:122.066388877778},
{lat:-2.83114823110667,lng:122.066666655556},
{lat:-2.83117412567015,lng:122.066944433333},
{lat:-2.83125288942476,lng:122.067222211111},
{lat:-2.83138887777778,lng:122.067494533956},
{lat:-2.83145378787729,lng:122.067499988889},
{lat:-2.83166665555556,lng:122.0675219265},
{lat:-2.83190552378557,lng:122.067777766667},
{lat:-2.83194443333334,lng:122.067801292263},
{lat:-2.83211940656569,lng:122.068055544444},
{lat:-2.8319959701396,lng:122.068333322222},
{lat:-2.83194443333334,lng:122.068387307844},
{lat:-2.83180484800467,lng:122.0686111},
{lat:-2.83170616997321,lng:122.068888877778},
{lat:-2.83179055552085,lng:122.069166655556},
{lat:-2.83180926673237,lng:122.069444433333},
{lat:-2.83182368342148,lng:122.069722211111},
{lat:-2.83175977627314,lng:122.069999988889},
{lat:-2.83179218733127,lng:122.070277766667},
{lat:-2.831702281303,lng:122.070555544444},
{lat:-2.83166665555556,lng:122.0706219241},
{lat:-2.83147354334323,lng:122.070833322222},
{lat:-2.83156284677517,lng:122.0711111},
{lat:-2.83166665555556,lng:122.071213211256},
{lat:-2.83185047594923,lng:122.071388877778},
{lat:-2.83194443333334,lng:122.071453304618},
{lat:-2.83212301511979,lng:122.071666655556},
{lat:-2.83222221111111,lng:122.071822126176},
{lat:-2.8323149545856,lng:122.071944433333},
{lat:-2.83249998888889,lng:122.072127150289},
{lat:-2.83267411918807,lng:122.072222211111},
{lat:-2.83277776666667,lng:122.072279115704},
{lat:-2.83305554444445,lng:122.072324919332},
{lat:-2.83333332222222,lng:122.072418299459},
{lat:-2.833403595214,lng:122.072499988889},
{lat:-2.83333332222222,lng:122.072672743734},
{lat:-2.83305554444445,lng:122.072624649005},
{lat:-2.83277776666667,lng:122.072689167324},
{lat:-2.83249998888889,lng:122.07262430875},
{lat:-2.83222221111111,lng:122.072630537795},
{lat:-2.83194443333334,lng:122.072549820596},
{lat:-2.83181491222999,lng:122.072499988889},
{lat:-2.83166665555556,lng:122.072443738643},
{lat:-2.83138887777778,lng:122.072248038906},
{lat:-2.8311111,lng:122.072227356297},
{lat:-2.83083332222222,lng:122.072355001507},
{lat:-2.83069443333333,lng:122.072355001507},]},
{ID:18,coord:[{lat:-2.83416665555556,lng:122.064121876209},
{lat:-2.8342576203299,lng:122.063888877778},
{lat:-2.83416665555556,lng:122.063725757098},
{lat:-2.83412082761876,lng:122.063888877778},
{lat:-2.83416665555556,lng:122.064121876209},]},
{ID:19,coord:[{lat:-2.83416665555556,lng:122.084170115122},
{lat:-2.83417218014842,lng:122.084166655556},
{lat:-2.83422839415522,lng:122.083888877778},
{lat:-2.83416665555556,lng:122.083795909297},
{lat:-2.83413784831191,lng:122.083888877778},
{lat:-2.83415562124821,lng:122.084166655556},
{lat:-2.83416665555556,lng:122.084170115122},]},
{ID:20,coord:[{lat:-2.83444443333333,lng:122.078941313606},
{lat:-2.83450055019846,lng:122.078888877778},
{lat:-2.83457436240834,lng:122.0786111},
{lat:-2.83444443333333,lng:122.078389064895},
{lat:-2.83438131540911,lng:122.0786111},
{lat:-2.83439971775838,lng:122.078888877778},
{lat:-2.83444443333333,lng:122.078941313606},]},
{ID:21,coord:[{lat:-2.83150897553547,lng:122.089583322222},
{lat:-2.83150897553547,lng:122.089444433333},
{lat:-2.83166665555556,lng:122.08933838145},
{lat:-2.83194443333334,lng:122.089171709189},
{lat:-2.83195859556536,lng:122.089166655556},
{lat:-2.83222221111111,lng:122.089019433216},
{lat:-2.83238225372815,lng:122.088888877778},
{lat:-2.83249998888889,lng:122.088829323433},
{lat:-2.832612059445,lng:122.088888877778},
{lat:-2.83277776666667,lng:122.089031038301},
{lat:-2.83303724404128,lng:122.089166655556},
{lat:-2.83305554444445,lng:122.089179368048},
{lat:-2.83306698610814,lng:122.089166655556},
{lat:-2.83316676864828,lng:122.088888877778},
{lat:-2.83330138021268,lng:122.0886111},
{lat:-2.83333332222222,lng:122.08858215158},
{lat:-2.8336111,lng:122.088527625449},
{lat:-2.83388887777778,lng:122.088555577641},
{lat:-2.83416665555556,lng:122.088500299481},
{lat:-2.83444443333333,lng:122.088419826602},
{lat:-2.83453015341504,lng:122.088333322222},
{lat:-2.83472221111111,lng:122.088281523989},
{lat:-2.83496879533631,lng:122.088333322222},
{lat:-2.83495524246412,lng:122.0886111},
{lat:-2.83472221111111,lng:122.088720948708},
{lat:-2.83444443333333,lng:122.088698843302},
{lat:-2.834168726072,lng:122.088888877778},
{lat:-2.83416665555556,lng:122.08889020025},
{lat:-2.83388887777778,lng:122.089078750807},
{lat:-2.83381381800296,lng:122.089166655556},
{lat:-2.83367340132636,lng:122.089444433333},
{lat:-2.83367340132636,lng:122.089583322222},]},
{ID:22,coord:[{lat:-2.83244299407791,lng:122.063472211111},
{lat:-2.83244299407791,lng:122.0636111},
{lat:-2.83225969685844,lng:122.063888877778},
{lat:-2.83223619463504,lng:122.064166655556},
{lat:-2.83227097566193,lng:122.064444433333},
{lat:-2.83231203517445,lng:122.064722211111},
{lat:-2.83249998888889,lng:122.064944595977},
{lat:-2.83250814935614,lng:122.064999988889},
{lat:-2.83250975840526,lng:122.065277766667},
{lat:-2.83249998888889,lng:122.065407911368},
{lat:-2.83246747200795,lng:122.065555544444},
{lat:-2.83225719551496,lng:122.065833322222},
{lat:-2.83222221111111,lng:122.065963897016},
{lat:-2.83217299635864,lng:122.0661111},
{lat:-2.83203239662737,lng:122.066388877778},
{lat:-2.83198900045745,lng:122.066666655556},
{lat:-2.83196728977924,lng:122.066944433333},
{lat:-2.83199473549053,lng:122.067222211111},
{lat:-2.83222221111111,lng:122.067392987896},
{lat:-2.83249998888889,lng:122.067451445291},
{lat:-2.83253513620155,lng:122.067499988889},
{lat:-2.83249998888889,lng:122.067699176256},
{lat:-2.83248249987034,lng:122.067777766667},
{lat:-2.8324296694539,lng:122.068055544444},
{lat:-2.83243120663846,lng:122.068333322222},
{lat:-2.83233248389703,lng:122.0686111},
{lat:-2.83225088463876,lng:122.068888877778},
{lat:-2.83223121206231,lng:122.069166655556},
{lat:-2.83229675364643,lng:122.069444433333},
{lat:-2.83229128511694,lng:122.069722211111},
{lat:-2.8322301978563,lng:122.069999988889},
{lat:-2.83222221111111,lng:122.070028850656},
{lat:-2.83214495945817,lng:122.070277766667},
{lat:-2.83202914295167,lng:122.070555544444},
{lat:-2.83194443333334,lng:122.070739336325},
{lat:-2.83189408344348,lng:122.070833322222},
{lat:-2.83184983250234,lng:122.0711111},
{lat:-2.83194443333334,lng:122.071202956451},
{lat:-2.83210864122705,lng:122.071388877778},
{lat:-2.83222221111111,lng:122.071523556206},
{lat:-2.83235491602124,lng:122.071666655556},
{lat:-2.83249998888889,lng:122.071851488272},
{lat:-2.83257427679193,lng:122.071944433333},
{lat:-2.83277776666667,lng:122.072088067136},
{lat:-2.83305554444445,lng:122.072127261661},
{lat:-2.83333332222222,lng:122.072181682882},
{lat:-2.83341380668383,lng:122.072222211111},
{lat:-2.8336111,lng:122.072483630568},
{lat:-2.83361918321855,lng:122.072499988889},
{lat:-2.83388887777778,lng:122.072693444836},
{lat:-2.83416665555556,lng:122.072681440876},
{lat:-2.83441994227933,lng:122.072499988889},
{lat:-2.83444443333333,lng:122.072482972128},
{lat:-2.83472221111111,lng:122.072479438489},
{lat:-2.83476083202737,lng:122.072499988889},
{lat:-2.83472221111111,lng:122.07251069023},
{lat:-2.83452551350082,lng:122.072777766667},
{lat:-2.83444443333333,lng:122.072861432226},
{lat:-2.83426850881817,lng:122.073055544444},
{lat:-2.83416665555556,lng:122.073266978547},
{lat:-2.83411283639747,lng:122.073333322222},
{lat:-2.83405577134766,lng:122.0736111},
{lat:-2.83388887777778,lng:122.073708988438},
{lat:-2.83385218784939,lng:122.0736111},
{lat:-2.83368106574594,lng:122.073333322222},
{lat:-2.8336111,lng:122.073249802565},
{lat:-2.83351984697632,lng:122.073055544444},
{lat:-2.83333332222222,lng:122.072921014188},
{lat:-2.83305554444445,lng:122.072809983351},
{lat:-2.83277776666667,lng:122.072861428539},
{lat:-2.83249998888889,lng:122.072806844983},
{lat:-2.83236090313972,lng:122.072777766667},
{lat:-2.83222221111111,lng:122.072755986885},
{lat:-2.83194443333334,lng:122.07271552931},
{lat:-2.83166665555556,lng:122.072606560099},
{lat:-2.8315219194384,lng:122.072499988889},
{lat:-2.83138887777778,lng:122.072405233007},
{lat:-2.8311111,lng:122.072410355816},
{lat:-2.83094765622991,lng:122.072499988889},
{lat:-2.83083332222222,lng:122.07255408701},
{lat:-2.83069443333333,lng:122.07255408701},]},
{ID:23,coord:[{lat:-2.83510828979988,lng:122.072222211111},
{lat:-2.83499998888889,lng:122.072317116586},
{lat:-2.83494324017,lng:122.072222211111},
{lat:-2.83499998888889,lng:122.072204609453},
{lat:-2.83510828979988,lng:122.072222211111},]},
{ID:24,coord:[{lat:-2.83384441982272,lng:122.063472211111},
{lat:-2.83384441982272,lng:122.0636111},
{lat:-2.83382804455454,lng:122.063888877778},
{lat:-2.83387582741657,lng:122.064166655556},
{lat:-2.83388887777778,lng:122.064218972074},
{lat:-2.83398331821842,lng:122.064444433333},
{lat:-2.83416665555556,lng:122.064632644739},
{lat:-2.83432048821838,lng:122.064722211111},
{lat:-2.83444443333333,lng:122.064823675212},
{lat:-2.83472221111111,lng:122.064935694613},
{lat:-2.83483725752189,lng:122.064722211111},
{lat:-2.83493644539636,lng:122.064444433333},
{lat:-2.83499998888889,lng:122.064307898088},
{lat:-2.83502685999626,lng:122.064166655556},
{lat:-2.83503534069083,lng:122.063888877778},
{lat:-2.83499998888889,lng:122.0637477552},
{lat:-2.83488359978359,lng:122.0636111},
{lat:-2.83488359978359,lng:122.063472211111},]},
{ID:25,coord:[{lat:-2.8337148949814,lng:122.063472211111},
{lat:-2.8337148949814,lng:122.0636111},
{lat:-2.83368572815889,lng:122.063888877778},
{lat:-2.83372322897735,lng:122.064166655556},
{lat:-2.83379390620256,lng:122.064444433333},
{lat:-2.83388887777778,lng:122.064655821104},
{lat:-2.8339530951254,lng:122.064722211111},
{lat:-2.83416665555556,lng:122.064934085405},
{lat:-2.83424488349222,lng:122.064999988889},
{lat:-2.83444443333333,lng:122.065102207904},
{lat:-2.83472221111111,lng:122.065271095537},
{lat:-2.83499998888889,lng:122.065196219261},
{lat:-2.83509661282016,lng:122.064999988889},
{lat:-2.83510085658548,lng:122.064722211111},
{lat:-2.8352227985176,lng:122.064444433333},
{lat:-2.83516889989696,lng:122.064166655556},
{lat:-2.83521910702583,lng:122.063888877778},
{lat:-2.83527776666667,lng:122.063693190245},
{lat:-2.83533183804283,lng:122.0636111},
{lat:-2.83533183804283,lng:122.063472211111},]},
{ID:26,coord:[{lat:-2.83528096563841,lng:122.0786111},
{lat:-2.83527776666667,lng:122.07861772141},
{lat:-2.83527519263886,lng:122.0786111},
{lat:-2.83527776666667,lng:122.07860255525},
{lat:-2.83528096563841,lng:122.0786111},]},
{ID:27,coord:[{lat:-2.83069443333333,lng:122.089394328916},
{lat:-2.83083332222222,lng:122.089394328916},
{lat:-2.8311111,lng:122.089307424615},
{lat:-2.83138887777778,lng:122.089265831304},
{lat:-2.83157926285994,lng:122.089166655556},
{lat:-2.83166665555556,lng:122.089105301362},
{lat:-2.83194443333334,lng:122.088974151205},
{lat:-2.83206538061135,lng:122.088888877778},
{lat:-2.83217011552241,lng:122.0886111},
{lat:-2.83222221111111,lng:122.088534819552},
{lat:-2.8323456190646,lng:122.0886111},
{lat:-2.83249998888889,lng:122.088645732788},
{lat:-2.83277776666667,lng:122.088811374238},
{lat:-2.83305554444445,lng:122.088721866029},
{lat:-2.83312248574208,lng:122.0886111},
{lat:-2.83333332222222,lng:122.088420023009},
{lat:-2.8336111,lng:122.08837020298},
{lat:-2.83388887777778,lng:122.088426844718},
{lat:-2.83416665555556,lng:122.088340049513},
{lat:-2.83418039588883,lng:122.088333322222},
{lat:-2.83444443333333,lng:122.088215631497},
{lat:-2.83472221111111,lng:122.088161925031},
{lat:-2.83499998888889,lng:122.088141347111},
{lat:-2.83527776666667,lng:122.088100173222},
{lat:-2.83555554444445,lng:122.088259017658},
{lat:-2.83567313813266,lng:122.088333322222},
{lat:-2.83583332222222,lng:122.088506277334},
{lat:-2.83589675597071,lng:122.0886111},
{lat:-2.83583332222222,lng:122.088660089174},
{lat:-2.83555554444445,lng:122.088641373051},
{lat:-2.83527776666667,lng:122.088683074032},
{lat:-2.83499998888889,lng:122.088746673562},
{lat:-2.83472221111111,lng:122.088858351319},
{lat:-2.83451404469693,lng:122.088888877778},
{lat:-2.83444443333333,lng:122.088904466313},
{lat:-2.83416665555556,lng:122.089080431883},
{lat:-2.834065418349,lng:122.089166655556},
{lat:-2.83390674798585,lng:122.089444433333},
{lat:-2.83390674798585,lng:122.089583322222},]},
{ID:28,coord:[{lat:-2.83269411923181,lng:122.063472211111},
{lat:-2.83269411923181,lng:122.0636111},
{lat:-2.83254960065479,lng:122.063888877778},
{lat:-2.83249998888889,lng:122.064112449552},
{lat:-2.83248825729809,lng:122.064166655556},
{lat:-2.83249998888889,lng:122.064192365075},
{lat:-2.83263605705328,lng:122.064444433333},
{lat:-2.83269191256101,lng:122.064722211111},
{lat:-2.83271476517342,lng:122.064999988889},
{lat:-2.83271377823344,lng:122.065277766667},
{lat:-2.8327459647984,lng:122.065555544444},
{lat:-2.83261875017254,lng:122.065833322222},
{lat:-2.83251285554958,lng:122.0661111},
{lat:-2.83249998888889,lng:122.06616547909},
{lat:-2.83242717812664,lng:122.066388877778},
{lat:-2.83249998888889,lng:122.066586781704},
{lat:-2.83253958183572,lng:122.066666655556},
{lat:-2.83268328004749,lng:122.066944433333},
{lat:-2.83277776666667,lng:122.067023216807},
{lat:-2.83296446796211,lng:122.067222211111},
{lat:-2.83305554444445,lng:122.06741673248},
{lat:-2.83307477662131,lng:122.067499988889},
{lat:-2.83305554444445,lng:122.067579324427},
{lat:-2.83298340883336,lng:122.067777766667},
{lat:-2.83283592277426,lng:122.068055544444},
{lat:-2.832797859566,lng:122.068333322222},
{lat:-2.83277776666667,lng:122.068434617767},
{lat:-2.832739354234,lng:122.0686111},
{lat:-2.83266386282705,lng:122.068888877778},
{lat:-2.83267609951365,lng:122.069166655556},
{lat:-2.83266875094711,lng:122.069444433333},
{lat:-2.83264841032934,lng:122.069722211111},
{lat:-2.83256438299071,lng:122.069999988889},
{lat:-2.83249998888889,lng:122.070208446493},
{lat:-2.83247173489716,lng:122.070277766667},
{lat:-2.83233480929702,lng:122.070555544444},
{lat:-2.83222221111111,lng:122.070717714797},
{lat:-2.83216041033224,lng:122.070833322222},
{lat:-2.83210071345021,lng:122.0711111},
{lat:-2.83222221111111,lng:122.071251600996},
{lat:-2.83232724127262,lng:122.071388877778},
{lat:-2.83249998888889,lng:122.07157416563},
{lat:-2.83257537895473,lng:122.071666655556},
{lat:-2.83277776666667,lng:122.071907759066},
{lat:-2.83288520227581,lng:122.071944433333},
{lat:-2.83305554444445,lng:122.071981251736},
{lat:-2.83333332222222,lng:122.071992063326},
{lat:-2.8336111,lng:122.072121931727},
{lat:-2.83369446264423,lng:122.072222211111},
{lat:-2.83386573530411,lng:122.072499988889},
{lat:-2.83388887777778,lng:122.072516589332},
{lat:-2.83397074975325,lng:122.072499988889},
{lat:-2.83416665555556,lng:122.07245411226},
{lat:-2.83444443333333,lng:122.072256008097},
{lat:-2.83449469645127,lng:122.072222211111},
{lat:-2.83472221111111,lng:122.072083607441},
{lat:-2.83499998888889,lng:122.071994293253},
{lat:-2.83511424982982,lng:122.071944433333},
{lat:-2.83527776666667,lng:122.071885141487},
{lat:-2.83555554444445,lng:122.071757576058},
{lat:-2.83569417197679,lng:122.071666655556},
{lat:-2.83583332222222,lng:122.071530366208},
{lat:-2.83594096539445,lng:122.071666655556},
{lat:-2.83587837800908,lng:122.071944433333},
{lat:-2.83583332222222,lng:122.071990666124},
{lat:-2.83566257837855,lng:122.072222211111},
{lat:-2.83555554444445,lng:122.072350230257},
{lat:-2.83537605445095,lng:122.072499988889},
{lat:-2.83527776666667,lng:122.072586222844},
{lat:-2.83499998888889,lng:122.072655405222},
{lat:-2.83481844795172,lng:122.072777766667},
{lat:-2.83472221111111,lng:122.072875707691},
{lat:-2.83454736924097,lng:122.073055544444},
{lat:-2.83444443333333,lng:122.073286251791},
{lat:-2.83442219242134,lng:122.073333322222},
{lat:-2.8344103078734,lng:122.0736111},
{lat:-2.83444443333333,lng:122.073757806553},
{lat:-2.83447779506728,lng:122.073888877778},
{lat:-2.83445789123995,lng:122.074166655556},
{lat:-2.83446567806021,lng:122.074444433333},
{lat:-2.83444443333333,lng:122.07452638097},
{lat:-2.83416665555556,lng:122.074479451739},
{lat:-2.83413889551601,lng:122.074444433333},
{lat:-2.83388887777778,lng:122.074230279561},
{lat:-2.83384398403858,lng:122.074166655556},
{lat:-2.83364725084967,lng:122.073888877778},
{lat:-2.8336111,lng:122.073712421762},
{lat:-2.83359887148372,lng:122.0736111},
{lat:-2.83346259106012,lng:122.073333322222},
{lat:-2.83333332222222,lng:122.073125758166},
{lat:-2.8332799235595,lng:122.073055544444},
{lat:-2.83305554444445,lng:122.072930266413},
{lat:-2.83277776666667,lng:122.072998604404},
{lat:-2.83249998888889,lng:122.072973858695},
{lat:-2.83222221111111,lng:122.072951511315},
{lat:-2.83194443333334,lng:122.072883416353},
{lat:-2.83168858202367,lng:122.072777766667},
{lat:-2.83166665555556,lng:122.072769070326},
{lat:-2.83138887777778,lng:122.072563960544},
{lat:-2.8311111,lng:122.072588134964},
{lat:-2.83083332222222,lng:122.072711977795},
{lat:-2.83069443333333,lng:122.072711977795},]},
{ID:29,coord:[{lat:-2.83587934049339,lng:122.065277766667},
{lat:-2.83583332222222,lng:122.065320642872},
{lat:-2.83555554444445,lng:122.065393300568},
{lat:-2.83536984584514,lng:122.065277766667},
{lat:-2.83539478880728,lng:122.064999988889},
{lat:-2.83534544008665,lng:122.064722211111},
{lat:-2.83543021917172,lng:122.064444433333},
{lat:-2.83539934339429,lng:122.064166655556},
{lat:-2.83555554444445,lng:122.064026330938},
{lat:-2.83567922737918,lng:122.064166655556},
{lat:-2.83582095741506,lng:122.064444433333},
{lat:-2.83583332222222,lng:122.064520796119},
{lat:-2.83585018011619,lng:122.064722211111},
{lat:-2.83593916246611,lng:122.064999988889},
{lat:-2.83587934049339,lng:122.065277766667},]},
{ID:30,coord:[{lat:-2.83069443333333,lng:122.088916191499},
{lat:-2.83083332222222,lng:122.088916191499},
{lat:-2.83088344180839,lng:122.088888877778},
{lat:-2.8311111,lng:122.088770336531},
{lat:-2.83138887777778,lng:122.088795242203},
{lat:-2.83154105308354,lng:122.0886111},
{lat:-2.83166665555556,lng:122.088489548339},
{lat:-2.83183968203386,lng:122.088333322222},
{lat:-2.83194443333334,lng:122.088271135693},
{lat:-2.83213535784061,lng:122.088055544444},
{lat:-2.83222221111111,lng:122.087875545771},
{lat:-2.83225931288804,lng:122.088055544444},
{lat:-2.83232908491387,lng:122.088333322222},
{lat:-2.83249998888889,lng:122.088484016258},
{lat:-2.83269495027808,lng:122.0886111},
{lat:-2.83277776666667,lng:122.0886624329},
{lat:-2.83287641767782,lng:122.0886111},
{lat:-2.83305554444445,lng:122.088495182588},
{lat:-2.83323681385162,lng:122.088333322222},
{lat:-2.83333332222222,lng:122.088279873105},
{lat:-2.8336111,lng:122.088263731058},
{lat:-2.83388887777778,lng:122.088297448779},
{lat:-2.83416665555556,lng:122.088203354332},
{lat:-2.83444443333333,lng:122.088069739405},
{lat:-2.83457448392182,lng:122.088055544444},
{lat:-2.83472221111111,lng:122.088040230171},
{lat:-2.83499998888889,lng:122.08797555621},
{lat:-2.83527776666667,lng:122.087932467416},
{lat:-2.83555554444445,lng:122.087978761371},
{lat:-2.83572566230872,lng:122.088055544444},
{lat:-2.83583332222222,lng:122.088126561948},
{lat:-2.83607866301907,lng:122.088333322222},
{lat:-2.8361111,lng:122.088389142989},
{lat:-2.8362247108673,lng:122.0886111},
{lat:-2.8361111,lng:122.088673539457},
{lat:-2.83585733090353,lng:122.088888877778},
{lat:-2.83583332222222,lng:122.088902060223},
{lat:-2.83572601036707,lng:122.088888877778},
{lat:-2.83555554444445,lng:122.088849784694},
{lat:-2.83527776666667,lng:122.088848513935},
{lat:-2.83508744494304,lng:122.088888877778},
{lat:-2.83499998888889,lng:122.088911160096},
{lat:-2.83472221111111,lng:122.08900987043},
{lat:-2.83444443333333,lng:122.089114289936},
{lat:-2.83435737601319,lng:122.089166655556},
{lat:-2.83416665555556,lng:122.08931079906},
{lat:-2.83407922839179,lng:122.089444433333},
{lat:-2.83407922839179,lng:122.089583322222},]},
{ID:31,coord:[{lat:-2.83287975573212,lng:122.063472211111},
{lat:-2.83287975573212,lng:122.0636111},
{lat:-2.83279972923633,lng:122.063888877778},
{lat:-2.8327882045,lng:122.064166655556},
{lat:-2.8328986040056,lng:122.064444433333},
{lat:-2.83292231651352,lng:122.064722211111},
{lat:-2.83294799746727,lng:122.064999988889},
{lat:-2.83301589094525,lng:122.065277766667},
{lat:-2.83299300495947,lng:122.065555544444},
{lat:-2.83288053344042,lng:122.065833322222},
{lat:-2.83277776666667,lng:122.066091202804},
{lat:-2.83277066870113,lng:122.0661111},
{lat:-2.83274631000985,lng:122.066388877778},
{lat:-2.83277776666667,lng:122.066435589349},
{lat:-2.83305554444445,lng:122.066625977017},
{lat:-2.83316487995378,lng:122.066666655556},
{lat:-2.83333332222222,lng:122.066814391281},
{lat:-2.83339168156929,lng:122.066944433333},
{lat:-2.83335325764453,lng:122.067222211111},
{lat:-2.83334350092887,lng:122.067499988889},
{lat:-2.83333332222222,lng:122.067579720977},
{lat:-2.83330876398954,lng:122.067777766667},
{lat:-2.83320468501139,lng:122.068055544444},
{lat:-2.83309834486601,lng:122.068333322222},
{lat:-2.83305554444445,lng:122.068439462448},
{lat:-2.83300646160024,lng:122.0686111},
{lat:-2.83295900753708,lng:122.068888877778},
{lat:-2.83293649879673,lng:122.069166655556},
{lat:-2.83290993051738,lng:122.069444433333},
{lat:-2.83291456070792,lng:122.069722211111},
{lat:-2.83280611112754,lng:122.069999988889},
{lat:-2.83277776666667,lng:122.070075790775},
{lat:-2.83272508012691,lng:122.070277766667},
{lat:-2.83264277375567,lng:122.070555544444},
{lat:-2.83249998888889,lng:122.070824415508},
{lat:-2.83249405669703,lng:122.070833322222},
{lat:-2.83241171667068,lng:122.0711111},
{lat:-2.83249998888889,lng:122.071256002224},
{lat:-2.83257505624393,lng:122.071388877778},
{lat:-2.83276894688522,lng:122.071666655556},
{lat:-2.83277776666667,lng:122.071677162519},
{lat:-2.83305554444445,lng:122.071788855709},
{lat:-2.83333332222222,lng:122.071837428316},
{lat:-2.8336111,lng:122.071919225634},
{lat:-2.83364899189996,lng:122.071944433333},
{lat:-2.83386956919214,lng:122.072222211111},
{lat:-2.83388887777778,lng:122.072252346691},
{lat:-2.83416665555556,lng:122.072249854589},
{lat:-2.83420476623784,lng:122.072222211111},
{lat:-2.83444443333333,lng:122.072042387284},
{lat:-2.83460810866801,lng:122.071944433333},
{lat:-2.83472221111111,lng:122.071874532305},
{lat:-2.83499998888889,lng:122.071775886944},
{lat:-2.83527776666667,lng:122.071710377702},
{lat:-2.83535212904613,lng:122.071666655556},
{lat:-2.83555554444445,lng:122.071509541138},
{lat:-2.83567754653904,lng:122.071388877778},
{lat:-2.83583332222222,lng:122.07111652116},
{lat:-2.83583930578649,lng:122.0711111},
{lat:-2.8361111,lng:122.070935364157},
{lat:-2.83627425441358,lng:122.0711111},
{lat:-2.83622374797038,lng:122.071388877778},
{lat:-2.8361926042441,lng:122.071666655556},
{lat:-2.83614749293208,lng:122.071944433333},
{lat:-2.8361111,lng:122.071988998197},
{lat:-2.83588241237395,lng:122.072222211111},
{lat:-2.83583332222222,lng:122.072275249754},
{lat:-2.835642606619,lng:122.072499988889},
{lat:-2.83555554444445,lng:122.07259455476},
{lat:-2.83535246834702,lng:122.072777766667},
{lat:-2.83527776666667,lng:122.072826016166},
{lat:-2.83499998888889,lng:122.072880736725},
{lat:-2.83482847221761,lng:122.073055544444},
{lat:-2.83476370986375,lng:122.073333322222},
{lat:-2.8347884230177,lng:122.0736111},
{lat:-2.83477537418524,lng:122.073888877778},
{lat:-2.83473666194534,lng:122.074166655556},
{lat:-2.83472221111111,lng:122.074299202478},
{lat:-2.83470779095628,lng:122.074444433333},
{lat:-2.83470601865743,lng:122.074722211111},
{lat:-2.83471085196468,lng:122.074999988889},
{lat:-2.83458951932008,lng:122.075277766667},
{lat:-2.83444443333333,lng:122.075537847365},
{lat:-2.8343185087176,lng:122.075277766667},
{lat:-2.83419993312269,lng:122.074999988889},
{lat:-2.83416665555556,lng:122.074965676651},
{lat:-2.83399205059898,lng:122.074722211111},
{lat:-2.83388887777778,lng:122.074596099734},
{lat:-2.83379478616644,lng:122.074444433333},
{lat:-2.8336111,lng:122.074172654817},
{lat:-2.83360707926718,lng:122.074166655556},
{lat:-2.83343731894329,lng:122.073888877778},
{lat:-2.83340601206145,lng:122.0736111},
{lat:-2.83333332222222,lng:122.073448570897},
{lat:-2.83327995629281,lng:122.073333322222},
{lat:-2.83306449068129,lng:122.073055544444},
{lat:-2.83305554444445,lng:122.073050549474},
{lat:-2.83303713048483,lng:122.073055544444},
{lat:-2.83277776666667,lng:122.073170365382},
{lat:-2.83249998888889,lng:122.073151803789},
{lat:-2.83222221111111,lng:122.073141041665},
{lat:-2.83195364202806,lng:122.073055544444},
{lat:-2.83194443333334,lng:122.073052613647},
{lat:-2.83166665555556,lng:122.072918892954},
{lat:-2.83146029133063,lng:122.072777766667},
{lat:-2.83138887777778,lng:122.072725015216},
{lat:-2.8311111,lng:122.072760902484},
{lat:-2.83107128078649,lng:122.072777766667},
{lat:-2.83083332222222,lng:122.072872107607},
{lat:-2.83069443333333,lng:122.072872107607},]},
{ID:32,coord:[{lat:-2.83642880618955,lng:122.070833322222},
{lat:-2.83638887777778,lng:122.070890425732},
{lat:-2.83632264536136,lng:122.070833322222},
{lat:-2.83638887777778,lng:122.070782357697},
{lat:-2.83642880618955,lng:122.070833322222},]},
{ID:33,coord:[{lat:-2.83638887777778,lng:122.069550841641},
{lat:-2.83655946226468,lng:122.069444433333},
{lat:-2.83655286337579,lng:122.069166655556},
{lat:-2.83638887777778,lng:122.069012525808},
{lat:-2.83615856929704,lng:122.068888877778},
{lat:-2.8361111,lng:122.06886053755},
{lat:-2.83583332222222,lng:122.068801676435},
{lat:-2.83555554444445,lng:122.068874166363},
{lat:-2.83554947045738,lng:122.068888877778},
{lat:-2.83555554444445,lng:122.068896414373},
{lat:-2.83579505958475,lng:122.069166655556},
{lat:-2.83583332222222,lng:122.069242232093},
{lat:-2.83591589056586,lng:122.069444433333},
{lat:-2.83593731977304,lng:122.069722211111},
{lat:-2.8361111,lng:122.069769383087},
{lat:-2.83618946879482,lng:122.069722211111},
{lat:-2.83638887777778,lng:122.069550841641},]},
{ID:34,coord:[{lat:-2.83646032429496,lng:122.068055544444},
{lat:-2.83638887777778,lng:122.068165216382},
{lat:-2.83630102524579,lng:122.068055544444},
{lat:-2.83638887777778,lng:122.06796841342},
{lat:-2.83646032429496,lng:122.068055544444},]},
{ID:35,coord:[{lat:-2.83666803464819,lng:122.070555544444},
{lat:-2.83666665555556,lng:122.070556638027},
{lat:-2.83666519373903,lng:122.070555544444},
{lat:-2.83666665555556,lng:122.070555061405},
{lat:-2.83666803464819,lng:122.070555544444},]},
{ID:36,coord:[{lat:-2.83069443333333,lng:122.088190888832},
{lat:-2.83083332222222,lng:122.088190888832},
{lat:-2.8311111,lng:122.08823061306},
{lat:-2.83138887777778,lng:122.088127664746},
{lat:-2.8314843377745,lng:122.088055544444},
{lat:-2.83166665555556,lng:122.087920079457},
{lat:-2.83194443333334,lng:122.087858448501},
{lat:-2.83199604359794,lng:122.087777766667},
{lat:-2.83209705092628,lng:122.087499988889},
{lat:-2.83214918086055,lng:122.087222211111},
{lat:-2.83217967443221,lng:122.086944433333},
{lat:-2.83222221111111,lng:122.086838716117},
{lat:-2.83224490299163,lng:122.086944433333},
{lat:-2.83230132103921,lng:122.087222211111},
{lat:-2.83236040829851,lng:122.087499988889},
{lat:-2.83238936371998,lng:122.087777766667},
{lat:-2.8324182190118,lng:122.088055544444},
{lat:-2.83249998888889,lng:122.088314265397},
{lat:-2.83252975284776,lng:122.088333322222},
{lat:-2.83277776666667,lng:122.088423656856},
{lat:-2.83297625649304,lng:122.088333322222},
{lat:-2.83305554444445,lng:122.088303032773},
{lat:-2.83333332222222,lng:122.088164986673},
{lat:-2.8336111,lng:122.088172847887},
{lat:-2.83388887777778,lng:122.0881662918},
{lat:-2.83416665555556,lng:122.088067691311},
{lat:-2.83419172148503,lng:122.088055544444},
{lat:-2.83444443333333,lng:122.087924755747},
{lat:-2.83472221111111,lng:122.087901667624},
{lat:-2.83499998888889,lng:122.087837058178},
{lat:-2.83527776666667,lng:122.087789969327},
{lat:-2.83549889273465,lng:122.087777766667},
{lat:-2.83555554444445,lng:122.087772575018},
{lat:-2.83556650095431,lng:122.087777766667},
{lat:-2.83583332222222,lng:122.087892120162},
{lat:-2.8361111,lng:122.088028262968},
{lat:-2.83638887777778,lng:122.087912345701},
{lat:-2.83666665555556,lng:122.087836206149},
{lat:-2.83694443333333,lng:122.087896010561},
{lat:-2.83711215591335,lng:122.087777766667},
{lat:-2.83722221111111,lng:122.087709506858},
{lat:-2.83749438308231,lng:122.087499988889},
{lat:-2.83749998888889,lng:122.087496270721},
{lat:-2.83750969245806,lng:122.087499988889},
{lat:-2.83766033677421,lng:122.087777766667},
{lat:-2.83756749857328,lng:122.088055544444},
{lat:-2.83749998888889,lng:122.088119504478},
{lat:-2.83722221111111,lng:122.088259679381},
{lat:-2.83713986041625,lng:122.088333322222},
{lat:-2.83694443333333,lng:122.088448748352},
{lat:-2.83673034149908,lng:122.0886111},
{lat:-2.83666665555556,lng:122.088665431951},
{lat:-2.83638887777778,lng:122.088705915606},
{lat:-2.8361111,lng:122.088846068739},
{lat:-2.83606065096224,lng:122.088888877778},
{lat:-2.83583332222222,lng:122.089013697153},
{lat:-2.83555554444445,lng:122.089005061584},
{lat:-2.83527776666667,lng:122.089147807034},
{lat:-2.83499998888889,lng:122.089109909855},
{lat:-2.83472221111111,lng:122.089165421562},
{lat:-2.83471930534902,lng:122.089166655556},
{lat:-2.83444443333333,lng:122.08942171508},
{lat:-2.83441127560991,lng:122.089444433333},
{lat:-2.83441127560991,lng:122.089583322222},]},
{ID:37,coord:[{lat:-2.83303912063807,lng:122.063472211111},
{lat:-2.83303912063807,lng:122.0636111},
{lat:-2.83298719442415,lng:122.063888877778},
{lat:-2.83302467222867,lng:122.064166655556},
{lat:-2.83305554444445,lng:122.064247422428},
{lat:-2.8331156669543,lng:122.064444433333},
{lat:-2.83315123552018,lng:122.064722211111},
{lat:-2.8332203652948,lng:122.064999988889},
{lat:-2.83333332222222,lng:122.065177165846},
{lat:-2.83338897949424,lng:122.065277766667},
{lat:-2.83339658749757,lng:122.065555544444},
{lat:-2.83333332222222,lng:122.065698474564},
{lat:-2.83323715117991,lng:122.065833322222},
{lat:-2.8330737060856,lng:122.0661111},
{lat:-2.83333332222222,lng:122.066294356423},
{lat:-2.83353541973724,lng:122.066388877778},
{lat:-2.8336111,lng:122.066433988344},
{lat:-2.83386927411342,lng:122.066666655556},
{lat:-2.83379581780004,lng:122.066944433333},
{lat:-2.83373578963255,lng:122.067222211111},
{lat:-2.83367255000558,lng:122.067499988889},
{lat:-2.8336111,lng:122.067686826549},
{lat:-2.83358963468917,lng:122.067777766667},
{lat:-2.83351826146907,lng:122.068055544444},
{lat:-2.83342858143092,lng:122.068333322222},
{lat:-2.833341510661,lng:122.0686111},
{lat:-2.83333332222222,lng:122.068625093831},
{lat:-2.8332093991787,lng:122.068888877778},
{lat:-2.83314680448316,lng:122.069166655556},
{lat:-2.83312981048542,lng:122.069444433333},
{lat:-2.83317319588696,lng:122.069722211111},
{lat:-2.83314873840319,lng:122.069999988889},
{lat:-2.83308960351596,lng:122.070277766667},
{lat:-2.83305554444445,lng:122.070424212617},
{lat:-2.83301183472134,lng:122.070555544444},
{lat:-2.83288301657091,lng:122.070833322222},
{lat:-2.83281099419991,lng:122.0711111},
{lat:-2.83293115402308,lng:122.071388877778},
{lat:-2.83305554444445,lng:122.071512260277},
{lat:-2.83324415725402,lng:122.071666655556},
{lat:-2.83333332222222,lng:122.071694528773},
{lat:-2.8336111,lng:122.071759015013},
{lat:-2.83388887777778,lng:122.071943321566},
{lat:-2.8338921103508,lng:122.071944433333},
{lat:-2.83416665555556,lng:122.072021459382},
{lat:-2.83427280716699,lng:122.071944433333},
{lat:-2.83444443333333,lng:122.071768691883},
{lat:-2.83469139262438,lng:122.071666655556},
{lat:-2.83472221111111,lng:122.071654475235},
{lat:-2.83499998888889,lng:122.071518462233},
{lat:-2.83527776666667,lng:122.071481569931},
{lat:-2.83540049370456,lng:122.071388877778},
{lat:-2.83555554444445,lng:122.071190921222},
{lat:-2.83560065564911,lng:122.0711111},
{lat:-2.83583332222222,lng:122.070858172349},
{lat:-2.83586948406773,lng:122.070833322222},
{lat:-2.8361111,lng:122.070691586225},
{lat:-2.83626381534852,lng:122.070555544444},
{lat:-2.83638887777778,lng:122.070478552381},
{lat:-2.83666665555556,lng:122.070369065858},
{lat:-2.83680561355736,lng:122.070277766667},
{lat:-2.83694443333333,lng:122.070192117508},
{lat:-2.83714499236475,lng:122.069999988889},
{lat:-2.83722221111111,lng:122.069910450554},
{lat:-2.83739460668908,lng:122.069722211111},
{lat:-2.83749998888889,lng:122.069547990204},
{lat:-2.83768198201776,lng:122.069722211111},
{lat:-2.83758606601885,lng:122.069999988889},
{lat:-2.83749998888889,lng:122.070202558166},
{lat:-2.83745676015008,lng:122.070277766667},
{lat:-2.83723205156606,lng:122.070555544444},
{lat:-2.83722221111111,lng:122.070564096335},
{lat:-2.83694443333333,lng:122.070666961751},
{lat:-2.83675661653732,lng:122.070833322222},
{lat:-2.83666665555556,lng:122.070921540573},
{lat:-2.83651047858438,lng:122.0711111},
{lat:-2.8364087910128,lng:122.071388877778},
{lat:-2.83638887777778,lng:122.071488717615},
{lat:-2.83635586533659,lng:122.071666655556},
{lat:-2.83633160532694,lng:122.071944433333},
{lat:-2.8361111,lng:122.072214452518},
{lat:-2.83610349195586,lng:122.072222211111},
{lat:-2.8358496872832,lng:122.072499988889},
{lat:-2.83583332222222,lng:122.0725116524},
{lat:-2.83558628491619,lng:122.072777766667},
{lat:-2.83555554444445,lng:122.072805341663},
{lat:-2.83527776666667,lng:122.072983641731},
{lat:-2.83513370265338,lng:122.073055544444},
{lat:-2.83509407646593,lng:122.073333322222},
{lat:-2.8350995948334,lng:122.0736111},
{lat:-2.83506865008053,lng:122.073888877778},
{lat:-2.83500276134053,lng:122.074166655556},
{lat:-2.83499998888889,lng:122.074181442368},
{lat:-2.83495777499876,lng:122.074444433333},
{lat:-2.83495811213259,lng:122.074722211111},
{lat:-2.83494023172358,lng:122.074999988889},
{lat:-2.83486184503605,lng:122.075277766667},
{lat:-2.83482252914296,lng:122.075555544444},
{lat:-2.83492987571074,lng:122.075833322222},
{lat:-2.83489526707395,lng:122.0761111},
{lat:-2.83472221111111,lng:122.076264298131},
{lat:-2.83462221226309,lng:122.0761111},
{lat:-2.83444443333333,lng:122.075948363426},
{lat:-2.83432803264988,lng:122.075833322222},
{lat:-2.83418993708706,lng:122.075555544444},
{lat:-2.83416665555556,lng:122.075511920151},
{lat:-2.83405619245464,lng:122.075277766667},
{lat:-2.83388887777778,lng:122.075049156955},
{lat:-2.83383432655162,lng:122.074999988889},
{lat:-2.83365281480527,lng:122.074722211111},
{lat:-2.8336111,lng:122.07464013451},
{lat:-2.83351176984829,lng:122.074444433333},
{lat:-2.83334879388548,lng:122.074166655556},
{lat:-2.83333332222222,lng:122.074135114769},
{lat:-2.83322648083429,lng:122.073888877778},
{lat:-2.83319066776802,lng:122.0736111},
{lat:-2.8330802800355,lng:122.073333322222},
{lat:-2.83305554444445,lng:122.073301529701},
{lat:-2.83293840410864,lng:122.073333322222},
{lat:-2.83277776666667,lng:122.073376287065},
{lat:-2.83249998888889,lng:122.07334040613},
{lat:-2.83244116463772,lng:122.073333322222},
{lat:-2.83222221111111,lng:122.073310281114},
{lat:-2.83194443333334,lng:122.073220926794},
{lat:-2.83166665555556,lng:122.073067177556},
{lat:-2.8316466272881,lng:122.073055544444},
{lat:-2.83138887777778,lng:122.072884668555},
{lat:-2.8311111,lng:122.072907760259},
{lat:-2.83083332222222,lng:122.073033836767},
{lat:-2.83069443333333,lng:122.073033836767},]},
{ID:38,coord:[{lat:-2.83069443333333,lng:122.085495365484},
{lat:-2.83083332222222,lng:122.085495365484},
{lat:-2.8311111,lng:122.085285792234},
{lat:-2.83135295560021,lng:122.085555544444},
{lat:-2.83114533553779,lng:122.085833322222},
{lat:-2.83116939240239,lng:122.0861111},
{lat:-2.83138887777778,lng:122.086199864009},
{lat:-2.83166665555556,lng:122.086116827377},
{lat:-2.83166753170566,lng:122.0861111},
{lat:-2.83169251514029,lng:122.085833322222},
{lat:-2.83166665555556,lng:122.085798779383},
{lat:-2.83142170686778,lng:122.085555544444},
{lat:-2.83138887777778,lng:122.085510773324},
{lat:-2.83112139507623,lng:122.085277766667},
{lat:-2.83138887777778,lng:122.085116125354},
{lat:-2.8314773491232,lng:122.084999988889},
{lat:-2.83166665555556,lng:122.08487114831},
{lat:-2.83194443333334,lng:122.084769346087},
{lat:-2.83222221111111,lng:122.084975235871},
{lat:-2.83224652615175,lng:122.084999988889},
{lat:-2.8322520049104,lng:122.085277766667},
{lat:-2.83223623976216,lng:122.085555544444},
{lat:-2.83227235460766,lng:122.085833322222},
{lat:-2.83231891277981,lng:122.0861111},
{lat:-2.83235552921765,lng:122.086388877778},
{lat:-2.83240800262841,lng:122.086666655556},
{lat:-2.83247852722878,lng:122.086944433333},
{lat:-2.83249998888889,lng:122.087017132731},
{lat:-2.83256164225777,lng:122.087222211111},
{lat:-2.83265495043084,lng:122.087499988889},
{lat:-2.83270012026489,lng:122.087777766667},
{lat:-2.83277776666667,lng:122.087971978773},
{lat:-2.83297099355827,lng:122.088055544444},
{lat:-2.83305554444445,lng:122.088062947466},
{lat:-2.83316504298793,lng:122.088055544444},
{lat:-2.83333332222222,lng:122.088046848655},
{lat:-2.83337225596716,lng:122.088055544444},
{lat:-2.8336111,lng:122.088081964716},
{lat:-2.83379202844382,lng:122.088055544444},
{lat:-2.83388887777778,lng:122.088033163721},
{lat:-2.83416665555556,lng:122.087900672941},
{lat:-2.83444443333333,lng:122.087779870004},
{lat:-2.8344779460304,lng:122.087777766667},
{lat:-2.83472221111111,lng:122.087750434163},
{lat:-2.83499998888889,lng:122.0876381673},
{lat:-2.83527776666667,lng:122.087594919507},
{lat:-2.83555554444445,lng:122.087535935769},
{lat:-2.83583332222222,lng:122.087671152402},
{lat:-2.8361111,lng:122.087767591685},
{lat:-2.83638887777778,lng:122.087672695756},
{lat:-2.83666665555556,lng:122.087627755485},
{lat:-2.83694443333333,lng:122.087685104054},
{lat:-2.83720718447103,lng:122.087499988889},
{lat:-2.83722221111111,lng:122.087490957843},
{lat:-2.83749998888889,lng:122.08730402312},
{lat:-2.83777776666667,lng:122.087250642818},
{lat:-2.83802580033207,lng:122.087499988889},
{lat:-2.83787237543369,lng:122.087777766667},
{lat:-2.83777776666667,lng:122.087995682505},
{lat:-2.837751308141,lng:122.088055544444},
{lat:-2.83749998888889,lng:122.088293649363},
{lat:-2.83743624398226,lng:122.088333322222},
{lat:-2.83722221111111,lng:122.088469194306},
{lat:-2.8370034112966,lng:122.0886111},
{lat:-2.83694443333333,lng:122.088675876902},
{lat:-2.83668153201618,lng:122.088888877778},
{lat:-2.83666665555556,lng:122.088897743576},
{lat:-2.83638887777778,lng:122.088907963132},
{lat:-2.8361111,lng:122.089010417441},
{lat:-2.83583332222222,lng:122.089125334083},
{lat:-2.83555554444445,lng:122.089148070467},
{lat:-2.83552196129084,lng:122.089166655556},
{lat:-2.83530362892825,lng:122.089444433333},
{lat:-2.83530362892825,lng:122.089583322222},]},
{ID:39,coord:[{lat:-2.83778675780555,lng:122.069444433333},
{lat:-2.83777776666667,lng:122.069477735465},
{lat:-2.83774786881018,lng:122.069444433333},
{lat:-2.83774260562929,lng:122.069166655556},
{lat:-2.83771069972203,lng:122.068888877778},
{lat:-2.83771332025148,lng:122.0686111},
{lat:-2.83777776666667,lng:122.068555503424},
{lat:-2.83799936168243,lng:122.0686111},
{lat:-2.83796820160001,lng:122.068888877778},
{lat:-2.83783117708332,lng:122.069166655556},
{lat:-2.83778675780555,lng:122.069444433333},]},
{ID:40,coord:[{lat:-2.83777776666667,lng:122.066802048299},
{lat:-2.83800219312629,lng:122.066666655556},
{lat:-2.83798566153905,lng:122.066388877778},
{lat:-2.8379728323525,lng:122.0661111},
{lat:-2.83800520820725,lng:122.065833322222},
{lat:-2.83785981291969,lng:122.065555544444},
{lat:-2.83777776666667,lng:122.065492417528},
{lat:-2.83761780550691,lng:122.065277766667},
{lat:-2.83749998888889,lng:122.065151701851},
{lat:-2.83722221111111,lng:122.065271498682},
{lat:-2.83722023910869,lng:122.065277766667},
{lat:-2.83722221111111,lng:122.065281267264},
{lat:-2.83735407917185,lng:122.065555544444},
{lat:-2.83735157767722,lng:122.065833322222},
{lat:-2.83726007774329,lng:122.0661111},
{lat:-2.83722221111111,lng:122.066196182039},
{lat:-2.83711196791259,lng:122.066388877778},
{lat:-2.83718826060477,lng:122.066666655556},
{lat:-2.83722221111111,lng:122.066728908095},
{lat:-2.83749998888889,lng:122.066766275676},
{lat:-2.83777776666667,lng:122.066802048299},]},
{ID:41,coord:[{lat:-2.83777776666667,lng:122.064525797261},
{lat:-2.83795577843979,lng:122.064444433333},
{lat:-2.83777776666667,lng:122.064226373831},
{lat:-2.83749998888889,lng:122.064312842211},
{lat:-2.83743271038644,lng:122.064444433333},
{lat:-2.83749998888889,lng:122.064553178234},
{lat:-2.83777776666667,lng:122.064525797261},]},
{ID:42,coord:[{lat:-2.83320578619303,lng:122.063472211111},
{lat:-2.83320578619303,lng:122.0636111},
{lat:-2.8331630232437,lng:122.063888877778},
{lat:-2.83321164989323,lng:122.064166655556},
{lat:-2.83330445921161,lng:122.064444433333},
{lat:-2.83333332222222,lng:122.064567993851},
{lat:-2.8333733928505,lng:122.064722211111},
{lat:-2.83350526173118,lng:122.064999988889},
{lat:-2.8336111,lng:122.06520722532},
{lat:-2.83365206390563,lng:122.065277766667},
{lat:-2.83377873235505,lng:122.065555544444},
{lat:-2.83388887777778,lng:122.065674398281},
{lat:-2.83416665555556,lng:122.065688307671},
{lat:-2.83425460954358,lng:122.065833322222},
{lat:-2.83444443333333,lng:122.066067482069},
{lat:-2.83472221111111,lng:122.066109761171},
{lat:-2.83472395926025,lng:122.0661111},
{lat:-2.83472221111111,lng:122.066115015524},
{lat:-2.83456422465513,lng:122.066388877778},
{lat:-2.83444443333333,lng:122.066599402327},
{lat:-2.83441484852801,lng:122.066666655556},
{lat:-2.83435714025445,lng:122.066944433333},
{lat:-2.83416665555556,lng:122.067141344326},
{lat:-2.83410726406985,lng:122.067222211111},
{lat:-2.83407087182473,lng:122.067499988889},
{lat:-2.83394865626112,lng:122.067777766667},
{lat:-2.83388887777778,lng:122.067975293151},
{lat:-2.83386431686989,lng:122.068055544444},
{lat:-2.83378883009388,lng:122.068333322222},
{lat:-2.83364594317389,lng:122.0686111},
{lat:-2.8336111,lng:122.068643297667},
{lat:-2.83347451118724,lng:122.068888877778},
{lat:-2.83334413161654,lng:122.069166655556},
{lat:-2.83338430114697,lng:122.069444433333},
{lat:-2.83344593821921,lng:122.069722211111},
{lat:-2.83346571711785,lng:122.069999988889},
{lat:-2.833399658599,lng:122.070277766667},
{lat:-2.83333332222222,lng:122.070549210405},
{lat:-2.83333180854119,lng:122.070555544444},
{lat:-2.83333332222222,lng:122.070616793347},
{lat:-2.83333825836136,lng:122.070833322222},
{lat:-2.83333332222222,lng:122.070862690074},
{lat:-2.83327414611585,lng:122.0711111},
{lat:-2.83333332222222,lng:122.071351565235},
{lat:-2.83334368567396,lng:122.071388877778},
{lat:-2.8336111,lng:122.071550717584},
{lat:-2.83388887777778,lng:122.071659805912},
{lat:-2.83397240465903,lng:122.071666655556},
{lat:-2.83416665555556,lng:122.071691256548},
{lat:-2.83419059281848,lng:122.071666655556},
{lat:-2.83444443333333,lng:122.071493967658},
{lat:-2.83464807469744,lng:122.071388877778},
{lat:-2.83472221111111,lng:122.071356815209},
{lat:-2.83499998888889,lng:122.071220347991},
{lat:-2.83524616194443,lng:122.0711111},
{lat:-2.83527776666667,lng:122.071099342918},
{lat:-2.83554918821892,lng:122.070833322222},
{lat:-2.83555554444445,lng:122.070827478778},
{lat:-2.83583332222222,lng:122.070624129434},
{lat:-2.83595527085114,lng:122.070555544444},
{lat:-2.8361111,lng:122.070489138695},
{lat:-2.83638887777778,lng:122.070327320772},
{lat:-2.83649764837253,lng:122.070277766667},
{lat:-2.83666665555556,lng:122.070213208197},
{lat:-2.83694443333333,lng:122.070017458519},
{lat:-2.83696266951331,lng:122.069999988889},
{lat:-2.83720341858396,lng:122.069722211111},
{lat:-2.83722221111111,lng:122.069697412391},
{lat:-2.83739527875752,lng:122.069444433333},
{lat:-2.83749641918141,lng:122.069166655556},
{lat:-2.83749998888889,lng:122.069134520051},
{lat:-2.8375339872238,lng:122.068888877778},
{lat:-2.83751227013768,lng:122.0686111},
{lat:-2.83777776666667,lng:122.068382061689},
{lat:-2.83805554444445,lng:122.068368604068},
{lat:-2.83826540792851,lng:122.0686111},
{lat:-2.83822082070467,lng:122.068888877778},
{lat:-2.83817507301503,lng:122.069166655556},
{lat:-2.83805554444445,lng:122.069363711845},
{lat:-2.83801717397788,lng:122.069444433333},
{lat:-2.8379209529033,lng:122.069722211111},
{lat:-2.83785685440497,lng:122.069999988889},
{lat:-2.83777776666667,lng:122.070204565005},
{lat:-2.83774417850524,lng:122.070277766667},
{lat:-2.83749998888889,lng:122.070549771956},
{lat:-2.83749502010918,lng:122.070555544444},
{lat:-2.83722221111111,lng:122.070792630302},
{lat:-2.8371151785575,lng:122.070833322222},
{lat:-2.83694443333333,lng:122.070898822299},
{lat:-2.83672840452028,lng:122.0711111},
{lat:-2.83666665555556,lng:122.071210907452},
{lat:-2.83658430493522,lng:122.071388877778},
{lat:-2.83654537475405,lng:122.071666655556},
{lat:-2.8365561226774,lng:122.071944433333},
{lat:-2.83641500095162,lng:122.072222211111},
{lat:-2.83638887777778,lng:122.072243442884},
{lat:-2.83613102987592,lng:122.072499988889},
{lat:-2.8361111,lng:122.072512270158},
{lat:-2.83583332222222,lng:122.072708921908},
{lat:-2.83576941274487,lng:122.072777766667},
{lat:-2.83555554444445,lng:122.072969612052},
{lat:-2.83541935313106,lng:122.073055544444},
{lat:-2.83531640325801,lng:122.073333322222},
{lat:-2.83529857148695,lng:122.0736111},
{lat:-2.83531544214472,lng:122.073888877778},
{lat:-2.83529510507388,lng:122.074166655556},
{lat:-2.83527776666667,lng:122.074402919068},
{lat:-2.83527428381695,lng:122.074444433333},
{lat:-2.83527776666667,lng:122.07445676606},
{lat:-2.83533497166462,lng:122.074722211111},
{lat:-2.83535110792696,lng:122.074999988889},
{lat:-2.83527776666667,lng:122.075203978},
{lat:-2.83522892175578,lng:122.075277766667},
{lat:-2.8352415615808,lng:122.075555544444},
{lat:-2.83527776666667,lng:122.075667717504},
{lat:-2.83531246865123,lng:122.075833322222},
{lat:-2.83527776666667,lng:122.075992830492},
{lat:-2.83525576008605,lng:122.0761111},
{lat:-2.8350955151425,lng:122.076388877778},
{lat:-2.83499998888889,lng:122.076458265409},
{lat:-2.83472221111111,lng:122.076517484602},
{lat:-2.83458807704674,lng:122.076388877778},
{lat:-2.83444443333333,lng:122.076205171495},
{lat:-2.83429179685197,lng:122.0761111},
{lat:-2.83416665555556,lng:122.075988005391},
{lat:-2.8340071521595,lng:122.075833322222},
{lat:-2.83388887777778,lng:122.07561740905},
{lat:-2.83386618914334,lng:122.075555544444},
{lat:-2.83377478016275,lng:122.075277766667},
{lat:-2.8336111,lng:122.075132629664},
{lat:-2.83333332222222,lng:122.075028851344},
{lat:-2.83326942959753,lng:122.074999988889},
{lat:-2.8331238060272,lng:122.074722211111},
{lat:-2.8330999172383,lng:122.074444433333},
{lat:-2.83305554444445,lng:122.07427863512},
{lat:-2.83303349589252,lng:122.074166655556},
{lat:-2.83297231606385,lng:122.073888877778},
{lat:-2.83277776666667,lng:122.073641217171},
{lat:-2.83273539666337,lng:122.0736111},
{lat:-2.83249998888889,lng:122.073534078056},
{lat:-2.83222221111111,lng:122.073459265838},
{lat:-2.83194443333334,lng:122.073392241968},
{lat:-2.83187020563407,lng:122.073333322222},
{lat:-2.83166665555556,lng:122.073206456957},
{lat:-2.83140683545505,lng:122.073055544444},
{lat:-2.83138887777778,lng:122.073043639343},
{lat:-2.8311111,lng:122.073051815357},
{lat:-2.83110303563568,lng:122.073055544444},
{lat:-2.83088234518353,lng:122.073333322222},
{lat:-2.83083332222222,lng:122.073393064551},
{lat:-2.83069443333333,lng:122.073393064551},]},
{ID:43,coord:[{lat:-2.83337943833139,lng:122.063472211111},
{lat:-2.83337943833139,lng:122.0636111},
{lat:-2.83333332222222,lng:122.06388120016},
{lat:-2.83333217494544,lng:122.063888877778},
{lat:-2.83333332222222,lng:122.0638946023},
{lat:-2.83338972237165,lng:122.064166655556},
{lat:-2.83347753539235,lng:122.064444433333},
{lat:-2.83358339098687,lng:122.064722211111},
{lat:-2.8336111,lng:122.064770886265},
{lat:-2.83373385363499,lng:122.064999988889},
{lat:-2.83388887777778,lng:122.065209753814},
{lat:-2.83398278779276,lng:122.065277766667},
{lat:-2.83416665555556,lng:122.065384864015},
{lat:-2.83444443333333,lng:122.065546629776},
{lat:-2.83445333352282,lng:122.065555544444},
{lat:-2.83471900691858,lng:122.065833322222},
{lat:-2.83472221111111,lng:122.065834967979},
{lat:-2.83499998888889,lng:122.065986751757},
{lat:-2.83503958134514,lng:122.0661111},
{lat:-2.83499998888889,lng:122.066215486741},
{lat:-2.83489787678437,lng:122.066388877778},
{lat:-2.83472269650122,lng:122.066666655556},
{lat:-2.83472221111111,lng:122.066669381816},
{lat:-2.83466973761914,lng:122.066944433333},
{lat:-2.83472221111111,lng:122.067166794032},
{lat:-2.83472966055084,lng:122.067222211111},
{lat:-2.83472221111111,lng:122.067300542726},
{lat:-2.83465423146715,lng:122.067499988889},
{lat:-2.83446128980424,lng:122.067777766667},
{lat:-2.83444443333333,lng:122.067802459201},
{lat:-2.83423499695881,lng:122.068055544444},
{lat:-2.83420155106331,lng:122.068333322222},
{lat:-2.83417486148825,lng:122.0686111},
{lat:-2.83416665555556,lng:122.068652151568},
{lat:-2.83393256175249,lng:122.068888877778},
{lat:-2.83388887777778,lng:122.06893775911},
{lat:-2.83374755233259,lng:122.069166655556},
{lat:-2.83375187343694,lng:122.069444433333},
{lat:-2.83388331889054,lng:122.069722211111},
{lat:-2.83388887777778,lng:122.06982540136},
{lat:-2.83390216166173,lng:122.069999988889},
{lat:-2.83388887777778,lng:122.070022804312},
{lat:-2.83378827424127,lng:122.070277766667},
{lat:-2.83364123712962,lng:122.070555544444},
{lat:-2.83362742739556,lng:122.070833322222},
{lat:-2.83369864404763,lng:122.0711111},
{lat:-2.83383238905818,lng:122.071388877778},
{lat:-2.83388887777778,lng:122.071414479527},
{lat:-2.83411351214587,lng:122.071388877778},
{lat:-2.83416665555556,lng:122.071371558413},
{lat:-2.83444443333333,lng:122.071186012827},
{lat:-2.83472221111111,lng:122.071146637416},
{lat:-2.83478038125203,lng:122.0711111},
{lat:-2.83499998888889,lng:122.070964893205},
{lat:-2.83527776666667,lng:122.070846134853},
{lat:-2.83529083942472,lng:122.070833322222},
{lat:-2.83555554444445,lng:122.070589971985},
{lat:-2.83560297750579,lng:122.070555544444},
{lat:-2.83583332222222,lng:122.070440028646},
{lat:-2.8361111,lng:122.070313615803},
{lat:-2.83617582245916,lng:122.070277766667},
{lat:-2.83638887777778,lng:122.070177995167},
{lat:-2.83666665555556,lng:122.070086407249},
{lat:-2.83678635994565,lng:122.069999988889},
{lat:-2.83694443333333,lng:122.069822746136},
{lat:-2.83703195999293,lng:122.069722211111},
{lat:-2.83722221111111,lng:122.069471154706},
{lat:-2.83724049169554,lng:122.069444433333},
{lat:-2.83735459174914,lng:122.069166655556},
{lat:-2.83738838380573,lng:122.068888877778},
{lat:-2.83738678897878,lng:122.0686111},
{lat:-2.83749998888889,lng:122.068421881634},
{lat:-2.83760536138996,lng:122.068333322222},
{lat:-2.83777776666667,lng:122.068220864323},
{lat:-2.83805554444445,lng:122.068241622202},
{lat:-2.83833332222222,lng:122.068229382734},
{lat:-2.83853917800214,lng:122.068333322222},
{lat:-2.83848469495532,lng:122.0686111},
{lat:-2.83844032860495,lng:122.068888877778},
{lat:-2.83847629790564,lng:122.069166655556},
{lat:-2.83840127162394,lng:122.069444433333},
{lat:-2.83833332222222,lng:122.069492941995},
{lat:-2.83814858426372,lng:122.069722211111},
{lat:-2.83808507146309,lng:122.069999988889},
{lat:-2.83807238829795,lng:122.070277766667},
{lat:-2.83805554444445,lng:122.070327661928},
{lat:-2.83777776666667,lng:122.070460750279},
{lat:-2.83768981095637,lng:122.070555544444},
{lat:-2.83749998888889,lng:122.07077067824},
{lat:-2.8374283432346,lng:122.070833322222},
{lat:-2.83722221111111,lng:122.07099841856},
{lat:-2.83697888875618,lng:122.0711111},
{lat:-2.83694443333333,lng:122.071133628054},
{lat:-2.83676970153898,lng:122.071388877778},
{lat:-2.83675028228375,lng:122.071666655556},
{lat:-2.83679831535459,lng:122.071944433333},
{lat:-2.83677910663967,lng:122.072222211111},
{lat:-2.83666665555556,lng:122.072415199048},
{lat:-2.83655551714548,lng:122.072499988889},
{lat:-2.83638887777778,lng:122.072570457985},
{lat:-2.8361111,lng:122.072733350545},
{lat:-2.8360455925219,lng:122.072777766667},
{lat:-2.83583332222222,lng:122.072970682245},
{lat:-2.83572909988449,lng:122.073055544444},
{lat:-2.83555554444445,lng:122.073254554067},
{lat:-2.83551757334497,lng:122.073333322222},
{lat:-2.83550047652208,lng:122.0736111},
{lat:-2.83555554444445,lng:122.073820203973},
{lat:-2.83558047932399,lng:122.073888877778},
{lat:-2.835583598015,lng:122.074166655556},
{lat:-2.83555554444445,lng:122.074323547195},
{lat:-2.83553594721567,lng:122.074444433333},
{lat:-2.83555554444445,lng:122.074549312226},
{lat:-2.83558546133403,lng:122.074722211111},
{lat:-2.83562082433468,lng:122.074999988889},
{lat:-2.83563740041454,lng:122.075277766667},
{lat:-2.8356567986008,lng:122.075555544444},
{lat:-2.83562817502858,lng:122.075833322222},
{lat:-2.83555554444445,lng:122.076044845456},
{lat:-2.83552600258778,lng:122.0761111},
{lat:-2.83538434920699,lng:122.076388877778},
{lat:-2.83527776666667,lng:122.076629506946},
{lat:-2.83512297768805,lng:122.076666655556},
{lat:-2.83499998888889,lng:122.076674117961},
{lat:-2.83472221111111,lng:122.076691557393},
{lat:-2.83463948174203,lng:122.076666655556},
{lat:-2.83444443333333,lng:122.076492510899},
{lat:-2.83433248147948,lng:122.076388877778},
{lat:-2.83416665555556,lng:122.076297169448},
{lat:-2.83388887777778,lng:122.076212044988},
{lat:-2.83372629417544,lng:122.0761111},
{lat:-2.83370470975964,lng:122.075833322222},
{lat:-2.8336111,lng:122.075572186848},
{lat:-2.8335977525719,lng:122.075555544444},
{lat:-2.83333332222222,lng:122.075414414589},
{lat:-2.83310073712047,lng:122.075277766667},
{lat:-2.83305554444445,lng:122.075250725818},
{lat:-2.83277776666667,lng:122.075084854145},
{lat:-2.83272186029192,lng:122.074999988889},
{lat:-2.83267504549775,lng:122.074722211111},
{lat:-2.83262476112605,lng:122.074444433333},
{lat:-2.83263930184768,lng:122.074166655556},
{lat:-2.83262028020683,lng:122.073888877778},
{lat:-2.83249998888889,lng:122.073793643646},
{lat:-2.83224009245557,lng:122.0736111},
{lat:-2.83222221111111,lng:122.073605058383},
{lat:-2.83194443333334,lng:122.073569624273},
{lat:-2.83166665555556,lng:122.073353745331},
{lat:-2.83162458593142,lng:122.073333322222},
{lat:-2.83138887777778,lng:122.073235941794},
{lat:-2.83116206025473,lng:122.073333322222},
{lat:-2.8311111,lng:122.073387890618},
{lat:-2.83094436093329,lng:122.0736111},
{lat:-2.83083332222222,lng:122.073735186415},
{lat:-2.83069443333333,lng:122.073735186415},]},
{ID:44,coord:[{lat:-2.83850910278649,lng:122.089583322222},
{lat:-2.83850910278649,lng:122.089444433333},
{lat:-2.83833332222222,lng:122.089332099408},
{lat:-2.83820321017967,lng:122.089444433333},
{lat:-2.83820321017967,lng:122.089583322222},]},
{ID:45,coord:[{lat:-2.83862714197413,lng:122.068055544444},
{lat:-2.8386111,lng:122.068124121969},
{lat:-2.83859329732858,lng:122.068055544444},
{lat:-2.8386111,lng:122.068045087582},
{lat:-2.83862714197413,lng:122.068055544444},]},
{ID:46,coord:[{lat:-2.8387961909992,lng:122.089583322222},
{lat:-2.8387961909992,lng:122.089444433333},
{lat:-2.83870486990026,lng:122.089166655556},
{lat:-2.8386111,lng:122.088977933782},
{lat:-2.83833332222222,lng:122.088907259374},
{lat:-2.83807198848239,lng:122.089166655556},
{lat:-2.83805554444445,lng:122.089181256389},
{lat:-2.83783669189822,lng:122.089444433333},
{lat:-2.83783669189822,lng:122.089583322222},]},
{ID:47,coord:[{lat:-2.83868790024619,lng:122.089583322222},
{lat:-2.83868790024619,lng:122.089444433333},
{lat:-2.8386111,lng:122.089208096301},
{lat:-2.83852167061518,lng:122.089166655556},
{lat:-2.83833332222222,lng:122.089112818091},
{lat:-2.83827908261946,lng:122.089166655556},
{lat:-2.83805554444445,lng:122.089365137437},
{lat:-2.8379896036044,lng:122.089444433333},
{lat:-2.8379896036044,lng:122.089583322222},]},
{ID:48,coord:[{lat:-2.8386111,lng:122.065867284195},
{lat:-2.83862889906538,lng:122.065833322222},
{lat:-2.8386111,lng:122.065561314172},
{lat:-2.83860810188042,lng:122.065555544444},
{lat:-2.8386111,lng:122.065552293807},
{lat:-2.83868335229122,lng:122.065277766667},
{lat:-2.83878621655346,lng:122.064999988889},
{lat:-2.8386111,lng:122.064837564988},
{lat:-2.83833332222222,lng:122.064893364018},
{lat:-2.83812495678328,lng:122.064999988889},
{lat:-2.8381214445053,lng:122.065277766667},
{lat:-2.83823908911613,lng:122.065555544444},
{lat:-2.83810133596269,lng:122.065833322222},
{lat:-2.83833332222222,lng:122.066066610442},
{lat:-2.8386111,lng:122.065867284195},]},
{ID:49,coord:[{lat:-2.83947427581383,lng:122.066944433333},
{lat:-2.83944443333333,lng:122.067032137622},
{lat:-2.83940149268428,lng:122.066944433333},
{lat:-2.83944443333333,lng:122.066874355809},
{lat:-2.83947427581383,lng:122.066944433333},]},
{ID:50,coord:[{lat:-2.83961418991222,lng:122.089583322222},
{lat:-2.83961418991222,lng:122.089444433333},
{lat:-2.83946431831241,lng:122.089166655556},
{lat:-2.83944443333333,lng:122.089144114856},
{lat:-2.83942706183107,lng:122.089166655556},
{lat:-2.83916665555556,lng:122.089438687733},
{lat:-2.83888887777778,lng:122.089398021047},
{lat:-2.83881195669435,lng:122.089166655556},
{lat:-2.83869759999897,lng:122.088888877778},
{lat:-2.8386111,lng:122.088748755679},
{lat:-2.83833332222222,lng:122.088689632428},
{lat:-2.8381326904815,lng:122.088888877778},
{lat:-2.83805554444445,lng:122.088965415565},
{lat:-2.83791592264304,lng:122.089166655556},
{lat:-2.83777776666667,lng:122.089332518396},
{lat:-2.83766113600137,lng:122.089444433333},
{lat:-2.83766113600137,lng:122.089583322222},]},
{ID:51,coord:[{lat:-2.83944939459376,lng:122.089583322222},
{lat:-2.83944939459376,lng:122.089444433333},
{lat:-2.83944443333333,lng:122.089435385212},
{lat:-2.83943604203925,lng:122.089444433333},
{lat:-2.83943604203925,lng:122.089583322222},]},
{ID:52,coord:[{lat:-2.8395412857934,lng:122.065277766667},
{lat:-2.83944443333333,lng:122.065356414154},
{lat:-2.83935760743655,lng:122.065277766667},
{lat:-2.83944443333333,lng:122.065177534604},
{lat:-2.8395412857934,lng:122.065277766667},]},
{ID:53,coord:[{lat:-2.83944443333333,lng:122.07864833785},
{lat:-2.83946539420538,lng:122.0786111},
{lat:-2.83955423066069,lng:122.078333322222},
{lat:-2.83956041527098,lng:122.078055544444},
{lat:-2.83944443333333,lng:122.077989680755},
{lat:-2.83935709229844,lng:122.078055544444},
{lat:-2.83916665555556,lng:122.078313802347},
{lat:-2.8391539305223,lng:122.078333322222},
{lat:-2.83902234624873,lng:122.0786111},
{lat:-2.83893502189266,lng:122.078888877778},
{lat:-2.83888887777778,lng:122.079048428649},
{lat:-2.83886307513652,lng:122.079166655556},
{lat:-2.83888887777778,lng:122.079217855734},
{lat:-2.83916665555556,lng:122.079169831597},
{lat:-2.83916942279506,lng:122.079166655556},
{lat:-2.83927743082907,lng:122.078888877778},
{lat:-2.83944443333333,lng:122.07864833785},]},
{ID:54,coord:[{lat:-2.84007315210846,lng:122.087777766667},
{lat:-2.83999998888889,lng:122.087847639816},
{lat:-2.83972221111111,lng:122.087904413767},
{lat:-2.83944443333333,lng:122.08801389255},
{lat:-2.83916665555556,lng:122.087852873303},
{lat:-2.83912398411235,lng:122.087777766667},
{lat:-2.83906438042229,lng:122.087499988889},
{lat:-2.83916665555556,lng:122.087439781702},
{lat:-2.83944443333333,lng:122.087392431624},
{lat:-2.83972221111111,lng:122.08735006425},
{lat:-2.83998181609178,lng:122.087222211111},
{lat:-2.83999998888889,lng:122.087216987555},
{lat:-2.84000900434268,lng:122.087222211111},
{lat:-2.84008464317175,lng:122.087499988889},
{lat:-2.84007315210846,lng:122.087777766667},]},
{ID:55,coord:[{lat:-2.84025925698919,lng:122.087777766667},
{lat:-2.83999998888889,lng:122.088025375763},
{lat:-2.83993388740064,lng:122.088055544444},
{lat:-2.83972221111111,lng:122.088162624241},
{lat:-2.83944443333333,lng:122.088201408607},
{lat:-2.83918342969111,lng:122.088055544444},
{lat:-2.83916665555556,lng:122.088044728857},
{lat:-2.83901498238165,lng:122.087777766667},
{lat:-2.83888887777778,lng:122.087527125145},
{lat:-2.83884025541245,lng:122.087499988889},
{lat:-2.83888887777778,lng:122.087459645103},
{lat:-2.83916665555556,lng:122.08732167341},
{lat:-2.83944443333333,lng:122.087265995804},
{lat:-2.8395704544637,lng:122.087222211111},
{lat:-2.83972221111111,lng:122.087178810413},
{lat:-2.83999998888889,lng:122.087088989425},
{lat:-2.8402299191977,lng:122.087222211111},
{lat:-2.8402217964034,lng:122.087499988889},
{lat:-2.84025925698919,lng:122.087777766667},]},
{ID:56,coord:[{lat:-2.83069443333333,lng:122.082599149733},
{lat:-2.83083332222222,lng:122.082599149733},
{lat:-2.83091897746336,lng:122.082777766667},
{lat:-2.83106789941372,lng:122.083055544444},
{lat:-2.8311111,lng:122.083140486482},
{lat:-2.83123443428263,lng:122.083333322222},
{lat:-2.83138887777778,lng:122.083460003424},
{lat:-2.83159808051195,lng:122.0836111},
{lat:-2.83166665555556,lng:122.083657554259},
{lat:-2.83194443333334,lng:122.083862573393},
{lat:-2.83199162468516,lng:122.083888877778},
{lat:-2.83194443333334,lng:122.084141834656},
{lat:-2.83194204953647,lng:122.084166655556},
{lat:-2.83194443333334,lng:122.08417236282},
{lat:-2.8321208797812,lng:122.084444433333},
{lat:-2.83222221111111,lng:122.08450430264},
{lat:-2.83249998888889,lng:122.08468087176},
{lat:-2.83252614618782,lng:122.084722211111},
{lat:-2.83271438483711,lng:122.084999988889},
{lat:-2.83255985999945,lng:122.085277766667},
{lat:-2.83249998888889,lng:122.085469397014},
{lat:-2.83248891432799,lng:122.085555544444},
{lat:-2.83249998888889,lng:122.085615043989},
{lat:-2.83254620346529,lng:122.085833322222},
{lat:-2.832579066955,lng:122.0861111},
{lat:-2.83268478597498,lng:122.086388877778},
{lat:-2.83277140006338,lng:122.086666655556},
{lat:-2.83273616392912,lng:122.086944433333},
{lat:-2.83277776666667,lng:122.087121074949},
{lat:-2.83279915696744,lng:122.087222211111},
{lat:-2.8329104142459,lng:122.087499988889},
{lat:-2.83305554444445,lng:122.087711660039},
{lat:-2.8331467732779,lng:122.087777766667},
{lat:-2.83333332222222,lng:122.087863345544},
{lat:-2.8336111,lng:122.08794931273},
{lat:-2.83388887777778,lng:122.087889339999},
{lat:-2.83409347910531,lng:122.087777766667},
{lat:-2.83416665555556,lng:122.087706903263},
{lat:-2.83444443333333,lng:122.087528727047},
{lat:-2.83466353692775,lng:122.087499988889},
{lat:-2.83472221111111,lng:122.087488218449},
{lat:-2.83499998888889,lng:122.087359431862},
{lat:-2.83527776666667,lng:122.087392639845},
{lat:-2.83555554444445,lng:122.087342549879},
{lat:-2.83583332222222,lng:122.08745592283},
{lat:-2.83595008861402,lng:122.087499988889},
{lat:-2.8361111,lng:122.087569060193},
{lat:-2.83627882669627,lng:122.087499988889},
{lat:-2.83638887777778,lng:122.087450829103},
{lat:-2.83666665555556,lng:122.087442367192},
{lat:-2.83689550647613,lng:122.087499988889},
{lat:-2.83694443333333,lng:122.087511504532},
{lat:-2.83696077855488,lng:122.087499988889},
{lat:-2.83722221111111,lng:122.087342867301},
{lat:-2.83738512493111,lng:122.087222211111},
{lat:-2.83749998888889,lng:122.08713756211},
{lat:-2.83777776666667,lng:122.086955078021},
{lat:-2.83805554444445,lng:122.086996193077},
{lat:-2.83833332222222,lng:122.087080496828},
{lat:-2.8386111,lng:122.087074017234},
{lat:-2.83888887777778,lng:122.087154651695},
{lat:-2.83916665555556,lng:122.087199701489},
{lat:-2.83944443333333,lng:122.087145321309},
{lat:-2.83972221111111,lng:122.087074736904},
{lat:-2.83999998888889,lng:122.086960991295},
{lat:-2.84005619495987,lng:122.086944433333},
{lat:-2.84027776666667,lng:122.086797192114},
{lat:-2.84045458412386,lng:122.086944433333},
{lat:-2.84043700525723,lng:122.087222211111},
{lat:-2.84040039665128,lng:122.087499988889},
{lat:-2.84042735633738,lng:122.087777766667},
{lat:-2.84038127877276,lng:122.088055544444},
{lat:-2.84027776666667,lng:122.088137563978},
{lat:-2.83999998888889,lng:122.088186876069},
{lat:-2.83975722066834,lng:122.088333322222},
{lat:-2.83972221111111,lng:122.088354312192},
{lat:-2.83944443333333,lng:122.088360268134},
{lat:-2.83928097573458,lng:122.088333322222},
{lat:-2.83916665555556,lng:122.088296416083},
{lat:-2.83907456746954,lng:122.088055544444},
{lat:-2.83890598065096,lng:122.087777766667},
{lat:-2.83888887777778,lng:122.087743773537},
{lat:-2.8386111,lng:122.087562290101},
{lat:-2.83833332222222,lng:122.08755293854},
{lat:-2.83805554444445,lng:122.087773562441},
{lat:-2.83805292474925,lng:122.087777766667},
{lat:-2.83790395984977,lng:122.088055544444},
{lat:-2.83777776666667,lng:122.088206531275},
{lat:-2.83764384389222,lng:122.088333322222},
{lat:-2.83749998888889,lng:122.088463192709},
{lat:-2.83727391103151,lng:122.0886111},
{lat:-2.83722221111111,lng:122.088661098543},
{lat:-2.83701155080623,lng:122.088888877778},
{lat:-2.83694443333333,lng:122.088956263178},
{lat:-2.83666665555556,lng:122.089119012064},
{lat:-2.83638887777778,lng:122.089148155509},
{lat:-2.8361952496273,lng:122.089166655556},
{lat:-2.8361111,lng:122.089173123561},
{lat:-2.83583332222222,lng:122.089247127332},
{lat:-2.83555554444445,lng:122.089312311338},
{lat:-2.83544442667329,lng:122.089444433333},
{lat:-2.83544442667329,lng:122.089583322222},]},
{ID:57,coord:[{lat:-2.84059462089805,lng:122.065555544444},
{lat:-2.84055554444445,lng:122.06564998325},
{lat:-2.84042013378742,lng:122.065555544444},
{lat:-2.84055554444445,lng:122.065301951774},
{lat:-2.84059462089805,lng:122.065555544444},]},
{ID:58,coord:[{lat:-2.838852595018,lng:122.063472211111},
{lat:-2.838852595018,lng:122.0636111},
{lat:-2.8386111,lng:122.063872906208},
{lat:-2.83857265056445,lng:122.063888877778},
{lat:-2.8386111,lng:122.063894400314},
{lat:-2.83888887777778,lng:122.063935084682},
{lat:-2.83912353216868,lng:122.064166655556},
{lat:-2.83916665555556,lng:122.064199881177},
{lat:-2.83929516523202,lng:122.064166655556},
{lat:-2.83944443333333,lng:122.06411795439},
{lat:-2.83972221111111,lng:122.064064175323},
{lat:-2.83999998888889,lng:122.063921219658},
{lat:-2.8400518200133,lng:122.063888877778},
{lat:-2.84027776666667,lng:122.063800578561},
{lat:-2.84055554444445,lng:122.063769374207},
{lat:-2.84057492716674,lng:122.0636111},
{lat:-2.84057492716674,lng:122.063472211111},]},
{ID:59,coord:[{lat:-2.84058208335439,lng:122.074166655556},
{lat:-2.84055554444445,lng:122.074224251818},
{lat:-2.84042236404758,lng:122.074444433333},
{lat:-2.84027776666667,lng:122.074571490455},
{lat:-2.84014326215239,lng:122.074444433333},
{lat:-2.84005905920491,lng:122.074166655556},
{lat:-2.84024536859884,lng:122.073888877778},
{lat:-2.84027776666667,lng:122.073860786607},
{lat:-2.84053430191696,lng:122.0736111},
{lat:-2.84055554444445,lng:122.073596951473},
{lat:-2.84059594274544,lng:122.0736111},
{lat:-2.84064862933851,lng:122.073888877778},
{lat:-2.84058208335439,lng:122.074166655556},]},
{ID:60,coord:[{lat:-2.84055554444445,lng:122.076693516378},
{lat:-2.84057572172826,lng:122.076666655556},
{lat:-2.84055554444445,lng:122.076639542022},
{lat:-2.84054053693291,lng:122.076666655556},
{lat:-2.84055554444445,lng:122.076693516378},]},
{ID:61,coord:[{lat:-2.83069443333333,lng:122.080010297264},
{lat:-2.83083332222222,lng:122.080010297264},
{lat:-2.83103000646377,lng:122.080277766667},
{lat:-2.83109579997378,lng:122.080555544444},
{lat:-2.8311111,lng:122.080574814857},
{lat:-2.83127184989032,lng:122.080833322222},
{lat:-2.83138887777778,lng:122.080998433357},
{lat:-2.8315482669344,lng:122.0811111},
{lat:-2.83166665555556,lng:122.081241324329},
{lat:-2.83177207326224,lng:122.081388877778},
{lat:-2.83166665555556,lng:122.081529004094},
{lat:-2.83149593525793,lng:122.081666655556},
{lat:-2.83138887777778,lng:122.081803838331},
{lat:-2.8313104347047,lng:122.081944433333},
{lat:-2.83130007301156,lng:122.082222211111},
{lat:-2.83131900414935,lng:122.082499988889},
{lat:-2.83138887777778,lng:122.08272400328},
{lat:-2.83144639059373,lng:122.082777766667},
{lat:-2.83154934529798,lng:122.083055544444},
{lat:-2.83166665555556,lng:122.083156837981},
{lat:-2.83194443333334,lng:122.083321519393},
{lat:-2.83222221111111,lng:122.083317312233},
{lat:-2.83225752924953,lng:122.083333322222},
{lat:-2.83249998888889,lng:122.083442826218},
{lat:-2.83255549661711,lng:122.0836111},
{lat:-2.83259916696769,lng:122.083888877778},
{lat:-2.83265612206421,lng:122.084166655556},
{lat:-2.83274280500608,lng:122.084444433333},
{lat:-2.83277776666667,lng:122.084495085143},
{lat:-2.83284810170141,lng:122.084722211111},
{lat:-2.83291528595787,lng:122.084999988889},
{lat:-2.83291478519726,lng:122.085277766667},
{lat:-2.83277776666667,lng:122.085460355296},
{lat:-2.83270548290472,lng:122.085555544444},
{lat:-2.83277776666667,lng:122.085731597498},
{lat:-2.83281977954822,lng:122.085833322222},
{lat:-2.83289928609238,lng:122.0861111},
{lat:-2.83294534697083,lng:122.086388877778},
{lat:-2.83302689279144,lng:122.086666655556},
{lat:-2.83305554444445,lng:122.086796613251},
{lat:-2.83308524532049,lng:122.086944433333},
{lat:-2.83305554444445,lng:122.087102427058},
{lat:-2.8330326987836,lng:122.087222211111},
{lat:-2.83305554444445,lng:122.087296139041},
{lat:-2.83316961920857,lng:122.087499988889},
{lat:-2.83333332222222,lng:122.087616770864},
{lat:-2.83355162403171,lng:122.087777766667},
{lat:-2.8336111,lng:122.087799541697},
{lat:-2.83372036168266,lng:122.087777766667},
{lat:-2.83388887777778,lng:122.087726242905},
{lat:-2.83412175414703,lng:122.087499988889},
{lat:-2.83416665555556,lng:122.087457314658},
{lat:-2.83444443333333,lng:122.087306377448},
{lat:-2.83459797105283,lng:122.087222211111},
{lat:-2.83472221111111,lng:122.087166284384},
{lat:-2.83499998888889,lng:122.087112106046},
{lat:-2.83527776666667,lng:122.087188266171},
{lat:-2.83555554444445,lng:122.087167065853},
{lat:-2.83569717216054,lng:122.087222211111},
{lat:-2.83583332222222,lng:122.087281270376},
{lat:-2.8361111,lng:122.087345504346},
{lat:-2.83638887777778,lng:122.087235476758},
{lat:-2.83666665555556,lng:122.087256654741},
{lat:-2.83694443333333,lng:122.087326635377},
{lat:-2.83715338501198,lng:122.087222211111},
{lat:-2.83722221111111,lng:122.087187044447},
{lat:-2.83749998888889,lng:122.086990204117},
{lat:-2.83756777262985,lng:122.086944433333},
{lat:-2.83777776666667,lng:122.086747451273},
{lat:-2.83805554444445,lng:122.086832703777},
{lat:-2.83833332222222,lng:122.086905582309},
{lat:-2.8386111,lng:122.08690568964},
{lat:-2.83888887777778,lng:122.086917291185},
{lat:-2.83894090431394,lng:122.086944433333},
{lat:-2.83916665555556,lng:122.087057120029},
{lat:-2.83944443333333,lng:122.087027698896},
{lat:-2.83972221111111,lng:122.086970663396},
{lat:-2.839784570713,lng:122.086944433333},
{lat:-2.83999998888889,lng:122.086751151226},
{lat:-2.8401138545561,lng:122.086666655556},
{lat:-2.84027776666667,lng:122.086507407005},
{lat:-2.84055554444445,lng:122.086450137642},
{lat:-2.84083332222222,lng:122.086504241468},
{lat:-2.8411111,lng:122.086517090477},
{lat:-2.84128767691193,lng:122.086666655556},
{lat:-2.8411111,lng:122.086910149807},
{lat:-2.84107016243363,lng:122.086944433333},
{lat:-2.84083332222222,lng:122.087056549191},
{lat:-2.84066847540908,lng:122.087222211111},
{lat:-2.84061443599131,lng:122.087499988889},
{lat:-2.84066047503261,lng:122.087777766667},
{lat:-2.84063783518344,lng:122.088055544444},
{lat:-2.84055554444445,lng:122.088100605122},
{lat:-2.84027776666667,lng:122.088324516871},
{lat:-2.84016989651763,lng:122.088333322222},
{lat:-2.83999998888889,lng:122.088347217556},
{lat:-2.83972221111111,lng:122.088511527063},
{lat:-2.83944443333333,lng:122.088490614106},
{lat:-2.83924325908157,lng:122.0886111},
{lat:-2.83916665555556,lng:122.088678041943},
{lat:-2.83913000327384,lng:122.0886111},
{lat:-2.83907764666653,lng:122.088333322222},
{lat:-2.83897697790905,lng:122.088055544444},
{lat:-2.83888887777778,lng:122.087911908887},
{lat:-2.83870301320177,lng:122.087777766667},
{lat:-2.8386111,lng:122.08771436347},
{lat:-2.83833332222222,lng:122.087715617662},
{lat:-2.83825572169487,lng:122.087777766667},
{lat:-2.83805554444445,lng:122.088047789733},
{lat:-2.83805137237487,lng:122.088055544444},
{lat:-2.837826398274,lng:122.088333322222},
{lat:-2.83777776666667,lng:122.088400203631},
{lat:-2.83753324647953,lng:122.0886111},
{lat:-2.83749998888889,lng:122.088650688708},
{lat:-2.83725097724856,lng:122.088888877778},
{lat:-2.83722221111111,lng:122.08891047749},
{lat:-2.83696716977526,lng:122.089166655556},
{lat:-2.83694443333333,lng:122.089183374664},
{lat:-2.83666665555556,lng:122.089320606388},
{lat:-2.83638887777778,lng:122.089379385783},
{lat:-2.8361111,lng:122.089366352892},
{lat:-2.83583332222222,lng:122.089374889026},
{lat:-2.83563308793423,lng:122.089444433333},
{lat:-2.83563308793423,lng:122.089583322222},]},
{ID:62,coord:[{lat:-2.84125489342477,lng:122.079999988889},
{lat:-2.8411111,lng:122.080113101222},
{lat:-2.84100169408174,lng:122.080277766667},
{lat:-2.84083332222222,lng:122.080493055415},
{lat:-2.84073692698628,lng:122.080555544444},
{lat:-2.84055554444445,lng:122.080706624389},
{lat:-2.84027776666667,lng:122.08080280532},
{lat:-2.84007933203855,lng:122.080833322222},
{lat:-2.83999998888889,lng:122.080847139645},
{lat:-2.83972221111111,lng:122.081078569327},
{lat:-2.83944443333333,lng:122.080865165244},
{lat:-2.83938479784335,lng:122.080833322222},
{lat:-2.83944443333333,lng:122.080579954818},
{lat:-2.83945990617854,lng:122.080555544444},
{lat:-2.83970537164334,lng:122.080277766667},
{lat:-2.83972221111111,lng:122.0802597432},
{lat:-2.83999998888889,lng:122.08000276301},
{lat:-2.84027776666667,lng:122.080007541745},
{lat:-2.84031196984812,lng:122.079999988889},
{lat:-2.84027818656159,lng:122.079722211111},
{lat:-2.84044808321057,lng:122.079444433333},
{lat:-2.84055554444445,lng:122.079359756996},
{lat:-2.84063572057447,lng:122.079444433333},
{lat:-2.84083332222222,lng:122.079623980035},
{lat:-2.84099884422107,lng:122.079722211111},
{lat:-2.8411111,lng:122.079767190345},
{lat:-2.84125489342477,lng:122.079999988889},]},
{ID:63,coord:[{lat:-2.8411111,lng:122.076363521954},
{lat:-2.84119808254831,lng:122.0761111},
{lat:-2.8411111,lng:122.07599780793},
{lat:-2.84083332222222,lng:122.076091700518},
{lat:-2.8408190568818,lng:122.0761111},
{lat:-2.84071972444711,lng:122.076388877778},
{lat:-2.84083332222222,lng:122.076481598598},
{lat:-2.84108033557617,lng:122.076388877778},
{lat:-2.8411111,lng:122.076363521954},]},
{ID:64,coord:[{lat:-2.84148547436187,lng:122.089583322222},
{lat:-2.84148547436187,lng:122.089444433333},
{lat:-2.84138887777778,lng:122.089321348254},
{lat:-2.8411111,lng:122.089176488797},
{lat:-2.84108009971744,lng:122.089166655556},
{lat:-2.84083332222222,lng:122.08910356511},
{lat:-2.84076833925919,lng:122.089166655556},
{lat:-2.84055554444445,lng:122.089381249235},
{lat:-2.84049424549163,lng:122.089444433333},
{lat:-2.84049424549163,lng:122.089583322222},]},
{ID:65,coord:[{lat:-2.83789038150011,lng:122.063472211111},
{lat:-2.83789038150011,lng:122.0636111},
{lat:-2.83777776666667,lng:122.063777567848},
{lat:-2.83772070320551,lng:122.063888877778},
{lat:-2.83777776666667,lng:122.064040495445},
{lat:-2.83782744717927,lng:122.064166655556},
{lat:-2.83805554444445,lng:122.064358792828},
{lat:-2.83833332222222,lng:122.06436651888},
{lat:-2.83857274206795,lng:122.064166655556},
{lat:-2.8386111,lng:122.06415152233},
{lat:-2.83863906979274,lng:122.064166655556},
{lat:-2.83888887777778,lng:122.064435223292},
{lat:-2.83890218046561,lng:122.064444433333},
{lat:-2.83898066166679,lng:122.064722211111},
{lat:-2.83916665555556,lng:122.064907232972},
{lat:-2.83933102542279,lng:122.064722211111},
{lat:-2.83944443333333,lng:122.064602380408},
{lat:-2.83972221111111,lng:122.064707300464},
{lat:-2.83989370797645,lng:122.064722211111},
{lat:-2.83999998888889,lng:122.064735460842},
{lat:-2.84001658921883,lng:122.064722211111},
{lat:-2.83999998888889,lng:122.064706004228},
{lat:-2.83989943904462,lng:122.064444433333},
{lat:-2.83999998888889,lng:122.064353535459},
{lat:-2.84027776666667,lng:122.064280685112},
{lat:-2.84055554444445,lng:122.064293301353},
{lat:-2.84067748601242,lng:122.064166655556},
{lat:-2.84083332222222,lng:122.063942107954},
{lat:-2.8411111,lng:122.063958811932},
{lat:-2.84138887777778,lng:122.063911447355},
{lat:-2.84141261724585,lng:122.063888877778},
{lat:-2.8415758524824,lng:122.0636111},
{lat:-2.8415758524824,lng:122.063472211111},]},
{ID:66,coord:[{lat:-2.84142705420536,lng:122.072777766667},
{lat:-2.84138887777778,lng:122.072939177012},
{lat:-2.84132020207332,lng:122.072777766667},
{lat:-2.84138887777778,lng:122.072742030343},
{lat:-2.84142705420536,lng:122.072777766667},]},
{ID:67,coord:[{lat:-2.84175404095068,lng:122.089583322222},
{lat:-2.84175404095068,lng:122.089444433333},
{lat:-2.84172483905248,lng:122.089166655556},
{lat:-2.84166665555556,lng:122.089116110575},
{lat:-2.84138887777778,lng:122.088909200423},
{lat:-2.8413524880457,lng:122.088888877778},
{lat:-2.8411111,lng:122.088821919246},
{lat:-2.84083332222222,lng:122.088773463305},
{lat:-2.84055554444445,lng:122.08888662699},
{lat:-2.8405541688574,lng:122.088888877778},
{lat:-2.84045756309807,lng:122.089166655556},
{lat:-2.84027776666667,lng:122.08934886404},
{lat:-2.83999998888889,lng:122.089386821844},
{lat:-2.83991850037673,lng:122.089444433333},
{lat:-2.83991850037673,lng:122.089583322222},]},
{ID:68,coord:[{lat:-2.84172111927635,lng:122.077777766667},
{lat:-2.84166665555556,lng:122.077957488803},
{lat:-2.84161400231556,lng:122.077777766667},
{lat:-2.84166665555556,lng:122.077747068388},
{lat:-2.84172111927635,lng:122.077777766667},]},
{ID:69,coord:[{lat:-2.84172810408771,lng:122.079166655556},
{lat:-2.84166665555556,lng:122.07929305055},
{lat:-2.84149745212912,lng:122.079444433333},
{lat:-2.84138887777778,lng:122.079516720625},
{lat:-2.8411164474896,lng:122.079444433333},
{lat:-2.84127941819132,lng:122.079166655556},
{lat:-2.84138887777778,lng:122.07899847711},
{lat:-2.84149444652231,lng:122.078888877778},
{lat:-2.84166665555556,lng:122.078628745237},
{lat:-2.84179095154838,lng:122.078888877778},
{lat:-2.84172810408771,lng:122.079166655556},]},
{ID:70,coord:[{lat:-2.84222301991922,lng:122.078055544444},
{lat:-2.84222221111111,lng:122.078080882601},
{lat:-2.84221275475658,lng:122.078055544444},
{lat:-2.84222221111111,lng:122.078052157678},
{lat:-2.84222301991922,lng:122.078055544444},]},
{ID:71,coord:[{lat:-2.83069443333333,lng:122.079224496019},
{lat:-2.83083332222222,lng:122.079224496019},
{lat:-2.83093173900087,lng:122.079166655556},
{lat:-2.8311111,lng:122.078996493323},
{lat:-2.8313696374313,lng:122.078888877778},
{lat:-2.83138887777778,lng:122.078880897216},
{lat:-2.8313928013669,lng:122.078888877778},
{lat:-2.83139466569091,lng:122.079166655556},
{lat:-2.83138887777778,lng:122.079182939748},
{lat:-2.83119365819394,lng:122.079444433333},
{lat:-2.83116083764367,lng:122.079722211111},
{lat:-2.83128976395041,lng:122.079999988889},
{lat:-2.83133135985712,lng:122.080277766667},
{lat:-2.83137575551112,lng:122.080555544444},
{lat:-2.83138887777778,lng:122.080579566261},
{lat:-2.83156295587271,lng:122.080833322222},
{lat:-2.83166665555556,lng:122.080912297662},
{lat:-2.83194443333334,lng:122.080995366113},
{lat:-2.83209119036837,lng:122.0811111},
{lat:-2.8322114670993,lng:122.081388877778},
{lat:-2.83222221111111,lng:122.081482459406},
{lat:-2.83227110444339,lng:122.081666655556},
{lat:-2.83222221111111,lng:122.081737449842},
{lat:-2.83194443333334,lng:122.081915706232},
{lat:-2.83191426769246,lng:122.081944433333},
{lat:-2.83194443333334,lng:122.082145505739},
{lat:-2.83198647845797,lng:122.082222211111},
{lat:-2.83222221111111,lng:122.082416541149},
{lat:-2.83249998888889,lng:122.082476880184},
{lat:-2.83277776666667,lng:122.082466854885},
{lat:-2.8327940875078,lng:122.082499988889},
{lat:-2.83286562053205,lng:122.082777766667},
{lat:-2.83282766543753,lng:122.083055544444},
{lat:-2.83277776666667,lng:122.083279040308},
{lat:-2.83275964936896,lng:122.083333322222},
{lat:-2.83277776666667,lng:122.083580588999},
{lat:-2.83277999873793,lng:122.0836111},
{lat:-2.83280564847933,lng:122.083888877778},
{lat:-2.83286863374239,lng:122.084166655556},
{lat:-2.83294887631722,lng:122.084444433333},
{lat:-2.83301365474497,lng:122.084722211111},
{lat:-2.83305554444445,lng:122.084917924432},
{lat:-2.83306888963744,lng:122.084999988889},
{lat:-2.83309870031868,lng:122.085277766667},
{lat:-2.83305554444445,lng:122.085503368284},
{lat:-2.83302633283222,lng:122.085555544444},
{lat:-2.83303596304135,lng:122.085833322222},
{lat:-2.83305554444445,lng:122.085933168217},
{lat:-2.83308634884927,lng:122.0861111},
{lat:-2.83314349118024,lng:122.086388877778},
{lat:-2.83324559410957,lng:122.086666655556},
{lat:-2.83331543685688,lng:122.086944433333},
{lat:-2.83333332222222,lng:122.087000167738},
{lat:-2.83342975327091,lng:122.087222211111},
{lat:-2.83358839965246,lng:122.087499988889},
{lat:-2.8336111,lng:122.08751665439},
{lat:-2.83382795350999,lng:122.087499988889},
{lat:-2.83388887777778,lng:122.087495119839},
{lat:-2.83416665555556,lng:122.087232544843},
{lat:-2.83418598488368,lng:122.087222211111},
{lat:-2.83444443333333,lng:122.08709263841},
{lat:-2.83472221111111,lng:122.086986895788},
{lat:-2.83490537489208,lng:122.086944433333},
{lat:-2.83499998888889,lng:122.086922341327},
{lat:-2.83510777437678,lng:122.086944433333},
{lat:-2.83527776666667,lng:122.086983852308},
{lat:-2.83555554444445,lng:122.087010295326},
{lat:-2.83583332222222,lng:122.087115715651},
{lat:-2.8361111,lng:122.0871140666},
{lat:-2.83638887777778,lng:122.087016749361},
{lat:-2.83666665555556,lng:122.087064913046},
{lat:-2.83694443333333,lng:122.087106574673},
{lat:-2.83722221111111,lng:122.086997214903},
{lat:-2.83730204604447,lng:122.086944433333},
{lat:-2.83749998888889,lng:122.086795789815},
{lat:-2.83763813968483,lng:122.086666655556},
{lat:-2.83777776666667,lng:122.086570521022},
{lat:-2.83800040543686,lng:122.086666655556},
{lat:-2.83805554444445,lng:122.086688302907},
{lat:-2.83833332222222,lng:122.086759457293},
{lat:-2.8386111,lng:122.086757352207},
{lat:-2.83888887777778,lng:122.086768272793},
{lat:-2.83916665555556,lng:122.086903854509},
{lat:-2.83944443333333,lng:122.086883624356},
{lat:-2.83972221111111,lng:122.086769544235},
{lat:-2.8398375868447,lng:122.086666655556},
{lat:-2.83999998888889,lng:122.086463643424},
{lat:-2.84007723283019,lng:122.086388877778},
{lat:-2.84027776666667,lng:122.086271697557},
{lat:-2.84055554444445,lng:122.086199490595},
{lat:-2.84083332222222,lng:122.086255873},
{lat:-2.8411111,lng:122.086286203126},
{lat:-2.84138887777778,lng:122.086244805666},
{lat:-2.84166665555556,lng:122.086263807614},
{lat:-2.84194443333333,lng:122.086187419599},
{lat:-2.84222221111111,lng:122.086133332013},
{lat:-2.84248563942681,lng:122.0861111},
{lat:-2.84249998888889,lng:122.0861103703},
{lat:-2.84277776666667,lng:122.085861628607},
{lat:-2.84282285712626,lng:122.085833322222},
{lat:-2.84305554444445,lng:122.085682399466},
{lat:-2.84313913762197,lng:122.085833322222},
{lat:-2.84330826167558,lng:122.0861111},
{lat:-2.84319705693763,lng:122.086388877778},
{lat:-2.84305554444445,lng:122.086559338507},
{lat:-2.84283776568362,lng:122.086666655556},
{lat:-2.84277776666667,lng:122.086694761932},
{lat:-2.84249998888889,lng:122.086760834456},
{lat:-2.84222221111111,lng:122.086696080972},
{lat:-2.84194443333333,lng:122.086868943965},
{lat:-2.84166665555556,lng:122.086803063335},
{lat:-2.84138887777778,lng:122.086913222186},
{lat:-2.84136436224691,lng:122.086944433333},
{lat:-2.8411111,lng:122.087150251316},
{lat:-2.84098516744679,lng:122.087222211111},
{lat:-2.84085212983232,lng:122.087499988889},
{lat:-2.8409411650523,lng:122.087777766667},
{lat:-2.84093826176127,lng:122.088055544444},
{lat:-2.84083332222222,lng:122.08819811306},
{lat:-2.84055554444445,lng:122.088272983288},
{lat:-2.84048091867122,lng:122.088333322222},
{lat:-2.84027776666667,lng:122.088506770299},
{lat:-2.83999998888889,lng:122.088534519761},
{lat:-2.83985353950621,lng:122.0886111},
{lat:-2.83972221111111,lng:122.088695933716},
{lat:-2.83944443333333,lng:122.088622295244},
{lat:-2.83916665555556,lng:122.088868230052},
{lat:-2.83902587082886,lng:122.0886111},
{lat:-2.83897359362132,lng:122.088333322222},
{lat:-2.83888887777778,lng:122.088085180883},
{lat:-2.83885705026546,lng:122.088055544444},
{lat:-2.8386111,lng:122.087899135101},
{lat:-2.83833332222222,lng:122.087921543191},
{lat:-2.8382299484448,lng:122.088055544444},
{lat:-2.83805554444445,lng:122.088263757538},
{lat:-2.83799938479807,lng:122.088333322222},
{lat:-2.83779798687739,lng:122.0886111},
{lat:-2.83777776666667,lng:122.088638317405},
{lat:-2.83755778590525,lng:122.088888877778},
{lat:-2.83749998888889,lng:122.088966140798},
{lat:-2.83722456028174,lng:122.089166655556},
{lat:-2.83722221111111,lng:122.089167960538},
{lat:-2.83694443333333,lng:122.089372064578},
{lat:-2.83680283589028,lng:122.089444433333},
{lat:-2.83680283589028,lng:122.089583322222},]},
{ID:72,coord:[{lat:-2.84305554444445,lng:122.06614689231},
{lat:-2.84315429168547,lng:122.0661111},
{lat:-2.84307790592942,lng:122.065833322222},
{lat:-2.84305554444445,lng:122.065817557659},
{lat:-2.84304524521802,lng:122.065833322222},
{lat:-2.84300687655164,lng:122.0661111},
{lat:-2.84305554444445,lng:122.06614689231},]},
{ID:73,coord:[{lat:-2.84275385664286,lng:122.063472211111},
{lat:-2.84275385664286,lng:122.0636111},
{lat:-2.84277776666667,lng:122.063648865597},
{lat:-2.84305554444445,lng:122.063779544355},
{lat:-2.84333332222222,lng:122.06377605407},
{lat:-2.84358456764658,lng:122.0636111},
{lat:-2.84358456764658,lng:122.063472211111},]},
{ID:74,coord:[{lat:-2.84333332222222,lng:122.075177796582},
{lat:-2.84351868436494,lng:122.074999988889},
{lat:-2.84351181880498,lng:122.074722211111},
{lat:-2.84339426189733,lng:122.074444433333},
{lat:-2.84333332222222,lng:122.074385142629},
{lat:-2.84305554444445,lng:122.074169324203},
{lat:-2.84282052391568,lng:122.074444433333},
{lat:-2.84281663000539,lng:122.074722211111},
{lat:-2.84277776666667,lng:122.074834734446},
{lat:-2.8427476351702,lng:122.074999988889},
{lat:-2.84277776666667,lng:122.075122393075},
{lat:-2.84285849341303,lng:122.075277766667},
{lat:-2.84304021403005,lng:122.075555544444},
{lat:-2.84305554444445,lng:122.0756144713},
{lat:-2.8430860613627,lng:122.075555544444},
{lat:-2.84325351958828,lng:122.075277766667},
{lat:-2.84333332222222,lng:122.075177796582},]},
{ID:75,coord:[{lat:-2.84363595603491,lng:122.069444433333},
{lat:-2.8436111,lng:122.069540692764},
{lat:-2.8435312925596,lng:122.069444433333},
{lat:-2.8436111,lng:122.069341899653},
{lat:-2.84363595603491,lng:122.069444433333},]},
{ID:76,coord:[{lat:-2.8335727074603,lng:122.063472211111},
{lat:-2.8335727074603,lng:122.0636111},
{lat:-2.83352115384171,lng:122.063888877778},
{lat:-2.83356469646301,lng:122.064166655556},
{lat:-2.8336111,lng:122.06431992634},
{lat:-2.83364350199124,lng:122.064444433333},
{lat:-2.83375550927158,lng:122.064722211111},
{lat:-2.83388887777778,lng:122.064941100367},
{lat:-2.83394816412697,lng:122.064999988889},
{lat:-2.83416665555556,lng:122.065170229137},
{lat:-2.83439781552685,lng:122.065277766667},
{lat:-2.83444443333333,lng:122.065302487827},
{lat:-2.83469707895464,lng:122.065555544444},
{lat:-2.83472221111111,lng:122.065581650243},
{lat:-2.83499998888889,lng:122.065623003397},
{lat:-2.83526380785711,lng:122.065833322222},
{lat:-2.83521120076775,lng:122.0661111},
{lat:-2.83510345813483,lng:122.066388877778},
{lat:-2.83499998888889,lng:122.066631114517},
{lat:-2.834976977285,lng:122.066666655556},
{lat:-2.83490747390533,lng:122.066944433333},
{lat:-2.83488486846114,lng:122.067222211111},
{lat:-2.83484827452118,lng:122.067499988889},
{lat:-2.83472221111111,lng:122.067773604431},
{lat:-2.83471877294738,lng:122.067777766667},
{lat:-2.83456060032736,lng:122.068055544444},
{lat:-2.83444443333333,lng:122.068302171434},
{lat:-2.83443474286471,lng:122.068333322222},
{lat:-2.83437350367963,lng:122.0686111},
{lat:-2.83432338948029,lng:122.068888877778},
{lat:-2.83434254223713,lng:122.069166655556},
{lat:-2.83438149289756,lng:122.069444433333},
{lat:-2.83438693272551,lng:122.069722211111},
{lat:-2.83438678211659,lng:122.069999988889},
{lat:-2.83437550042888,lng:122.070277766667},
{lat:-2.83444443333333,lng:122.0704096334},
{lat:-2.83455804717897,lng:122.070555544444},
{lat:-2.83472221111111,lng:122.070742933197},
{lat:-2.83499998888889,lng:122.070698566362},
{lat:-2.83520305532879,lng:122.070555544444},
{lat:-2.83527776666667,lng:122.07051368307},
{lat:-2.83555554444445,lng:122.070419828761},
{lat:-2.83582988505453,lng:122.070277766667},
{lat:-2.83583332222222,lng:122.070274996492},
{lat:-2.8361111,lng:122.070154257984},
{lat:-2.83638887777778,lng:122.070029598484},
{lat:-2.83649587538391,lng:122.069999988889},
{lat:-2.83666665555556,lng:122.06993243467},
{lat:-2.83685259288522,lng:122.069722211111},
{lat:-2.83694443333333,lng:122.069612259257},
{lat:-2.83707183652303,lng:122.069444433333},
{lat:-2.83720979806951,lng:122.069166655556},
{lat:-2.83722221111111,lng:122.06909789514},
{lat:-2.83725019140351,lng:122.068888877778},
{lat:-2.83726622431465,lng:122.0686111},
{lat:-2.83741635541572,lng:122.068333322222},
{lat:-2.83749998888889,lng:122.06825303772},
{lat:-2.83777776666667,lng:122.068064452627},
{lat:-2.83805554444445,lng:122.068135773362},
{lat:-2.83827034512761,lng:122.068055544444},
{lat:-2.83833332222222,lng:122.068027340953},
{lat:-2.8386111,lng:122.067848754401},
{lat:-2.83879656356146,lng:122.067777766667},
{lat:-2.83888887777778,lng:122.067731697827},
{lat:-2.83895638533986,lng:122.067777766667},
{lat:-2.83891954994408,lng:122.068055544444},
{lat:-2.83888887777778,lng:122.068111558756},
{lat:-2.8387826406102,lng:122.068333322222},
{lat:-2.83871024923306,lng:122.0686111},
{lat:-2.83867746402914,lng:122.068888877778},
{lat:-2.8387392541098,lng:122.069166655556},
{lat:-2.83877003858604,lng:122.069444433333},
{lat:-2.8386111,lng:122.069604345649},
{lat:-2.83845846774284,lng:122.069722211111},
{lat:-2.83833332222222,lng:122.069905531822},
{lat:-2.83829574544882,lng:122.069999988889},
{lat:-2.83824565175061,lng:122.070277766667},
{lat:-2.838229702461,lng:122.070555544444},
{lat:-2.83805554444445,lng:122.070669439847},
{lat:-2.83777776666667,lng:122.070732751382},
{lat:-2.83769378851306,lng:122.070833322222},
{lat:-2.83749998888889,lng:122.070978757068},
{lat:-2.83733418922084,lng:122.0711111},
{lat:-2.83722221111111,lng:122.071217086474},
{lat:-2.83697864049987,lng:122.071388877778},
{lat:-2.83697503220285,lng:122.071666655556},
{lat:-2.8370313639253,lng:122.071944433333},
{lat:-2.83701479020928,lng:122.072222211111},
{lat:-2.83694443333333,lng:122.07236228868},
{lat:-2.83685817432703,lng:122.072499988889},
{lat:-2.83666665555556,lng:122.072688440525},
{lat:-2.83647480924607,lng:122.072777766667},
{lat:-2.83638887777778,lng:122.072830022171},
{lat:-2.8361111,lng:122.073044362288},
{lat:-2.83609863864179,lng:122.073055544444},
{lat:-2.83590036093677,lng:122.073333322222},
{lat:-2.83583332222222,lng:122.073484384374},
{lat:-2.83578370378029,lng:122.0736111},
{lat:-2.83583332222222,lng:122.0737869233},
{lat:-2.83586424283798,lng:122.073888877778},
{lat:-2.83587825219219,lng:122.074166655556},
{lat:-2.83583332222222,lng:122.07429324796},
{lat:-2.83579278632345,lng:122.074444433333},
{lat:-2.83582709122676,lng:122.074722211111},
{lat:-2.83581472048757,lng:122.074999988889},
{lat:-2.83583332222222,lng:122.075075054316},
{lat:-2.83588881717971,lng:122.075277766667},
{lat:-2.83587562054242,lng:122.075555544444},
{lat:-2.83583337485068,lng:122.075833322222},
{lat:-2.83583332222222,lng:122.075833539532},
{lat:-2.83576321906154,lng:122.0761111},
{lat:-2.83563222226572,lng:122.076388877778},
{lat:-2.83562333305427,lng:122.076666655556},
{lat:-2.83562117544419,lng:122.076944433333},
{lat:-2.83558004382321,lng:122.077222211111},
{lat:-2.83555554444445,lng:122.077251045915},
{lat:-2.83545834460792,lng:122.077222211111},
{lat:-2.83527776666667,lng:122.077165484848},
{lat:-2.8351595648135,lng:122.076944433333},
{lat:-2.83499998888889,lng:122.076827914061},
{lat:-2.83472221111111,lng:122.07684725713},
{lat:-2.83444443333333,lng:122.076893621342},
{lat:-2.834239946024,lng:122.076666655556},
{lat:-2.83416665555556,lng:122.076597041856},
{lat:-2.83388887777778,lng:122.076499734941},
{lat:-2.8336111,lng:122.076619179017},
{lat:-2.83349219911046,lng:122.076666655556},
{lat:-2.83333332222222,lng:122.076754514644},
{lat:-2.83305554444445,lng:122.076757433003},
{lat:-2.83299430537253,lng:122.076666655556},
{lat:-2.83305554444445,lng:122.076617878867},
{lat:-2.83322537416627,lng:122.076388877778},
{lat:-2.83333332222222,lng:122.076204675791},
{lat:-2.83335262037111,lng:122.0761111},
{lat:-2.83333332222222,lng:122.075963095103},
{lat:-2.83305554444445,lng:122.075885031603},
{lat:-2.83277776666667,lng:122.076068655721},
{lat:-2.83249998888889,lng:122.076083046128},
{lat:-2.83238445713802,lng:122.0761111},
{lat:-2.83235323049823,lng:122.076388877778},
{lat:-2.83245068527336,lng:122.076666655556},
{lat:-2.83226190102064,lng:122.076944433333},
{lat:-2.83234531814487,lng:122.077222211111},
{lat:-2.83222221111111,lng:122.077497314059},
{lat:-2.8320499550833,lng:122.077222211111},
{lat:-2.83218335240105,lng:122.076944433333},
{lat:-2.83205192225099,lng:122.076666655556},
{lat:-2.83202930395,lng:122.076388877778},
{lat:-2.83194443333334,lng:122.076364951576},
{lat:-2.83187770521818,lng:122.076388877778},
{lat:-2.83166665555556,lng:122.076594960307},
{lat:-2.83164679353012,lng:122.076666655556},
{lat:-2.83155731150622,lng:122.076944433333},
{lat:-2.83138887777778,lng:122.077094752096},
{lat:-2.83131456614546,lng:122.077222211111},
{lat:-2.83138887777778,lng:122.077370915982},
{lat:-2.83154966618819,lng:122.077499988889},
{lat:-2.83166665555556,lng:122.077620497169},
{lat:-2.83194443333334,lng:122.077514887099},
{lat:-2.83216824311285,lng:122.077777766667},
{lat:-2.83204525045158,lng:122.078055544444},
{lat:-2.83194443333334,lng:122.078274132357},
{lat:-2.8319199797894,lng:122.078333322222},
{lat:-2.83187122501916,lng:122.0786111},
{lat:-2.83187733749082,lng:122.078888877778},
{lat:-2.83185830088239,lng:122.079166655556},
{lat:-2.83175587868744,lng:122.079444433333},
{lat:-2.83166665555556,lng:122.079638429686},
{lat:-2.83162096351379,lng:122.079722211111},
{lat:-2.83158093688775,lng:122.079999988889},
{lat:-2.83158091326396,lng:122.080277766667},
{lat:-2.83166330073556,lng:122.080555544444},
{lat:-2.83166665555556,lng:122.080560065985},
{lat:-2.83194443333334,lng:122.080594865884},
{lat:-2.83222221111111,lng:122.080668449319},
{lat:-2.83237177732969,lng:122.080833322222},
{lat:-2.83249998888889,lng:122.081048366523},
{lat:-2.83258081593323,lng:122.0811111},
{lat:-2.83277776666667,lng:122.081282517398},
{lat:-2.83282178625084,lng:122.081388877778},
{lat:-2.83277776666667,lng:122.081612654317},
{lat:-2.83276442164051,lng:122.081666655556},
{lat:-2.83274633971494,lng:122.081944433333},
{lat:-2.83277776666667,lng:122.081981917608},
{lat:-2.83289703311041,lng:122.082222211111},
{lat:-2.83300757678838,lng:122.082499988889},
{lat:-2.83302923819201,lng:122.082777766667},
{lat:-2.83303477559602,lng:122.083055544444},
{lat:-2.83305554444445,lng:122.083149228033},
{lat:-2.833123973125,lng:122.083333322222},
{lat:-2.83308879087256,lng:122.0836111},
{lat:-2.83305554444445,lng:122.083727881411},
{lat:-2.83302299084542,lng:122.083888877778},
{lat:-2.83305554444445,lng:122.084084414847},
{lat:-2.83306866951375,lng:122.084166655556},
{lat:-2.83314073550948,lng:122.084444433333},
{lat:-2.83317255983267,lng:122.084722211111},
{lat:-2.83319467209276,lng:122.084999988889},
{lat:-2.83322667082548,lng:122.085277766667},
{lat:-2.83326275726084,lng:122.085555544444},
{lat:-2.83325017911521,lng:122.085833322222},
{lat:-2.8332771885217,lng:122.0861111},
{lat:-2.83333332222222,lng:122.086327692577},
{lat:-2.83335533077296,lng:122.086388877778},
{lat:-2.83350384978391,lng:122.086666655556},
{lat:-2.8336111,lng:122.086941771389},
{lat:-2.83361256339403,lng:122.086944433333},
{lat:-2.83388887777778,lng:122.087174414621},
{lat:-2.83416665555556,lng:122.086957043056},
{lat:-2.83420347644593,lng:122.086944433333},
{lat:-2.83444443333333,lng:122.086883890994},
{lat:-2.83472221111111,lng:122.086801608299},
{lat:-2.83499998888889,lng:122.086741661737},
{lat:-2.83527776666667,lng:122.086805575682},
{lat:-2.83555554444445,lng:122.086847303722},
{lat:-2.83580552418464,lng:122.086944433333},
{lat:-2.83583332222222,lng:122.086954809169},
{lat:-2.83589068919492,lng:122.086944433333},
{lat:-2.8361111,lng:122.086893877332},
{lat:-2.83638887777778,lng:122.086807756207},
{lat:-2.83666665555556,lng:122.086841010463},
{lat:-2.83694443333333,lng:122.086811671502},
{lat:-2.83722221111111,lng:122.086820330389},
{lat:-2.83741395972933,lng:122.086666655556},
{lat:-2.83749998888889,lng:122.086595054224},
{lat:-2.83777776666667,lng:122.08641170196},
{lat:-2.83805554444445,lng:122.08650361737},
{lat:-2.83833332222222,lng:122.08660125472},
{lat:-2.8386111,lng:122.086600875082},
{lat:-2.83888887777778,lng:122.086624718412},
{lat:-2.83905139648049,lng:122.086666655556},
{lat:-2.83916665555556,lng:122.086710316006},
{lat:-2.83944443333333,lng:122.086675441753},
{lat:-2.83946390194902,lng:122.086666655556},
{lat:-2.83972221111111,lng:122.08644582088},
{lat:-2.83976598394073,lng:122.086388877778},
{lat:-2.83999998888889,lng:122.086234262386},
{lat:-2.84021262080533,lng:122.0861111},
{lat:-2.84027776666667,lng:122.086078970032},
{lat:-2.84055554444445,lng:122.086002688668},
{lat:-2.84083332222222,lng:122.086045867523},
{lat:-2.8411111,lng:122.086103883674},
{lat:-2.84138887777778,lng:122.086039350987},
{lat:-2.84166665555556,lng:122.086039420365},
{lat:-2.84194443333333,lng:122.085978947476},
{lat:-2.84222221111111,lng:122.085898282679},
{lat:-2.84237666617434,lng:122.085833322222},
{lat:-2.84249998888889,lng:122.085606757876},
{lat:-2.84251828027325,lng:122.085555544444},
{lat:-2.84249998888889,lng:122.085409999539},
{lat:-2.84248557272,lng:122.085277766667},
{lat:-2.84249998888889,lng:122.08507523119},
{lat:-2.84277776666667,lng:122.085212201244},
{lat:-2.84289078202306,lng:122.085277766667},
{lat:-2.84305554444445,lng:122.085343772113},
{lat:-2.84324606739773,lng:122.085555544444},
{lat:-2.84333332222222,lng:122.085664979471},
{lat:-2.84351777772341,lng:122.085833322222},
{lat:-2.8436111,lng:122.085966890903},
{lat:-2.84388887777778,lng:122.086110266184},
{lat:-2.84389371307607,lng:122.0861111},
{lat:-2.84388887777778,lng:122.086113641595},
{lat:-2.84361734972457,lng:122.086388877778},
{lat:-2.8436111,lng:122.086390258341},
{lat:-2.84333332222222,lng:122.086628358095},
{lat:-2.84329925238354,lng:122.086666655556},
{lat:-2.84312527192145,lng:122.086944433333},
{lat:-2.84305554444445,lng:122.087176441771},
{lat:-2.84277776666667,lng:122.0871420776},
{lat:-2.84249998888889,lng:122.087048904893},
{lat:-2.84222221111111,lng:122.087004688463},
{lat:-2.84194443333333,lng:122.087066748377},
{lat:-2.84166665555556,lng:122.087037401595},
{lat:-2.84138887777778,lng:122.087214773148},
{lat:-2.84137894559985,lng:122.087222211111},
{lat:-2.84114593531137,lng:122.087499988889},
{lat:-2.84111638517511,lng:122.087777766667},
{lat:-2.84127693149896,lng:122.088055544444},
{lat:-2.84138887777778,lng:122.088234622023},
{lat:-2.84146490283785,lng:122.088333322222},
{lat:-2.84138887777778,lng:122.088381452545},
{lat:-2.8411111,lng:122.088357975714},
{lat:-2.84083332222222,lng:122.08842488984},
{lat:-2.84055554444445,lng:122.088459293522},
{lat:-2.84037574545078,lng:122.0886111},
{lat:-2.84027776666667,lng:122.088887066447},
{lat:-2.83999998888889,lng:122.088881258582},
{lat:-2.83997465267271,lng:122.088888877778},
{lat:-2.83972221111111,lng:122.088947053017},
{lat:-2.8396745135608,lng:122.088888877778},
{lat:-2.83944443333333,lng:122.088770291518},
{lat:-2.83931294188093,lng:122.088888877778},
{lat:-2.83916665555556,lng:122.089078696573},
{lat:-2.8390112962883,lng:122.088888877778},
{lat:-2.83892173838388,lng:122.0886111},
{lat:-2.83888887777778,lng:122.088436277208},
{lat:-2.83881985976573,lng:122.088333322222},
{lat:-2.8386111,lng:122.088140719131},
{lat:-2.83833332222222,lng:122.088166620782},
{lat:-2.83819855698951,lng:122.088333322222},
{lat:-2.83805554444445,lng:122.088500626203},
{lat:-2.8379761106397,lng:122.0886111},
{lat:-2.83777776666667,lng:122.088878080815},
{lat:-2.83776828741765,lng:122.088888877778},
{lat:-2.83755799303844,lng:122.089166655556},
{lat:-2.83749998888889,lng:122.089222171914},
{lat:-2.83722221111111,lng:122.089382681421},
{lat:-2.83713384679335,lng:122.089444433333},
{lat:-2.83713384679335,lng:122.089583322222},]},
{ID:77,coord:[{lat:-2.83626448224928,lng:122.063472211111},
{lat:-2.83626448224928,lng:122.0636111},
{lat:-2.83627275517244,lng:122.063888877778},
{lat:-2.83621550578941,lng:122.064166655556},
{lat:-2.8361478962161,lng:122.064444433333},
{lat:-2.8361111,lng:122.064552944943},
{lat:-2.83605920460563,lng:122.064722211111},
{lat:-2.8361111,lng:122.064838844474},
{lat:-2.83618096516636,lng:122.064999988889},
{lat:-2.8361111,lng:122.065229431484},
{lat:-2.836095481122,lng:122.065277766667},
{lat:-2.83583332222222,lng:122.065522025707},
{lat:-2.83576130693502,lng:122.065555544444},
{lat:-2.83555554444445,lng:122.065730699793},
{lat:-2.83549316684974,lng:122.065833322222},
{lat:-2.83540316939923,lng:122.0661111},
{lat:-2.83527776666667,lng:122.066372206425},
{lat:-2.83527121026549,lng:122.066388877778},
{lat:-2.83514178675948,lng:122.066666655556},
{lat:-2.83508475972356,lng:122.066944433333},
{lat:-2.83503780566933,lng:122.067222211111},
{lat:-2.83499998888889,lng:122.067450201379},
{lat:-2.83499188867875,lng:122.067499988889},
{lat:-2.83495026727154,lng:122.067777766667},
{lat:-2.83493304944555,lng:122.068055544444},
{lat:-2.8348619607414,lng:122.068333322222},
{lat:-2.83474934140846,lng:122.0686111},
{lat:-2.83472221111111,lng:122.068698832619},
{lat:-2.83467477166477,lng:122.068888877778},
{lat:-2.8347020698379,lng:122.069166655556},
{lat:-2.83472221111111,lng:122.069392335536},
{lat:-2.83476360010274,lng:122.069444433333},
{lat:-2.83499998888889,lng:122.069636609818},
{lat:-2.83501426307525,lng:122.069722211111},
{lat:-2.83500700327738,lng:122.069999988889},
{lat:-2.83527776666667,lng:122.070240796042},
{lat:-2.83555554444445,lng:122.070224742502},
{lat:-2.83583332222222,lng:122.070027961284},
{lat:-2.83609700075954,lng:122.069999988889},
{lat:-2.8361111,lng:122.069998601046},
{lat:-2.83638887777778,lng:122.06982584382},
{lat:-2.83666153918029,lng:122.069722211111},
{lat:-2.83666665555556,lng:122.069719125253},
{lat:-2.83687745389193,lng:122.069444433333},
{lat:-2.83694443333333,lng:122.069340661162},
{lat:-2.83702343751462,lng:122.069166655556},
{lat:-2.83703573761849,lng:122.068888877778},
{lat:-2.83709081091104,lng:122.0686111},
{lat:-2.83722221111111,lng:122.06842271082},
{lat:-2.83726715969213,lng:122.068333322222},
{lat:-2.83749998888889,lng:122.068109816305},
{lat:-2.83757961183892,lng:122.068055544444},
{lat:-2.83777776666667,lng:122.067924737606},
{lat:-2.83805554444445,lng:122.068018143692},
{lat:-2.83833332222222,lng:122.067900426497},
{lat:-2.83850060006745,lng:122.067777766667},
{lat:-2.8386111,lng:122.067689937937},
{lat:-2.83887102787768,lng:122.067499988889},
{lat:-2.83888887777778,lng:122.06748213018},
{lat:-2.83910234140433,lng:122.067222211111},
{lat:-2.83916665555556,lng:122.067085841042},
{lat:-2.83936353270504,lng:122.067222211111},
{lat:-2.83934337341476,lng:122.067499988889},
{lat:-2.83926152409173,lng:122.067777766667},
{lat:-2.83916665555556,lng:122.068024400585},
{lat:-2.8391536645119,lng:122.068055544444},
{lat:-2.83902471010345,lng:122.068333322222},
{lat:-2.8389614437651,lng:122.0686111},
{lat:-2.83890065113165,lng:122.068888877778},
{lat:-2.83896317261797,lng:122.069166655556},
{lat:-2.83901868504635,lng:122.069444433333},
{lat:-2.83888887777778,lng:122.069714779132},
{lat:-2.83888152213127,lng:122.069722211111},
{lat:-2.8386111,lng:122.069842167496},
{lat:-2.83847760635543,lng:122.069999988889},
{lat:-2.83841185112941,lng:122.070277766667},
{lat:-2.83845665340257,lng:122.070555544444},
{lat:-2.83841369205706,lng:122.070833322222},
{lat:-2.83833332222222,lng:122.070874364228},
{lat:-2.83805554444445,lng:122.070877263996},
{lat:-2.83777776666667,lng:122.070984610756},
{lat:-2.83760483217545,lng:122.0711111},
{lat:-2.83749998888889,lng:122.071203920698},
{lat:-2.83730348113517,lng:122.071388877778},
{lat:-2.83724030156981,lng:122.071666655556},
{lat:-2.83725944789694,lng:122.071944433333},
{lat:-2.83722221111111,lng:122.072104170128},
{lat:-2.83720381221193,lng:122.072222211111},
{lat:-2.83709196053231,lng:122.072499988889},
{lat:-2.83694443333333,lng:122.072656822512},
{lat:-2.83682138814264,lng:122.072777766667},
{lat:-2.83666665555556,lng:122.072936111709},
{lat:-2.83648757155447,lng:122.073055544444},
{lat:-2.83638887777778,lng:122.073189639562},
{lat:-2.83627719097175,lng:122.073333322222},
{lat:-2.83618078987876,lng:122.0736111},
{lat:-2.83624888824722,lng:122.073888877778},
{lat:-2.83623734016803,lng:122.074166655556},
{lat:-2.83617531730232,lng:122.074444433333},
{lat:-2.83611159829791,lng:122.074722211111},
{lat:-2.8361111,lng:122.074729638669},
{lat:-2.83609446371016,lng:122.074999988889},
{lat:-2.83610302189111,lng:122.075277766667},
{lat:-2.83609521073811,lng:122.075555544444},
{lat:-2.83605733968995,lng:122.075833322222},
{lat:-2.83597261502385,lng:122.0761111},
{lat:-2.83584282955124,lng:122.076388877778},
{lat:-2.83583332222222,lng:122.076437731107},
{lat:-2.83579765019422,lng:122.076666655556},
{lat:-2.83580297028432,lng:122.076944433333},
{lat:-2.83583332222222,lng:122.077071296447},
{lat:-2.83593451119912,lng:122.077222211111},
{lat:-2.83583332222222,lng:122.077476793799},
{lat:-2.83581891355705,lng:122.077499988889},
{lat:-2.83555554444445,lng:122.077554960957},
{lat:-2.83545722130607,lng:122.077499988889},
{lat:-2.83527776666667,lng:122.077385107109},
{lat:-2.83512277167697,lng:122.077222211111},
{lat:-2.83499998888889,lng:122.077017577948},
{lat:-2.83472221111111,lng:122.077035631299},
{lat:-2.83444443333333,lng:122.077198105785},
{lat:-2.83416665555556,lng:122.077005645266},
{lat:-2.83411172845183,lng:122.076944433333},
{lat:-2.83388887777778,lng:122.076762538468},
{lat:-2.83365195916073,lng:122.076944433333},
{lat:-2.8336111,lng:122.077000602719},
{lat:-2.83333332222222,lng:122.077183894503},
{lat:-2.8332952873336,lng:122.077222211111},
{lat:-2.83315570410755,lng:122.077499988889},
{lat:-2.83305554444445,lng:122.077683580298},
{lat:-2.83277776666667,lng:122.077728120401},
{lat:-2.83273082631926,lng:122.077777766667},
{lat:-2.83255073347969,lng:122.078055544444},
{lat:-2.83249998888889,lng:122.078102535795},
{lat:-2.83231014702312,lng:122.078333322222},
{lat:-2.83223426359562,lng:122.0786111},
{lat:-2.83230606459017,lng:122.078888877778},
{lat:-2.83226533622388,lng:122.079166655556},
{lat:-2.83222221111111,lng:122.079212208022},
{lat:-2.83209875057952,lng:122.079444433333},
{lat:-2.8319713365614,lng:122.079722211111},
{lat:-2.83194443333334,lng:122.079830258376},
{lat:-2.83190746369074,lng:122.079999988889},
{lat:-2.83194443333334,lng:122.080101885892},
{lat:-2.83222221111111,lng:122.080214361945},
{lat:-2.83233685509111,lng:122.080277766667},
{lat:-2.83249998888889,lng:122.080374747486},
{lat:-2.83262618560651,lng:122.080555544444},
{lat:-2.83277776666667,lng:122.080778506524},
{lat:-2.83283842759856,lng:122.080833322222},
{lat:-2.83305554444445,lng:122.080976332672},
{lat:-2.83313084987745,lng:122.0811111},
{lat:-2.83308551222311,lng:122.081388877778},
{lat:-2.83305554444445,lng:122.081517533117},
{lat:-2.83302384401325,lng:122.081666655556},
{lat:-2.83305554444445,lng:122.081886509808},
{lat:-2.83308235083597,lng:122.081944433333},
{lat:-2.83333332222222,lng:122.082204277588},
{lat:-2.83333830618852,lng:122.082222211111},
{lat:-2.83333332222222,lng:122.082290618701},
{lat:-2.83331201973754,lng:122.082499988889},
{lat:-2.83333332222222,lng:122.082744918282},
{lat:-2.83333626218079,lng:122.082777766667},
{lat:-2.83337681543343,lng:122.083055544444},
{lat:-2.83342728331934,lng:122.083333322222},
{lat:-2.83337944627572,lng:122.0836111},
{lat:-2.83333332222222,lng:122.083750285949},
{lat:-2.83328041441778,lng:122.083888877778},
{lat:-2.83327701747112,lng:122.084166655556},
{lat:-2.83333332222222,lng:122.084429736442},
{lat:-2.8333370520379,lng:122.084444433333},
{lat:-2.83333332222222,lng:122.084545904012},
{lat:-2.83332921299298,lng:122.084722211111},
{lat:-2.83332045454807,lng:122.084999988889},
{lat:-2.83333332222222,lng:122.085105669381},
{lat:-2.83336751902169,lng:122.085277766667},
{lat:-2.83348888876738,lng:122.085555544444},
{lat:-2.83350619742617,lng:122.085833322222},
{lat:-2.83351684564404,lng:122.0861111},
{lat:-2.8336111,lng:122.086364828082},
{lat:-2.83362096843893,lng:122.086388877778},
{lat:-2.83379431746785,lng:122.086666655556},
{lat:-2.83388887777778,lng:122.086806221042},
{lat:-2.83416665555556,lng:122.08672223583},
{lat:-2.83444443333333,lng:122.086679966239},
{lat:-2.83449713272174,lng:122.086666655556},
{lat:-2.83472221111111,lng:122.086589598278},
{lat:-2.83499998888889,lng:122.086543327396},
{lat:-2.83527776666667,lng:122.086631906026},
{lat:-2.83547507038122,lng:122.086666655556},
{lat:-2.83555554444445,lng:122.086679805031},
{lat:-2.83583332222222,lng:122.086743578809},
{lat:-2.8361111,lng:122.086689819999},
{lat:-2.83618579981911,lng:122.086666655556},
{lat:-2.83638887777778,lng:122.086596802792},
{lat:-2.83666665555556,lng:122.086569139002},
{lat:-2.83694443333333,lng:122.086501538878},
{lat:-2.83722221111111,lng:122.086637533746},
{lat:-2.83749998888889,lng:122.086416537799},
{lat:-2.83754255079151,lng:122.086388877778},
{lat:-2.83777776666667,lng:122.086211134448},
{lat:-2.83805554444445,lng:122.086318828249},
{lat:-2.83824574063506,lng:122.086388877778},
{lat:-2.83833332222222,lng:122.086422032841},
{lat:-2.8386111,lng:122.086431590308},
{lat:-2.83888887777778,lng:122.086492877622},
{lat:-2.83916665555556,lng:122.08652662925},
{lat:-2.83944443333333,lng:122.086406545896},
{lat:-2.83946406243507,lng:122.086388877778},
{lat:-2.83972221111111,lng:122.086142137228},
{lat:-2.83978288207517,lng:122.0861111},
{lat:-2.83999998888889,lng:122.086030476518},
{lat:-2.84027776666667,lng:122.085908412469},
{lat:-2.84053846404985,lng:122.085833322222},
{lat:-2.84055554444445,lng:122.085828220356},
{lat:-2.84060794185318,lng:122.085833322222},
{lat:-2.84083332222222,lng:122.085856782694},
{lat:-2.8411111,lng:122.085889169629},
{lat:-2.84136133252864,lng:122.085833322222},
{lat:-2.84138887777778,lng:122.085827508663},
{lat:-2.84163471412446,lng:122.085833322222},
{lat:-2.84166665555556,lng:122.085834086085},
{lat:-2.84167172625416,lng:122.085833322222},
{lat:-2.84194443333333,lng:122.085769419292},
{lat:-2.84220009531689,lng:122.085555544444},
{lat:-2.84219634521903,lng:122.085277766667},
{lat:-2.8421644621915,lng:122.084999988889},
{lat:-2.84222221111111,lng:122.084733742429},
{lat:-2.84222880383029,lng:122.084722211111},
{lat:-2.84246198158928,lng:122.084444433333},
{lat:-2.84249998888889,lng:122.084349196906},
{lat:-2.84277776666667,lng:122.084369178522},
{lat:-2.84279768306739,lng:122.084444433333},
{lat:-2.84283123428617,lng:122.084722211111},
{lat:-2.84289663204154,lng:122.084999988889},
{lat:-2.84305554444445,lng:122.08511838821},
{lat:-2.84331667824398,lng:122.085277766667},
{lat:-2.84333332222222,lng:122.085297597204},
{lat:-2.84350224915373,lng:122.085555544444},
{lat:-2.8436111,lng:122.08565568049},
{lat:-2.84388887777778,lng:122.085763728497},
{lat:-2.84416665555556,lng:122.085579279376},
{lat:-2.8442351139128,lng:122.085555544444},
{lat:-2.84444443333333,lng:122.085308179648},
{lat:-2.84451729065142,lng:122.085277766667},
{lat:-2.84472221111111,lng:122.085217290824},
{lat:-2.84474258565193,lng:122.085277766667},
{lat:-2.8448232250531,lng:122.085555544444},
{lat:-2.84472221111111,lng:122.085741988502},
{lat:-2.84464868136961,lng:122.085833322222},
{lat:-2.84458100519433,lng:122.0861111},
{lat:-2.84444443333333,lng:122.086362742164},
{lat:-2.84423718279817,lng:122.086388877778},
{lat:-2.84416665555556,lng:122.08639489828},
{lat:-2.84388887777778,lng:122.086587382339},
{lat:-2.8436111,lng:122.086647513343},
{lat:-2.84358960202138,lng:122.086666655556},
{lat:-2.84333332222222,lng:122.086901470974},
{lat:-2.84330427140566,lng:122.086944433333},
{lat:-2.84321903765683,lng:122.087222211111},
{lat:-2.84305554444445,lng:122.087478099178},
{lat:-2.84277776666667,lng:122.087365807372},
{lat:-2.84249998888889,lng:122.087293463933},
{lat:-2.84222221111111,lng:122.087285318151},
{lat:-2.84194443333333,lng:122.087270095243},
{lat:-2.84166665555556,lng:122.087385192007},
{lat:-2.84159929272179,lng:122.087499988889},
{lat:-2.84149410569292,lng:122.087777766667},
{lat:-2.84166665555556,lng:122.087891992563},
{lat:-2.84173535764316,lng:122.088055544444},
{lat:-2.84178005572064,lng:122.088333322222},
{lat:-2.84174245776779,lng:122.0886111},
{lat:-2.84166665555556,lng:122.088798813495},
{lat:-2.84138887777778,lng:122.088682919978},
{lat:-2.84115056300345,lng:122.0886111},
{lat:-2.8411111,lng:122.088597874038},
{lat:-2.84083332222222,lng:122.088567841001},
{lat:-2.84067146806455,lng:122.0886111},
{lat:-2.84055554444445,lng:122.088660179893},
{lat:-2.84041577390436,lng:122.088888877778},
{lat:-2.84027776666667,lng:122.08915201999},
{lat:-2.84011626909526,lng:122.089166655556},
{lat:-2.83999998888889,lng:122.089175110009},
{lat:-2.83972221111111,lng:122.089311050092},
{lat:-2.83965215624212,lng:122.089166655556},
{lat:-2.83944443333333,lng:122.088931190405},
{lat:-2.8392629667617,lng:122.089166655556},
{lat:-2.83916665555556,lng:122.089267266599},
{lat:-2.83897896157707,lng:122.089166655556},
{lat:-2.83888887777778,lng:122.089031574468},
{lat:-2.83884501168849,lng:122.088888877778},
{lat:-2.83871728423952,lng:122.0886111},
{lat:-2.8386111,lng:122.08847452493},
{lat:-2.83833332222222,lng:122.088433849045},
{lat:-2.83818431793427,lng:122.0886111},
{lat:-2.83805554444445,lng:122.088738935192},
{lat:-2.83794306006133,lng:122.088888877778},
{lat:-2.83777776666667,lng:122.08914295785},
{lat:-2.8377597926565,lng:122.089166655556},
{lat:-2.83749998888889,lng:122.08941531637},
{lat:-2.83745147460061,lng:122.089444433333},
{lat:-2.83745147460061,lng:122.089583322222},]},
{ID:78,coord:[{lat:-2.83681146329712,lng:122.063472211111},
{lat:-2.83681146329712,lng:122.0636111},
{lat:-2.83666665555556,lng:122.06374633386},
{lat:-2.8365624002084,lng:122.063888877778},
{lat:-2.8365552096286,lng:122.064166655556},
{lat:-2.83638887777778,lng:122.064419936687},
{lat:-2.83637908431466,lng:122.064444433333},
{lat:-2.83634585722726,lng:122.064722211111},
{lat:-2.83638463963578,lng:122.064999988889},
{lat:-2.8363407653183,lng:122.065277766667},
{lat:-2.83632462003112,lng:122.065555544444},
{lat:-2.8361111,lng:122.065684149273},
{lat:-2.83583332222222,lng:122.065822836626},
{lat:-2.83582127664892,lng:122.065833322222},
{lat:-2.83565059051696,lng:122.0661111},
{lat:-2.83555554444445,lng:122.066358416342},
{lat:-2.83554671221235,lng:122.066388877778},
{lat:-2.83532324014369,lng:122.066666655556},
{lat:-2.83527776666667,lng:122.066739616569},
{lat:-2.83522680195164,lng:122.066944433333},
{lat:-2.83518422203407,lng:122.067222211111},
{lat:-2.83520197120265,lng:122.067499988889},
{lat:-2.83527776666667,lng:122.067712631044},
{lat:-2.83545957051863,lng:122.067499988889},
{lat:-2.83555554444445,lng:122.067446287625},
{lat:-2.83562781274027,lng:122.067499988889},
{lat:-2.83568157186588,lng:122.067777766667},
{lat:-2.83571024087899,lng:122.068055544444},
{lat:-2.83583332222222,lng:122.068226524136},
{lat:-2.83595163996812,lng:122.068333322222},
{lat:-2.8361111,lng:122.068461993447},
{lat:-2.83638887777778,lng:122.068552371593},
{lat:-2.83666665555556,lng:122.068560785364},
{lat:-2.83694443333333,lng:122.06845312319},
{lat:-2.8370113996085,lng:122.068333322222},
{lat:-2.83722221111111,lng:122.068124149765},
{lat:-2.8372970831422,lng:122.068055544444},
{lat:-2.83749998888889,lng:122.06787181697},
{lat:-2.83777776666667,lng:122.067786030947},
{lat:-2.83805554444445,lng:122.06786362229},
{lat:-2.83831751613479,lng:122.067777766667},
{lat:-2.83833332222222,lng:122.067769886522},
{lat:-2.8386111,lng:122.067552368569},
{lat:-2.83868277679505,lng:122.067499988889},
{lat:-2.83888887777778,lng:122.067293786194},
{lat:-2.83894766021667,lng:122.067222211111},
{lat:-2.83908035759845,lng:122.066944433333},
{lat:-2.83916665555556,lng:122.066809523824},
{lat:-2.83928891063942,lng:122.066666655556},
{lat:-2.83944443333333,lng:122.066519417658},
{lat:-2.83958419385794,lng:122.066388877778},
{lat:-2.83971337058255,lng:122.0661111},
{lat:-2.83972221111111,lng:122.066099802686},
{lat:-2.83999998888889,lng:122.066102051999},
{lat:-2.84000715120964,lng:122.0661111},
{lat:-2.84000297159565,lng:122.066388877778},
{lat:-2.83999998888889,lng:122.066405021877},
{lat:-2.83993663784968,lng:122.066666655556},
{lat:-2.83980698190994,lng:122.066944433333},
{lat:-2.83972221111111,lng:122.067073350885},
{lat:-2.8396320595969,lng:122.067222211111},
{lat:-2.8395485435139,lng:122.067499988889},
{lat:-2.83947801887443,lng:122.067777766667},
{lat:-2.83944443333333,lng:122.067881260066},
{lat:-2.83938497368971,lng:122.068055544444},
{lat:-2.83930269037438,lng:122.068333322222},
{lat:-2.83923358741748,lng:122.0686111},
{lat:-2.83918949046961,lng:122.068888877778},
{lat:-2.8391841447303,lng:122.069166655556},
{lat:-2.83928403507367,lng:122.069444433333},
{lat:-2.8393033759981,lng:122.069722211111},
{lat:-2.83916665555556,lng:122.069788297923},
{lat:-2.83888887777778,lng:122.069885896095},
{lat:-2.83868304680982,lng:122.069999988889},
{lat:-2.8386111,lng:122.070135045127},
{lat:-2.83857081497697,lng:122.070277766667},
{lat:-2.8386111,lng:122.070447232406},
{lat:-2.83864186039107,lng:122.070555544444},
{lat:-2.83875820318205,lng:122.070833322222},
{lat:-2.8386111,lng:122.070997386166},
{lat:-2.83833332222222,lng:122.071083092015},
{lat:-2.83805554444445,lng:122.071096989629},
{lat:-2.83801891014159,lng:122.0711111},
{lat:-2.83777776666667,lng:122.071235360637},
{lat:-2.8376018960492,lng:122.071388877778},
{lat:-2.83749998888889,lng:122.071569085489},
{lat:-2.83746343107276,lng:122.071666655556},
{lat:-2.83749998888889,lng:122.071812077779},
{lat:-2.83754120539908,lng:122.071944433333},
{lat:-2.83749998888889,lng:122.072148297803},
{lat:-2.83748429158981,lng:122.072222211111},
{lat:-2.83733257166897,lng:122.072499988889},
{lat:-2.83722221111111,lng:122.072716796135},
{lat:-2.83716804546939,lng:122.072777766667},
{lat:-2.83694443333333,lng:122.072929089984},
{lat:-2.83682128520866,lng:122.073055544444},
{lat:-2.83666665555556,lng:122.073328950336},
{lat:-2.83666341386194,lng:122.073333322222},
{lat:-2.83666577844803,lng:122.0736111},
{lat:-2.83666665555556,lng:122.073669815432},
{lat:-2.83667040137337,lng:122.073888877778},
{lat:-2.83671354644974,lng:122.074166655556},
{lat:-2.83666665555556,lng:122.074260509947},
{lat:-2.83654330971902,lng:122.074444433333},
{lat:-2.83642981095256,lng:122.074722211111},
{lat:-2.83643751074562,lng:122.074999988889},
{lat:-2.83641707003079,lng:122.075277766667},
{lat:-2.83638887777778,lng:122.075367928847},
{lat:-2.83632412902728,lng:122.075555544444},
{lat:-2.836242676528,lng:122.075833322222},
{lat:-2.8361628807392,lng:122.0761111},
{lat:-2.8361111,lng:122.076268611832},
{lat:-2.83606053476238,lng:122.076388877778},
{lat:-2.83600551937046,lng:122.076666655556},
{lat:-2.83605851022434,lng:122.076944433333},
{lat:-2.8361111,lng:122.077008164731},
{lat:-2.83632027928598,lng:122.077222211111},
{lat:-2.83617273123875,lng:122.077499988889},
{lat:-2.8361111,lng:122.077584033546},
{lat:-2.83583332222222,lng:122.077717268489},
{lat:-2.83555554444445,lng:122.077721161655},
{lat:-2.83527776666667,lng:122.077586040937},
{lat:-2.83514186982,lng:122.077499988889},
{lat:-2.83499998888889,lng:122.077343757866},
{lat:-2.83472221111111,lng:122.077283250818},
{lat:-2.83444443333333,lng:122.077435808427},
{lat:-2.83416665555556,lng:122.077444469272},
{lat:-2.83388887777778,lng:122.07736259807},
{lat:-2.8336111,lng:122.077486467307},
{lat:-2.83359034504814,lng:122.077499988889},
{lat:-2.83344843435059,lng:122.077777766667},
{lat:-2.83336750651376,lng:122.078055544444},
{lat:-2.83333332222222,lng:122.078264206334},
{lat:-2.83305554444445,lng:122.078069468174},
{lat:-2.83292908313238,lng:122.078333322222},
{lat:-2.83282204411488,lng:122.0786111},
{lat:-2.83287117079398,lng:122.078888877778},
{lat:-2.83305554444445,lng:122.079087155545},
{lat:-2.83311504654176,lng:122.079166655556},
{lat:-2.83312496546162,lng:122.079444433333},
{lat:-2.83307657376404,lng:122.079722211111},
{lat:-2.83310544457669,lng:122.079999988889},
{lat:-2.83313562731225,lng:122.080277766667},
{lat:-2.83321382530292,lng:122.080555544444},
{lat:-2.83332821101064,lng:122.080833322222},
{lat:-2.83333332222222,lng:122.080851027253},
{lat:-2.83340589407459,lng:122.0811111},
{lat:-2.83341095391031,lng:122.081388877778},
{lat:-2.8335408045146,lng:122.081666655556},
{lat:-2.8336111,lng:122.081916291913},
{lat:-2.83361412046289,lng:122.081944433333},
{lat:-2.8336111,lng:122.081961164624},
{lat:-2.83357518284032,lng:122.082222211111},
{lat:-2.83360851350525,lng:122.082499988889},
{lat:-2.8336111,lng:122.082512115863},
{lat:-2.83367178563913,lng:122.082777766667},
{lat:-2.83367044435734,lng:122.083055544444},
{lat:-2.83368333811263,lng:122.083333322222},
{lat:-2.8336111,lng:122.083600333703},
{lat:-2.83360903483799,lng:122.0836111},
{lat:-2.83355820818802,lng:122.083888877778},
{lat:-2.83355152838129,lng:122.084166655556},
{lat:-2.83358410803188,lng:122.084444433333},
{lat:-2.83359712269109,lng:122.084722211111},
{lat:-2.83353833126217,lng:122.084999988889},
{lat:-2.83357278939876,lng:122.085277766667},
{lat:-2.8336111,lng:122.085357807359},
{lat:-2.83388887777778,lng:122.085414170817},
{lat:-2.83400225010262,lng:122.085555544444},
{lat:-2.83416665555556,lng:122.085816267706},
{lat:-2.83419551576727,lng:122.085833322222},
{lat:-2.83416665555556,lng:122.085893768186},
{lat:-2.83402687525908,lng:122.0861111},
{lat:-2.83416665555556,lng:122.086273384758},
{lat:-2.83426471610669,lng:122.086388877778},
{lat:-2.83444443333333,lng:122.086409086533},
{lat:-2.83450311264054,lng:122.086388877778},
{lat:-2.83472221111111,lng:122.086124368292},
{lat:-2.83499998888889,lng:122.086294224277},
{lat:-2.83512109378928,lng:122.086388877778},
{lat:-2.83527776666667,lng:122.08645136638},
{lat:-2.83555554444445,lng:122.086443817042},
{lat:-2.83583332222222,lng:122.086421244979},
{lat:-2.8361111,lng:122.086422517173},
{lat:-2.83627780873433,lng:122.086388877778},
{lat:-2.83638887777778,lng:122.086372898226},
{lat:-2.83666665555556,lng:122.086330272961},
{lat:-2.83694443333333,lng:122.086277692609},
{lat:-2.83722221111111,lng:122.08636287759},
{lat:-2.83749998888889,lng:122.086170489426},
{lat:-2.83758534349074,lng:122.0861111},
{lat:-2.83777776666667,lng:122.086004551729},
{lat:-2.83798062201304,lng:122.0861111},
{lat:-2.83805554444445,lng:122.086144464026},
{lat:-2.83833332222222,lng:122.086269485604},
{lat:-2.8386111,lng:122.086292802051},
{lat:-2.83888887777778,lng:122.086354363766},
{lat:-2.83916665555556,lng:122.086347868286},
{lat:-2.83943264470364,lng:122.0861111},
{lat:-2.83944443333333,lng:122.08609917708},
{lat:-2.83972221111111,lng:122.085948303857},
{lat:-2.83997650480827,lng:122.085833322222},
{lat:-2.83999998888889,lng:122.085821920561},
{lat:-2.84027776666667,lng:122.085714849113},
{lat:-2.84055554444445,lng:122.085651349903},
{lat:-2.84083332222222,lng:122.085626623797},
{lat:-2.8411111,lng:122.085638916146},
{lat:-2.84138887777778,lng:122.085624461644},
{lat:-2.84166665555556,lng:122.085575470509},
{lat:-2.8417191520087,lng:122.085555544444},
{lat:-2.84194443333333,lng:122.08536973252},
{lat:-2.84197983751057,lng:122.085277766667},
{lat:-2.84201712280325,lng:122.084999988889},
{lat:-2.84205197069989,lng:122.084722211111},
{lat:-2.84219062553156,lng:122.084444433333},
{lat:-2.84222221111111,lng:122.084398411158},
{lat:-2.84236963440212,lng:122.084166655556},
{lat:-2.84239764452607,lng:122.083888877778},
{lat:-2.84245633792275,lng:122.0836111},
{lat:-2.84249998888889,lng:122.083538835667},
{lat:-2.84270052343219,lng:122.083333322222},
{lat:-2.84277776666667,lng:122.083196182528},
{lat:-2.84299559983883,lng:122.083055544444},
{lat:-2.84305554444445,lng:122.083033625029},
{lat:-2.84316623626657,lng:122.083055544444},
{lat:-2.84315720152935,lng:122.083333322222},
{lat:-2.84305554444445,lng:122.083490699254},
{lat:-2.84296569893003,lng:122.0836111},
{lat:-2.84288237106524,lng:122.083888877778},
{lat:-2.84288864032213,lng:122.084166655556},
{lat:-2.84297079428078,lng:122.084444433333},
{lat:-2.84299975026763,lng:122.084722211111},
{lat:-2.84305554444445,lng:122.084839142947},
{lat:-2.84325104666139,lng:122.084999988889},
{lat:-2.84333332222222,lng:122.085046489411},
{lat:-2.84359781603794,lng:122.085277766667},
{lat:-2.8436111,lng:122.085298031758},
{lat:-2.84388887777778,lng:122.085501647131},
{lat:-2.84409836918258,lng:122.085277766667},
{lat:-2.84416665555556,lng:122.085164930175},
{lat:-2.84428939004617,lng:122.084999988889},
{lat:-2.84444443333333,lng:122.084904075911},
{lat:-2.84464134492043,lng:122.084722211111},
{lat:-2.84472221111111,lng:122.084624620215},
{lat:-2.84480807065751,lng:122.084722211111},
{lat:-2.84487864188462,lng:122.084999988889},
{lat:-2.84497226140175,lng:122.085277766667},
{lat:-2.84499998888889,lng:122.085360417321},
{lat:-2.84509889447641,lng:122.085555544444},
{lat:-2.84499998888889,lng:122.085768651923},
{lat:-2.84497288247113,lng:122.085833322222},
{lat:-2.844918402728,lng:122.0861111},
{lat:-2.84499998888889,lng:122.086352761868},
{lat:-2.84500730189056,lng:122.086388877778},
{lat:-2.84501936328017,lng:122.086666655556},
{lat:-2.84499998888889,lng:122.086709837828},
{lat:-2.84474523265666,lng:122.086944433333},
{lat:-2.84472221111111,lng:122.086953395787},
{lat:-2.84444443333333,lng:122.086950056207},
{lat:-2.84444095045725,lng:122.086944433333},
{lat:-2.84416665555556,lng:122.086724224124},
{lat:-2.84388887777778,lng:122.086822848357},
{lat:-2.8436111,lng:122.086897341267},
{lat:-2.84355997664868,lng:122.086944433333},
{lat:-2.84342859188682,lng:122.087222211111},
{lat:-2.84333332222222,lng:122.087451621912},
{lat:-2.8433124621269,lng:122.087499988889},
{lat:-2.84305554444445,lng:122.087672950184},
{lat:-2.84277776666667,lng:122.087557670827},
{lat:-2.84249998888889,lng:122.087540537425},
{lat:-2.84222221111111,lng:122.087553740083},
{lat:-2.84194443333333,lng:122.087731967327},
{lat:-2.8419222684337,lng:122.087777766667},
{lat:-2.84194443333333,lng:122.087934766278},
{lat:-2.84195456099972,lng:122.088055544444},
{lat:-2.84196012062952,lng:122.088333322222},
{lat:-2.84194443333333,lng:122.088404281769},
{lat:-2.84190310808782,lng:122.0886111},
{lat:-2.84194443333333,lng:122.088873473088},
{lat:-2.84194709603237,lng:122.088888877778},
{lat:-2.84204520933325,lng:122.089166655556},
{lat:-2.84207859394001,lng:122.089444433333},
{lat:-2.84207859394001,lng:122.089583322222},]},
{ID:79,coord:[{lat:-2.83706771222189,lng:122.063472211111},
{lat:-2.83706771222189,lng:122.0636111},
{lat:-2.83699913484566,lng:122.063888877778},
{lat:-2.83694443333333,lng:122.064001433266},
{lat:-2.83685471283112,lng:122.064166655556},
{lat:-2.83666665555556,lng:122.064443605537},
{lat:-2.83666604433863,lng:122.064444433333},
{lat:-2.83658274745327,lng:122.064722211111},
{lat:-2.83655466451707,lng:122.064999988889},
{lat:-2.83652304358506,lng:122.065277766667},
{lat:-2.83652850593005,lng:122.065555544444},
{lat:-2.83650293814976,lng:122.065833322222},
{lat:-2.83648256284538,lng:122.0661111},
{lat:-2.83644784817169,lng:122.066388877778},
{lat:-2.83647702497523,lng:122.066666655556},
{lat:-2.83638887777778,lng:122.066801348298},
{lat:-2.83624180580588,lng:122.066944433333},
{lat:-2.83611466803529,lng:122.067222211111},
{lat:-2.83621129803381,lng:122.067499988889},
{lat:-2.83638887777778,lng:122.067530733697},
{lat:-2.83666665555556,lng:122.067649025079},
{lat:-2.83694443333333,lng:122.067735657862},
{lat:-2.83722221111111,lng:122.0675824348},
{lat:-2.83749998888889,lng:122.067507753329},
{lat:-2.83777776666667,lng:122.06753638052},
{lat:-2.83805554444445,lng:122.06762863378},
{lat:-2.83833332222222,lng:122.067534823646},
{lat:-2.83838696120053,lng:122.067499988889},
{lat:-2.8386111,lng:122.067361881178},
{lat:-2.8387506884945,lng:122.067222211111},
{lat:-2.83888887777778,lng:122.067026991763},
{lat:-2.8389286530098,lng:122.066944433333},
{lat:-2.83907798965004,lng:122.066666655556},
{lat:-2.83916665555556,lng:122.066503795348},
{lat:-2.83929629684311,lng:122.066388877778},
{lat:-2.83944443333333,lng:122.066283410517},
{lat:-2.83953687241647,lng:122.0661111},
{lat:-2.83972221111111,lng:122.065874255627},
{lat:-2.83983883483003,lng:122.065833322222},
{lat:-2.83999998888889,lng:122.065769012152},
{lat:-2.84027776666667,lng:122.065662392814},
{lat:-2.84040671095468,lng:122.065833322222},
{lat:-2.84028794580735,lng:122.0661111},
{lat:-2.84027776666667,lng:122.066137837614},
{lat:-2.84019377166446,lng:122.066388877778},
{lat:-2.84014395834663,lng:122.066666655556},
{lat:-2.8400574310364,lng:122.066944433333},
{lat:-2.83999998888889,lng:122.067041710304},
{lat:-2.83988940156516,lng:122.067222211111},
{lat:-2.83974850415074,lng:122.067499988889},
{lat:-2.83972221111111,lng:122.067645067292},
{lat:-2.83969788542198,lng:122.067777766667},
{lat:-2.83960863097214,lng:122.068055544444},
{lat:-2.83955044580681,lng:122.068333322222},
{lat:-2.83946897302813,lng:122.0686111},
{lat:-2.83944443333333,lng:122.068812205886},
{lat:-2.83943271451839,lng:122.068888877778},
{lat:-2.83944443333333,lng:122.06912402821},
{lat:-2.83944614749337,lng:122.069166655556},
{lat:-2.83956431600684,lng:122.069444433333},
{lat:-2.8396593834725,lng:122.069722211111},
{lat:-2.83944443333333,lng:122.069913547296},
{lat:-2.83916665555556,lng:122.069998817693},
{lat:-2.83916181856968,lng:122.069999988889},
{lat:-2.83888887777778,lng:122.07010236002},
{lat:-2.83877255332377,lng:122.070277766667},
{lat:-2.83883177211875,lng:122.070555544444},
{lat:-2.83888887777778,lng:122.070654814517},
{lat:-2.83900439350862,lng:122.070833322222},
{lat:-2.83904455249766,lng:122.0711111},
{lat:-2.83888887777778,lng:122.071194506619},
{lat:-2.8386111,lng:122.071232028627},
{lat:-2.83833332222222,lng:122.07133654178},
{lat:-2.8381739487497,lng:122.071388877778},
{lat:-2.83805554444445,lng:122.071595226984},
{lat:-2.83797943269525,lng:122.071666655556},
{lat:-2.83790673872422,lng:122.071944433333},
{lat:-2.83799321345216,lng:122.072222211111},
{lat:-2.83778615075487,lng:122.072499988889},
{lat:-2.83777776666667,lng:122.072505712563},
{lat:-2.83756697776329,lng:122.072777766667},
{lat:-2.83749998888889,lng:122.072931392424},
{lat:-2.83741153582174,lng:122.073055544444},
{lat:-2.83722221111111,lng:122.073224516075},
{lat:-2.83713489891872,lng:122.073333322222},
{lat:-2.83710224128267,lng:122.0736111},
{lat:-2.83709077884247,lng:122.073888877778},
{lat:-2.83701515947259,lng:122.074166655556},
{lat:-2.83694443333333,lng:122.074358378884},
{lat:-2.83690440037768,lng:122.074444433333},
{lat:-2.83676013391853,lng:122.074722211111},
{lat:-2.83670397498003,lng:122.074999988889},
{lat:-2.83666665555556,lng:122.07512911717},
{lat:-2.83662513628079,lng:122.075277766667},
{lat:-2.83651272198779,lng:122.075555544444},
{lat:-2.83641456549631,lng:122.075833322222},
{lat:-2.83638887777778,lng:122.075922748401},
{lat:-2.83633310227655,lng:122.0761111},
{lat:-2.83625958909397,lng:122.076388877778},
{lat:-2.83624391522567,lng:122.076666655556},
{lat:-2.83638887777778,lng:122.076844580773},
{lat:-2.83645853271176,lng:122.076944433333},
{lat:-2.83661655760935,lng:122.077222211111},
{lat:-2.83638887777778,lng:122.077498294638},
{lat:-2.83638784056849,lng:122.077499988889},
{lat:-2.83619579611673,lng:122.077777766667},
{lat:-2.8361111,lng:122.0778915853},
{lat:-2.83583332222222,lng:122.077955085726},
{lat:-2.83555554444445,lng:122.07794158886},
{lat:-2.83527776666667,lng:122.077812801084},
{lat:-2.83521005187529,lng:122.077777766667},
{lat:-2.83499998888889,lng:122.077671551212},
{lat:-2.83472221111111,lng:122.077554168139},
{lat:-2.83444443333333,lng:122.077650796686},
{lat:-2.83416665555556,lng:122.077741728749},
{lat:-2.83407037020078,lng:122.077777766667},
{lat:-2.83388887777778,lng:122.077906087835},
{lat:-2.83375302289436,lng:122.078055544444},
{lat:-2.8336958802319,lng:122.078333322222},
{lat:-2.83367283047498,lng:122.0786111},
{lat:-2.83361783676925,lng:122.078888877778},
{lat:-2.8336111,lng:122.078903910858},
{lat:-2.83350444860801,lng:122.079166655556},
{lat:-2.83348715053438,lng:122.079444433333},
{lat:-2.83347107287941,lng:122.079722211111},
{lat:-2.8335497699255,lng:122.079999988889},
{lat:-2.8336111,lng:122.080093964532},
{lat:-2.83388887777778,lng:122.080118933296},
{lat:-2.83416665555556,lng:122.080173457349},
{lat:-2.83425159241003,lng:122.080277766667},
{lat:-2.83426752134297,lng:122.080555544444},
{lat:-2.83416665555556,lng:122.080661836008},
{lat:-2.83388887777778,lng:122.080758830242},
{lat:-2.83383038356331,lng:122.080833322222},
{lat:-2.83384911299392,lng:122.0811111},
{lat:-2.83388887777778,lng:122.08119667368},
{lat:-2.83406260581892,lng:122.081388877778},
{lat:-2.83402703923222,lng:122.081666655556},
{lat:-2.83394000799494,lng:122.081944433333},
{lat:-2.83398662295733,lng:122.082222211111},
{lat:-2.83398988499145,lng:122.082499988889},
{lat:-2.83398201832406,lng:122.082777766667},
{lat:-2.83399543664657,lng:122.083055544444},
{lat:-2.83399798662737,lng:122.083333322222},
{lat:-2.83416665555556,lng:122.083587257733},
{lat:-2.83419426218242,lng:122.0836111},
{lat:-2.83444443333333,lng:122.083843695764},
{lat:-2.83450421295024,lng:122.083888877778},
{lat:-2.83472221111111,lng:122.084026170483},
{lat:-2.83483631426903,lng:122.084166655556},
{lat:-2.83481458353965,lng:122.084444433333},
{lat:-2.8348645614007,lng:122.084722211111},
{lat:-2.83485187225232,lng:122.084999988889},
{lat:-2.83497363631309,lng:122.085277766667},
{lat:-2.83499998888889,lng:122.085427329347},
{lat:-2.83501894940884,lng:122.085555544444},
{lat:-2.83506728371926,lng:122.085833322222},
{lat:-2.83515187761385,lng:122.0861111},
{lat:-2.83527776666667,lng:122.086216500188},
{lat:-2.83555554444445,lng:122.086249892842},
{lat:-2.83583332222222,lng:122.086190916888},
{lat:-2.8361111,lng:122.086233123723},
{lat:-2.83638887777778,lng:122.086176540268},
{lat:-2.83666665555556,lng:122.086149169891},
{lat:-2.83685957639533,lng:122.0861111},
{lat:-2.83694443333333,lng:122.08609410373},
{lat:-2.83722221111111,lng:122.086096427063},
{lat:-2.83749998888889,lng:122.085961920522},
{lat:-2.83772182811984,lng:122.085833322222},
{lat:-2.83777776666667,lng:122.085803933556},
{lat:-2.83788990927968,lng:122.085833322222},
{lat:-2.83805554444445,lng:122.085898371578},
{lat:-2.83830797871834,lng:122.0861111},
{lat:-2.83833332222222,lng:122.086122993126},
{lat:-2.8386111,lng:122.086164305018},
{lat:-2.83888887777778,lng:122.086190922677},
{lat:-2.83916665555556,lng:122.086175683487},
{lat:-2.83923920964217,lng:122.0861111},
{lat:-2.83944443333333,lng:122.085903538492},
{lat:-2.83958126062755,lng:122.085833322222},
{lat:-2.83972221111111,lng:122.085727883271},
{lat:-2.83999998888889,lng:122.085626036517},
{lat:-2.84016461340243,lng:122.085555544444},
{lat:-2.84027776666667,lng:122.085508027773},
{lat:-2.84055554444445,lng:122.085454304015},
{lat:-2.84083332222222,lng:122.085345782604},
{lat:-2.8411111,lng:122.085396546892},
{lat:-2.84138887777778,lng:122.085437121444},
{lat:-2.84166665555556,lng:122.085304755807},
{lat:-2.84169614038051,lng:122.085277766667},
{lat:-2.84181734886327,lng:122.084999988889},
{lat:-2.84187368418354,lng:122.084722211111},
{lat:-2.84194443333333,lng:122.084568936321},
{lat:-2.84200238963191,lng:122.084444433333},
{lat:-2.84218944093831,lng:122.084166655556},
{lat:-2.84222221111111,lng:122.084021686202},
{lat:-2.84224402648556,lng:122.083888877778},
{lat:-2.84226793253562,lng:122.0836111},
{lat:-2.84242425287274,lng:122.083333322222},
{lat:-2.84249998888889,lng:122.083270039948},
{lat:-2.84266938553983,lng:122.083055544444},
{lat:-2.84277776666667,lng:122.082915391313},
{lat:-2.84300438936099,lng:122.082777766667},
{lat:-2.84305554444445,lng:122.082744818909},
{lat:-2.84325722385256,lng:122.082499988889},
{lat:-2.84328837542584,lng:122.082222211111},
{lat:-2.84332361230735,lng:122.081944433333},
{lat:-2.84333332222222,lng:122.081920533801},
{lat:-2.84335201607678,lng:122.081944433333},
{lat:-2.84342393922619,lng:122.082222211111},
{lat:-2.8436111,lng:122.082489825503},
{lat:-2.84362065656501,lng:122.082499988889},
{lat:-2.84367608129147,lng:122.082777766667},
{lat:-2.8436111,lng:122.083040936203},
{lat:-2.84360677440782,lng:122.083055544444},
{lat:-2.84345216713006,lng:122.083333322222},
{lat:-2.84333332222222,lng:122.08349171018},
{lat:-2.84325266123555,lng:122.0836111},
{lat:-2.84305554444445,lng:122.083876147246},
{lat:-2.84304964335889,lng:122.083888877778},
{lat:-2.84304923777917,lng:122.084166655556},
{lat:-2.84305554444445,lng:122.084186499744},
{lat:-2.84321352444267,lng:122.084444433333},
{lat:-2.84330133218345,lng:122.084722211111},
{lat:-2.84333332222222,lng:122.084749219848},
{lat:-2.8436111,lng:122.084935848453},
{lat:-2.84373868240753,lng:122.084999988889},
{lat:-2.84388887777778,lng:122.085107108612},
{lat:-2.84395976811472,lng:122.084999988889},
{lat:-2.84416665555556,lng:122.084767363025},
{lat:-2.84425398042846,lng:122.084722211111},
{lat:-2.84444443333333,lng:122.084642135294},
{lat:-2.84461442897402,lng:122.084444433333},
{lat:-2.84472221111111,lng:122.08437680801},
{lat:-2.84499998888889,lng:122.084400101448},
{lat:-2.84505851788244,lng:122.084444433333},
{lat:-2.8450741859704,lng:122.084722211111},
{lat:-2.84508403924866,lng:122.084999988889},
{lat:-2.84519157923754,lng:122.085277766667},
{lat:-2.84527776666667,lng:122.085402609833},
{lat:-2.84545343979459,lng:122.085555544444},
{lat:-2.84527776666667,lng:122.08574852051},
{lat:-2.84522525844384,lng:122.085833322222},
{lat:-2.8451793850813,lng:122.0861111},
{lat:-2.84525798966005,lng:122.086388877778},
{lat:-2.84527776666667,lng:122.086464796342},
{lat:-2.84531820254942,lng:122.086666655556},
{lat:-2.84539169594171,lng:122.086944433333},
{lat:-2.84527776666667,lng:122.087122085705},
{lat:-2.84499998888889,lng:122.087107890508},
{lat:-2.84478108526632,lng:122.087222211111},
{lat:-2.84472221111111,lng:122.087262669129},
{lat:-2.84444443333333,lng:122.087462942464},
{lat:-2.84427638792974,lng:122.087222211111},
{lat:-2.84416665555556,lng:122.08706943775},
{lat:-2.84388887777778,lng:122.087078356066},
{lat:-2.84372039449675,lng:122.087222211111},
{lat:-2.84365885451591,lng:122.087499988889},
{lat:-2.8436111,lng:122.087585593793},
{lat:-2.84341981256872,lng:122.087777766667},
{lat:-2.84333332222222,lng:122.087839109826},
{lat:-2.84305554444445,lng:122.087884598144},
{lat:-2.84278776511308,lng:122.087777766667},
{lat:-2.84277776666667,lng:122.087774194502},
{lat:-2.84249998888889,lng:122.08773440694},
{lat:-2.84232309020971,lng:122.087777766667},
{lat:-2.84222221111111,lng:122.08784764626},
{lat:-2.84214858776083,lng:122.088055544444},
{lat:-2.84213786351832,lng:122.088333322222},
{lat:-2.84209359833147,lng:122.0886111},
{lat:-2.84212339694566,lng:122.088888877778},
{lat:-2.84220037901031,lng:122.089166655556},
{lat:-2.84222221111111,lng:122.089299493771},
{lat:-2.84224726900369,lng:122.089444433333},
{lat:-2.84224726900369,lng:122.089583322222},]},
{ID:80,coord:[{lat:-2.84527776666667,lng:122.074763910673},
{lat:-2.84531994911029,lng:122.074722211111},
{lat:-2.8453904008815,lng:122.074444433333},
{lat:-2.84553840293451,lng:122.074166655556},
{lat:-2.84527776666667,lng:122.07398836035},
{lat:-2.84516193089039,lng:122.074166655556},
{lat:-2.84517612939632,lng:122.074444433333},
{lat:-2.84520770113514,lng:122.074722211111},
{lat:-2.84527776666667,lng:122.074763910673},]},
{ID:81,coord:[{lat:-2.83750884776357,lng:122.063472211111},
{lat:-2.83750884776357,lng:122.0636111},
{lat:-2.83749998888889,lng:122.063623580672},
{lat:-2.83724926252048,lng:122.063888877778},
{lat:-2.83722221111111,lng:122.063936539074},
{lat:-2.83713432774251,lng:122.064166655556},
{lat:-2.83701032064127,lng:122.064444433333},
{lat:-2.83694443333333,lng:122.064548992932},
{lat:-2.83687330317074,lng:122.064722211111},
{lat:-2.83682985936038,lng:122.064999988889},
{lat:-2.83673225718848,lng:122.065277766667},
{lat:-2.83671684819312,lng:122.065555544444},
{lat:-2.83679438685124,lng:122.065833322222},
{lat:-2.83674658231156,lng:122.0661111},
{lat:-2.8367124330189,lng:122.066388877778},
{lat:-2.83674125608969,lng:122.066666655556},
{lat:-2.83666665555556,lng:122.066752603041},
{lat:-2.83654787300113,lng:122.066944433333},
{lat:-2.83665018205543,lng:122.067222211111},
{lat:-2.83666665555556,lng:122.067229759839},
{lat:-2.83694443333333,lng:122.067342604232},
{lat:-2.83718492008875,lng:122.067222211111},
{lat:-2.83722221111111,lng:122.067211825262},
{lat:-2.83749998888889,lng:122.067166642266},
{lat:-2.83777776666667,lng:122.067208616089},
{lat:-2.83805554444445,lng:122.067068447749},
{lat:-2.83825738370148,lng:122.066944433333},
{lat:-2.83833332222222,lng:122.066909471682},
{lat:-2.8386111,lng:122.066903269835},
{lat:-2.83879593687527,lng:122.066666655556},
{lat:-2.83888887777778,lng:122.066489808154},
{lat:-2.83891876818837,lng:122.066388877778},
{lat:-2.83896285160016,lng:122.0661111},
{lat:-2.83906135125429,lng:122.065833322222},
{lat:-2.83916665555556,lng:122.065760669698},
{lat:-2.83931325905335,lng:122.065833322222},
{lat:-2.83944443333333,lng:122.065887649567},
{lat:-2.8394788595444,lng:122.065833322222},
{lat:-2.83972221111111,lng:122.065570975511},
{lat:-2.83978420760573,lng:122.065555544444},
{lat:-2.83999998888889,lng:122.065462741714},
{lat:-2.84021211577467,lng:122.065277766667},
{lat:-2.84027776666667,lng:122.065203956987},
{lat:-2.84039944385495,lng:122.064999988889},
{lat:-2.84055554444445,lng:122.064815685694},
{lat:-2.84062975401215,lng:122.064722211111},
{lat:-2.84083332222222,lng:122.064471174925},
{lat:-2.8411111,lng:122.064482078163},
{lat:-2.84120252980012,lng:122.064444433333},
{lat:-2.84138887777778,lng:122.064370855708},
{lat:-2.84166665555556,lng:122.064224923829},
{lat:-2.84175915538843,lng:122.064444433333},
{lat:-2.84166874939634,lng:122.064722211111},
{lat:-2.84166665555556,lng:122.064726570852},
{lat:-2.84138887777778,lng:122.064907585235},
{lat:-2.84122957846852,lng:122.064999988889},
{lat:-2.8411111,lng:122.065027763352},
{lat:-2.84087526404022,lng:122.065277766667},
{lat:-2.84083332222222,lng:122.065386501792},
{lat:-2.84078018096349,lng:122.065555544444},
{lat:-2.84069550734778,lng:122.065833322222},
{lat:-2.84055554444445,lng:122.066032663845},
{lat:-2.84050504427258,lng:122.0661111},
{lat:-2.84039322698211,lng:122.066388877778},
{lat:-2.84033175831434,lng:122.066666655556},
{lat:-2.84031145025649,lng:122.066944433333},
{lat:-2.84027776666667,lng:122.067014009142},
{lat:-2.84014761036088,lng:122.067222211111},
{lat:-2.83999998888889,lng:122.067442552979},
{lat:-2.83996587480432,lng:122.067499988889},
{lat:-2.83988530864459,lng:122.067777766667},
{lat:-2.83981131552124,lng:122.068055544444},
{lat:-2.83975980943612,lng:122.068333322222},
{lat:-2.83972221111111,lng:122.068449562052},
{lat:-2.83966315252612,lng:122.0686111},
{lat:-2.83962396216943,lng:122.068888877778},
{lat:-2.83964240743814,lng:122.069166655556},
{lat:-2.83972221111111,lng:122.069324319066},
{lat:-2.8398037690494,lng:122.069444433333},
{lat:-2.83994960649331,lng:122.069722211111},
{lat:-2.83972221111111,lng:122.069994243496},
{lat:-2.83971559494321,lng:122.069999988889},
{lat:-2.83944443333333,lng:122.070161362011},
{lat:-2.83916665555556,lng:122.070233551013},
{lat:-2.83908624846271,lng:122.070277766667},
{lat:-2.83910099670093,lng:122.070555544444},
{lat:-2.83916665555556,lng:122.070687134035},
{lat:-2.83926561530957,lng:122.070833322222},
{lat:-2.83940105339252,lng:122.0711111},
{lat:-2.83916665555556,lng:122.0712831961},
{lat:-2.83892726841881,lng:122.071388877778},
{lat:-2.83888887777778,lng:122.071407536909},
{lat:-2.8386111,lng:122.071492349666},
{lat:-2.83852531452834,lng:122.071666655556},
{lat:-2.83848953582463,lng:122.071944433333},
{lat:-2.83847357868842,lng:122.072222211111},
{lat:-2.83842525144307,lng:122.072499988889},
{lat:-2.83833332222222,lng:122.072626964791},
{lat:-2.83819687544338,lng:122.072777766667},
{lat:-2.8380973460638,lng:122.073055544444},
{lat:-2.83805554444445,lng:122.073097809587},
{lat:-2.83777776666667,lng:122.073333185852},
{lat:-2.83777761967297,lng:122.073333322222},
{lat:-2.83749998888889,lng:122.073510920735},
{lat:-2.83742621547493,lng:122.0736111},
{lat:-2.83734434696832,lng:122.073888877778},
{lat:-2.83727030945933,lng:122.074166655556},
{lat:-2.83722221111111,lng:122.074269179817},
{lat:-2.83713836411481,lng:122.074444433333},
{lat:-2.83704215573265,lng:122.074722211111},
{lat:-2.83694443333333,lng:122.074936146087},
{lat:-2.83691926747507,lng:122.074999988889},
{lat:-2.83680751236485,lng:122.075277766667},
{lat:-2.83668850213788,lng:122.075555544444},
{lat:-2.83666665555556,lng:122.075602626035},
{lat:-2.83657968607243,lng:122.075833322222},
{lat:-2.83651685836241,lng:122.0761111},
{lat:-2.83647138488564,lng:122.076388877778},
{lat:-2.83649749065143,lng:122.076666655556},
{lat:-2.83666665555556,lng:122.076921314239},
{lat:-2.83671400751808,lng:122.076944433333},
{lat:-2.83694443333333,lng:122.077099399137},
{lat:-2.83702237128932,lng:122.077222211111},
{lat:-2.83694443333333,lng:122.077346766046},
{lat:-2.83681142971226,lng:122.077499988889},
{lat:-2.83666665555556,lng:122.077629691484},
{lat:-2.8364946346839,lng:122.077777766667},
{lat:-2.83638887777778,lng:122.077875612362},
{lat:-2.83626429605193,lng:122.078055544444},
{lat:-2.8361111,lng:122.078263587272},
{lat:-2.83583332222222,lng:122.078309150761},
{lat:-2.83563598347617,lng:122.078333322222},
{lat:-2.83555554444445,lng:122.078350317309},
{lat:-2.83551366530685,lng:122.078333322222},
{lat:-2.83527776666667,lng:122.078220899019},
{lat:-2.83514988081634,lng:122.078055544444},
{lat:-2.83499998888889,lng:122.077983770087},
{lat:-2.83472221111111,lng:122.077868837519},
{lat:-2.83444443333333,lng:122.077925894356},
{lat:-2.83423246341433,lng:122.078055544444},
{lat:-2.83416665555556,lng:122.078115807216},
{lat:-2.83404153288334,lng:122.078333322222},
{lat:-2.83400705632082,lng:122.0786111},
{lat:-2.83393100138677,lng:122.078888877778},
{lat:-2.83388887777778,lng:122.079004280582},
{lat:-2.83383260365101,lng:122.079166655556},
{lat:-2.83384169961242,lng:122.079444433333},
{lat:-2.83388887777778,lng:122.079571473484},
{lat:-2.83416665555556,lng:122.079683511533},
{lat:-2.83444443333333,lng:122.079573266866},
{lat:-2.83461061147205,lng:122.079444433333},
{lat:-2.83472221111111,lng:122.079372799119},
{lat:-2.83499998888889,lng:122.079264176536},
{lat:-2.83508277339328,lng:122.079444433333},
{lat:-2.83504227340557,lng:122.079722211111},
{lat:-2.83499998888889,lng:122.079784726299},
{lat:-2.83475891320537,lng:122.079999988889},
{lat:-2.834817877871,lng:122.080277766667},
{lat:-2.83475241161074,lng:122.080555544444},
{lat:-2.83472221111111,lng:122.080624747593},
{lat:-2.83460278203278,lng:122.080833322222},
{lat:-2.83454453253555,lng:122.0811111},
{lat:-2.83452553335176,lng:122.081388877778},
{lat:-2.8344967373498,lng:122.081666655556},
{lat:-2.83444443333333,lng:122.081844137629},
{lat:-2.83438460155708,lng:122.081944433333},
{lat:-2.83436920623492,lng:122.082222211111},
{lat:-2.83429077266765,lng:122.082499988889},
{lat:-2.83423589733576,lng:122.082777766667},
{lat:-2.83421331363292,lng:122.083055544444},
{lat:-2.83426269363553,lng:122.083333322222},
{lat:-2.83444443333333,lng:122.083503668278},
{lat:-2.83462487781678,lng:122.0836111},
{lat:-2.83472221111111,lng:122.083693925308},
{lat:-2.83488819010183,lng:122.083888877778},
{lat:-2.83499998888889,lng:122.084019514505},
{lat:-2.8351004323426,lng:122.084166655556},
{lat:-2.83520578153049,lng:122.084444433333},
{lat:-2.83527776666667,lng:122.08464412802},
{lat:-2.83531208415733,lng:122.084722211111},
{lat:-2.83535822987314,lng:122.084999988889},
{lat:-2.83544182502087,lng:122.085277766667},
{lat:-2.83541015562929,lng:122.085555544444},
{lat:-2.83538592768316,lng:122.085833322222},
{lat:-2.83555554444445,lng:122.08602306035},
{lat:-2.83583332222222,lng:122.085932480127},
{lat:-2.8361111,lng:122.086036702704},
{lat:-2.83638887777778,lng:122.085978087202},
{lat:-2.83666665555556,lng:122.085946475256},
{lat:-2.83694443333333,lng:122.085910285068},
{lat:-2.83722221111111,lng:122.08591788245},
{lat:-2.83738469363706,lng:122.085833322222},
{lat:-2.83749998888889,lng:122.085764582052},
{lat:-2.83777776666667,lng:122.085628391201},
{lat:-2.83805554444445,lng:122.085655458921},
{lat:-2.83820421477584,lng:122.085833322222},
{lat:-2.83833332222222,lng:122.08594879315},
{lat:-2.8386111,lng:122.086018620437},
{lat:-2.83888887777778,lng:122.086022108405},
{lat:-2.83916665555556,lng:122.085974726495},
{lat:-2.83930628557976,lng:122.085833322222},
{lat:-2.83944443333333,lng:122.08571768671},
{lat:-2.83963731039506,lng:122.085555544444},
{lat:-2.83972221111111,lng:122.085478574736},
{lat:-2.83999998888889,lng:122.085395838306},
{lat:-2.84027776666667,lng:122.085315925628},
{lat:-2.84041590744335,lng:122.085277766667},
{lat:-2.84055554444445,lng:122.085243108643},
{lat:-2.84083332222222,lng:122.085155663987},
{lat:-2.8411111,lng:122.085171959364},
{lat:-2.84138887777778,lng:122.085243579413},
{lat:-2.84157352547415,lng:122.084999988889},
{lat:-2.84166665555556,lng:122.084781859858},
{lat:-2.84168703242714,lng:122.084722211111},
{lat:-2.84174910643402,lng:122.084444433333},
{lat:-2.84194443333333,lng:122.084218540489},
{lat:-2.84197803961489,lng:122.084166655556},
{lat:-2.84206523810516,lng:122.083888877778},
{lat:-2.84207096689339,lng:122.0836111},
{lat:-2.84219333259821,lng:122.083333322222},
{lat:-2.84222221111111,lng:122.083313567094},
{lat:-2.84249998888889,lng:122.083084518082},
{lat:-2.84252287066058,lng:122.083055544444},
{lat:-2.84272977573527,lng:122.082777766667},
{lat:-2.84277776666667,lng:122.082722532074},
{lat:-2.84305554444445,lng:122.082543874875},
{lat:-2.84309169564695,lng:122.082499988889},
{lat:-2.84315777763165,lng:122.082222211111},
{lat:-2.84319101750376,lng:122.081944433333},
{lat:-2.84329608780085,lng:122.081666655556},
{lat:-2.84333332222222,lng:122.081619335842},
{lat:-2.84344494777071,lng:122.081666655556},
{lat:-2.8436072920503,lng:122.081944433333},
{lat:-2.8436111,lng:122.081958059894},
{lat:-2.84371017162048,lng:122.082222211111},
{lat:-2.84388887777778,lng:122.082411877227},
{lat:-2.84402661914002,lng:122.082499988889},
{lat:-2.84392201719723,lng:122.082777766667},
{lat:-2.84388887777778,lng:122.082852722618},
{lat:-2.8438013289953,lng:122.083055544444},
{lat:-2.84370876398715,lng:122.083333322222},
{lat:-2.84362550317273,lng:122.0836111},
{lat:-2.8436111,lng:122.083655230205},
{lat:-2.84346620893161,lng:122.083888877778},
{lat:-2.84344799989904,lng:122.084166655556},
{lat:-2.84352916542831,lng:122.084444433333},
{lat:-2.8436111,lng:122.084569296179},
{lat:-2.84388887777778,lng:122.084673028815},
{lat:-2.84416665555556,lng:122.084545733736},
{lat:-2.84434867841316,lng:122.084444433333},
{lat:-2.84444443333333,lng:122.084401188196},
{lat:-2.84472221111111,lng:122.084232576805},
{lat:-2.8449336965973,lng:122.084166655556},
{lat:-2.84499998888889,lng:122.084144090644},
{lat:-2.84527776666667,lng:122.084083393975},
{lat:-2.8453257234232,lng:122.084166655556},
{lat:-2.84533824080844,lng:122.084444433333},
{lat:-2.8452810206957,lng:122.084722211111},
{lat:-2.84527776666667,lng:122.084775239619},
{lat:-2.84526228544491,lng:122.084999988889},
{lat:-2.84527776666667,lng:122.08503488769},
{lat:-2.84555554444445,lng:122.085275836551},
{lat:-2.8455620552311,lng:122.085277766667},
{lat:-2.84583332222222,lng:122.085482804645},
{lat:-2.84600092319454,lng:122.085555544444},
{lat:-2.84583332222222,lng:122.085600317375},
{lat:-2.84555554444445,lng:122.085777123568},
{lat:-2.84550332893906,lng:122.085833322222},
{lat:-2.84540931244454,lng:122.0861111},
{lat:-2.84544644905629,lng:122.086388877778},
{lat:-2.84551097231827,lng:122.086666655556},
{lat:-2.84555554444445,lng:122.086779053744},
{lat:-2.84562673840315,lng:122.086944433333},
{lat:-2.84572022345953,lng:122.087222211111},
{lat:-2.84555554444445,lng:122.087343909822},
{lat:-2.84527776666667,lng:122.087370432103},
{lat:-2.84499998888889,lng:122.087364444641},
{lat:-2.84481511975781,lng:122.087499988889},
{lat:-2.84472221111111,lng:122.087615539044},
{lat:-2.84445114932864,lng:122.087777766667},
{lat:-2.84444443333333,lng:122.087780384295},
{lat:-2.84443650022135,lng:122.087777766667},
{lat:-2.84416665555556,lng:122.087636853348},
{lat:-2.84388887777778,lng:122.087521815989},
{lat:-2.84375230534631,lng:122.087777766667},
{lat:-2.8436111,lng:122.087910863063},
{lat:-2.84342358393093,lng:122.088055544444},
{lat:-2.84333332222222,lng:122.088131555096},
{lat:-2.84305554444445,lng:122.088132427849},
{lat:-2.84277776666667,lng:122.088152771747},
{lat:-2.84257086440106,lng:122.088055544444},
{lat:-2.84249998888889,lng:122.088026968689},
{lat:-2.84246513898564,lng:122.088055544444},
{lat:-2.84237082950727,lng:122.088333322222},
{lat:-2.84231422581022,lng:122.0886111},
{lat:-2.84231776707538,lng:122.088888877778},
{lat:-2.84237492039164,lng:122.089166655556},
{lat:-2.84241049598843,lng:122.089444433333},
{lat:-2.84241049598843,lng:122.089583322222},]},
{ID:82,coord:[{lat:-2.84588092762504,lng:122.080555544444},
{lat:-2.84583332222222,lng:122.080776735967},
{lat:-2.84571299176245,lng:122.080555544444},
{lat:-2.84583332222222,lng:122.080495911551},
{lat:-2.84588092762504,lng:122.080555544444},]},
{ID:83,coord:[{lat:-2.8461111,lng:122.082812282726},
{lat:-2.84626443159587,lng:122.082777766667},
{lat:-2.8461111,lng:122.082602264192},
{lat:-2.84601721011761,lng:122.082777766667},
{lat:-2.8461111,lng:122.082812282726},]},
{ID:84,coord:[{lat:-2.84602772268021,lng:122.063472211111},
{lat:-2.84602772268021,lng:122.0636111},
{lat:-2.8461111,lng:122.063679784253},
{lat:-2.8462348046323,lng:122.0636111},
{lat:-2.8462348046323,lng:122.063472211111},]},
{ID:85,coord:[{lat:-2.84184352733505,lng:122.063472211111},
{lat:-2.84184352733505,lng:122.0636111},
{lat:-2.84194443333333,lng:122.063716370184},
{lat:-2.84203703002668,lng:122.063888877778},
{lat:-2.84200107165386,lng:122.064166655556},
{lat:-2.84202810773794,lng:122.064444433333},
{lat:-2.84194443333333,lng:122.064647797202},
{lat:-2.84191942888096,lng:122.064722211111},
{lat:-2.84194443333333,lng:122.064844375923},
{lat:-2.84197758606811,lng:122.064999988889},
{lat:-2.84194443333333,lng:122.065073332707},
{lat:-2.84166665555556,lng:122.065217655255},
{lat:-2.84138887777778,lng:122.06517743957},
{lat:-2.8411111,lng:122.065269121581},
{lat:-2.84110294482021,lng:122.065277766667},
{lat:-2.84096209515721,lng:122.065555544444},
{lat:-2.84090140877413,lng:122.065833322222},
{lat:-2.84083332222222,lng:122.065971929213},
{lat:-2.84073512836697,lng:122.0661111},
{lat:-2.84059931480045,lng:122.066388877778},
{lat:-2.84055554444445,lng:122.066511776132},
{lat:-2.84050817711604,lng:122.066666655556},
{lat:-2.84050133187485,lng:122.066944433333},
{lat:-2.84038127836857,lng:122.067222211111},
{lat:-2.84027776666667,lng:122.067373353832},
{lat:-2.84018943497781,lng:122.067499988889},
{lat:-2.84008243043233,lng:122.067777766667},
{lat:-2.83999998888889,lng:122.068035252543},
{lat:-2.83999457792764,lng:122.068055544444},
{lat:-2.83993160360942,lng:122.068333322222},
{lat:-2.83985490963688,lng:122.0686111},
{lat:-2.83980968507408,lng:122.068888877778},
{lat:-2.83983782777454,lng:122.069166655556},
{lat:-2.83999998888889,lng:122.069379343424},
{lat:-2.84009438472163,lng:122.069444433333},
{lat:-2.84021595526359,lng:122.069722211111},
{lat:-2.83999998888889,lng:122.069963569194},
{lat:-2.83996885984843,lng:122.069999988889},
{lat:-2.83972221111111,lng:122.070226799469},
{lat:-2.83963697417726,lng:122.070277766667},
{lat:-2.83949243534097,lng:122.070555544444},
{lat:-2.83956071739449,lng:122.070833322222},
{lat:-2.83962653039422,lng:122.0711111},
{lat:-2.83944443333333,lng:122.071345250242},
{lat:-2.83938348065048,lng:122.071388877778},
{lat:-2.83916665555556,lng:122.071599719785},
{lat:-2.83895862376015,lng:122.071666655556},
{lat:-2.83890862076036,lng:122.071944433333},
{lat:-2.83896600125379,lng:122.072222211111},
{lat:-2.83889244074069,lng:122.072499988889},
{lat:-2.83888887777778,lng:122.07250490764},
{lat:-2.83862287663159,lng:122.072777766667},
{lat:-2.8386111,lng:122.072806846064},
{lat:-2.83854001877517,lng:122.073055544444},
{lat:-2.83851785244849,lng:122.073333322222},
{lat:-2.83839752442776,lng:122.0736111},
{lat:-2.83833332222222,lng:122.073710746553},
{lat:-2.83810175330489,lng:122.073888877778},
{lat:-2.83805554444445,lng:122.073909825448},
{lat:-2.83798376302929,lng:122.073888877778},
{lat:-2.83777776666667,lng:122.07378466214},
{lat:-2.83769024847777,lng:122.073888877778},
{lat:-2.83749998888889,lng:122.074125821606},
{lat:-2.8374861999139,lng:122.074166655556},
{lat:-2.83737608900266,lng:122.074444433333},
{lat:-2.83731338526796,lng:122.074722211111},
{lat:-2.83722221111111,lng:122.074929831233},
{lat:-2.83719547880307,lng:122.074999988889},
{lat:-2.83703374813087,lng:122.075277766667},
{lat:-2.83694443333333,lng:122.075405875441},
{lat:-2.83689174116057,lng:122.075555544444},
{lat:-2.8368108360518,lng:122.075833322222},
{lat:-2.83674471985872,lng:122.0761111},
{lat:-2.836747854686,lng:122.076388877778},
{lat:-2.83687070799553,lng:122.076666655556},
{lat:-2.83694443333333,lng:122.076705495106},
{lat:-2.83722221111111,lng:122.076704622225},
{lat:-2.83749998888889,lng:122.076856210495},
{lat:-2.83756727216459,lng:122.076944433333},
{lat:-2.83749998888889,lng:122.076991677731},
{lat:-2.83735720899998,lng:122.077222211111},
{lat:-2.83722221111111,lng:122.077437827148},
{lat:-2.83718450817331,lng:122.077499988889},
{lat:-2.83702824197011,lng:122.077777766667},
{lat:-2.83694443333333,lng:122.077869453125},
{lat:-2.83666665555556,lng:122.078005123757},
{lat:-2.83661289754729,lng:122.078055544444},
{lat:-2.83645300431753,lng:122.078333322222},
{lat:-2.83638887777778,lng:122.078497163068},
{lat:-2.83631724222941,lng:122.0786111},
{lat:-2.83613027082147,lng:122.078888877778},
{lat:-2.8361111,lng:122.079002305368},
{lat:-2.83600848256975,lng:122.079166655556},
{lat:-2.83583332222222,lng:122.079357128647},
{lat:-2.8357920634021,lng:122.079444433333},
{lat:-2.83557852162114,lng:122.079722211111},
{lat:-2.83555554444445,lng:122.079738299892},
{lat:-2.83541785701212,lng:122.079999988889},
{lat:-2.83529480319999,lng:122.080277766667},
{lat:-2.83527776666667,lng:122.080323072079},
{lat:-2.83512081122317,lng:122.080555544444},
{lat:-2.83499998888889,lng:122.080673065638},
{lat:-2.83493370972828,lng:122.080833322222},
{lat:-2.83487714382231,lng:122.0811111},
{lat:-2.83482386748465,lng:122.081388877778},
{lat:-2.83472221111111,lng:122.08165429029},
{lat:-2.83471830288122,lng:122.081666655556},
{lat:-2.83460425187993,lng:122.081944433333},
{lat:-2.8345860667914,lng:122.082222211111},
{lat:-2.83453021320309,lng:122.082499988889},
{lat:-2.83444443333333,lng:122.082704572194},
{lat:-2.8344163566258,lng:122.082777766667},
{lat:-2.83437279581422,lng:122.083055544444},
{lat:-2.83444443333333,lng:122.083255076488},
{lat:-2.8345227513043,lng:122.083333322222},
{lat:-2.83472221111111,lng:122.083439980838},
{lat:-2.83487716151106,lng:122.0836111},
{lat:-2.83499998888889,lng:122.083751822535},
{lat:-2.83515606156086,lng:122.083888877778},
{lat:-2.83527776666667,lng:122.084107836834},
{lat:-2.83533192526798,lng:122.084166655556},
{lat:-2.83555554444445,lng:122.084424680422},
{lat:-2.83558306563777,lng:122.084444433333},
{lat:-2.8357614055955,lng:122.084722211111},
{lat:-2.83581070839902,lng:122.084999988889},
{lat:-2.83583332222222,lng:122.085086623627},
{lat:-2.83590780596156,lng:122.085277766667},
{lat:-2.83599231680498,lng:122.085555544444},
{lat:-2.8361111,lng:122.085725991311},
{lat:-2.83638887777778,lng:122.08572360119},
{lat:-2.83666665555556,lng:122.085682393099},
{lat:-2.83694443333333,lng:122.08571380303},
{lat:-2.83722221111111,lng:122.085705641178},
{lat:-2.83749998888889,lng:122.085560038192},
{lat:-2.83751004289759,lng:122.085555544444},
{lat:-2.83777776666667,lng:122.085455812043},
{lat:-2.83805554444445,lng:122.085447592277},
{lat:-2.83826590577702,lng:122.085555544444},
{lat:-2.83833332222222,lng:122.085652252015},
{lat:-2.8385181962512,lng:122.085833322222},
{lat:-2.8386111,lng:122.085860790293},
{lat:-2.83888887777778,lng:122.08584816486},
{lat:-2.83894307307234,lng:122.085833322222},
{lat:-2.83916665555556,lng:122.085756584931},
{lat:-2.83941699825587,lng:122.085555544444},
{lat:-2.83944443333333,lng:122.085531823794},
{lat:-2.83972221111111,lng:122.08528405496},
{lat:-2.83973827305536,lng:122.085277766667},
{lat:-2.83999998888889,lng:122.085184237905},
{lat:-2.84027776666667,lng:122.085132195148},
{lat:-2.84055554444445,lng:122.085070500676},
{lat:-2.84082416162583,lng:122.084999988889},
{lat:-2.84083332222222,lng:122.084995597294},
{lat:-2.8411111,lng:122.084943998969},
{lat:-2.84138887777778,lng:122.084846066989},
{lat:-2.84143289484839,lng:122.084722211111},
{lat:-2.84148230173815,lng:122.084444433333},
{lat:-2.84162098375983,lng:122.084166655556},
{lat:-2.84166665555556,lng:122.084105624605},
{lat:-2.84182447384538,lng:122.083888877778},
{lat:-2.84185456284146,lng:122.0836111},
{lat:-2.84188653739601,lng:122.083333322222},
{lat:-2.84194443333333,lng:122.083262915776},
{lat:-2.84222221111111,lng:122.083094146026},
{lat:-2.84227013258265,lng:122.083055544444},
{lat:-2.84249998888889,lng:122.082852418104},
{lat:-2.84255438153724,lng:122.082777766667},
{lat:-2.84277776666667,lng:122.082520664205},
{lat:-2.84280994917402,lng:122.082499988889},
{lat:-2.84300001526114,lng:122.082222211111},
{lat:-2.84305554444445,lng:122.081969673278},
{lat:-2.84305842270018,lng:122.081944433333},
{lat:-2.84312843902177,lng:122.081666655556},
{lat:-2.84333332222222,lng:122.08140627782},
{lat:-2.84337246997988,lng:122.081388877778},
{lat:-2.84360348644582,lng:122.0811111},
{lat:-2.8436111,lng:122.081097660536},
{lat:-2.84388887777778,lng:122.080953553441},
{lat:-2.8441101361649,lng:122.0811111},
{lat:-2.84407823385345,lng:122.081388877778},
{lat:-2.84388993758888,lng:122.081666655556},
{lat:-2.84388887777778,lng:122.081692806405},
{lat:-2.84388287467536,lng:122.081944433333},
{lat:-2.84388887777778,lng:122.08195651785},
{lat:-2.84416665555556,lng:122.08207790786},
{lat:-2.84444443333333,lng:122.082032506228},
{lat:-2.84472221111111,lng:122.082165479541},
{lat:-2.84475557771615,lng:122.082222211111},
{lat:-2.84472221111111,lng:122.082384996742},
{lat:-2.84468419823058,lng:122.082499988889},
{lat:-2.84444443333333,lng:122.082627366614},
{lat:-2.84416665555556,lng:122.082711127154},
{lat:-2.84411964627553,lng:122.082777766667},
{lat:-2.84400401629263,lng:122.083055544444},
{lat:-2.84391627763069,lng:122.083333322222},
{lat:-2.84388887777778,lng:122.083448587147},
{lat:-2.84384567632257,lng:122.0836111},
{lat:-2.84378251734414,lng:122.083888877778},
{lat:-2.84380080481348,lng:122.084166655556},
{lat:-2.84388887777778,lng:122.084271303562},
{lat:-2.84416665555556,lng:122.084308935195},
{lat:-2.84444443333333,lng:122.0842351768},
{lat:-2.84456440103209,lng:122.084166655556},
{lat:-2.84472221111111,lng:122.084080618216},
{lat:-2.84499998888889,lng:122.083986589044},
{lat:-2.845154443092,lng:122.083888877778},
{lat:-2.84527776666667,lng:122.08379635226},
{lat:-2.84555554444445,lng:122.083812907027},
{lat:-2.84561956476587,lng:122.083888877778},
{lat:-2.84555554444445,lng:122.084135807404},
{lat:-2.84554772938473,lng:122.084166655556},
{lat:-2.84549308606431,lng:122.084444433333},
{lat:-2.84543981149754,lng:122.084722211111},
{lat:-2.84545738206569,lng:122.084999988889},
{lat:-2.84555554444445,lng:122.085091821303},
{lat:-2.84583332222222,lng:122.085189820548},
{lat:-2.8461111,lng:122.085190616052},
{lat:-2.84638887777778,lng:122.085211143272},
{lat:-2.84666665555556,lng:122.085232235564},
{lat:-2.84675179172077,lng:122.085277766667},
{lat:-2.84668228289869,lng:122.085555544444},
{lat:-2.84666665555556,lng:122.085562886233},
{lat:-2.84638887777778,lng:122.085750712101},
{lat:-2.84615339941602,lng:122.085833322222},
{lat:-2.8461111,lng:122.085843809671},
{lat:-2.84583332222222,lng:122.085908595052},
{lat:-2.8456726882438,lng:122.0861111},
{lat:-2.8456558753869,lng:122.086388877778},
{lat:-2.84571491682925,lng:122.086666655556},
{lat:-2.84583332222222,lng:122.08693833943},
{lat:-2.84583643178552,lng:122.086944433333},
{lat:-2.84600335309746,lng:122.087222211111},
{lat:-2.84602386367864,lng:122.087499988889},
{lat:-2.8458752781137,lng:122.087777766667},
{lat:-2.84583332222222,lng:122.087839162057},
{lat:-2.84578386362651,lng:122.087777766667},
{lat:-2.84555554444445,lng:122.087617626915},
{lat:-2.84527776666667,lng:122.087575528158},
{lat:-2.84499998888889,lng:122.087638038135},
{lat:-2.84487815855783,lng:122.087777766667},
{lat:-2.84472221111111,lng:122.088026735745},
{lat:-2.84458738680677,lng:122.088055544444},
{lat:-2.84444443333333,lng:122.08808100482},
{lat:-2.8444035494705,lng:122.088055544444},
{lat:-2.84416665555556,lng:122.087945646733},
{lat:-2.84388887777778,lng:122.087951703999},
{lat:-2.84377476464449,lng:122.088055544444},
{lat:-2.8436111,lng:122.088207525307},
{lat:-2.84344742735807,lng:122.088333322222},
{lat:-2.84333332222222,lng:122.08854155137},
{lat:-2.84305554444445,lng:122.088588329153},
{lat:-2.84298075811966,lng:122.0886111},
{lat:-2.84277776666667,lng:122.088691945026},
{lat:-2.84264972114991,lng:122.088888877778},
{lat:-2.84276927232365,lng:122.089166655556},
{lat:-2.84277776666667,lng:122.089178568599},
{lat:-2.84290436106884,lng:122.089444433333},
{lat:-2.84290436106884,lng:122.089583322222},]},
{ID:86,coord:[{lat:-2.84694443333333,lng:122.073060431336},
{lat:-2.84695352823936,lng:122.073055544444},
{lat:-2.84715482675515,lng:122.072777766667},
{lat:-2.84708268838295,lng:122.072499988889},
{lat:-2.84703652869679,lng:122.072222211111},
{lat:-2.84694443333333,lng:122.072041519176},
{lat:-2.8468227444803,lng:122.072222211111},
{lat:-2.84679103487561,lng:122.072499988889},
{lat:-2.84675112434666,lng:122.072777766667},
{lat:-2.84693272986522,lng:122.073055544444},
{lat:-2.84694443333333,lng:122.073060431336},]},
{ID:87,coord:[{lat:-2.84722221111111,lng:122.071413297861},
{lat:-2.84724909028633,lng:122.071388877778},
{lat:-2.84746823902308,lng:122.0711111},
{lat:-2.84722221111111,lng:122.070951235049},
{lat:-2.84694443333333,lng:122.071021748095},
{lat:-2.84666665555556,lng:122.071035807602},
{lat:-2.84663746121366,lng:122.0711111},
{lat:-2.8464886665332,lng:122.071388877778},
{lat:-2.84666665555556,lng:122.071521256603},
{lat:-2.84680393076541,lng:122.071666655556},
{lat:-2.84694443333333,lng:122.071806757051},
{lat:-2.84702085861924,lng:122.071666655556},
{lat:-2.84722221111111,lng:122.071413297861},]},
{ID:88,coord:[{lat:-2.84774989205872,lng:122.076388877778},
{lat:-2.84749998888889,lng:122.076553364057},
{lat:-2.84722221111111,lng:122.076612439024},
{lat:-2.84694443333333,lng:122.076543223982},
{lat:-2.84669468465839,lng:122.076388877778},
{lat:-2.84694443333333,lng:122.07619779274},
{lat:-2.84722221111111,lng:122.076183215609},
{lat:-2.84749998888889,lng:122.076246504963},
{lat:-2.84774989205872,lng:122.076388877778},]},
{ID:89,coord:[{lat:-2.84752862988926,lng:122.0736111},
{lat:-2.84749998888889,lng:122.073697330719},
{lat:-2.84734507308604,lng:122.0736111},
{lat:-2.84749998888889,lng:122.07356423678},
{lat:-2.84752862988926,lng:122.0736111},]},
{ID:90,coord:[{lat:-2.84212788687605,lng:122.063472211111},
{lat:-2.84212788687605,lng:122.0636111},
{lat:-2.84222221111111,lng:122.063777989878},
{lat:-2.84234014759344,lng:122.063888877778},
{lat:-2.84249998888889,lng:122.064026104573},
{lat:-2.84277165913444,lng:122.064166655556},
{lat:-2.84277776666667,lng:122.064169903339},
{lat:-2.84305554444445,lng:122.064272297385},
{lat:-2.84333332222222,lng:122.064389454612},
{lat:-2.84349374649443,lng:122.064444433333},
{lat:-2.84335027512066,lng:122.064722211111},
{lat:-2.84333332222222,lng:122.064729675782},
{lat:-2.84305554444445,lng:122.064889503005},
{lat:-2.8429036287537,lng:122.064999988889},
{lat:-2.84277776666667,lng:122.06521323456},
{lat:-2.84249998888889,lng:122.065241531845},
{lat:-2.8424516679573,lng:122.065277766667},
{lat:-2.84229058110809,lng:122.065555544444},
{lat:-2.84222221111111,lng:122.065735812601},
{lat:-2.84203545797666,lng:122.065555544444},
{lat:-2.84194443333333,lng:122.065519475238},
{lat:-2.84166665555556,lng:122.065427191534},
{lat:-2.84138887777778,lng:122.065416708751},
{lat:-2.84118065376358,lng:122.065555544444},
{lat:-2.8411111,lng:122.065660482716},
{lat:-2.84106433599069,lng:122.065833322222},
{lat:-2.84095501296125,lng:122.0661111},
{lat:-2.84083332222222,lng:122.066335196894},
{lat:-2.84080475891913,lng:122.066388877778},
{lat:-2.84071318551841,lng:122.066666655556},
{lat:-2.84070863792337,lng:122.066944433333},
{lat:-2.84063323255389,lng:122.067222211111},
{lat:-2.84055554444445,lng:122.067286805642},
{lat:-2.84040740220641,lng:122.067499988889},
{lat:-2.84030187007768,lng:122.067777766667},
{lat:-2.84027776666667,lng:122.06782665038},
{lat:-2.8401801520575,lng:122.068055544444},
{lat:-2.84012363443723,lng:122.068333322222},
{lat:-2.84004586826665,lng:122.0686111},
{lat:-2.83999998888889,lng:122.068847121987},
{lat:-2.83999226191258,lng:122.068888877778},
{lat:-2.83999998888889,lng:122.068944841723},
{lat:-2.84004775078948,lng:122.069166655556},
{lat:-2.84027776666667,lng:122.069337556294},
{lat:-2.84042336681443,lng:122.069444433333},
{lat:-2.84048482678127,lng:122.069722211111},
{lat:-2.84027776666667,lng:122.069916119955},
{lat:-2.84020163068623,lng:122.069999988889},
{lat:-2.83999998888889,lng:122.070209810216},
{lat:-2.83992584063546,lng:122.070277766667},
{lat:-2.83977669075208,lng:122.070555544444},
{lat:-2.83977947626124,lng:122.070833322222},
{lat:-2.83983878424008,lng:122.0711111},
{lat:-2.83981885247166,lng:122.071388877778},
{lat:-2.83972221111111,lng:122.071506108315},
{lat:-2.83945721234359,lng:122.071666655556},
{lat:-2.83944443333333,lng:122.071690593017},
{lat:-2.83935424974975,lng:122.071944433333},
{lat:-2.83932909964433,lng:122.072222211111},
{lat:-2.83926400545348,lng:122.072499988889},
{lat:-2.83916665555556,lng:122.072580164874},
{lat:-2.83902106783936,lng:122.072777766667},
{lat:-2.83891057454699,lng:122.073055544444},
{lat:-2.83888887777778,lng:122.073157063776},
{lat:-2.83884081490224,lng:122.073333322222},
{lat:-2.83880277141429,lng:122.0736111},
{lat:-2.83864175479599,lng:122.073888877778},
{lat:-2.8386111,lng:122.073906697622},
{lat:-2.83833332222222,lng:122.074122241857},
{lat:-2.83824171959666,lng:122.074166655556},
{lat:-2.83805554444445,lng:122.07430113682},
{lat:-2.83793133116391,lng:122.074444433333},
{lat:-2.83777776666667,lng:122.074669044482},
{lat:-2.8377320904774,lng:122.074722211111},
{lat:-2.83771390761259,lng:122.074999988889},
{lat:-2.83749998888889,lng:122.075210196986},
{lat:-2.83745593382133,lng:122.075277766667},
{lat:-2.83734033904827,lng:122.075555544444},
{lat:-2.83722221111111,lng:122.075737977794},
{lat:-2.83719330782875,lng:122.075833322222},
{lat:-2.83722127515711,lng:122.0761111},
{lat:-2.83722221111111,lng:122.076112463526},
{lat:-2.83749998888889,lng:122.076291140486},
{lat:-2.83761495488263,lng:122.0761111},
{lat:-2.83759549144668,lng:122.075833322222},
{lat:-2.83777776666667,lng:122.075664635171},
{lat:-2.83805554444445,lng:122.075586952448},
{lat:-2.83833332222222,lng:122.075751332471},
{lat:-2.83839616002047,lng:122.075833322222},
{lat:-2.83841987271552,lng:122.0761111},
{lat:-2.8386111,lng:122.076265586037},
{lat:-2.83865351691941,lng:122.076388877778},
{lat:-2.83863267255842,lng:122.076666655556},
{lat:-2.8386111,lng:122.076698887234},
{lat:-2.83839223844055,lng:122.076944433333},
{lat:-2.83833332222222,lng:122.077030158678},
{lat:-2.83805554444445,lng:122.076991619297},
{lat:-2.83779816783173,lng:122.077222211111},
{lat:-2.83777776666667,lng:122.077240210692},
{lat:-2.83758184171878,lng:122.077499988889},
{lat:-2.83750834743653,lng:122.077777766667},
{lat:-2.83749998888889,lng:122.077795080529},
{lat:-2.83733227260836,lng:122.078055544444},
{lat:-2.83725065081773,lng:122.078333322222},
{lat:-2.83722221111111,lng:122.078391091342},
{lat:-2.83694443333333,lng:122.078574401217},
{lat:-2.83688891302462,lng:122.0786111},
{lat:-2.83666665555556,lng:122.078835399816},
{lat:-2.83664300128637,lng:122.078888877778},
{lat:-2.83652618353375,lng:122.079166655556},
{lat:-2.83638887777778,lng:122.079389813864},
{lat:-2.83635054973545,lng:122.079444433333},
{lat:-2.83614066132657,lng:122.079722211111},
{lat:-2.8361111,lng:122.079765834695},
{lat:-2.83594695916593,lng:122.079999988889},
{lat:-2.83583332222222,lng:122.080174859142},
{lat:-2.83572241297354,lng:122.080277766667},
{lat:-2.83556608207179,lng:122.080555544444},
{lat:-2.83555554444445,lng:122.08057431649},
{lat:-2.83533878607557,lng:122.080833322222},
{lat:-2.83527776666667,lng:122.080921129366},
{lat:-2.83516668741192,lng:122.0811111},
{lat:-2.83506045548849,lng:122.081388877778},
{lat:-2.83499998888889,lng:122.081560792607},
{lat:-2.83495071006253,lng:122.081666655556},
{lat:-2.83480299410463,lng:122.081944433333},
{lat:-2.83476459858199,lng:122.082222211111},
{lat:-2.83472681967358,lng:122.082499988889},
{lat:-2.83472221111111,lng:122.08250917928},
{lat:-2.83458893821228,lng:122.082777766667},
{lat:-2.83456478676661,lng:122.083055544444},
{lat:-2.83472221111111,lng:122.083212897398},
{lat:-2.83489391057267,lng:122.083333322222},
{lat:-2.83499998888889,lng:122.083456193175},
{lat:-2.83527776666667,lng:122.083602418442},
{lat:-2.83532678117887,lng:122.0836111},
{lat:-2.83555554444445,lng:122.083668754201},
{lat:-2.8357637660719,lng:122.083888877778},
{lat:-2.83583332222222,lng:122.083989162209},
{lat:-2.83605898935005,lng:122.084166655556},
{lat:-2.8361111,lng:122.08419823842},
{lat:-2.83623689964442,lng:122.084166655556},
{lat:-2.83638887777778,lng:122.084130856505},
{lat:-2.83662219376515,lng:122.084166655556},
{lat:-2.83666665555556,lng:122.0841772579},
{lat:-2.83688127003811,lng:122.084444433333},
{lat:-2.83694443333333,lng:122.084510933326},
{lat:-2.8371648592165,lng:122.084722211111},
{lat:-2.83703312778333,lng:122.084999988889},
{lat:-2.83694443333333,lng:122.085135310937},
{lat:-2.83686363808559,lng:122.085277766667},
{lat:-2.83694443333333,lng:122.085419930211},
{lat:-2.83722221111111,lng:122.085463490327},
{lat:-2.83749998888889,lng:122.085316674577},
{lat:-2.83777776666667,lng:122.085285334819},
{lat:-2.83786815146845,lng:122.085277766667},
{lat:-2.83805554444445,lng:122.085257769059},
{lat:-2.83833332222222,lng:122.085269357086},
{lat:-2.83834411415583,lng:122.085277766667},
{lat:-2.83856803343261,lng:122.085555544444},
{lat:-2.8386111,lng:122.085598128417},
{lat:-2.83888887777778,lng:122.085620086631},
{lat:-2.83910575965522,lng:122.085555544444},
{lat:-2.83916665555556,lng:122.085527362727},
{lat:-2.83944443333333,lng:122.085297131238},
{lat:-2.83946625766927,lng:122.085277766667},
{lat:-2.83972221111111,lng:122.085077672794},
{lat:-2.83999998888889,lng:122.085006678262},
{lat:-2.84003375914284,lng:122.084999988889},
{lat:-2.84027776666667,lng:122.084904298112},
{lat:-2.84055554444445,lng:122.084763278246},
{lat:-2.84067665375473,lng:122.084722211111},
{lat:-2.84083332222222,lng:122.084615590898},
{lat:-2.8411111,lng:122.084505805939},
{lat:-2.84113299197944,lng:122.084444433333},
{lat:-2.84122542829377,lng:122.084166655556},
{lat:-2.84128099820939,lng:122.083888877778},
{lat:-2.84137210480578,lng:122.0836111},
{lat:-2.84138887777778,lng:122.083595109314},
{lat:-2.84158664213515,lng:122.083333322222},
{lat:-2.84166665555556,lng:122.083174744514},
{lat:-2.84173552652814,lng:122.083055544444},
{lat:-2.84194443333333,lng:122.082914759837},
{lat:-2.84213763930309,lng:122.082777766667},
{lat:-2.84222221111111,lng:122.082721510876},
{lat:-2.84249998888889,lng:122.082514173599},
{lat:-2.84251102167763,lng:122.082499988889},
{lat:-2.84272411542641,lng:122.082222211111},
{lat:-2.84277776666667,lng:122.08218262745},
{lat:-2.84290157179081,lng:122.081944433333},
{lat:-2.84296307534171,lng:122.081666655556},
{lat:-2.84305554444445,lng:122.081450694018},
{lat:-2.8430971501686,lng:122.081388877778},
{lat:-2.84333332222222,lng:122.081194772754},
{lat:-2.84340994032989,lng:122.0811111},
{lat:-2.84357191221234,lng:122.080833322222},
{lat:-2.8436111,lng:122.080782646251},
{lat:-2.84388887777778,lng:122.080590127837},
{lat:-2.84407810529511,lng:122.080555544444},
{lat:-2.84416665555556,lng:122.080535250257},
{lat:-2.84419763209171,lng:122.080555544444},
{lat:-2.84444443333333,lng:122.080703247061},
{lat:-2.84467897658211,lng:122.080833322222},
{lat:-2.84458009393736,lng:122.0811111},
{lat:-2.84444443333333,lng:122.081303866368},
{lat:-2.8444048231974,lng:122.081388877778},
{lat:-2.8444170948376,lng:122.081666655556},
{lat:-2.84444443333333,lng:122.081683701991},
{lat:-2.84472221111111,lng:122.081762961919},
{lat:-2.84499998888889,lng:122.081693770859},
{lat:-2.84527776666667,lng:122.081796810278},
{lat:-2.84531433019005,lng:122.081944433333},
{lat:-2.84527776666667,lng:122.081987916709},
{lat:-2.84506791980861,lng:122.082222211111},
{lat:-2.84499998888889,lng:122.082289650986},
{lat:-2.84488850410297,lng:122.082499988889},
{lat:-2.84472221111111,lng:122.082728960131},
{lat:-2.84463422357329,lng:122.082777766667},
{lat:-2.84444443333333,lng:122.082879727805},
{lat:-2.8442327423631,lng:122.083055544444},
{lat:-2.84416665555556,lng:122.083182394658},
{lat:-2.84411315905962,lng:122.083333322222},
{lat:-2.84408361197533,lng:122.0836111},
{lat:-2.84412858657605,lng:122.083888877778},
{lat:-2.84416665555556,lng:122.083935346324},
{lat:-2.84444443333333,lng:122.083982828163},
{lat:-2.84472221111111,lng:122.083922154724},
{lat:-2.84482114459857,lng:122.083888877778},
{lat:-2.84499998888889,lng:122.083809570898},
{lat:-2.84527537902388,lng:122.0836111},
{lat:-2.84527776666667,lng:122.083609639436},
{lat:-2.84555554444445,lng:122.083569504329},
{lat:-2.84577446712573,lng:122.0836111},
{lat:-2.84583332222222,lng:122.08375924369},
{lat:-2.84585233268945,lng:122.083888877778},
{lat:-2.84583332222222,lng:122.08391554385},
{lat:-2.84573163804674,lng:122.084166655556},
{lat:-2.84566295749244,lng:122.084444433333},
{lat:-2.84561684114132,lng:122.084722211111},
{lat:-2.84574984874056,lng:122.084999988889},
{lat:-2.84583332222222,lng:122.085033607494},
{lat:-2.8461111,lng:122.085044141726},
{lat:-2.84638887777778,lng:122.085025743839},
{lat:-2.84666665555556,lng:122.085049147886},
{lat:-2.84694443333333,lng:122.085181988164},
{lat:-2.84722221111111,lng:122.085185353249},
{lat:-2.84749998888889,lng:122.085216973413},
{lat:-2.84772967881083,lng:122.085277766667},
{lat:-2.84777776666667,lng:122.085365605034},
{lat:-2.84782417299191,lng:122.085555544444},
{lat:-2.84777776666667,lng:122.085608414188},
{lat:-2.84749998888889,lng:122.085752819489},
{lat:-2.84722221111111,lng:122.085832159821},
{lat:-2.84694443333333,lng:122.085805745933},
{lat:-2.84688546612076,lng:122.085833322222},
{lat:-2.84666665555556,lng:122.085946484404},
{lat:-2.84638887777778,lng:122.086106434148},
{lat:-2.84636954129014,lng:122.0861111},
{lat:-2.8461111,lng:122.086187183248},
{lat:-2.84593443744776,lng:122.086388877778},
{lat:-2.8459468887516,lng:122.086666655556},
{lat:-2.8460791525334,lng:122.086944433333},
{lat:-2.8461111,lng:122.086999155058},
{lat:-2.84627664237288,lng:122.087222211111},
{lat:-2.84638887777778,lng:122.087485718536},
{lat:-2.84640346174937,lng:122.087499988889},
{lat:-2.84638887777778,lng:122.087508265167},
{lat:-2.84617034205601,lng:122.087777766667},
{lat:-2.8461111,lng:122.087862929672},
{lat:-2.84598628614334,lng:122.088055544444},
{lat:-2.84583332222222,lng:122.088269686169},
{lat:-2.84570427293215,lng:122.088055544444},
{lat:-2.84555554444445,lng:122.087879598639},
{lat:-2.84527776666667,lng:122.087801332508},
{lat:-2.84499998888889,lng:122.088011648287},
{lat:-2.84497464378785,lng:122.088055544444},
{lat:-2.84497101615493,lng:122.088333322222},
{lat:-2.84472221111111,lng:122.088527131217},
{lat:-2.84444443333333,lng:122.088434005913},
{lat:-2.84423839465788,lng:122.088333322222},
{lat:-2.84416665555556,lng:122.088299671364},
{lat:-2.84409118664594,lng:122.088333322222},
{lat:-2.84388887777778,lng:122.088491436192},
{lat:-2.84380101476466,lng:122.0886111},
{lat:-2.84388887777778,lng:122.088845377426},
{lat:-2.84390807034972,lng:122.088888877778},
{lat:-2.84405942218101,lng:122.089166655556},
{lat:-2.84401691251566,lng:122.089444433333},
{lat:-2.84401691251566,lng:122.089583322222},]},
{ID:91,coord:[{lat:-2.84778368678755,lng:122.082499988889},
{lat:-2.84777776666667,lng:122.082508585037},
{lat:-2.8477343932835,lng:122.082499988889},
{lat:-2.84777776666667,lng:122.082494384119},
{lat:-2.84778368678755,lng:122.082499988889},]},
{ID:92,coord:[{lat:-2.84246592905127,lng:122.063472211111},
{lat:-2.84246592905127,lng:122.0636111},
{lat:-2.84249998888889,lng:122.063643295492},
{lat:-2.84261913831811,lng:122.063888877778},
{lat:-2.84277776666667,lng:122.064017334848},
{lat:-2.84305554444445,lng:122.064081510298},
{lat:-2.84333332222222,lng:122.06411564279},
{lat:-2.8436111,lng:122.06412040271},
{lat:-2.84384636135611,lng:122.064166655556},
{lat:-2.84388887777778,lng:122.0641790825},
{lat:-2.8441561811801,lng:122.064444433333},
{lat:-2.84412167759846,lng:122.064722211111},
{lat:-2.8439865007574,lng:122.064999988889},
{lat:-2.84392066588562,lng:122.065277766667},
{lat:-2.84391603750365,lng:122.065555544444},
{lat:-2.84388887777778,lng:122.065625541102},
{lat:-2.84369031696995,lng:122.065555544444},
{lat:-2.84368381298895,lng:122.065277766667},
{lat:-2.8436111,lng:122.065209789202},
{lat:-2.84333332222222,lng:122.065201507051},
{lat:-2.84305554444445,lng:122.065277280965},
{lat:-2.84305519218449,lng:122.065277766667},
{lat:-2.84277776666667,lng:122.065518066039},
{lat:-2.84270391467931,lng:122.065555544444},
{lat:-2.84249998888889,lng:122.065816521538},
{lat:-2.84249231045176,lng:122.065833322222},
{lat:-2.84238166982154,lng:122.0661111},
{lat:-2.84222221111111,lng:122.066148371015},
{lat:-2.84218967953887,lng:122.0661111},
{lat:-2.84194443333333,lng:122.065848948143},
{lat:-2.84192828514423,lng:122.065833322222},
{lat:-2.84166665555556,lng:122.065635913724},
{lat:-2.84138887777778,lng:122.06575759629},
{lat:-2.84133306911655,lng:122.065833322222},
{lat:-2.84119701839599,lng:122.0661111},
{lat:-2.8411111,lng:122.066337430641},
{lat:-2.84109354357668,lng:122.066388877778},
{lat:-2.84102034877768,lng:122.066666655556},
{lat:-2.84099388045451,lng:122.066944433333},
{lat:-2.84107135118251,lng:122.067222211111},
{lat:-2.84083332222222,lng:122.067452478643},
{lat:-2.84076777079216,lng:122.067499988889},
{lat:-2.84055554444445,lng:122.067674318954},
{lat:-2.8405165127312,lng:122.067777766667},
{lat:-2.8404298178075,lng:122.068055544444},
{lat:-2.84035513970792,lng:122.068333322222},
{lat:-2.84027776666667,lng:122.068484471366},
{lat:-2.84023765469448,lng:122.0686111},
{lat:-2.84019723419761,lng:122.068888877778},
{lat:-2.84027776666667,lng:122.069075121676},
{lat:-2.8403726737852,lng:122.069166655556},
{lat:-2.84055554444445,lng:122.069279478166},
{lat:-2.84068902736461,lng:122.069444433333},
{lat:-2.84083332222222,lng:122.069656647884},
{lat:-2.84091411131151,lng:122.069722211111},
{lat:-2.84083332222222,lng:122.069885357434},
{lat:-2.84055554444445,lng:122.069941701747},
{lat:-2.84049257167362,lng:122.069999988889},
{lat:-2.84028925293631,lng:122.070277766667},
{lat:-2.84027776666667,lng:122.070302838644},
{lat:-2.84014436823036,lng:122.070555544444},
{lat:-2.84007844309849,lng:122.070833322222},
{lat:-2.84012960226501,lng:122.0711111},
{lat:-2.84015076162854,lng:122.071388877778},
{lat:-2.84010300949554,lng:122.071666655556},
{lat:-2.83999998888889,lng:122.071801218842},
{lat:-2.83981423634713,lng:122.071944433333},
{lat:-2.83975050628718,lng:122.072222211111},
{lat:-2.83980322599922,lng:122.072499988889},
{lat:-2.83976551270933,lng:122.072777766667},
{lat:-2.83972221111111,lng:122.072834971808},
{lat:-2.83944443333333,lng:122.073046977514},
{lat:-2.83940313747627,lng:122.073055544444},
{lat:-2.83916665555556,lng:122.073167351228},
{lat:-2.83913115123011,lng:122.073333322222},
{lat:-2.83916665555556,lng:122.073444541448},
{lat:-2.83932055919213,lng:122.0736111},
{lat:-2.83939232975221,lng:122.073888877778},
{lat:-2.8393097290964,lng:122.074166655556},
{lat:-2.839191227493,lng:122.074444433333},
{lat:-2.83916665555556,lng:122.074482843585},
{lat:-2.83911169377682,lng:122.074444433333},
{lat:-2.83888887777778,lng:122.074270970319},
{lat:-2.83876583887814,lng:122.074444433333},
{lat:-2.8386111,lng:122.074589688787},
{lat:-2.83854897810009,lng:122.074722211111},
{lat:-2.83860720626605,lng:122.074999988889},
{lat:-2.8386111,lng:122.075009234712},
{lat:-2.83870255854892,lng:122.075277766667},
{lat:-2.83888887777778,lng:122.075498180402},
{lat:-2.83900633199876,lng:122.075555544444},
{lat:-2.83902363353941,lng:122.075833322222},
{lat:-2.83893965021538,lng:122.0761111},
{lat:-2.83895010229329,lng:122.076388877778},
{lat:-2.83889247609675,lng:122.076666655556},
{lat:-2.83888887777778,lng:122.076671461583},
{lat:-2.83872172116437,lng:122.076944433333},
{lat:-2.83866639271038,lng:122.077222211111},
{lat:-2.8386111,lng:122.077279240015},
{lat:-2.83833332222222,lng:122.077412557627},
{lat:-2.83819705083241,lng:122.077499988889},
{lat:-2.83807544448671,lng:122.077777766667},
{lat:-2.83805554444445,lng:122.077807181148},
{lat:-2.83777776666667,lng:122.078043173242},
{lat:-2.83777273615271,lng:122.078055544444},
{lat:-2.83768491501192,lng:122.078333322222},
{lat:-2.83749998888889,lng:122.078609274531},
{lat:-2.83749915941483,lng:122.0786111},
{lat:-2.83737358571748,lng:122.078888877778},
{lat:-2.83722221111111,lng:122.079081841815},
{lat:-2.83708212693011,lng:122.079166655556},
{lat:-2.83694443333333,lng:122.079251875465},
{lat:-2.8366813910001,lng:122.079444433333},
{lat:-2.83666665555556,lng:122.079458130654},
{lat:-2.83639737383905,lng:122.079722211111},
{lat:-2.83638887777778,lng:122.079726823341},
{lat:-2.83618429594216,lng:122.079999988889},
{lat:-2.8361111,lng:122.080103176263},
{lat:-2.83598381715073,lng:122.080277766667},
{lat:-2.83583332222222,lng:122.080505746996},
{lat:-2.83579595822714,lng:122.080555544444},
{lat:-2.83564673666983,lng:122.080833322222},
{lat:-2.83555554444445,lng:122.080972877431},
{lat:-2.83545224059903,lng:122.0811111},
{lat:-2.83527776666667,lng:122.081311714905},
{lat:-2.8352362069752,lng:122.081388877778},
{lat:-2.83513607679859,lng:122.081666655556},
{lat:-2.83502821278214,lng:122.081944433333},
{lat:-2.83499998888889,lng:122.082017517541},
{lat:-2.83494033751357,lng:122.082222211111},
{lat:-2.83489976215522,lng:122.082499988889},
{lat:-2.83477951693348,lng:122.082777766667},
{lat:-2.83485166407778,lng:122.083055544444},
{lat:-2.83499998888889,lng:122.083146361353},
{lat:-2.83527776666667,lng:122.083121469779},
{lat:-2.83555554444445,lng:122.083115590975},
{lat:-2.83583332222222,lng:122.083140591979},
{lat:-2.8361111,lng:122.083325181243},
{lat:-2.83638887777778,lng:122.083318082598},
{lat:-2.83644841107166,lng:122.083333322222},
{lat:-2.83666665555556,lng:122.083383124666},
{lat:-2.83694443333333,lng:122.083463522447},
{lat:-2.83722221111111,lng:122.083517504318},
{lat:-2.83729997255896,lng:122.0836111},
{lat:-2.83739376892169,lng:122.083888877778},
{lat:-2.83749998888889,lng:122.084070777269},
{lat:-2.8375234225148,lng:122.084166655556},
{lat:-2.83758581180391,lng:122.084444433333},
{lat:-2.83758648635525,lng:122.084722211111},
{lat:-2.83777776666667,lng:122.084888014392},
{lat:-2.83794048041431,lng:122.084999988889},
{lat:-2.83805554444445,lng:122.085040500621},
{lat:-2.83833332222222,lng:122.085047841701},
{lat:-2.8386111,lng:122.085250591538},
{lat:-2.83884916792381,lng:122.085277766667},
{lat:-2.83888887777778,lng:122.085281608186},
{lat:-2.83889763492211,lng:122.085277766667},
{lat:-2.83916665555556,lng:122.085136854152},
{lat:-2.8393393258278,lng:122.084999988889},
{lat:-2.83944443333333,lng:122.08489992695},
{lat:-2.83961324363285,lng:122.084722211111},
{lat:-2.83972221111111,lng:122.084603741948},
{lat:-2.83999998888889,lng:122.084588772637},
{lat:-2.84027776666667,lng:122.08449321107},
{lat:-2.84040720085425,lng:122.084444433333},
{lat:-2.84055554444445,lng:122.084331173223},
{lat:-2.84063061865006,lng:122.084166655556},
{lat:-2.84062856413998,lng:122.083888877778},
{lat:-2.84057570791263,lng:122.0836111},
{lat:-2.84071488211318,lng:122.083333322222},
{lat:-2.84083332222222,lng:122.083198283563},
{lat:-2.84094407033459,lng:122.083055544444},
{lat:-2.8411111,lng:122.082800913485},
{lat:-2.84113354490739,lng:122.082777766667},
{lat:-2.84138887777778,lng:122.082592179612},
{lat:-2.84166665555556,lng:122.082574478577},
{lat:-2.84184671140717,lng:122.082499988889},
{lat:-2.84194443333333,lng:122.082429641551},
{lat:-2.84222221111111,lng:122.082284776609},
{lat:-2.84228212976657,lng:122.082222211111},
{lat:-2.84249998888889,lng:122.082003540243},
{lat:-2.84260139890276,lng:122.081944433333},
{lat:-2.84277776666667,lng:122.081773142467},
{lat:-2.84279946959242,lng:122.081666655556},
{lat:-2.84286754984881,lng:122.081388877778},
{lat:-2.84305554444445,lng:122.081155829735},
{lat:-2.84311396087365,lng:122.0811111},
{lat:-2.84333332222222,lng:122.080940181148},
{lat:-2.84339873315043,lng:122.080833322222},
{lat:-2.8436111,lng:122.080558698494},
{lat:-2.84361541337758,lng:122.080555544444},
{lat:-2.84381765102733,lng:122.080277766667},
{lat:-2.84388887777778,lng:122.08009888853},
{lat:-2.84416665555556,lng:122.080183690564},
{lat:-2.84433097567718,lng:122.080277766667},
{lat:-2.84444443333333,lng:122.080359728172},
{lat:-2.84472221111111,lng:122.080495779258},
{lat:-2.84499998888889,lng:122.080491300228},
{lat:-2.84527776666667,lng:122.080524962558},
{lat:-2.84540287958962,lng:122.080555544444},
{lat:-2.84555292164705,lng:122.080833322222},
{lat:-2.84555554444445,lng:122.080835985404},
{lat:-2.84583332222222,lng:122.08088546027},
{lat:-2.8458875099125,lng:122.0811111},
{lat:-2.84597456677543,lng:122.081388877778},
{lat:-2.84595173564824,lng:122.081666655556},
{lat:-2.84583332222222,lng:122.081769356775},
{lat:-2.84564794930258,lng:122.081944433333},
{lat:-2.84555554444445,lng:122.082031180348},
{lat:-2.84539252492728,lng:122.082222211111},
{lat:-2.84527776666667,lng:122.082297914354},
{lat:-2.84507484900123,lng:122.082499988889},
{lat:-2.84499998888889,lng:122.082677794835},
{lat:-2.84494145903177,lng:122.082777766667},
{lat:-2.84472221111111,lng:122.083045976898},
{lat:-2.84469542133129,lng:122.083055544444},
{lat:-2.84444443333333,lng:122.083328427432},
{lat:-2.8444426086247,lng:122.083333322222},
{lat:-2.84444443333333,lng:122.083344153826},
{lat:-2.84456887926255,lng:122.0836111},
{lat:-2.84472221111111,lng:122.083654486338},
{lat:-2.84492922997382,lng:122.0836111},
{lat:-2.84499998888889,lng:122.083593038765},
{lat:-2.84527776666667,lng:122.083432315358},
{lat:-2.84555554444445,lng:122.083404102906},
{lat:-2.84583332222222,lng:122.08347789861},
{lat:-2.8461111,lng:122.083531838939},
{lat:-2.84632133999684,lng:122.0836111},
{lat:-2.84617283989988,lng:122.083888877778},
{lat:-2.8461111,lng:122.083958987041},
{lat:-2.84595397884487,lng:122.084166655556},
{lat:-2.84585084111239,lng:122.084444433333},
{lat:-2.84585372025061,lng:122.084722211111},
{lat:-2.8461111,lng:122.084842592392},
{lat:-2.84638887777778,lng:122.08484101211},
{lat:-2.84666665555556,lng:122.084829831445},
{lat:-2.84694443333333,lng:122.084956039466},
{lat:-2.84722221111111,lng:122.084988766254},
{lat:-2.84749998888889,lng:122.0849603139},
{lat:-2.84760238778371,lng:122.084999988889},
{lat:-2.84777776666667,lng:122.085055177972},
{lat:-2.84805554444445,lng:122.085116306726},
{lat:-2.84833332222222,lng:122.085241847369},
{lat:-2.84839088946176,lng:122.085277766667},
{lat:-2.84833332222222,lng:122.085384593021},
{lat:-2.8482561847043,lng:122.085555544444},
{lat:-2.84805554444445,lng:122.085745391466},
{lat:-2.84797706156769,lng:122.085833322222},
{lat:-2.84777776666667,lng:122.086024896347},
{lat:-2.84749998888889,lng:122.086061795335},
{lat:-2.84722221111111,lng:122.086083984126},
{lat:-2.84710454815374,lng:122.0861111},
{lat:-2.84694443333333,lng:122.086149808191},
{lat:-2.84666665555556,lng:122.086251728093},
{lat:-2.84643180828065,lng:122.086388877778},
{lat:-2.84638887777778,lng:122.086467626912},
{lat:-2.84627318586043,lng:122.086666655556},
{lat:-2.84638523421565,lng:122.086944433333},
{lat:-2.84638887777778,lng:122.086949382528},
{lat:-2.84665984897778,lng:122.087222211111},
{lat:-2.84666665555556,lng:122.087228932711},
{lat:-2.84694443333333,lng:122.087351950279},
{lat:-2.84716233249423,lng:122.087499988889},
{lat:-2.84707649301382,lng:122.087777766667},
{lat:-2.84694443333333,lng:122.087856653732},
{lat:-2.84666665555556,lng:122.087810836319},
{lat:-2.84638887777778,lng:122.087906369314},
{lat:-2.84627590527603,lng:122.088055544444},
{lat:-2.84620223378672,lng:122.088333322222},
{lat:-2.84617695073328,lng:122.0886111},
{lat:-2.8461111,lng:122.088760079299},
{lat:-2.84583332222222,lng:122.088677667502},
{lat:-2.84576732292914,lng:122.0886111},
{lat:-2.84558777297453,lng:122.088333322222},
{lat:-2.84555554444445,lng:122.088277237601},
{lat:-2.84538924244348,lng:122.088333322222},
{lat:-2.84528291825948,lng:122.0886111},
{lat:-2.84527776666667,lng:122.088624575245},
{lat:-2.84515725791393,lng:122.088888877778},
{lat:-2.84499998888889,lng:122.089010204051},
{lat:-2.84472221111111,lng:122.088980491979},
{lat:-2.84444443333333,lng:122.089076783705},
{lat:-2.84440547886924,lng:122.089166655556},
{lat:-2.84438154284643,lng:122.089444433333},
{lat:-2.84438154284643,lng:122.089583322222},]},
{ID:93,coord:[{lat:-2.84837573607595,lng:122.089583322222},
{lat:-2.84837573607595,lng:122.089444433333},
{lat:-2.84833332222222,lng:122.089436408588},
{lat:-2.84832713599661,lng:122.089444433333},
{lat:-2.84832713599661,lng:122.089583322222},]},
{ID:94,coord:[{lat:-2.84834163915955,lng:122.077777766667},
{lat:-2.84833332222222,lng:122.077783505707},
{lat:-2.84832396247126,lng:122.077777766667},
{lat:-2.84833332222222,lng:122.077753800469},
{lat:-2.84834163915955,lng:122.077777766667},]},
{ID:95,coord:[{lat:-2.84840624481699,lng:122.075555544444},
{lat:-2.84833332222222,lng:122.075719080352},
{lat:-2.84830449701408,lng:122.075555544444},
{lat:-2.84833332222222,lng:122.075520488387},
{lat:-2.84840624481699,lng:122.075555544444},]},
{ID:96,coord:[{lat:-2.84262135699626,lng:122.063472211111},
{lat:-2.84262135699626,lng:122.0636111},
{lat:-2.84277776666667,lng:122.063858147206},
{lat:-2.84283591595405,lng:122.063888877778},
{lat:-2.84305554444445,lng:122.063957599396},
{lat:-2.84333332222222,lng:122.063957100911},
{lat:-2.8436111,lng:122.063889515452},
{lat:-2.84388887777778,lng:122.06392400021},
{lat:-2.84416665555556,lng:122.064054013804},
{lat:-2.84429498080952,lng:122.064166655556},
{lat:-2.8443154958943,lng:122.064444433333},
{lat:-2.84428124679902,lng:122.064722211111},
{lat:-2.84420900022532,lng:122.064999988889},
{lat:-2.84416665555556,lng:122.065127209461},
{lat:-2.84409356533252,lng:122.065277766667},
{lat:-2.84414161474678,lng:122.065555544444},
{lat:-2.84407707193399,lng:122.065833322222},
{lat:-2.8439098600139,lng:122.0661111},
{lat:-2.84395474614769,lng:122.066388877778},
{lat:-2.84388887777778,lng:122.066576713482},
{lat:-2.84366732254731,lng:122.066388877778},
{lat:-2.8436111,lng:122.066294797555},
{lat:-2.84357608103151,lng:122.066388877778},
{lat:-2.84357918328693,lng:122.066666655556},
{lat:-2.84333332222222,lng:122.066895902971},
{lat:-2.84313756181283,lng:122.066944433333},
{lat:-2.84305554444445,lng:122.066964721265},
{lat:-2.84290228352708,lng:122.066944433333},
{lat:-2.84277776666667,lng:122.066930308805},
{lat:-2.84249998888889,lng:122.066827095867},
{lat:-2.84222221111111,lng:122.066679209911},
{lat:-2.84220477758355,lng:122.066666655556},
{lat:-2.84198954641383,lng:122.066388877778},
{lat:-2.84194443333333,lng:122.066334240884},
{lat:-2.84166665555556,lng:122.066193709825},
{lat:-2.84155974054863,lng:122.066388877778},
{lat:-2.84154180823864,lng:122.066666655556},
{lat:-2.84158087588069,lng:122.066944433333},
{lat:-2.84166665555556,lng:122.067129010254},
{lat:-2.84178941003581,lng:122.067222211111},
{lat:-2.84194443333333,lng:122.067293298952},
{lat:-2.84208555509472,lng:122.067499988889},
{lat:-2.84207823676542,lng:122.067777766667},
{lat:-2.84194443333333,lng:122.06784861292},
{lat:-2.84176232197005,lng:122.067777766667},
{lat:-2.84166665555556,lng:122.067714191131},
{lat:-2.84151973207651,lng:122.067777766667},
{lat:-2.84138887777778,lng:122.06782858351},
{lat:-2.8411111,lng:122.067904283593},
{lat:-2.84094975952152,lng:122.068055544444},
{lat:-2.84083332222222,lng:122.068274399551},
{lat:-2.84079973347268,lng:122.068333322222},
{lat:-2.8406367319831,lng:122.0686111},
{lat:-2.84062598166066,lng:122.068888877778},
{lat:-2.84077174808179,lng:122.069166655556},
{lat:-2.84083332222222,lng:122.069250506833},
{lat:-2.8411111,lng:122.069366205297},
{lat:-2.84126145936866,lng:122.069444433333},
{lat:-2.84138887777778,lng:122.06961923334},
{lat:-2.84151774091538,lng:122.069722211111},
{lat:-2.84138887777778,lng:122.069763621605},
{lat:-2.8411111,lng:122.069956635371},
{lat:-2.84108168092174,lng:122.069999988889},
{lat:-2.84084560233063,lng:122.070277766667},
{lat:-2.84083332222222,lng:122.070290667873},
{lat:-2.84057516370274,lng:122.070555544444},
{lat:-2.84055554444445,lng:122.07061591319},
{lat:-2.84049793806928,lng:122.070833322222},
{lat:-2.8405186043684,lng:122.0711111},
{lat:-2.84055554444445,lng:122.071215728646},
{lat:-2.84072101000952,lng:122.071388877778},
{lat:-2.84076581435714,lng:122.071666655556},
{lat:-2.84060060895336,lng:122.071944433333},
{lat:-2.84055554444445,lng:122.07198941185},
{lat:-2.84028956230077,lng:122.072222211111},
{lat:-2.84041961847724,lng:122.072499988889},
{lat:-2.84046762868854,lng:122.072777766667},
{lat:-2.8405095288755,lng:122.073055544444},
{lat:-2.84055554444445,lng:122.07326709539},
{lat:-2.84083332222222,lng:122.073258255372},
{lat:-2.84106572639415,lng:122.073333322222},
{lat:-2.84105367699665,lng:122.0736111},
{lat:-2.84100589757295,lng:122.073888877778},
{lat:-2.84096653471233,lng:122.074166655556},
{lat:-2.84083332222222,lng:122.074422268665},
{lat:-2.84082511341829,lng:122.074444433333},
{lat:-2.84079593417734,lng:122.074722211111},
{lat:-2.84055554444445,lng:122.074960737758},
{lat:-2.84027776666667,lng:122.074859444585},
{lat:-2.83999998888889,lng:122.07496332763},
{lat:-2.83998414930276,lng:122.074999988889},
{lat:-2.83989723374717,lng:122.075277766667},
{lat:-2.83981541949396,lng:122.075555544444},
{lat:-2.83972221111111,lng:122.075717778341},
{lat:-2.83964889250513,lng:122.075833322222},
{lat:-2.83948785699924,lng:122.0761111},
{lat:-2.83944443333333,lng:122.076203704095},
{lat:-2.83935853178265,lng:122.076388877778},
{lat:-2.83926946463064,lng:122.076666655556},
{lat:-2.83916665555556,lng:122.076923812578},
{lat:-2.83915429976956,lng:122.076944433333},
{lat:-2.83892406582961,lng:122.077222211111},
{lat:-2.83888887777778,lng:122.077263189711},
{lat:-2.83866950609934,lng:122.077499988889},
{lat:-2.8386111,lng:122.077747231418},
{lat:-2.83860176218634,lng:122.077777766667},
{lat:-2.83833332222222,lng:122.078039688297},
{lat:-2.8383233102426,lng:122.078055544444},
{lat:-2.83812962223232,lng:122.078333322222},
{lat:-2.83805554444445,lng:122.078486901021},
{lat:-2.8380082276227,lng:122.0786111},
{lat:-2.83785662976746,lng:122.078888877778},
{lat:-2.83777776666667,lng:122.079026050977},
{lat:-2.83758171196357,lng:122.079166655556},
{lat:-2.83749998888889,lng:122.079214739919},
{lat:-2.83722221111111,lng:122.07938257282},
{lat:-2.83713549867948,lng:122.079444433333},
{lat:-2.83694443333333,lng:122.079647388479},
{lat:-2.83686465419898,lng:122.079722211111},
{lat:-2.83666665555556,lng:122.079841338102},
{lat:-2.83638887777778,lng:122.079989572884},
{lat:-2.83638107692051,lng:122.079999988889},
{lat:-2.83619708198805,lng:122.080277766667},
{lat:-2.8361111,lng:122.080406842944},
{lat:-2.83601729686123,lng:122.080555544444},
{lat:-2.83589603050656,lng:122.080833322222},
{lat:-2.83583332222222,lng:122.080936148899},
{lat:-2.83572252503954,lng:122.0811111},
{lat:-2.83555554444445,lng:122.081320623206},
{lat:-2.83549625785712,lng:122.081388877778},
{lat:-2.83534587054515,lng:122.081666655556},
{lat:-2.83527776666667,lng:122.081865226035},
{lat:-2.8352610997015,lng:122.081944433333},
{lat:-2.8352482993385,lng:122.082222211111},
{lat:-2.83527776666667,lng:122.082264455687},
{lat:-2.83550401766638,lng:122.082222211111},
{lat:-2.83555554444445,lng:122.082207857636},
{lat:-2.83558569566441,lng:122.082222211111},
{lat:-2.83583332222222,lng:122.082377143005},
{lat:-2.8361111,lng:122.082399651974},
{lat:-2.83626652855455,lng:122.082499988889},
{lat:-2.83638887777778,lng:122.082562067688},
{lat:-2.83666665555556,lng:122.082719864585},
{lat:-2.83694443333333,lng:122.082769293026},
{lat:-2.83697922858118,lng:122.082777766667},
{lat:-2.83722221111111,lng:122.082844997796},
{lat:-2.83749998888889,lng:122.082895142296},
{lat:-2.83766010758285,lng:122.083055544444},
{lat:-2.83777776666667,lng:122.083170349196},
{lat:-2.83784009472924,lng:122.083333322222},
{lat:-2.83794320430113,lng:122.0836111},
{lat:-2.8379883071258,lng:122.083888877778},
{lat:-2.83791300825023,lng:122.084166655556},
{lat:-2.83794689811095,lng:122.084444433333},
{lat:-2.83802610921786,lng:122.084722211111},
{lat:-2.83805554444445,lng:122.084744868313},
{lat:-2.83833332222222,lng:122.084753762325},
{lat:-2.83845464222871,lng:122.084722211111},
{lat:-2.8386111,lng:122.084612151544},
{lat:-2.83888887777778,lng:122.084707762056},
{lat:-2.83916665555556,lng:122.084713723262},
{lat:-2.83927183423827,lng:122.084444433333},
{lat:-2.83930470307914,lng:122.084166655556},
{lat:-2.83936132395445,lng:122.083888877778},
{lat:-2.83944443333333,lng:122.083733269924},
{lat:-2.8396859062778,lng:122.0836111},
{lat:-2.83972221111111,lng:122.083583143269},
{lat:-2.83976329991531,lng:122.0836111},
{lat:-2.83999998888889,lng:122.08382757692},
{lat:-2.84006124404338,lng:122.0836111},
{lat:-2.84006621165672,lng:122.083333322222},
{lat:-2.83999998888889,lng:122.083259566366},
{lat:-2.83988032129155,lng:122.083055544444},
{lat:-2.83998196360845,lng:122.082777766667},
{lat:-2.83999998888889,lng:122.082763264079},
{lat:-2.84027776666667,lng:122.082742741867},
{lat:-2.84045479315378,lng:122.082499988889},
{lat:-2.84055554444445,lng:122.082285680656},
{lat:-2.84058082941474,lng:122.082222211111},
{lat:-2.84067607353911,lng:122.081944433333},
{lat:-2.84083332222222,lng:122.081679876901},
{lat:-2.84096327952878,lng:122.081944433333},
{lat:-2.8411111,lng:122.082111964608},
{lat:-2.84138887777778,lng:122.082074395466},
{lat:-2.84166665555556,lng:122.082079979864},
{lat:-2.84182268082364,lng:122.081944433333},
{lat:-2.84194443333333,lng:122.08178518764},
{lat:-2.84200937085063,lng:122.081666655556},
{lat:-2.84208009420755,lng:122.081388877778},
{lat:-2.84222221111111,lng:122.081305302531},
{lat:-2.84247016221426,lng:122.0811111},
{lat:-2.84249998888889,lng:122.081087030351},
{lat:-2.84277776666667,lng:122.080997020252},
{lat:-2.84305554444445,lng:122.080886221721},
{lat:-2.84312243702002,lng:122.080833322222},
{lat:-2.84332952731186,lng:122.080555544444},
{lat:-2.84333332222222,lng:122.080549993335},
{lat:-2.84356146799009,lng:122.080277766667},
{lat:-2.8436111,lng:122.080165217701},
{lat:-2.84365459125261,lng:122.079999988889},
{lat:-2.84369161917433,lng:122.079722211111},
{lat:-2.84388887777778,lng:122.079520594517},
{lat:-2.84400868283187,lng:122.079722211111},
{lat:-2.84416665555556,lng:122.079915040664},
{lat:-2.84442746650336,lng:122.079999988889},
{lat:-2.84444443333333,lng:122.080006792787},
{lat:-2.84472221111111,lng:122.080246795938},
{lat:-2.84492446544804,lng:122.080277766667},
{lat:-2.84499998888889,lng:122.080285003545},
{lat:-2.84515686496021,lng:122.080277766667},
{lat:-2.84527776666667,lng:122.08026977264},
{lat:-2.84555554444445,lng:122.080219544963},
{lat:-2.84583332222222,lng:122.080074787826},
{lat:-2.8461111,lng:122.080018390729},
{lat:-2.84626791813757,lng:122.080277766667},
{lat:-2.84623480134802,lng:122.080555544444},
{lat:-2.84621874487672,lng:122.080833322222},
{lat:-2.84625169966315,lng:122.0811111},
{lat:-2.84629726964358,lng:122.081388877778},
{lat:-2.84627451723718,lng:122.081666655556},
{lat:-2.8461111,lng:122.081879346554},
{lat:-2.84603596789019,lng:122.081944433333},
{lat:-2.84583332222222,lng:122.0820558344},
{lat:-2.84566012353961,lng:122.082222211111},
{lat:-2.84555554444445,lng:122.082324835663},
{lat:-2.84527776666667,lng:122.082493366838},
{lat:-2.84527111698657,lng:122.082499988889},
{lat:-2.84518051085185,lng:122.082777766667},
{lat:-2.84519901099509,lng:122.083055544444},
{lat:-2.84527776666667,lng:122.083113319948},
{lat:-2.84555554444445,lng:122.083210751856},
{lat:-2.84582760823673,lng:122.083333322222},
{lat:-2.84583332222222,lng:122.083335038652},
{lat:-2.84584596293321,lng:122.083333322222},
{lat:-2.8461111,lng:122.083275917262},
{lat:-2.84638887777778,lng:122.083226407333},
{lat:-2.84666665555556,lng:122.083256269183},
{lat:-2.84681169000739,lng:122.083333322222},
{lat:-2.8467077784494,lng:122.0836111},
{lat:-2.84666665555556,lng:122.083654414833},
{lat:-2.84648843140232,lng:122.083888877778},
{lat:-2.84638887777778,lng:122.084042006154},
{lat:-2.84628332209805,lng:122.084166655556},
{lat:-2.84622553398349,lng:122.084444433333},
{lat:-2.84638887777778,lng:122.084568784765},
{lat:-2.84666665555556,lng:122.084565081268},
{lat:-2.84694443333333,lng:122.084683923944},
{lat:-2.84715802277172,lng:122.084722211111},
{lat:-2.84722221111111,lng:122.084730028975},
{lat:-2.84726307765858,lng:122.084722211111},
{lat:-2.84749998888889,lng:122.084639318539},
{lat:-2.84771522288715,lng:122.084722211111},
{lat:-2.84777776666667,lng:122.084739548834},
{lat:-2.84805554444445,lng:122.084799292432},
{lat:-2.84833332222222,lng:122.084914405421},
{lat:-2.84850284123893,lng:122.084999988889},
{lat:-2.8486111,lng:122.085062491766},
{lat:-2.84888887777778,lng:122.085139564898},
{lat:-2.84892283001578,lng:122.085277766667},
{lat:-2.84888887777778,lng:122.085349250563},
{lat:-2.84872669574543,lng:122.085555544444},
{lat:-2.8486111,lng:122.085656852569},
{lat:-2.84833332222222,lng:122.085792834023},
{lat:-2.84829127698347,lng:122.085833322222},
{lat:-2.84811411820858,lng:122.0861111},
{lat:-2.84805554444445,lng:122.086192590921},
{lat:-2.84777776666667,lng:122.086364139759},
{lat:-2.84765066387269,lng:122.086388877778},
{lat:-2.84749998888889,lng:122.08642751742},
{lat:-2.84741985576805,lng:122.086388877778},
{lat:-2.84722221111111,lng:122.086334512922},
{lat:-2.84702794554338,lng:122.086388877778},
{lat:-2.84694443333333,lng:122.086433777029},
{lat:-2.84666665555556,lng:122.086652028714},
{lat:-2.84665817257767,lng:122.086666655556},
{lat:-2.84666665555556,lng:122.086704693384},
{lat:-2.84676854415306,lng:122.086944433333},
{lat:-2.84694443333333,lng:122.087016429238},
{lat:-2.84722221111111,lng:122.087123302012},
{lat:-2.84749998888889,lng:122.087163172677},
{lat:-2.84757418297802,lng:122.087222211111},
{lat:-2.84756850109027,lng:122.087499988889},
{lat:-2.84749998888889,lng:122.087610590998},
{lat:-2.84740694836545,lng:122.087777766667},
{lat:-2.84726963283037,lng:122.088055544444},
{lat:-2.84722221111111,lng:122.088144679101},
{lat:-2.84694443333333,lng:122.088128147276},
{lat:-2.84670683786423,lng:122.088055544444},
{lat:-2.84666665555556,lng:122.088045296129},
{lat:-2.84664731599813,lng:122.088055544444},
{lat:-2.84648328164597,lng:122.088333322222},
{lat:-2.84646802952056,lng:122.0886111},
{lat:-2.84648965387583,lng:122.088888877778},
{lat:-2.8463970991512,lng:122.089166655556},
{lat:-2.84638887777778,lng:122.089181178357},
{lat:-2.8461111,lng:122.089236782297},
{lat:-2.84599908110727,lng:122.089166655556},
{lat:-2.84583332222222,lng:122.089060587085},
{lat:-2.84555554444445,lng:122.088894167329},
{lat:-2.8453467899612,lng:122.089166655556},
{lat:-2.84527776666667,lng:122.08927606715},
{lat:-2.84504447754566,lng:122.089444433333},
{lat:-2.84504447754566,lng:122.089583322222},]},
{ID:97,coord:[{lat:-2.84888887777778,lng:122.087957987169},
{lat:-2.84906287580477,lng:122.087777766667},
{lat:-2.849000452913,lng:122.087499988889},
{lat:-2.84888887777778,lng:122.087299845706},
{lat:-2.84864238238029,lng:122.087499988889},
{lat:-2.8486111,lng:122.087595105893},
{lat:-2.84855048241825,lng:122.087777766667},
{lat:-2.8486111,lng:122.087856187743},
{lat:-2.84888887777778,lng:122.087957987169},]},
{ID:98,coord:[{lat:-2.84372939185608,lng:122.063472211111},
{lat:-2.84372939185608,lng:122.0636111},
{lat:-2.84388887777778,lng:122.06364474482},
{lat:-2.84416665555556,lng:122.063791915134},
{lat:-2.84444443333333,lng:122.063867129067},
{lat:-2.84447964974419,lng:122.063888877778},
{lat:-2.84461921198008,lng:122.064166655556},
{lat:-2.84448550978462,lng:122.064444433333},
{lat:-2.84444443333333,lng:122.064558135365},
{lat:-2.84441382943497,lng:122.064722211111},
{lat:-2.84432754359473,lng:122.064999988889},
{lat:-2.84423600239114,lng:122.065277766667},
{lat:-2.84426375038675,lng:122.065555544444},
{lat:-2.84427375598634,lng:122.065833322222},
{lat:-2.84422332229123,lng:122.0661111},
{lat:-2.84423557818987,lng:122.066388877778},
{lat:-2.84424480539768,lng:122.066666655556},
{lat:-2.84422939802705,lng:122.066944433333},
{lat:-2.84416665555556,lng:122.067187217642},
{lat:-2.84413411834363,lng:122.067222211111},
{lat:-2.8440328488484,lng:122.067499988889},
{lat:-2.84388887777778,lng:122.067658871919},
{lat:-2.84379215098507,lng:122.067499988889},
{lat:-2.8436111,lng:122.06724529388},
{lat:-2.84333332222222,lng:122.067453209287},
{lat:-2.84322573259077,lng:122.067499988889},
{lat:-2.84305554444445,lng:122.067646417119},
{lat:-2.8429687816088,lng:122.067777766667},
{lat:-2.84277776666667,lng:122.067992992736},
{lat:-2.8426861214987,lng:122.068055544444},
{lat:-2.84249998888889,lng:122.068117613797},
{lat:-2.84222221111111,lng:122.06817423329},
{lat:-2.84194443333333,lng:122.068211884791},
{lat:-2.84166665555556,lng:122.068184563171},
{lat:-2.84148044887898,lng:122.068333322222},
{lat:-2.84138887777778,lng:122.06854890629},
{lat:-2.84136526637062,lng:122.0686111},
{lat:-2.8413551836759,lng:122.068888877778},
{lat:-2.84138887777778,lng:122.06898800448},
{lat:-2.84166665555556,lng:122.069162753186},
{lat:-2.84166852876749,lng:122.069166655556},
{lat:-2.84187733735499,lng:122.069444433333},
{lat:-2.84192521123802,lng:122.069722211111},
{lat:-2.84194443333333,lng:122.069784770607},
{lat:-2.84202646469236,lng:122.069999988889},
{lat:-2.84194443333333,lng:122.070098437184},
{lat:-2.84166665555556,lng:122.070225022787},
{lat:-2.84152408654771,lng:122.070277766667},
{lat:-2.84138887777778,lng:122.070391809208},
{lat:-2.84118463521016,lng:122.070555544444},
{lat:-2.8411111,lng:122.070608024367},
{lat:-2.84098454063178,lng:122.070833322222},
{lat:-2.84106143597204,lng:122.0711111},
{lat:-2.8411111,lng:122.071166306651},
{lat:-2.84138887777778,lng:122.071367545362},
{lat:-2.84143777858129,lng:122.071388877778},
{lat:-2.84166665555556,lng:122.071489398248},
{lat:-2.84179838576117,lng:122.071666655556},
{lat:-2.84186003502383,lng:122.071944433333},
{lat:-2.84167545638599,lng:122.072222211111},
{lat:-2.84180187045476,lng:122.072499988889},
{lat:-2.84190944286232,lng:122.072777766667},
{lat:-2.84194443333333,lng:122.073036463901},
{lat:-2.84194934519462,lng:122.073055544444},
{lat:-2.84205877593316,lng:122.073333322222},
{lat:-2.84199187379627,lng:122.0736111},
{lat:-2.84194443333333,lng:122.073713555846},
{lat:-2.84180567917857,lng:122.073888877778},
{lat:-2.84166665555556,lng:122.074061090962},
{lat:-2.84159549128364,lng:122.074166655556},
{lat:-2.84150873376355,lng:122.074444433333},
{lat:-2.84138887777778,lng:122.074616075786},
{lat:-2.84130157188774,lng:122.074722211111},
{lat:-2.8411592791276,lng:122.074999988889},
{lat:-2.8411111,lng:122.075015182405},
{lat:-2.84083332222222,lng:122.07510702933},
{lat:-2.84055554444445,lng:122.075216017167},
{lat:-2.84029338978164,lng:122.075277766667},
{lat:-2.84027776666667,lng:122.075292754107},
{lat:-2.84019372189682,lng:122.075555544444},
{lat:-2.84011359752126,lng:122.075833322222},
{lat:-2.83999998888889,lng:122.07606529259},
{lat:-2.83995426355583,lng:122.0761111},
{lat:-2.83974024299306,lng:122.076388877778},
{lat:-2.83972221111111,lng:122.076585515256},
{lat:-2.83971000301906,lng:122.076666655556},
{lat:-2.83966941819558,lng:122.076944433333},
{lat:-2.83945592848087,lng:122.077222211111},
{lat:-2.83944443333333,lng:122.077231001809},
{lat:-2.83916689439412,lng:122.077499988889},
{lat:-2.83916665555556,lng:122.077500257776},
{lat:-2.83888942886283,lng:122.077777766667},
{lat:-2.83888887777778,lng:122.07777826071},
{lat:-2.83866973089844,lng:122.078055544444},
{lat:-2.8386111,lng:122.0781239537},
{lat:-2.83844016342629,lng:122.078333322222},
{lat:-2.83833332222222,lng:122.078604137456},
{lat:-2.83832951827552,lng:122.0786111},
{lat:-2.83816716553293,lng:122.078888877778},
{lat:-2.8380658828496,lng:122.079166655556},
{lat:-2.83805554444445,lng:122.07919662737},
{lat:-2.83788528879543,lng:122.079444433333},
{lat:-2.83777776666667,lng:122.079606876435},
{lat:-2.83762454481232,lng:122.079722211111},
{lat:-2.83749998888889,lng:122.079791374952},
{lat:-2.83722221111111,lng:122.079825554322},
{lat:-2.83694443333333,lng:122.079984892143},
{lat:-2.83691570525925,lng:122.079999988889},
{lat:-2.83666665555556,lng:122.08014362575},
{lat:-2.83647552334288,lng:122.080277766667},
{lat:-2.83638887777778,lng:122.08036462511},
{lat:-2.83627798353474,lng:122.080555544444},
{lat:-2.83615686633358,lng:122.080833322222},
{lat:-2.8361111,lng:122.080937904828},
{lat:-2.83602786013575,lng:122.0811111},
{lat:-2.83585582078244,lng:122.081388877778},
{lat:-2.8361111,lng:122.081552000275},
{lat:-2.83638887777778,lng:122.081545915866},
{lat:-2.83666665555556,lng:122.081565516032},
{lat:-2.83671997832418,lng:122.081666655556},
{lat:-2.83694443333333,lng:122.08190701817},
{lat:-2.83697872263939,lng:122.081944433333},
{lat:-2.83694443333333,lng:122.082198877107},
{lat:-2.83692601420349,lng:122.082222211111},
{lat:-2.83694443333333,lng:122.08222929199},
{lat:-2.8371373913084,lng:122.082499988889},
{lat:-2.83722221111111,lng:122.082548697545},
{lat:-2.83749998888889,lng:122.082554979756},
{lat:-2.83772373307178,lng:122.082499988889},
{lat:-2.83777776666667,lng:122.082483294806},
{lat:-2.83782591323423,lng:122.082499988889},
{lat:-2.83803285547203,lng:122.082777766667},
{lat:-2.83804810280709,lng:122.083055544444},
{lat:-2.83805554444445,lng:122.083088346193},
{lat:-2.83807363090817,lng:122.083055544444},
{lat:-2.8381023142112,lng:122.082777766667},
{lat:-2.83833332222222,lng:122.08256165096},
{lat:-2.83846051354648,lng:122.082777766667},
{lat:-2.8385225726968,lng:122.083055544444},
{lat:-2.8386111,lng:122.08322122912},
{lat:-2.83882557180987,lng:122.083055544444},
{lat:-2.83888887777778,lng:122.083001435815},
{lat:-2.83905566662214,lng:122.082777766667},
{lat:-2.83916665555556,lng:122.082594226262},
{lat:-2.83944443333333,lng:122.08255034832},
{lat:-2.83948906992072,lng:122.082499988889},
{lat:-2.83967020836912,lng:122.082222211111},
{lat:-2.83972221111111,lng:122.082167325195},
{lat:-2.83999998888889,lng:122.08201844311},
{lat:-2.84019503687806,lng:122.081944433333},
{lat:-2.84027776666667,lng:122.081908850831},
{lat:-2.84040648455534,lng:122.081666655556},
{lat:-2.84055217246789,lng:122.081388877778},
{lat:-2.84055554444445,lng:122.081381266329},
{lat:-2.84083332222222,lng:122.081228740376},
{lat:-2.84106639697598,lng:122.081388877778},
{lat:-2.8411111,lng:122.081435311087},
{lat:-2.8412166100568,lng:122.081388877778},
{lat:-2.84138887777778,lng:122.081320362509},
{lat:-2.84166665555556,lng:122.081135791621},
{lat:-2.8416917256131,lng:122.0811111},
{lat:-2.84194443333333,lng:122.080895358807},
{lat:-2.84205136819777,lng:122.080833322222},
{lat:-2.84222221111111,lng:122.080752686983},
{lat:-2.84249998888889,lng:122.080652513137},
{lat:-2.84277776666667,lng:122.08063769038},
{lat:-2.84288722987603,lng:122.080555544444},
{lat:-2.84301485495302,lng:122.080277766667},
{lat:-2.84305554444445,lng:122.080202052998},
{lat:-2.8432151194065,lng:122.079999988889},
{lat:-2.84333332222222,lng:122.07974307346},
{lat:-2.84333934523789,lng:122.079722211111},
{lat:-2.84333816475468,lng:122.079444433333},
{lat:-2.84341219959452,lng:122.079166655556},
{lat:-2.8436111,lng:122.078921060348},
{lat:-2.84372438452307,lng:122.078888877778},
{lat:-2.84386170463045,lng:122.0786111},
{lat:-2.84388887777778,lng:122.078592466962},
{lat:-2.84390107420512,lng:122.0786111},
{lat:-2.84393203408109,lng:122.078888877778},
{lat:-2.84402678948778,lng:122.079166655556},
{lat:-2.84407565434076,lng:122.079444433333},
{lat:-2.84416665555556,lng:122.079592995277},
{lat:-2.84444443333333,lng:122.079656091698},
{lat:-2.84460053228371,lng:122.079722211111},
{lat:-2.84472221111111,lng:122.079821710917},
{lat:-2.84491098640614,lng:122.079999988889},
{lat:-2.84499998888889,lng:122.080030371714},
{lat:-2.84515970206244,lng:122.079999988889},
{lat:-2.84527776666667,lng:122.079973339565},
{lat:-2.84555554444445,lng:122.07985973429},
{lat:-2.84579028563302,lng:122.079722211111},
{lat:-2.84583332222222,lng:122.079687392927},
{lat:-2.84588224586471,lng:122.079722211111},
{lat:-2.8461111,lng:122.079781442111},
{lat:-2.84635179281429,lng:122.079722211111},
{lat:-2.84638887777778,lng:122.079709253564},
{lat:-2.8464111991158,lng:122.079722211111},
{lat:-2.84657885025078,lng:122.079999988889},
{lat:-2.84662999536538,lng:122.080277766667},
{lat:-2.84656856073966,lng:122.080555544444},
{lat:-2.84655167966487,lng:122.080833322222},
{lat:-2.84662895938718,lng:122.0811111},
{lat:-2.84666665555556,lng:122.081228547737},
{lat:-2.84669743326184,lng:122.081388877778},
{lat:-2.84666665555556,lng:122.081458256327},
{lat:-2.84656409541579,lng:122.081666655556},
{lat:-2.84639680226565,lng:122.081944433333},
{lat:-2.84638887777778,lng:122.0819571701},
{lat:-2.8461111,lng:122.082157388615},
{lat:-2.84597413670729,lng:122.082222211111},
{lat:-2.84583332222222,lng:122.082349674991},
{lat:-2.84567659302225,lng:122.082499988889},
{lat:-2.84555554444445,lng:122.082664674457},
{lat:-2.84549535758993,lng:122.082777766667},
{lat:-2.84555554444445,lng:122.082879610726},
{lat:-2.84571973312753,lng:122.083055544444},
{lat:-2.84583332222222,lng:122.08310048046},
{lat:-2.84607058148452,lng:122.083055544444},
{lat:-2.8461111,lng:122.083047873091},
{lat:-2.84638887777778,lng:122.08297603829},
{lat:-2.84666665555556,lng:122.082904228236},
{lat:-2.84694443333333,lng:122.082795976552},
{lat:-2.8471962140047,lng:122.082777766667},
{lat:-2.84722221111111,lng:122.082776399987},
{lat:-2.84722459343848,lng:122.082777766667},
{lat:-2.84736014258326,lng:122.083055544444},
{lat:-2.84732486527439,lng:122.083333322222},
{lat:-2.84722221111111,lng:122.083582842772},
{lat:-2.84719198356153,lng:122.0836111},
{lat:-2.84694443333333,lng:122.083871202056},
{lat:-2.84692815358251,lng:122.083888877778},
{lat:-2.84690489476584,lng:122.084166655556},
{lat:-2.84694443333333,lng:122.084201118153},
{lat:-2.84722221111111,lng:122.084241420033},
{lat:-2.84749998888889,lng:122.08420086311},
{lat:-2.84777776666667,lng:122.084284684319},
{lat:-2.84805554444445,lng:122.084341536399},
{lat:-2.84833332222222,lng:122.084420047168},
{lat:-2.84835185563164,lng:122.084444433333},
{lat:-2.84849601042018,lng:122.084722211111},
{lat:-2.8486111,lng:122.084793497455},
{lat:-2.84888887777778,lng:122.084772153678},
{lat:-2.84913008571612,lng:122.084722211111},
{lat:-2.84916665555556,lng:122.084709810053},
{lat:-2.84927047058342,lng:122.084722211111},
{lat:-2.84923630146121,lng:122.084999988889},
{lat:-2.84916665555556,lng:122.085198153015},
{lat:-2.84914722884087,lng:122.085277766667},
{lat:-2.84906939998678,lng:122.085555544444},
{lat:-2.84888887777778,lng:122.085735103917},
{lat:-2.84877713633943,lng:122.085833322222},
{lat:-2.8486111,lng:122.085966338246},
{lat:-2.84840654486536,lng:122.0861111},
{lat:-2.84833332222222,lng:122.086197324802},
{lat:-2.84820642599076,lng:122.086388877778},
{lat:-2.84805554444445,lng:122.086612267909},
{lat:-2.84793631330923,lng:122.086666655556},
{lat:-2.84803033322152,lng:122.086944433333},
{lat:-2.8480237626867,lng:122.087222211111},
{lat:-2.84790895662061,lng:122.087499988889},
{lat:-2.84777776666667,lng:122.087626773572},
{lat:-2.84767757847139,lng:122.087777766667},
{lat:-2.84755594611683,lng:122.088055544444},
{lat:-2.84756247942063,lng:122.088333322222},
{lat:-2.8475186423934,lng:122.0886111},
{lat:-2.84749998888889,lng:122.088640985554},
{lat:-2.84722924548038,lng:122.088888877778},
{lat:-2.84722221111111,lng:122.088899812342},
{lat:-2.84718770667814,lng:122.088888877778},
{lat:-2.84694443333333,lng:122.088724003274},
{lat:-2.84690788131485,lng:122.088888877778},
{lat:-2.84685833899428,lng:122.089166655556},
{lat:-2.84670644803889,lng:122.089444433333},
{lat:-2.84670644803889,lng:122.089583322222},]},
{ID:99,coord:[{lat:-2.84916665555556,lng:122.079491418927},
{lat:-2.84918576932696,lng:122.079444433333},
{lat:-2.84916665555556,lng:122.079403296146},
{lat:-2.84888887777778,lng:122.079226815902},
{lat:-2.84871824061919,lng:122.079444433333},
{lat:-2.84888887777778,lng:122.079721113127},
{lat:-2.84916665555556,lng:122.079491418927},]},
{ID:100,coord:[{lat:-2.84920480221311,lng:122.076388877778},
{lat:-2.84916665555556,lng:122.07651073126},
{lat:-2.84907429183083,lng:122.076388877778},
{lat:-2.84916665555556,lng:122.076291314866},
{lat:-2.84920480221311,lng:122.076388877778},]},
{ID:101,coord:[{lat:-2.84944443333333,lng:122.082982191758},
{lat:-2.84957514383721,lng:122.082777766667},
{lat:-2.8495981926926,lng:122.082499988889},
{lat:-2.84944443333333,lng:122.082463576867},
{lat:-2.84933134100778,lng:122.082499988889},
{lat:-2.84916665555556,lng:122.082537257156},
{lat:-2.84888887777778,lng:122.082596065702},
{lat:-2.84872661456043,lng:122.082777766667},
{lat:-2.8486111,lng:122.083016407858},
{lat:-2.84858981592947,lng:122.083055544444},
{lat:-2.8486111,lng:122.083201974287},
{lat:-2.8486330125498,lng:122.083333322222},
{lat:-2.84888887777778,lng:122.083485943135},
{lat:-2.84916665555556,lng:122.083431781124},
{lat:-2.84939558802247,lng:122.083333322222},
{lat:-2.84941823696601,lng:122.083055544444},
{lat:-2.84944443333333,lng:122.082982191758},]},
{ID:102,coord:[{lat:-2.84972221111111,lng:122.087105050849},
{lat:-2.84980674296087,lng:122.086944433333},
{lat:-2.8498108845013,lng:122.086666655556},
{lat:-2.84972221111111,lng:122.086557560107},
{lat:-2.84953713606482,lng:122.086388877778},
{lat:-2.84944443333333,lng:122.086255943734},
{lat:-2.84916665555556,lng:122.086229667711},
{lat:-2.84888887777778,lng:122.086381478707},
{lat:-2.84888128879698,lng:122.086388877778},
{lat:-2.84885193219396,lng:122.086666655556},
{lat:-2.84888887777778,lng:122.086755668053},
{lat:-2.84902649525428,lng:122.086944433333},
{lat:-2.84916665555556,lng:122.087034345718},
{lat:-2.84930446881517,lng:122.086944433333},
{lat:-2.84944443333333,lng:122.086764664815},
{lat:-2.84963937159277,lng:122.086944433333},
{lat:-2.84972221111111,lng:122.087105050849},]},
{ID:103,coord:[{lat:-2.84999998888889,lng:122.085872406747},
{lat:-2.85002777520396,lng:122.085833322222},
{lat:-2.84999998888889,lng:122.085678501073},
{lat:-2.84980494880704,lng:122.085833322222},
{lat:-2.84999998888889,lng:122.085872406747},]},
{ID:104,coord:[{lat:-2.85027776666667,lng:122.088398260094},
{lat:-2.85038772975041,lng:122.088333322222},
{lat:-2.85054955353509,lng:122.088055544444},
{lat:-2.85047779334706,lng:122.087777766667},
{lat:-2.85027776666667,lng:122.087579263009},
{lat:-2.84999998888889,lng:122.087637985354},
{lat:-2.84972221111111,lng:122.08771672422},
{lat:-2.84964033855706,lng:122.087777766667},
{lat:-2.84944443333333,lng:122.087899074479},
{lat:-2.84921981209027,lng:122.088055544444},
{lat:-2.84916665555556,lng:122.088142850533},
{lat:-2.84905345394598,lng:122.088333322222},
{lat:-2.84888887777778,lng:122.08837720866},
{lat:-2.8486111,lng:122.088394714697},
{lat:-2.84854313444279,lng:122.0886111},
{lat:-2.84858343019612,lng:122.088888877778},
{lat:-2.84852368023824,lng:122.089166655556},
{lat:-2.8486111,lng:122.089370984048},
{lat:-2.84888887777778,lng:122.089435336091},
{lat:-2.84916665555556,lng:122.08925019493},
{lat:-2.84939732719881,lng:122.089166655556},
{lat:-2.84944443333333,lng:122.089130103247},
{lat:-2.84972221111111,lng:122.08908956261},
{lat:-2.84989460629476,lng:122.088888877778},
{lat:-2.84993394229602,lng:122.0886111},
{lat:-2.84999998888889,lng:122.088562099185},
{lat:-2.85027776666667,lng:122.088398260094},]},
{ID:105,coord:[{lat:-2.85076881247522,lng:122.081944433333},
{lat:-2.85055554444445,lng:122.082077562846},
{lat:-2.85047099731284,lng:122.081944433333},
{lat:-2.85048936210324,lng:122.081666655556},
{lat:-2.85055554444445,lng:122.081596580394},
{lat:-2.8506968383467,lng:122.081666655556},
{lat:-2.85076881247522,lng:122.081944433333},]},
{ID:106,coord:[{lat:-2.85101380962081,lng:122.084722211111},
{lat:-2.85083332222222,lng:122.084838608853},
{lat:-2.85055554444445,lng:122.084772652459},
{lat:-2.85054123436812,lng:122.084722211111},
{lat:-2.85055554444445,lng:122.084706290817},
{lat:-2.85083332222222,lng:122.084662493361},
{lat:-2.85101380962081,lng:122.084722211111},]},
{ID:107,coord:[{lat:-2.85091855382699,lng:122.069166655556},
{lat:-2.85083332222222,lng:122.069379050726},
{lat:-2.85066321460497,lng:122.069166655556},
{lat:-2.85083332222222,lng:122.06904658542},
{lat:-2.85091855382699,lng:122.069166655556},]},
{ID:108,coord:[{lat:-2.85111300603886,lng:122.0836111},
{lat:-2.8511111,lng:122.083617650416},
{lat:-2.85110722949379,lng:122.0836111},
{lat:-2.8511111,lng:122.083587501276},
{lat:-2.85111300603886,lng:122.0836111},]},
{ID:109,coord:[{lat:-2.8511111,lng:122.067933997272},
{lat:-2.85111928306255,lng:122.067777766667},
{lat:-2.8511111,lng:122.067608833796},
{lat:-2.85103579948826,lng:122.067777766667},
{lat:-2.8511111,lng:122.067933997272},]},
{ID:110,coord:[{lat:-2.8516023141337,lng:122.084999988889},
{lat:-2.85138887777778,lng:122.085208975638},
{lat:-2.8512167045671,lng:122.085277766667},
{lat:-2.8511111,lng:122.085351321757},
{lat:-2.85083332222222,lng:122.085442797191},
{lat:-2.85055554444445,lng:122.085508520239},
{lat:-2.85043995451761,lng:122.085277766667},
{lat:-2.85034654676952,lng:122.084999988889},
{lat:-2.85027776666667,lng:122.084845298142},
{lat:-2.85019839863974,lng:122.084722211111},
{lat:-2.85027776666667,lng:122.084580811376},
{lat:-2.85039116853595,lng:122.084444433333},
{lat:-2.85055554444445,lng:122.08432570252},
{lat:-2.85083332222222,lng:122.084400520753},
{lat:-2.85089455652566,lng:122.084444433333},
{lat:-2.8511111,lng:122.084533574887},
{lat:-2.85138887777778,lng:122.084585253299},
{lat:-2.85158415317893,lng:122.084722211111},
{lat:-2.8516023141337,lng:122.084999988889},]},
{ID:111,coord:[{lat:-2.84932793740548,lng:122.089583322222},
{lat:-2.84932793740548,lng:122.089444433333},
{lat:-2.84944443333333,lng:122.089388076932},
{lat:-2.84972221111111,lng:122.089332575175},
{lat:-2.84999998888889,lng:122.089249728753},
{lat:-2.85027776666667,lng:122.089168509022},
{lat:-2.85028433142599,lng:122.089166655556},
{lat:-2.85052030145299,lng:122.088888877778},
{lat:-2.8505552467308,lng:122.0886111},
{lat:-2.85055554444445,lng:122.088610938738},
{lat:-2.85083332222222,lng:122.088549413369},
{lat:-2.85100112535735,lng:122.0886111},
{lat:-2.8511111,lng:122.088724574337},
{lat:-2.85121186131259,lng:122.088888877778},
{lat:-2.85138887777778,lng:122.088968922068},
{lat:-2.85152518552227,lng:122.088888877778},
{lat:-2.85138887777778,lng:122.088628178547},
{lat:-2.85134920062097,lng:122.0886111},
{lat:-2.85138887777778,lng:122.08859188155},
{lat:-2.85161761678071,lng:122.0886111},
{lat:-2.85166665555556,lng:122.088614808742},
{lat:-2.85166709695061,lng:122.0886111},
{lat:-2.85166665555556,lng:122.088607066986},
{lat:-2.85152232103631,lng:122.088333322222},
{lat:-2.85166665555556,lng:122.088200278887},
{lat:-2.85174766354483,lng:122.088055544444},
{lat:-2.85194443333334,lng:122.087792947366},
{lat:-2.851976771549,lng:122.087777766667},
{lat:-2.85194443333334,lng:122.087701636545},
{lat:-2.85166665555556,lng:122.087705357989},
{lat:-2.85151067174455,lng:122.087777766667},
{lat:-2.85138887777778,lng:122.087805608994},
{lat:-2.85132969535505,lng:122.087777766667},
{lat:-2.85126325943028,lng:122.087499988889},
{lat:-2.8511111,lng:122.087264260367},
{lat:-2.85108970299178,lng:122.087222211111},
{lat:-2.85086057443474,lng:122.086944433333},
{lat:-2.85083332222222,lng:122.086918173424},
{lat:-2.85055554444445,lng:122.086815361278},
{lat:-2.85027776666667,lng:122.086764195354},
{lat:-2.85023534637509,lng:122.086666655556},
{lat:-2.85012873360097,lng:122.086388877778},
{lat:-2.85009938424119,lng:122.0861111},
{lat:-2.85021552692523,lng:122.085833322222},
{lat:-2.85018301906027,lng:122.085555544444},
{lat:-2.85015315945696,lng:122.085277766667},
{lat:-2.85000832422629,lng:122.084999988889},
{lat:-2.84999998888889,lng:122.084987225793},
{lat:-2.84972221111111,lng:122.084902221159},
{lat:-2.84964375003209,lng:122.084999988889},
{lat:-2.84944443333333,lng:122.085245136246},
{lat:-2.84943073241411,lng:122.085277766667},
{lat:-2.84938087234884,lng:122.085555544444},
{lat:-2.84923618101534,lng:122.085833322222},
{lat:-2.84916665555556,lng:122.085879938761},
{lat:-2.84888887777778,lng:122.086040175355},
{lat:-2.84879969982433,lng:122.0861111},
{lat:-2.8486111,lng:122.086294823734},
{lat:-2.84852819862424,lng:122.086388877778},
{lat:-2.84852530835443,lng:122.086666655556},
{lat:-2.8486111,lng:122.086770698548},
{lat:-2.84870405581219,lng:122.086944433333},
{lat:-2.8486111,lng:122.087075439673},
{lat:-2.84847741791986,lng:122.087222211111},
{lat:-2.84833332222222,lng:122.087406469194},
{lat:-2.84826065027536,lng:122.087499988889},
{lat:-2.84805554444445,lng:122.087716607709},
{lat:-2.84799265244674,lng:122.087777766667},
{lat:-2.84782574283514,lng:122.088055544444},
{lat:-2.84788373825804,lng:122.088333322222},
{lat:-2.84796275608032,lng:122.0886111},
{lat:-2.84805554444445,lng:122.088799017942},
{lat:-2.84824821703303,lng:122.088888877778},
{lat:-2.84812229386733,lng:122.089166655556},
{lat:-2.84805554444445,lng:122.089263885378},
{lat:-2.84791108293755,lng:122.089444433333},
{lat:-2.84791108293755,lng:122.089583322222},]},
{ID:112,coord:[{lat:-2.85194443333334,lng:122.083295987133},
{lat:-2.85200296850736,lng:122.083055544444},
{lat:-2.85195217408798,lng:122.082777766667},
{lat:-2.85202284388439,lng:122.082499988889},
{lat:-2.85204589205484,lng:122.082222211111},
{lat:-2.8520440054681,lng:122.081944433333},
{lat:-2.85194443333334,lng:122.081682145921},
{lat:-2.85166665555556,lng:122.081770394201},
{lat:-2.85144118807961,lng:122.081944433333},
{lat:-2.85155242928091,lng:122.082222211111},
{lat:-2.85138887777778,lng:122.08240539887},
{lat:-2.8511986275526,lng:122.082499988889},
{lat:-2.85124017661359,lng:122.082777766667},
{lat:-2.85129913022109,lng:122.083055544444},
{lat:-2.85138887777778,lng:122.08319567413},
{lat:-2.85166665555556,lng:122.083327195172},
{lat:-2.85194443333334,lng:122.083295987133},]},
{ID:113,coord:[{lat:-2.85249998888889,lng:122.072788927191},
{lat:-2.85251914345601,lng:122.072777766667},
{lat:-2.85249998888889,lng:122.072696432539},
{lat:-2.85245936353579,lng:122.072499988889},
{lat:-2.8524807154606,lng:122.072222211111},
{lat:-2.85249998888889,lng:122.072154302283},
{lat:-2.8525554277355,lng:122.071944433333},
{lat:-2.85249998888889,lng:122.071687061332},
{lat:-2.85249586958244,lng:122.071666655556},
{lat:-2.85244093787215,lng:122.071388877778},
{lat:-2.85236760043587,lng:122.0711111},
{lat:-2.85236602997482,lng:122.070833322222},
{lat:-2.8523487979227,lng:122.070555544444},
{lat:-2.85234464823313,lng:122.070277766667},
{lat:-2.85231300387806,lng:122.069999988889},
{lat:-2.85226262469161,lng:122.069722211111},
{lat:-2.85222221111111,lng:122.06964347696},
{lat:-2.85194443333334,lng:122.069595698691},
{lat:-2.85166665555556,lng:122.069460109255},
{lat:-2.85165841425397,lng:122.069444433333},
{lat:-2.8514541170205,lng:122.069166655556},
{lat:-2.85140568868236,lng:122.068888877778},
{lat:-2.85138887777778,lng:122.068755399238},
{lat:-2.8511111,lng:122.068647322206},
{lat:-2.85107348217475,lng:122.0686111},
{lat:-2.85105610966934,lng:122.068333322222},
{lat:-2.85109148572179,lng:122.068055544444},
{lat:-2.85083332222222,lng:122.06783553801},
{lat:-2.85063703826549,lng:122.068055544444},
{lat:-2.85055554444445,lng:122.068159122977},
{lat:-2.85027776666667,lng:122.068299963235},
{lat:-2.85023630713313,lng:122.068333322222},
{lat:-2.85005461005522,lng:122.0686111},
{lat:-2.85019674080227,lng:122.068888877778},
{lat:-2.85022807205704,lng:122.069166655556},
{lat:-2.85014345689859,lng:122.069444433333},
{lat:-2.84999998888889,lng:122.069642954074},
{lat:-2.84994118473455,lng:122.069722211111},
{lat:-2.84999998888889,lng:122.069997585505},
{lat:-2.85027776666667,lng:122.069877237586},
{lat:-2.85055554444445,lng:122.069917281825},
{lat:-2.85065275104444,lng:122.069999988889},
{lat:-2.85083332222222,lng:122.070208142137},
{lat:-2.85087676060059,lng:122.070277766667},
{lat:-2.85083332222222,lng:122.070445383771},
{lat:-2.85077678698472,lng:122.070555544444},
{lat:-2.85059679319808,lng:122.070833322222},
{lat:-2.85067382034573,lng:122.0711111},
{lat:-2.8507598672429,lng:122.071388877778},
{lat:-2.85083332222222,lng:122.071597736189},
{lat:-2.85086342401916,lng:122.071666655556},
{lat:-2.85091772964853,lng:122.071944433333},
{lat:-2.85107328895997,lng:122.072222211111},
{lat:-2.8511111,lng:122.072288514997},
{lat:-2.85126825365306,lng:122.072499988889},
{lat:-2.85138887777778,lng:122.072613550233},
{lat:-2.85166665555556,lng:122.072749258525},
{lat:-2.85172849059988,lng:122.072777766667},
{lat:-2.85194443333334,lng:122.072862230606},
{lat:-2.85219075149333,lng:122.072777766667},
{lat:-2.85222221111111,lng:122.072737492618},
{lat:-2.85241806883286,lng:122.072777766667},
{lat:-2.85249998888889,lng:122.072788927191},]},
{ID:114,coord:[{lat:-2.85249737435258,lng:122.089583322222},
{lat:-2.85249737435258,lng:122.089444433333},
{lat:-2.85249998888889,lng:122.089434667242},
{lat:-2.85277776666667,lng:122.089336619387},
{lat:-2.85285727461647,lng:122.089444433333},
{lat:-2.85285727461647,lng:122.089583322222},]},
{ID:115,coord:[{lat:-2.85239056332413,lng:122.089583322222},
{lat:-2.85239056332413,lng:122.089444433333},
{lat:-2.85246869207854,lng:122.089166655556},
{lat:-2.85249998888889,lng:122.089079415356},
{lat:-2.85274814500444,lng:122.089166655556},
{lat:-2.85277776666667,lng:122.089173063908},
{lat:-2.85297788944585,lng:122.089444433333},
{lat:-2.85297788944585,lng:122.089583322222},]},
{ID:116,coord:[{lat:-2.85277776666667,lng:122.072501060514},
{lat:-2.85277791003071,lng:122.072499988889},
{lat:-2.85277776666667,lng:122.072499168249},
{lat:-2.85277188026983,lng:122.072499988889},
{lat:-2.85277776666667,lng:122.072501060514},]},
{ID:117,coord:[{lat:-2.85192840598549,lng:122.063472211111},
{lat:-2.85192840598549,lng:122.0636111},
{lat:-2.85185452020942,lng:122.063888877778},
{lat:-2.85180699282849,lng:122.064166655556},
{lat:-2.85185534925492,lng:122.064444433333},
{lat:-2.85194443333334,lng:122.064617534981},
{lat:-2.85222221111111,lng:122.064593908984},
{lat:-2.85249998888889,lng:122.064503118451},
{lat:-2.8525907875,lng:122.064444433333},
{lat:-2.85277776666667,lng:122.064313882146},
{lat:-2.85293001630575,lng:122.064166655556},
{lat:-2.85285630582567,lng:122.063888877778},
{lat:-2.85277776666667,lng:122.063826843286},
{lat:-2.85249998888889,lng:122.063750484677},
{lat:-2.85227506169867,lng:122.0636111},
{lat:-2.85227506169867,lng:122.063472211111},]},
{ID:118,coord:[{lat:-2.851413169655,lng:122.063472211111},
{lat:-2.851413169655,lng:122.0636111},
{lat:-2.85138887777778,lng:122.063646222333},
{lat:-2.85119455368357,lng:122.063888877778},
{lat:-2.8511111,lng:122.064059570943},
{lat:-2.85104888217491,lng:122.064166655556},
{lat:-2.8511111,lng:122.064297521056},
{lat:-2.85118626682912,lng:122.064444433333},
{lat:-2.85128089539588,lng:122.064722211111},
{lat:-2.85136757748878,lng:122.064999988889},
{lat:-2.85138887777778,lng:122.065029764106},
{lat:-2.85162410419088,lng:122.065277766667},
{lat:-2.85166665555556,lng:122.065327304509},
{lat:-2.85184629339522,lng:122.065555544444},
{lat:-2.85190067777198,lng:122.065833322222},
{lat:-2.85194443333334,lng:122.065906090227},
{lat:-2.85222221111111,lng:122.065907051613},
{lat:-2.85249998888889,lng:122.065942094502},
{lat:-2.85265625214502,lng:122.065833322222},
{lat:-2.85277776666667,lng:122.0657290897},
{lat:-2.85292893395666,lng:122.065555544444},
{lat:-2.85304090709103,lng:122.065277766667},
{lat:-2.85305554444445,lng:122.065184782958},
{lat:-2.85307105058355,lng:122.064999988889},
{lat:-2.85313717978264,lng:122.064722211111},
{lat:-2.85320046123958,lng:122.064444433333},
{lat:-2.85324685764923,lng:122.064166655556},
{lat:-2.85325773003388,lng:122.063888877778},
{lat:-2.85323990391648,lng:122.0636111},
{lat:-2.85323990391648,lng:122.063472211111},]},
{ID:119,coord:[{lat:-2.85076122561123,lng:122.063472211111},
{lat:-2.85076122561123,lng:122.0636111},
{lat:-2.85068829086674,lng:122.063888877778},
{lat:-2.8506191824641,lng:122.064166655556},
{lat:-2.8506319427389,lng:122.064444433333},
{lat:-2.85074715659022,lng:122.064722211111},
{lat:-2.85083332222222,lng:122.064948213608},
{lat:-2.85085214732323,lng:122.064999988889},
{lat:-2.85091692760842,lng:122.065277766667},
{lat:-2.85103547217225,lng:122.065555544444},
{lat:-2.85106587710859,lng:122.065833322222},
{lat:-2.8511111,lng:122.065892250454},
{lat:-2.85123642344355,lng:122.0661111},
{lat:-2.8511731818134,lng:122.066388877778},
{lat:-2.8511111,lng:122.066421517464},
{lat:-2.85083332222222,lng:122.066512182867},
{lat:-2.85065516248612,lng:122.066666655556},
{lat:-2.85055554444445,lng:122.066740240801},
{lat:-2.85037743965697,lng:122.066666655556},
{lat:-2.85027776666667,lng:122.066611862715},
{lat:-2.85011694243593,lng:122.066666655556},
{lat:-2.84999998888889,lng:122.066731004358},
{lat:-2.84993791245082,lng:122.066944433333},
{lat:-2.84994969088878,lng:122.067222211111},
{lat:-2.84996687013824,lng:122.067499988889},
{lat:-2.84999998888889,lng:122.067594019182},
{lat:-2.85006601116798,lng:122.067777766667},
{lat:-2.84999998888889,lng:122.067926019118},
{lat:-2.84984462744037,lng:122.068055544444},
{lat:-2.84972221111111,lng:122.068130005621},
{lat:-2.84944443333333,lng:122.068325650083},
{lat:-2.84943751726103,lng:122.068333322222},
{lat:-2.84939887135916,lng:122.0686111},
{lat:-2.84944443333333,lng:122.068691837672},
{lat:-2.84952719736553,lng:122.068888877778},
{lat:-2.84959320087458,lng:122.069166655556},
{lat:-2.84944443333333,lng:122.069381457463},
{lat:-2.849414026277,lng:122.069444433333},
{lat:-2.84939741656969,lng:122.069722211111},
{lat:-2.84944443333333,lng:122.069825228724},
{lat:-2.84950167099922,lng:122.069999988889},
{lat:-2.8497086634867,lng:122.070277766667},
{lat:-2.84972221111111,lng:122.07028897498},
{lat:-2.84995825345437,lng:122.070555544444},
{lat:-2.84996476599606,lng:122.070833322222},
{lat:-2.84999998888889,lng:122.071010713634},
{lat:-2.85003510335213,lng:122.0711111},
{lat:-2.85027710140475,lng:122.071388877778},
{lat:-2.85027776666667,lng:122.071389638912},
{lat:-2.85046977758273,lng:122.071666655556},
{lat:-2.85046439731397,lng:122.071944433333},
{lat:-2.85053131633434,lng:122.072222211111},
{lat:-2.85055554444445,lng:122.072346806114},
{lat:-2.85059953303638,lng:122.072499988889},
{lat:-2.85082113643489,lng:122.072777766667},
{lat:-2.85083332222222,lng:122.072786844968},
{lat:-2.8511111,lng:122.072985113894},
{lat:-2.8511688290749,lng:122.073055544444},
{lat:-2.85138315198336,lng:122.073333322222},
{lat:-2.85138887777778,lng:122.07333774696},
{lat:-2.85166665555556,lng:122.073487047883},
{lat:-2.85178308873125,lng:122.0736111},
{lat:-2.85191000835057,lng:122.073888877778},
{lat:-2.85191775854589,lng:122.074166655556},
{lat:-2.85182320110553,lng:122.074444433333},
{lat:-2.85186962993815,lng:122.074722211111},
{lat:-2.85194443333334,lng:122.074826629468},
{lat:-2.85203385450476,lng:122.074722211111},
{lat:-2.85215025173117,lng:122.074444433333},
{lat:-2.85207323805801,lng:122.074166655556},
{lat:-2.85222221111111,lng:122.073936830917},
{lat:-2.85226745743324,lng:122.074166655556},
{lat:-2.85249998888889,lng:122.074422747128},
{lat:-2.85253568361636,lng:122.074444433333},
{lat:-2.85275628870048,lng:122.074722211111},
{lat:-2.85277776666667,lng:122.074735014606},
{lat:-2.85285586685963,lng:122.074722211111},
{lat:-2.85297264294495,lng:122.074444433333},
{lat:-2.85295909299137,lng:122.074166655556},
{lat:-2.85286414420321,lng:122.073888877778},
{lat:-2.85284165665654,lng:122.0736111},
{lat:-2.85294973066451,lng:122.073333322222},
{lat:-2.85305554444445,lng:122.07307604528},
{lat:-2.85305755699237,lng:122.073055544444},
{lat:-2.85307998602273,lng:122.072777766667},
{lat:-2.85308024701551,lng:122.072499988889},
{lat:-2.85306892564104,lng:122.072222211111},
{lat:-2.85305554444445,lng:122.072126277291},
{lat:-2.85298952501399,lng:122.071944433333},
{lat:-2.85282234917328,lng:122.071666655556},
{lat:-2.85277776666667,lng:122.071515753301},
{lat:-2.85273993014171,lng:122.071388877778},
{lat:-2.85270882227737,lng:122.0711111},
{lat:-2.85269997993168,lng:122.070833322222},
{lat:-2.85271290265777,lng:122.070555544444},
{lat:-2.85268556889225,lng:122.070277766667},
{lat:-2.85263778467569,lng:122.069999988889},
{lat:-2.85257558088066,lng:122.069722211111},
{lat:-2.85253493720173,lng:122.069444433333},
{lat:-2.85250210893786,lng:122.069166655556},
{lat:-2.85249998888889,lng:122.069159737794},
{lat:-2.85222221111111,lng:122.068944722836},
{lat:-2.85208842140587,lng:122.068888877778},
{lat:-2.85194443333334,lng:122.06878669742},
{lat:-2.85188267894526,lng:122.0686111},
{lat:-2.85177649338286,lng:122.068333322222},
{lat:-2.85166665555556,lng:122.068058756695},
{lat:-2.85166276582107,lng:122.068055544444},
{lat:-2.85166665555556,lng:122.0680022884},
{lat:-2.85168043261607,lng:122.067777766667},
{lat:-2.85168873504171,lng:122.067499988889},
{lat:-2.85194443333334,lng:122.067305892494},
{lat:-2.85200688637092,lng:122.067222211111},
{lat:-2.8521081583493,lng:122.066944433333},
{lat:-2.85222221111111,lng:122.066672283251},
{lat:-2.85222926765293,lng:122.066666655556},
{lat:-2.85249998888889,lng:122.066585582544},
{lat:-2.85277776666667,lng:122.06652826206},
{lat:-2.85289776475343,lng:122.066388877778},
{lat:-2.85305554444445,lng:122.066226659608},
{lat:-2.85326413297674,lng:122.0661111},
{lat:-2.85333332222222,lng:122.06605371167},
{lat:-2.85344620574109,lng:122.065833322222},
{lat:-2.8534536292622,lng:122.065555544444},
{lat:-2.85345546302734,lng:122.065277766667},
{lat:-2.85346021948514,lng:122.064999988889},
{lat:-2.85345063145056,lng:122.064722211111},
{lat:-2.85342716857687,lng:122.064444433333},
{lat:-2.85342590708315,lng:122.064166655556},
{lat:-2.85343408584208,lng:122.063888877778},
{lat:-2.85341879670575,lng:122.0636111},
{lat:-2.85341879670575,lng:122.063472211111},]},
{ID:120,coord:[{lat:-2.8536111,lng:122.085209156369},
{lat:-2.85384765144772,lng:122.084999988889},
{lat:-2.85378503972681,lng:122.084722211111},
{lat:-2.85370793216575,lng:122.084444433333},
{lat:-2.8536111,lng:122.084206158385},
{lat:-2.85356726227401,lng:122.084166655556},
{lat:-2.85342063583015,lng:122.083888877778},
{lat:-2.85333332222222,lng:122.083719241036},
{lat:-2.85317581294296,lng:122.083888877778},
{lat:-2.8532343614836,lng:122.084166655556},
{lat:-2.85330624387084,lng:122.084444433333},
{lat:-2.85333332222222,lng:122.084522240777},
{lat:-2.85345843892394,lng:122.084722211111},
{lat:-2.85353890384324,lng:122.084999988889},
{lat:-2.8536111,lng:122.085209156369},]},
{ID:121,coord:[{lat:-2.85015713681716,lng:122.063472211111},
{lat:-2.85015713681716,lng:122.0636111},
{lat:-2.85010943655945,lng:122.063888877778},
{lat:-2.84999998888889,lng:122.064060341576},
{lat:-2.8499537790283,lng:122.064166655556},
{lat:-2.84999998888889,lng:122.064304130138},
{lat:-2.85004285698642,lng:122.064444433333},
{lat:-2.85013722318908,lng:122.064722211111},
{lat:-2.850144082712,lng:122.064999988889},
{lat:-2.85013964284369,lng:122.065277766667},
{lat:-2.85018540438914,lng:122.065555544444},
{lat:-2.85027776666667,lng:122.065826011183},
{lat:-2.85028439084786,lng:122.065833322222},
{lat:-2.85027776666667,lng:122.065854253344},
{lat:-2.85011912608478,lng:122.0661111},
{lat:-2.84999998888889,lng:122.066139313352},
{lat:-2.84981079606071,lng:122.066388877778},
{lat:-2.84972221111111,lng:122.066553024583},
{lat:-2.84966990367196,lng:122.066666655556},
{lat:-2.84963284035789,lng:122.066944433333},
{lat:-2.84960605516263,lng:122.067222211111},
{lat:-2.84954241247908,lng:122.067499988889},
{lat:-2.84944443333333,lng:122.067662016863},
{lat:-2.84936336455222,lng:122.067777766667},
{lat:-2.84916665555556,lng:122.067973332885},
{lat:-2.84895867143435,lng:122.068055544444},
{lat:-2.84888887777778,lng:122.06820267238},
{lat:-2.84880084583391,lng:122.068055544444},
{lat:-2.8486111,lng:122.067897694646},
{lat:-2.84852854469678,lng:122.068055544444},
{lat:-2.8486111,lng:122.068187064887},
{lat:-2.84881378741781,lng:122.068333322222},
{lat:-2.84888887777778,lng:122.068476666448},
{lat:-2.84890938753485,lng:122.0686111},
{lat:-2.8489683423731,lng:122.068888877778},
{lat:-2.84888887777778,lng:122.069079445945},
{lat:-2.8488689563779,lng:122.069166655556},
{lat:-2.84888887777778,lng:122.069365652583},
{lat:-2.84889944102779,lng:122.069444433333},
{lat:-2.84897216701136,lng:122.069722211111},
{lat:-2.84907178713662,lng:122.069999988889},
{lat:-2.84916665555556,lng:122.070127710416},
{lat:-2.84929145684393,lng:122.070277766667},
{lat:-2.84944443333333,lng:122.07048822957},
{lat:-2.84952747931911,lng:122.070555544444},
{lat:-2.84968839826507,lng:122.070833322222},
{lat:-2.84972221111111,lng:122.071051044593},
{lat:-2.84973522378496,lng:122.0711111},
{lat:-2.84986614170762,lng:122.071388877778},
{lat:-2.84999998888889,lng:122.071619536373},
{lat:-2.85004154887555,lng:122.071666655556},
{lat:-2.85017353410652,lng:122.071944433333},
{lat:-2.85024304394183,lng:122.072222211111},
{lat:-2.85027776666667,lng:122.072358923182},
{lat:-2.85030787015469,lng:122.072499988889},
{lat:-2.85036633763806,lng:122.072777766667},
{lat:-2.85055554444445,lng:122.072972250338},
{lat:-2.85067155734066,lng:122.073055544444},
{lat:-2.85083332222222,lng:122.073177197981},
{lat:-2.85094519047535,lng:122.073333322222},
{lat:-2.8511111,lng:122.073542284205},
{lat:-2.85119673718063,lng:122.0736111},
{lat:-2.85138887777778,lng:122.073772330439},
{lat:-2.85148128313847,lng:122.073888877778},
{lat:-2.85145616366138,lng:122.074166655556},
{lat:-2.85141843771888,lng:122.074444433333},
{lat:-2.85149882165068,lng:122.074722211111},
{lat:-2.85160451389161,lng:122.074999988889},
{lat:-2.85166665555556,lng:122.075121538788},
{lat:-2.85180118399746,lng:122.075277766667},
{lat:-2.85194443333334,lng:122.075412162742},
{lat:-2.85216634594487,lng:122.075277766667},
{lat:-2.85222221111111,lng:122.075215061191},
{lat:-2.85249998888889,lng:122.075238162748},
{lat:-2.85253383405333,lng:122.075277766667},
{lat:-2.85272610416308,lng:122.075555544444},
{lat:-2.85277776666667,lng:122.07564464117},
{lat:-2.85305554444445,lng:122.075828127034},
{lat:-2.85333332222222,lng:122.075772135893},
{lat:-2.85354029565468,lng:122.075555544444},
{lat:-2.8536111,lng:122.075339015237},
{lat:-2.85362251448477,lng:122.075277766667},
{lat:-2.8536111,lng:122.07524247405},
{lat:-2.85353477975058,lng:122.074999988889},
{lat:-2.85344485642026,lng:122.074722211111},
{lat:-2.85335463174812,lng:122.074444433333},
{lat:-2.85333332222222,lng:122.074398902567},
{lat:-2.85321696538947,lng:122.074166655556},
{lat:-2.85316333325584,lng:122.073888877778},
{lat:-2.85314214612966,lng:122.0736111},
{lat:-2.85319148238522,lng:122.073333322222},
{lat:-2.85321035395837,lng:122.073055544444},
{lat:-2.85323264921459,lng:122.072777766667},
{lat:-2.85321002957902,lng:122.072499988889},
{lat:-2.85318914142485,lng:122.072222211111},
{lat:-2.85315132690852,lng:122.071944433333},
{lat:-2.85307368659317,lng:122.071666655556},
{lat:-2.85305554444445,lng:122.071599536911},
{lat:-2.85296649900746,lng:122.071388877778},
{lat:-2.85296993778435,lng:122.0711111},
{lat:-2.85294917670651,lng:122.070833322222},
{lat:-2.8529519501306,lng:122.070555544444},
{lat:-2.85290497741712,lng:122.070277766667},
{lat:-2.85283623863618,lng:122.069999988889},
{lat:-2.85278502992254,lng:122.069722211111},
{lat:-2.85277776666667,lng:122.069647778944},
{lat:-2.85275259529944,lng:122.069444433333},
{lat:-2.852723561806,lng:122.069166655556},
{lat:-2.85267511248664,lng:122.068888877778},
{lat:-2.85251803209498,lng:122.0686111},
{lat:-2.85249998888889,lng:122.068570439534},
{lat:-2.85235884652285,lng:122.068333322222},
{lat:-2.85236812361397,lng:122.068055544444},
{lat:-2.85240374184247,lng:122.067777766667},
{lat:-2.85244585457655,lng:122.067499988889},
{lat:-2.85249998888889,lng:122.067357272574},
{lat:-2.85255020204542,lng:122.067222211111},
{lat:-2.85277776666667,lng:122.06706946608},
{lat:-2.85295046100503,lng:122.066944433333},
{lat:-2.85305554444445,lng:122.066810227807},
{lat:-2.85316967077688,lng:122.066666655556},
{lat:-2.85333332222222,lng:122.066547096961},
{lat:-2.8535017036579,lng:122.066388877778},
{lat:-2.8536111,lng:122.066291660456},
{lat:-2.85378474180719,lng:122.0661111},
{lat:-2.85385082791586,lng:122.065833322222},
{lat:-2.85381966955113,lng:122.065555544444},
{lat:-2.85376364650426,lng:122.065277766667},
{lat:-2.85371399552622,lng:122.064999988889},
{lat:-2.85366426317365,lng:122.064722211111},
{lat:-2.85361473122234,lng:122.064444433333},
{lat:-2.8536111,lng:122.064422143635},
{lat:-2.8535749371868,lng:122.064166655556},
{lat:-2.85357842969337,lng:122.063888877778},
{lat:-2.85355861020355,lng:122.0636111},
{lat:-2.85355861020355,lng:122.063472211111},]},
{ID:122,coord:[{lat:-2.85228375229568,lng:122.089583322222},
{lat:-2.85228375229568,lng:122.089444433333},
{lat:-2.85237334590746,lng:122.089166655556},
{lat:-2.85246372149548,lng:122.088888877778},
{lat:-2.85249998888889,lng:122.088829125626},
{lat:-2.85257648805483,lng:122.088888877778},
{lat:-2.85277776666667,lng:122.089010181816},
{lat:-2.85288515784698,lng:122.089166655556},
{lat:-2.85305554444445,lng:122.089391824155},
{lat:-2.85333332222222,lng:122.089380931525},
{lat:-2.8536111,lng:122.089262206862},
{lat:-2.85388887777778,lng:122.089210936438},
{lat:-2.85402776666667,lng:122.089210936438},]},
{ID:123,coord:[{lat:-2.85214683080916,lng:122.089583322222},
{lat:-2.85214683080916,lng:122.089444433333},
{lat:-2.85222221111111,lng:122.089327746536},
{lat:-2.85227799973638,lng:122.089166655556},
{lat:-2.85233561176148,lng:122.088888877778},
{lat:-2.85249998888889,lng:122.088618059114},
{lat:-2.85277776666667,lng:122.088828319761},
{lat:-2.85280289247603,lng:122.088888877778},
{lat:-2.85299692836595,lng:122.089166655556},
{lat:-2.85305554444445,lng:122.089244117636},
{lat:-2.85333332222222,lng:122.089190208416},
{lat:-2.85338238726317,lng:122.089166655556},
{lat:-2.8536111,lng:122.089014202801},
{lat:-2.85388887777778,lng:122.088927581714},
{lat:-2.85402776666667,lng:122.088927581714},]},
{ID:124,coord:[{lat:-2.85196897626213,lng:122.089583322222},
{lat:-2.85196897626213,lng:122.089444433333},
{lat:-2.85216433149853,lng:122.089166655556},
{lat:-2.85221003634275,lng:122.088888877778},
{lat:-2.85222221111111,lng:122.088778715939},
{lat:-2.85226471444263,lng:122.0886111},
{lat:-2.85249998888889,lng:122.088423330843},
{lat:-2.85277776666667,lng:122.088593967074},
{lat:-2.85278629623061,lng:122.0886111},
{lat:-2.85290137131931,lng:122.088888877778},
{lat:-2.85305554444445,lng:122.089101934935},
{lat:-2.85333332222222,lng:122.089033798818},
{lat:-2.85351242166407,lng:122.088888877778},
{lat:-2.8536111,lng:122.088810059716},
{lat:-2.85388887777778,lng:122.088698255703},
{lat:-2.85402776666667,lng:122.088698255703},]},
{ID:125,coord:[{lat:-2.85172552898501,lng:122.089583322222},
{lat:-2.85172552898501,lng:122.089444433333},
{lat:-2.85194443333334,lng:122.08927794844},
{lat:-2.85202482336348,lng:122.089166655556},
{lat:-2.85210399939473,lng:122.088888877778},
{lat:-2.85211272212208,lng:122.0886111},
{lat:-2.85222221111111,lng:122.08837970337},
{lat:-2.85228768042583,lng:122.088333322222},
{lat:-2.85249998888889,lng:122.088237678304},
{lat:-2.85268020085013,lng:122.088333322222},
{lat:-2.85277776666667,lng:122.08839198408},
{lat:-2.85288685271599,lng:122.0886111},
{lat:-2.85299985016259,lng:122.088888877778},
{lat:-2.85305554444445,lng:122.088965843626},
{lat:-2.85331331334237,lng:122.088888877778},
{lat:-2.85333332222222,lng:122.088882490658},
{lat:-2.8536111,lng:122.088660437999},
{lat:-2.85370863082809,lng:122.0886111},
{lat:-2.85388887777778,lng:122.088503839136},
{lat:-2.85402776666667,lng:122.088503839136},]},
{ID:126,coord:[{lat:-2.85143637209006,lng:122.089583322222},
{lat:-2.85143637209006,lng:122.089444433333},
{lat:-2.85166665555556,lng:122.089293781392},
{lat:-2.85183462482328,lng:122.089166655556},
{lat:-2.85194443333334,lng:122.089039890163},
{lat:-2.8519979624467,lng:122.088888877778},
{lat:-2.85198005677947,lng:122.0886111},
{lat:-2.85205493448426,lng:122.088333322222},
{lat:-2.85222221111111,lng:122.088169853276},
{lat:-2.85249998888889,lng:122.088059386894},
{lat:-2.85277776666667,lng:122.08813398435},
{lat:-2.85286456095355,lng:122.088333322222},
{lat:-2.85298740920137,lng:122.0886111},
{lat:-2.85305554444445,lng:122.088780354539},
{lat:-2.85333332222222,lng:122.088736996639},
{lat:-2.85348985370153,lng:122.0886111},
{lat:-2.8536111,lng:122.08850803826},
{lat:-2.85388145669971,lng:122.088333322222},
{lat:-2.85388887777778,lng:122.088323233596},
{lat:-2.85402776666667,lng:122.088323233596},]},
{ID:127,coord:[{lat:-2.85402776666667,lng:122.08248370568},
{lat:-2.85388887777778,lng:122.08248370568},
{lat:-2.85387334918458,lng:122.082222211111},
{lat:-2.8538564045301,lng:122.081944433333},
{lat:-2.85386658717094,lng:122.081666655556},
{lat:-2.85388887777778,lng:122.081577756816},
{lat:-2.85402776666667,lng:122.081577756816},]},
{ID:128,coord:[{lat:-2.85001572091784,lng:122.089583322222},
{lat:-2.85001572091784,lng:122.089444433333},
{lat:-2.85027776666667,lng:122.089357948817},
{lat:-2.85055554444445,lng:122.089294328908},
{lat:-2.85083332222222,lng:122.089311080253},
{lat:-2.8511111,lng:122.089245592744},
{lat:-2.85138887777778,lng:122.089272146861},
{lat:-2.85155395653951,lng:122.089166655556},
{lat:-2.85166665555556,lng:122.089081544696},
{lat:-2.85183793167984,lng:122.088888877778},
{lat:-2.85182727007524,lng:122.0886111},
{lat:-2.85184564481733,lng:122.088333322222},
{lat:-2.85194443333334,lng:122.088202734407},
{lat:-2.85209677601179,lng:122.088055544444},
{lat:-2.85222221111111,lng:122.087959117079},
{lat:-2.85249998888889,lng:122.08788252196},
{lat:-2.85277776666667,lng:122.087918844505},
{lat:-2.85292825299186,lng:122.088055544444},
{lat:-2.85298688045501,lng:122.088333322222},
{lat:-2.85305554444445,lng:122.088509760457},
{lat:-2.85333332222222,lng:122.088578175366},
{lat:-2.8536111,lng:122.088354271779},
{lat:-2.85364351747645,lng:122.088333322222},
{lat:-2.85385409607796,lng:122.088055544444},
{lat:-2.85382164945641,lng:122.087777766667},
{lat:-2.85387156722358,lng:122.087499988889},
{lat:-2.85388887777778,lng:122.087466324593},
{lat:-2.85402776666667,lng:122.087466324593},]},
{ID:129,coord:[{lat:-2.85402776666667,lng:122.083045364675},
{lat:-2.85388887777778,lng:122.083045364675},
{lat:-2.85376099411218,lng:122.082777766667},
{lat:-2.85373745784908,lng:122.082499988889},
{lat:-2.85372459138124,lng:122.082222211111},
{lat:-2.85370413157935,lng:122.081944433333},
{lat:-2.85370851527756,lng:122.081666655556},
{lat:-2.85376823825543,lng:122.081388877778},
{lat:-2.85377159108976,lng:122.0811111},
{lat:-2.85377571489231,lng:122.080833322222},
{lat:-2.85383064414774,lng:122.080555544444},
{lat:-2.85388887777778,lng:122.080289447133},
{lat:-2.85402776666667,lng:122.080289447133},]},
{ID:130,coord:[{lat:-2.84436260158873,lng:122.063472211111},
{lat:-2.84436260158873,lng:122.0636111},
{lat:-2.84444443333333,lng:122.063649781252},
{lat:-2.84472221111111,lng:122.063716500406},
{lat:-2.84476988738101,lng:122.063888877778},
{lat:-2.84480434178421,lng:122.064166655556},
{lat:-2.84473404524216,lng:122.064444433333},
{lat:-2.84474651129013,lng:122.064722211111},
{lat:-2.84472221111111,lng:122.064751303271},
{lat:-2.84444976129372,lng:122.064999988889},
{lat:-2.84444443333333,lng:122.065005162285},
{lat:-2.84435613186467,lng:122.065277766667},
{lat:-2.8443729693644,lng:122.065555544444},
{lat:-2.84442805421475,lng:122.065833322222},
{lat:-2.84439462051303,lng:122.0661111},
{lat:-2.84436457610972,lng:122.066388877778},
{lat:-2.8443834368487,lng:122.066666655556},
{lat:-2.84436265263085,lng:122.066944433333},
{lat:-2.84431151467454,lng:122.067222211111},
{lat:-2.84432548585347,lng:122.067499988889},
{lat:-2.84438216274923,lng:122.067777766667},
{lat:-2.84429774445072,lng:122.068055544444},
{lat:-2.84429919745125,lng:122.068333322222},
{lat:-2.84416665555556,lng:122.06845462106},
{lat:-2.84394281895348,lng:122.0686111},
{lat:-2.84388887777778,lng:122.068692949799},
{lat:-2.84367620629749,lng:122.0686111},
{lat:-2.8436111,lng:122.068492755445},
{lat:-2.84333332222222,lng:122.068398821149},
{lat:-2.84305554444445,lng:122.068412662002},
{lat:-2.84277776666667,lng:122.068423173884},
{lat:-2.84249998888889,lng:122.068535964763},
{lat:-2.84229259607298,lng:122.0686111},
{lat:-2.84222221111111,lng:122.068682505034},
{lat:-2.84210932995017,lng:122.068888877778},
{lat:-2.84213942272082,lng:122.069166655556},
{lat:-2.84222221111111,lng:122.069227086331},
{lat:-2.84249998888889,lng:122.069387333218},
{lat:-2.84255714234102,lng:122.069444433333},
{lat:-2.84277776666667,lng:122.069544580165},
{lat:-2.84284853772632,lng:122.069722211111},
{lat:-2.84277776666667,lng:122.069937332359},
{lat:-2.84274898985517,lng:122.069999988889},
{lat:-2.8425774531999,lng:122.070277766667},
{lat:-2.84252801446466,lng:122.070555544444},
{lat:-2.84249998888889,lng:122.070719381141},
{lat:-2.84246868592692,lng:122.070555544444},
{lat:-2.84234118719603,lng:122.070277766667},
{lat:-2.84222221111111,lng:122.070212104556},
{lat:-2.84216834608367,lng:122.070277766667},
{lat:-2.84201630292766,lng:122.070555544444},
{lat:-2.84195588806709,lng:122.070833322222},
{lat:-2.84199666700206,lng:122.0711111},
{lat:-2.84214352404619,lng:122.071388877778},
{lat:-2.84220159922006,lng:122.071666655556},
{lat:-2.84219650973888,lng:122.071944433333},
{lat:-2.84213838209228,lng:122.072222211111},
{lat:-2.84214120264693,lng:122.072499988889},
{lat:-2.84222221111111,lng:122.07260800087},
{lat:-2.84249998888889,lng:122.07265762477},
{lat:-2.84257379956062,lng:122.072777766667},
{lat:-2.84256555682508,lng:122.073055544444},
{lat:-2.84251549487105,lng:122.073333322222},
{lat:-2.84249998888889,lng:122.073380243607},
{lat:-2.84233599079625,lng:122.0736111},
{lat:-2.84222221111111,lng:122.073851236028},
{lat:-2.8422039810625,lng:122.073888877778},
{lat:-2.84203503012776,lng:122.074166655556},
{lat:-2.84194443333333,lng:122.074288265853},
{lat:-2.84183842206621,lng:122.074444433333},
{lat:-2.84171295455978,lng:122.074722211111},
{lat:-2.84169520167388,lng:122.074999988889},
{lat:-2.84166665555556,lng:122.075142826028},
{lat:-2.84145086585003,lng:122.075277766667},
{lat:-2.84138887777778,lng:122.075295897703},
{lat:-2.84125089452316,lng:122.075277766667},
{lat:-2.8411111,lng:122.075269790731},
{lat:-2.8410668058923,lng:122.075277766667},
{lat:-2.84083332222222,lng:122.075326680296},
{lat:-2.84058755659813,lng:122.075555544444},
{lat:-2.84055554444445,lng:122.075615406542},
{lat:-2.84042038399165,lng:122.075833322222},
{lat:-2.84027776666667,lng:122.076063562851},
{lat:-2.84025550709692,lng:122.0761111},
{lat:-2.84002887102489,lng:122.076388877778},
{lat:-2.83999998888889,lng:122.076499899445},
{lat:-2.83996071675778,lng:122.076666655556},
{lat:-2.8399200660445,lng:122.076944433333},
{lat:-2.83983609802297,lng:122.077222211111},
{lat:-2.83972221111111,lng:122.07733879014},
{lat:-2.83949779218312,lng:122.077499988889},
{lat:-2.83944443333333,lng:122.077546976235},
{lat:-2.83924530263956,lng:122.077777766667},
{lat:-2.83916665555556,lng:122.077842694272},
{lat:-2.83892909034092,lng:122.078055544444},
{lat:-2.83888887777778,lng:122.078108116542},
{lat:-2.8387108843148,lng:122.078333322222},
{lat:-2.8386111,lng:122.078463305554},
{lat:-2.83854435568684,lng:122.0786111},
{lat:-2.83845726217474,lng:122.078888877778},
{lat:-2.83833332222222,lng:122.079164205665},
{lat:-2.83833225245173,lng:122.079166655556},
{lat:-2.83824890265659,lng:122.079444433333},
{lat:-2.83820534197628,lng:122.079722211111},
{lat:-2.83805554444445,lng:122.079941517585},
{lat:-2.83798762470622,lng:122.079999988889},
{lat:-2.83781218818787,lng:122.080277766667},
{lat:-2.83777776666667,lng:122.080355274357},
{lat:-2.83749998888889,lng:122.08044620873},
{lat:-2.83722221111111,lng:122.080555492694},
{lat:-2.8372221619768,lng:122.080555544444},
{lat:-2.83712633222792,lng:122.080833322222},
{lat:-2.83694443333333,lng:122.08097976343},
{lat:-2.83685618082417,lng:122.0811111},
{lat:-2.83691063495146,lng:122.081388877778},
{lat:-2.83694443333333,lng:122.081470477598},
{lat:-2.83708163065705,lng:122.081666655556},
{lat:-2.83722221111111,lng:122.081837697978},
{lat:-2.83749998888889,lng:122.081922554134},
{lat:-2.83751924298228,lng:122.081944433333},
{lat:-2.83777776666667,lng:122.082026180933},
{lat:-2.83788257184799,lng:122.081944433333},
{lat:-2.83805554444445,lng:122.081785190336},
{lat:-2.83815218018399,lng:122.081666655556},
{lat:-2.83833332222222,lng:122.081509530685},
{lat:-2.83853797696452,lng:122.081666655556},
{lat:-2.83858385027111,lng:122.081944433333},
{lat:-2.8386111,lng:122.082014881472},
{lat:-2.83888887777778,lng:122.081947918543},
{lat:-2.83889466979077,lng:122.081944433333},
{lat:-2.83909939205364,lng:122.081666655556},
{lat:-2.83916665555556,lng:122.081604811749},
{lat:-2.83944443333333,lng:122.081395515982},
{lat:-2.83944891871003,lng:122.081388877778},
{lat:-2.83944443333333,lng:122.081377892152},
{lat:-2.83932664600089,lng:122.0811111},
{lat:-2.83916665555556,lng:122.081042575994},
{lat:-2.83898057717478,lng:122.080833322222},
{lat:-2.83888887777778,lng:122.080735395685},
{lat:-2.83879965157429,lng:122.080833322222},
{lat:-2.83874742573396,lng:122.0811111},
{lat:-2.8386111,lng:122.081275759855},
{lat:-2.83833332222222,lng:122.081227010235},
{lat:-2.83824691738892,lng:122.0811111},
{lat:-2.83805554444445,lng:122.080841301893},
{lat:-2.83804418508721,lng:122.080833322222},
{lat:-2.83805554444445,lng:122.080787182985},
{lat:-2.83833332222222,lng:122.080652976073},
{lat:-2.83839481098982,lng:122.080555544444},
{lat:-2.8386111,lng:122.080367589405},
{lat:-2.83867335425566,lng:122.080277766667},
{lat:-2.83888887777778,lng:122.080016043495},
{lat:-2.8389045222004,lng:122.079999988889},
{lat:-2.83902460721435,lng:122.079722211111},
{lat:-2.83916665555556,lng:122.079460279319},
{lat:-2.83918913731045,lng:122.079444433333},
{lat:-2.83941643152298,lng:122.079166655556},
{lat:-2.83944443333333,lng:122.079115452412},
{lat:-2.83963957821751,lng:122.078888877778},
{lat:-2.83972221111111,lng:122.078668003572},
{lat:-2.83979423828317,lng:122.0786111},
{lat:-2.83999487622358,lng:122.078333322222},
{lat:-2.83999998888889,lng:122.078318814627},
{lat:-2.84027776666667,lng:122.078171017095},
{lat:-2.84055554444445,lng:122.078093787655},
{lat:-2.84058327589555,lng:122.078055544444},
{lat:-2.84055554444445,lng:122.077985654997},
{lat:-2.84034645311534,lng:122.077777766667},
{lat:-2.84055554444445,lng:122.077689041969},
{lat:-2.84067292165825,lng:122.077499988889},
{lat:-2.84083332222222,lng:122.077376070406},
{lat:-2.8411111,lng:122.077359898989},
{lat:-2.84138887777778,lng:122.077347907428},
{lat:-2.84166665555556,lng:122.077299725784},
{lat:-2.84194443333333,lng:122.077280055806},
{lat:-2.84222221111111,lng:122.077349398814},
{lat:-2.84249998888889,lng:122.07748011747},
{lat:-2.84252274202837,lng:122.077499988889},
{lat:-2.8425287218308,lng:122.077777766667},
{lat:-2.84249998888889,lng:122.077810975087},
{lat:-2.8424200684251,lng:122.078055544444},
{lat:-2.84249998888889,lng:122.078311071314},
{lat:-2.84251875803024,lng:122.078333322222},
{lat:-2.84249998888889,lng:122.078358633999},
{lat:-2.84241202783097,lng:122.0786111},
{lat:-2.8423133697604,lng:122.078888877778},
{lat:-2.84223915433826,lng:122.079166655556},
{lat:-2.84222221111111,lng:122.079279145503},
{lat:-2.84218463320975,lng:122.079444433333},
{lat:-2.84222221111111,lng:122.079526582739},
{lat:-2.84231814042227,lng:122.079722211111},
{lat:-2.84226035919075,lng:122.079999988889},
{lat:-2.84249998888889,lng:122.080273931033},
{lat:-2.84277776666667,lng:122.080047333588},
{lat:-2.84280402153186,lng:122.079999988889},
{lat:-2.84297620631346,lng:122.079722211111},
{lat:-2.84305554444445,lng:122.079466061986},
{lat:-2.84305901800625,lng:122.079444433333},
{lat:-2.84313418558615,lng:122.079166655556},
{lat:-2.84318484730672,lng:122.078888877778},
{lat:-2.84323227712252,lng:122.0786111},
{lat:-2.84333332222222,lng:122.07855828494},
{lat:-2.8436111,lng:122.078409982575},
{lat:-2.84371984654416,lng:122.078333322222},
{lat:-2.84388887777778,lng:122.078254506068},
{lat:-2.84416665555556,lng:122.078328217385},
{lat:-2.8441693852508,lng:122.078333322222},
{lat:-2.84416665555556,lng:122.078356289784},
{lat:-2.84415377893543,lng:122.0786111},
{lat:-2.84416665555556,lng:122.078651536757},
{lat:-2.84426962119977,lng:122.078888877778},
{lat:-2.84438103710407,lng:122.079166655556},
{lat:-2.84444443333333,lng:122.079233150346},
{lat:-2.84472221111111,lng:122.07923878258},
{lat:-2.84499998888889,lng:122.079380867552},
{lat:-2.845089620047,lng:122.079444433333},
{lat:-2.84527776666667,lng:122.079598202013},
{lat:-2.84538082195914,lng:122.079444433333},
{lat:-2.845517173189,lng:122.079166655556},
{lat:-2.84555554444445,lng:122.079145063498},
{lat:-2.84583332222222,lng:122.079110672874},
{lat:-2.84590447828931,lng:122.079166655556},
{lat:-2.84609110659481,lng:122.079444433333},
{lat:-2.8461111,lng:122.079461153923},
{lat:-2.84616984727074,lng:122.079444433333},
{lat:-2.84638887777778,lng:122.079370169558},
{lat:-2.84666665555556,lng:122.079257485002},
{lat:-2.84675879975787,lng:122.079444433333},
{lat:-2.84678460254675,lng:122.079722211111},
{lat:-2.84684277588294,lng:122.079999988889},
{lat:-2.8468794978306,lng:122.080277766667},
{lat:-2.84686290281235,lng:122.080555544444},
{lat:-2.84691963480285,lng:122.080833322222},
{lat:-2.84694443333333,lng:122.080926362963},
{lat:-2.84698183433069,lng:122.0811111},
{lat:-2.84697135324332,lng:122.081388877778},
{lat:-2.84694443333333,lng:122.081487590203},
{lat:-2.84688588201622,lng:122.081666655556},
{lat:-2.84666665555556,lng:122.081911727685},
{lat:-2.84664474422931,lng:122.081944433333},
{lat:-2.84651956212909,lng:122.082222211111},
{lat:-2.84638887777778,lng:122.082373935901},
{lat:-2.84617632138484,lng:122.082499988889},
{lat:-2.84638887777778,lng:122.08269460046},
{lat:-2.84666665555556,lng:122.082524519042},
{lat:-2.84671991626795,lng:122.082499988889},
{lat:-2.84694443333333,lng:122.082351047405},
{lat:-2.84722221111111,lng:122.082465030884},
{lat:-2.84741471651002,lng:122.082499988889},
{lat:-2.84749998888889,lng:122.082518745701},
{lat:-2.84769566379604,lng:122.082777766667},
{lat:-2.84763201650234,lng:122.083055544444},
{lat:-2.84765728059465,lng:122.083333322222},
{lat:-2.84769517597249,lng:122.0836111},
{lat:-2.84777776666667,lng:122.083724771513},
{lat:-2.84805554444445,lng:122.083834320127},
{lat:-2.84821101951044,lng:122.083888877778},
{lat:-2.84833332222222,lng:122.083920354064},
{lat:-2.8486111,lng:122.084056581909},
{lat:-2.84881600887898,lng:122.084166655556},
{lat:-2.84888887777778,lng:122.084243525619},
{lat:-2.84900670647923,lng:122.084166655556},
{lat:-2.84916665555556,lng:122.08412923228},
{lat:-2.84925046078114,lng:122.084166655556},
{lat:-2.84944443333333,lng:122.084234754454},
{lat:-2.84972221111111,lng:122.084307157368},
{lat:-2.84999998888889,lng:122.084197409504},
{lat:-2.85013800520019,lng:122.084166655556},
{lat:-2.85027776666667,lng:122.084144463233},
{lat:-2.85051892923448,lng:122.083888877778},
{lat:-2.85055554444445,lng:122.083836159243},
{lat:-2.85080515749664,lng:122.083888877778},
{lat:-2.85083332222222,lng:122.083898413074},
{lat:-2.8511111,lng:122.084091402601},
{lat:-2.85116013354248,lng:122.084166655556},
{lat:-2.85138887777778,lng:122.084271966658},
{lat:-2.85165574388856,lng:122.084444433333},
{lat:-2.85166665555556,lng:122.084451074494},
{lat:-2.85194443333334,lng:122.084647441023},
{lat:-2.85213697423664,lng:122.084722211111},
{lat:-2.85222221111111,lng:122.084825328872},
{lat:-2.85246933576141,lng:122.084999988889},
{lat:-2.8524069783799,lng:122.085277766667},
{lat:-2.85245786721436,lng:122.085555544444},
{lat:-2.85249998888889,lng:122.085648382298},
{lat:-2.85258634808566,lng:122.085833322222},
{lat:-2.85257525627845,lng:122.0861111},
{lat:-2.85261950375645,lng:122.086388877778},
{lat:-2.85277776666667,lng:122.086628360579},
{lat:-2.8528273594689,lng:122.086666655556},
{lat:-2.85277776666667,lng:122.086687098818},
{lat:-2.85255971933146,lng:122.086944433333},
{lat:-2.85249998888889,lng:122.08702620127},
{lat:-2.85227468593465,lng:122.087222211111},
{lat:-2.85222255847551,lng:122.087499988889},
{lat:-2.85249998888889,lng:122.08761199906},
{lat:-2.85277776666667,lng:122.087678177648},
{lat:-2.85290367063103,lng:122.087777766667},
{lat:-2.85305554444445,lng:122.087900498524},
{lat:-2.85320020220304,lng:122.088055544444},
{lat:-2.85333224995157,lng:122.088333322222},
{lat:-2.85333332222222,lng:122.088333737724},
{lat:-2.85333386780065,lng:122.088333322222},
{lat:-2.8536111,lng:122.088108766184},
{lat:-2.85365239238098,lng:122.088055544444},
{lat:-2.85366217230475,lng:122.087777766667},
{lat:-2.85365304798485,lng:122.087499988889},
{lat:-2.85373974746222,lng:122.087222211111},
{lat:-2.85388887777778,lng:122.087011503983},
{lat:-2.85402776666667,lng:122.087011503983},]},
{ID:131,coord:[{lat:-2.85402776666667,lng:122.083325411681},
{lat:-2.85388887777778,lng:122.083325411681},
{lat:-2.85372843627317,lng:122.083055544444},
{lat:-2.8536111,lng:122.082809511701},
{lat:-2.85359098656206,lng:122.082777766667},
{lat:-2.85352359357915,lng:122.082499988889},
{lat:-2.85353183811786,lng:122.082222211111},
{lat:-2.85353614345707,lng:122.081944433333},
{lat:-2.85354543838323,lng:122.081666655556},
{lat:-2.8535964401976,lng:122.081388877778},
{lat:-2.85360382615346,lng:122.0811111},
{lat:-2.85358497807775,lng:122.080833322222},
{lat:-2.8536041688496,lng:122.080555544444},
{lat:-2.8536111,lng:122.080531156102},
{lat:-2.85368360241647,lng:122.080277766667},
{lat:-2.8537427374839,lng:122.079999988889},
{lat:-2.85384831629684,lng:122.079722211111},
{lat:-2.85388887777778,lng:122.0796635366},
{lat:-2.85402776666667,lng:122.0796635366},]},
{ID:132,coord:[{lat:-2.84491912323556,lng:122.063472211111},
{lat:-2.84491912323556,lng:122.0636111},
{lat:-2.84492329337995,lng:122.063888877778},
{lat:-2.84491611751886,lng:122.064166655556},
{lat:-2.84488531530502,lng:122.064444433333},
{lat:-2.84499998888889,lng:122.064681668088},
{lat:-2.84503738711676,lng:122.064722211111},
{lat:-2.84499998888889,lng:122.064849733196},
{lat:-2.84490347154047,lng:122.064999988889},
{lat:-2.84472221111111,lng:122.065116996072},
{lat:-2.84455613836556,lng:122.065277766667},
{lat:-2.84454287595272,lng:122.065555544444},
{lat:-2.84468503234681,lng:122.065833322222},
{lat:-2.84463652547726,lng:122.0661111},
{lat:-2.84455382004846,lng:122.066388877778},
{lat:-2.84456823103759,lng:122.066666655556},
{lat:-2.84452038674587,lng:122.066944433333},
{lat:-2.84448058506034,lng:122.067222211111},
{lat:-2.84466488947293,lng:122.067499988889},
{lat:-2.84472221111111,lng:122.06755218781},
{lat:-2.84478537643673,lng:122.067777766667},
{lat:-2.84484362024835,lng:122.068055544444},
{lat:-2.84472221111111,lng:122.06831576855},
{lat:-2.84468632534408,lng:122.068333322222},
{lat:-2.84444443333333,lng:122.068413324606},
{lat:-2.8442215950724,lng:122.0686111},
{lat:-2.84416665555556,lng:122.068682079142},
{lat:-2.84402501891188,lng:122.068888877778},
{lat:-2.84393145641256,lng:122.069166655556},
{lat:-2.84388887777778,lng:122.069244916101},
{lat:-2.84382163117096,lng:122.069444433333},
{lat:-2.84373793859813,lng:122.069722211111},
{lat:-2.8436111,lng:122.06992503967},
{lat:-2.84342354022074,lng:122.069999988889},
{lat:-2.84333332222222,lng:122.070081963448},
{lat:-2.84319394685502,lng:122.070277766667},
{lat:-2.84333332222222,lng:122.070345835967},
{lat:-2.84353541874635,lng:122.070555544444},
{lat:-2.84346266947742,lng:122.070833322222},
{lat:-2.8433550232421,lng:122.0711111},
{lat:-2.84333332222222,lng:122.071173926148},
{lat:-2.84305554444445,lng:122.071236787926},
{lat:-2.84299332323522,lng:122.071388877778},
{lat:-2.84305554444445,lng:122.07155376007},
{lat:-2.84309949195865,lng:122.071666655556},
{lat:-2.84305554444445,lng:122.071929327125},
{lat:-2.84304162204323,lng:122.071944433333},
{lat:-2.8429088989516,lng:122.072222211111},
{lat:-2.84279136069959,lng:122.072499988889},
{lat:-2.84277776666667,lng:122.072610260278},
{lat:-2.84275896477966,lng:122.072777766667},
{lat:-2.84269262036677,lng:122.073055544444},
{lat:-2.84266910150494,lng:122.073333322222},
{lat:-2.84263808362462,lng:122.0736111},
{lat:-2.84249998888889,lng:122.073867244333},
{lat:-2.84248804287766,lng:122.073888877778},
{lat:-2.84228660767619,lng:122.074166655556},
{lat:-2.84222221111111,lng:122.074285560841},
{lat:-2.84209107130175,lng:122.074444433333},
{lat:-2.84196657986148,lng:122.074722211111},
{lat:-2.8419453045084,lng:122.074999988889},
{lat:-2.84208547679869,lng:122.075277766667},
{lat:-2.84213119999653,lng:122.075555544444},
{lat:-2.84194443333333,lng:122.075827522592},
{lat:-2.84166665555556,lng:122.075798864803},
{lat:-2.84138887777778,lng:122.075793860828},
{lat:-2.8411111,lng:122.075603276983},
{lat:-2.84083332222222,lng:122.075702142278},
{lat:-2.84076580714878,lng:122.075833322222},
{lat:-2.84058052717055,lng:122.0761111},
{lat:-2.84055554444445,lng:122.076148477706},
{lat:-2.84030707521589,lng:122.076388877778},
{lat:-2.84027776666667,lng:122.076436721265},
{lat:-2.84019092042352,lng:122.076666655556},
{lat:-2.84017694221281,lng:122.076944433333},
{lat:-2.84026906969194,lng:122.077222211111},
{lat:-2.84027776666667,lng:122.077234828863},
{lat:-2.84043750864148,lng:122.077222211111},
{lat:-2.84055554444445,lng:122.07721701927},
{lat:-2.84081374052011,lng:122.076944433333},
{lat:-2.84083332222222,lng:122.076920905796},
{lat:-2.8411111,lng:122.076795732391},
{lat:-2.84138887777778,lng:122.076667120198},
{lat:-2.84140562745168,lng:122.076666655556},
{lat:-2.84166665555556,lng:122.076649520672},
{lat:-2.84173500355984,lng:122.076666655556},
{lat:-2.84194443333333,lng:122.076694779585},
{lat:-2.84222221111111,lng:122.076754089874},
{lat:-2.84249998888889,lng:122.076876849208},
{lat:-2.84254151342365,lng:122.076944433333},
{lat:-2.84277776666667,lng:122.077132303044},
{lat:-2.84288210784157,lng:122.077222211111},
{lat:-2.84303861531742,lng:122.077499988889},
{lat:-2.84305554444445,lng:122.077534944302},
{lat:-2.84333332222222,lng:122.077763352388},
{lat:-2.84353730282943,lng:122.077499988889},
{lat:-2.8436111,lng:122.077447152083},
{lat:-2.84368006551724,lng:122.077499988889},
{lat:-2.84371784153002,lng:122.077777766667},
{lat:-2.84388887777778,lng:122.077977628881},
{lat:-2.84407264643114,lng:122.077777766667},
{lat:-2.84407075729908,lng:122.077499988889},
{lat:-2.84416665555556,lng:122.077404235477},
{lat:-2.84431019137244,lng:122.077499988889},
{lat:-2.84444443333333,lng:122.077702064561},
{lat:-2.84472221111111,lng:122.077701795136},
{lat:-2.84499998888889,lng:122.077674471519},
{lat:-2.84527776666667,lng:122.077713897185},
{lat:-2.84544371119025,lng:122.077777766667},
{lat:-2.84527776666667,lng:122.077946515661},
{lat:-2.84499998888889,lng:122.077935986494},
{lat:-2.84487620114061,lng:122.078055544444},
{lat:-2.84472221111111,lng:122.07829925302},
{lat:-2.84470810585779,lng:122.078333322222},
{lat:-2.84472221111111,lng:122.078435723214},
{lat:-2.84475847144085,lng:122.0786111},
{lat:-2.84499998888889,lng:122.078846430896},
{lat:-2.84527776666667,lng:122.078774343061},
{lat:-2.84555554444445,lng:122.078739017079},
{lat:-2.84583332222222,lng:122.078701677143},
{lat:-2.8461111,lng:122.078783749146},
{lat:-2.84637673611522,lng:122.0786111},
{lat:-2.8463429177034,lng:122.078333322222},
{lat:-2.84638887777778,lng:122.078305753346},
{lat:-2.84641175773455,lng:122.078333322222},
{lat:-2.84639737275935,lng:122.0786111},
{lat:-2.84666665555556,lng:122.078754575276},
{lat:-2.84688003296804,lng:122.078888877778},
{lat:-2.84694443333333,lng:122.078958651801},
{lat:-2.84710994338668,lng:122.079166655556},
{lat:-2.8470799567503,lng:122.079444433333},
{lat:-2.84701212714825,lng:122.079722211111},
{lat:-2.84706690263004,lng:122.079999988889},
{lat:-2.84712019050214,lng:122.080277766667},
{lat:-2.84712138638882,lng:122.080555544444},
{lat:-2.84718107907277,lng:122.080833322222},
{lat:-2.84722221111111,lng:122.080975963705},
{lat:-2.8472787045611,lng:122.0811111},
{lat:-2.84728481389273,lng:122.081388877778},
{lat:-2.84722221111111,lng:122.081489369927},
{lat:-2.84717668849352,lng:122.081666655556},
{lat:-2.84721644732034,lng:122.081944433333},
{lat:-2.84722221111111,lng:122.08195241868},
{lat:-2.84749998888889,lng:122.0821312194},
{lat:-2.84777776666667,lng:122.081985877796},
{lat:-2.84788611734461,lng:122.081944433333},
{lat:-2.84805554444445,lng:122.081811139758},
{lat:-2.84815578067905,lng:122.081666655556},
{lat:-2.84826916117183,lng:122.081388877778},
{lat:-2.84833332222222,lng:122.081250824999},
{lat:-2.84839445406688,lng:122.081388877778},
{lat:-2.8486111,lng:122.081581077585},
{lat:-2.84888887777778,lng:122.081593828796},
{lat:-2.84894778080285,lng:122.081666655556},
{lat:-2.84904257377721,lng:122.081944433333},
{lat:-2.84888887777778,lng:122.082152783332},
{lat:-2.84881796030881,lng:122.082222211111},
{lat:-2.8486111,lng:122.082353312342},
{lat:-2.84847514874711,lng:122.082499988889},
{lat:-2.84833332222222,lng:122.082700546744},
{lat:-2.84826318724685,lng:122.082777766667},
{lat:-2.84812355351752,lng:122.083055544444},
{lat:-2.84812186196303,lng:122.083333322222},
{lat:-2.84833332222222,lng:122.083529516879},
{lat:-2.84842302257183,lng:122.0836111},
{lat:-2.8486111,lng:122.083709438782},
{lat:-2.84888887777778,lng:122.083762226604},
{lat:-2.84916665555556,lng:122.083771131932},
{lat:-2.84944443333333,lng:122.083844600205},
{lat:-2.84972221111111,lng:122.083875843102},
{lat:-2.84999998888889,lng:122.083809081695},
{lat:-2.85027776666667,lng:122.083783230617},
{lat:-2.85039204372796,lng:122.0836111},
{lat:-2.85054242540547,lng:122.083333322222},
{lat:-2.85055554444445,lng:122.083279255316},
{lat:-2.85060836470849,lng:122.083055544444},
{lat:-2.85064049023233,lng:122.082777766667},
{lat:-2.85083332222222,lng:122.082614862085},
{lat:-2.85105593139014,lng:122.082499988889},
{lat:-2.85100424731139,lng:122.082222211111},
{lat:-2.85103916024811,lng:122.081944433333},
{lat:-2.85105048133829,lng:122.081666655556},
{lat:-2.85104512991453,lng:122.081388877778},
{lat:-2.8511111,lng:122.081127777153},
{lat:-2.85127585623292,lng:122.081388877778},
{lat:-2.85138887777778,lng:122.081510151463},
{lat:-2.85144803382879,lng:122.081388877778},
{lat:-2.85143630239346,lng:122.0811111},
{lat:-2.85145648308647,lng:122.080833322222},
{lat:-2.85153533074501,lng:122.080555544444},
{lat:-2.85166665555556,lng:122.080384316859},
{lat:-2.85187664206835,lng:122.080555544444},
{lat:-2.85183387704526,lng:122.080833322222},
{lat:-2.85194443333334,lng:122.080923546476},
{lat:-2.85218871222054,lng:122.0811111},
{lat:-2.85222221111111,lng:122.081368350917},
{lat:-2.85234447439596,lng:122.0811111},
{lat:-2.85222221111111,lng:122.081013677071},
{lat:-2.85212625701736,lng:122.080833322222},
{lat:-2.85206077573447,lng:122.080555544444},
{lat:-2.85215107055922,lng:122.080277766667},
{lat:-2.85222221111111,lng:122.080248697264},
{lat:-2.85224044901496,lng:122.080277766667},
{lat:-2.85249998888889,lng:122.080448647033},
{lat:-2.85259461824036,lng:122.080555544444},
{lat:-2.85257118609469,lng:122.080833322222},
{lat:-2.85251369919912,lng:122.0811111},
{lat:-2.85252016364167,lng:122.081388877778},
{lat:-2.85259348890083,lng:122.081666655556},
{lat:-2.85277776666667,lng:122.081839866965},
{lat:-2.85305554444445,lng:122.08188130419},
{lat:-2.85327922817689,lng:122.081944433333},
{lat:-2.85333332222222,lng:122.081976332727},
{lat:-2.85334347651336,lng:122.081944433333},
{lat:-2.8533743233953,lng:122.081666655556},
{lat:-2.85342930182204,lng:122.081388877778},
{lat:-2.85344484175943,lng:122.0811111},
{lat:-2.85340604105366,lng:122.080833322222},
{lat:-2.85339795862945,lng:122.080555544444},
{lat:-2.85350167354126,lng:122.080277766667},
{lat:-2.85354089906306,lng:122.079999988889},
{lat:-2.85359167316537,lng:122.079722211111},
{lat:-2.8536111,lng:122.079668844977},
{lat:-2.85376006800859,lng:122.079444433333},
{lat:-2.85388887777778,lng:122.079322711505},
{lat:-2.85402776666667,lng:122.079322711505},]},
{ID:133,coord:[{lat:-2.85402776666667,lng:122.083586872554},
{lat:-2.85388887777778,lng:122.083586872554},
{lat:-2.85373864184227,lng:122.083333322222},
{lat:-2.8536111,lng:122.083127847103},
{lat:-2.85355404053004,lng:122.083055544444},
{lat:-2.85337136007187,lng:122.082777766667},
{lat:-2.85333332222222,lng:122.082717743424},
{lat:-2.8531043276485,lng:122.082777766667},
{lat:-2.85305554444445,lng:122.082784192426},
{lat:-2.85277776666667,lng:122.082949999968},
{lat:-2.85269072550543,lng:122.083055544444},
{lat:-2.85264890004589,lng:122.083333322222},
{lat:-2.85264272819828,lng:122.0836111},
{lat:-2.85267101199532,lng:122.083888877778},
{lat:-2.85271256998656,lng:122.084166655556},
{lat:-2.85277776666667,lng:122.084253107257},
{lat:-2.8528015403103,lng:122.084444433333},
{lat:-2.8529028879416,lng:122.084722211111},
{lat:-2.85304344474394,lng:122.084999988889},
{lat:-2.85303378873446,lng:122.085277766667},
{lat:-2.85305554444445,lng:122.085309286331},
{lat:-2.85312374500767,lng:122.085555544444},
{lat:-2.85327121175306,lng:122.085833322222},
{lat:-2.85333332222222,lng:122.085944323947},
{lat:-2.85342165618967,lng:122.0861111},
{lat:-2.8536111,lng:122.08625236851},
{lat:-2.85379196791358,lng:122.086388877778},
{lat:-2.85388887777778,lng:122.086490157881},
{lat:-2.85402776666667,lng:122.086490157881},]},
{ID:134,coord:[{lat:-2.8451015220355,lng:122.063472211111},
{lat:-2.8451015220355,lng:122.0636111},
{lat:-2.84507112139324,lng:122.063888877778},
{lat:-2.8450356561044,lng:122.064166655556},
{lat:-2.84504971134632,lng:122.064444433333},
{lat:-2.84527776666667,lng:122.064685819319},
{lat:-2.84555554444445,lng:122.064660000372},
{lat:-2.8456733889451,lng:122.064722211111},
{lat:-2.84582554466301,lng:122.064999988889},
{lat:-2.84555554444445,lng:122.065122513535},
{lat:-2.84527776666667,lng:122.065087305015},
{lat:-2.84499998888889,lng:122.065276743017},
{lat:-2.84499855988492,lng:122.065277766667},
{lat:-2.84483807368798,lng:122.065555544444},
{lat:-2.84491493785677,lng:122.065833322222},
{lat:-2.84487608501499,lng:122.0661111},
{lat:-2.84483323636444,lng:122.066388877778},
{lat:-2.84479508442,lng:122.066666655556},
{lat:-2.84472221111111,lng:122.06692217493},
{lat:-2.84471701341754,lng:122.066944433333},
{lat:-2.84472221111111,lng:122.067068229954},
{lat:-2.84473245796382,lng:122.067222211111},
{lat:-2.84489426835579,lng:122.067499988889},
{lat:-2.84493488534959,lng:122.067777766667},
{lat:-2.84499998888889,lng:122.067997266675},
{lat:-2.84504424492626,lng:122.068055544444},
{lat:-2.84514154548776,lng:122.068333322222},
{lat:-2.84499998888889,lng:122.068502306812},
{lat:-2.84472221111111,lng:122.06855984642},
{lat:-2.84458719194296,lng:122.0686111},
{lat:-2.84444443333333,lng:122.068732882918},
{lat:-2.84433440774855,lng:122.068888877778},
{lat:-2.84426994600764,lng:122.069166655556},
{lat:-2.84425693574915,lng:122.069444433333},
{lat:-2.84416665555556,lng:122.069577914352},
{lat:-2.843996069147,lng:122.069722211111},
{lat:-2.84388887777778,lng:122.069815577151},
{lat:-2.84382456722357,lng:122.069999988889},
{lat:-2.84385278065212,lng:122.070277766667},
{lat:-2.84376957291732,lng:122.070555544444},
{lat:-2.84380620429753,lng:122.070833322222},
{lat:-2.84382148379492,lng:122.0711111},
{lat:-2.8436111,lng:122.071242079082},
{lat:-2.84356316822148,lng:122.071388877778},
{lat:-2.84345038329351,lng:122.071666655556},
{lat:-2.84346619105839,lng:122.071944433333},
{lat:-2.84338501835087,lng:122.072222211111},
{lat:-2.84333332222222,lng:122.072409362228},
{lat:-2.84305554444445,lng:122.072377798088},
{lat:-2.84299472504897,lng:122.072499988889},
{lat:-2.8429168336364,lng:122.072777766667},
{lat:-2.8428479035653,lng:122.073055544444},
{lat:-2.84284479603063,lng:122.073333322222},
{lat:-2.84287021499644,lng:122.0736111},
{lat:-2.84277776666667,lng:122.073736421584},
{lat:-2.84267106923771,lng:122.073888877778},
{lat:-2.84249998888889,lng:122.074079564047},
{lat:-2.84243506632135,lng:122.074166655556},
{lat:-2.8422888124754,lng:122.074444433333},
{lat:-2.84222221111111,lng:122.074570556307},
{lat:-2.84213637336505,lng:122.074722211111},
{lat:-2.84211185447624,lng:122.074999988889},
{lat:-2.84222221111111,lng:122.075139836192},
{lat:-2.8424835434955,lng:122.075277766667},
{lat:-2.84248717007772,lng:122.075555544444},
{lat:-2.84249998888889,lng:122.075673112358},
{lat:-2.84251046742338,lng:122.075833322222},
{lat:-2.84257155295004,lng:122.0761111},
{lat:-2.84260914026222,lng:122.076388877778},
{lat:-2.84277776666667,lng:122.076652387018},
{lat:-2.84280207179395,lng:122.076666655556},
{lat:-2.84305554444445,lng:122.076832906984},
{lat:-2.84325721677314,lng:122.076944433333},
{lat:-2.84333332222222,lng:122.076998254714},
{lat:-2.8436111,lng:122.077084192075},
{lat:-2.84388887777778,lng:122.077079381383},
{lat:-2.84416665555556,lng:122.077050556476},
{lat:-2.84444443333333,lng:122.076985554243},
{lat:-2.84469912505038,lng:122.077222211111},
{lat:-2.84472221111111,lng:122.077256648661},
{lat:-2.84499998888889,lng:122.077293796008},
{lat:-2.84527776666667,lng:122.077328954207},
{lat:-2.84555554444445,lng:122.077339179917},
{lat:-2.84583332222222,lng:122.077455828283},
{lat:-2.8461111,lng:122.077373465255},
{lat:-2.846286191183,lng:122.077222211111},
{lat:-2.84638887777778,lng:122.077152291266},
{lat:-2.84662779659365,lng:122.077222211111},
{lat:-2.84648375396095,lng:122.077499988889},
{lat:-2.84648308038713,lng:122.077777766667},
{lat:-2.84660782709122,lng:122.078055544444},
{lat:-2.84666665555556,lng:122.078126825333},
{lat:-2.84683784911646,lng:122.078333322222},
{lat:-2.84694443333333,lng:122.078447741973},
{lat:-2.84711480587956,lng:122.0786111},
{lat:-2.84722221111111,lng:122.078739166608},
{lat:-2.84749998888889,lng:122.07888079561},
{lat:-2.84751671517798,lng:122.078888877778},
{lat:-2.84757398721292,lng:122.079166655556},
{lat:-2.84749998888889,lng:122.079217424149},
{lat:-2.84738638798969,lng:122.079444433333},
{lat:-2.84729350115825,lng:122.079722211111},
{lat:-2.84733147076131,lng:122.079999988889},
{lat:-2.84746482045423,lng:122.080277766667},
{lat:-2.84749998888889,lng:122.080372528843},
{lat:-2.84757572498107,lng:122.080555544444},
{lat:-2.84757536617152,lng:122.080833322222},
{lat:-2.84777776666667,lng:122.081015002022},
{lat:-2.84805554444445,lng:122.080833948606},
{lat:-2.84805598419301,lng:122.080833322222},
{lat:-2.84820157600243,lng:122.080555544444},
{lat:-2.84833332222222,lng:122.080296784107},
{lat:-2.8486111,lng:122.08053630382},
{lat:-2.84863080035212,lng:122.080555544444},
{lat:-2.84888887777778,lng:122.080746228711},
{lat:-2.84905046640544,lng:122.080833322222},
{lat:-2.84916665555556,lng:122.08094573295},
{lat:-2.84932958476951,lng:122.0811111},
{lat:-2.84940991213925,lng:122.081388877778},
{lat:-2.8494117211791,lng:122.081666655556},
{lat:-2.84944443333333,lng:122.081905458108},
{lat:-2.84945987173112,lng:122.081944433333},
{lat:-2.84962135668023,lng:122.082222211111},
{lat:-2.84972221111111,lng:122.082451126108},
{lat:-2.84986218615892,lng:122.082222211111},
{lat:-2.84999998888889,lng:122.082141067589},
{lat:-2.85011287005487,lng:122.081944433333},
{lat:-2.85018557537476,lng:122.081666655556},
{lat:-2.85027776666667,lng:122.081525417118},
{lat:-2.8503949629582,lng:122.081388877778},
{lat:-2.85055554444445,lng:122.081249181865},
{lat:-2.85083332222222,lng:122.081119177844},
{lat:-2.85083783371378,lng:122.0811111},
{lat:-2.85089838791497,lng:122.080833322222},
{lat:-2.8511111,lng:122.080604491278},
{lat:-2.85119004087143,lng:122.080555544444},
{lat:-2.8512125728821,lng:122.080277766667},
{lat:-2.85138887777778,lng:122.080082925633},
{lat:-2.85166665555556,lng:122.080089564875},
{lat:-2.85176399901199,lng:122.079999988889},
{lat:-2.85194443333334,lng:122.079853409279},
{lat:-2.85222221111111,lng:122.079784837288},
{lat:-2.85235125250177,lng:122.079999988889},
{lat:-2.85249998888889,lng:122.080184673022},
{lat:-2.85258988166779,lng:122.080277766667},
{lat:-2.85277776666667,lng:122.08049208334},
{lat:-2.85292184399952,lng:122.080555544444},
{lat:-2.85277776666667,lng:122.080798089052},
{lat:-2.85277135855773,lng:122.080833322222},
{lat:-2.8527100967709,lng:122.0811111},
{lat:-2.85276612178555,lng:122.081388877778},
{lat:-2.85277776666667,lng:122.081419108462},
{lat:-2.85305554444445,lng:122.081526792661},
{lat:-2.85315969368715,lng:122.081388877778},
{lat:-2.8532325186707,lng:122.0811111},
{lat:-2.85317602606038,lng:122.080833322222},
{lat:-2.85311929669012,lng:122.080555544444},
{lat:-2.85333332222222,lng:122.080278257225},
{lat:-2.85333352645361,lng:122.080277766667},
{lat:-2.85338048006242,lng:122.079999988889},
{lat:-2.85343057930027,lng:122.079722211111},
{lat:-2.85353901953766,lng:122.079444433333},
{lat:-2.8536111,lng:122.079313603165},
{lat:-2.85376620885246,lng:122.079166655556},
{lat:-2.85388887777778,lng:122.079058034298},
{lat:-2.85402776666667,lng:122.079058034298},]},
{ID:135,coord:[{lat:-2.85402776666667,lng:122.083942463106},
{lat:-2.85388887777778,lng:122.083942463106},
{lat:-2.85386606348017,lng:122.083888877778},
{lat:-2.85373410090723,lng:122.0836111},
{lat:-2.8536111,lng:122.083387632127},
{lat:-2.85356950921703,lng:122.083333322222},
{lat:-2.85335743849102,lng:122.083055544444},
{lat:-2.85333332222222,lng:122.083019657423},
{lat:-2.85305554444445,lng:122.082958352305},
{lat:-2.85293339583228,lng:122.083055544444},
{lat:-2.8528457961311,lng:122.083333322222},
{lat:-2.85285072810153,lng:122.0836111},
{lat:-2.85286498622147,lng:122.083888877778},
{lat:-2.85289587712521,lng:122.084166655556},
{lat:-2.85295932503739,lng:122.084444433333},
{lat:-2.85305554444445,lng:122.084586374515},
{lat:-2.85312930762002,lng:122.084722211111},
{lat:-2.8531907141824,lng:122.084999988889},
{lat:-2.85325816320925,lng:122.085277766667},
{lat:-2.85333332222222,lng:122.08542325606},
{lat:-2.85338210321949,lng:122.085555544444},
{lat:-2.85348522605647,lng:122.085833322222},
{lat:-2.8536111,lng:122.085996132886},
{lat:-2.85369251060524,lng:122.0861111},
{lat:-2.85388887777778,lng:122.086282554762},
{lat:-2.85402776666667,lng:122.086282554762},]},
{ID:136,coord:[{lat:-2.84525366111053,lng:122.063472211111},
{lat:-2.84525366111053,lng:122.0636111},
{lat:-2.84521337251022,lng:122.063888877778},
{lat:-2.84517852727427,lng:122.064166655556},
{lat:-2.84525523710917,lng:122.064444433333},
{lat:-2.84527776666667,lng:122.064468279826},
{lat:-2.84551081608456,lng:122.064444433333},
{lat:-2.84555554444445,lng:122.064440111325},
{lat:-2.84556934674363,lng:122.064444433333},
{lat:-2.84583332222222,lng:122.064554125651},
{lat:-2.8461111,lng:122.064720175399},
{lat:-2.84611481127774,lng:122.064722211111},
{lat:-2.84638761561349,lng:122.064999988889},
{lat:-2.8461111,lng:122.06509909727},
{lat:-2.84591419867697,lng:122.065277766667},
{lat:-2.84583332222222,lng:122.065323977967},
{lat:-2.84555554444445,lng:122.065344270222},
{lat:-2.84527776666667,lng:122.065363183513},
{lat:-2.84514511934082,lng:122.065555544444},
{lat:-2.84515873613608,lng:122.065833322222},
{lat:-2.84512465600651,lng:122.0661111},
{lat:-2.84509006387858,lng:122.066388877778},
{lat:-2.84503166535415,lng:122.066666655556},
{lat:-2.84499998888889,lng:122.066843382469},
{lat:-2.84497794860682,lng:122.066944433333},
{lat:-2.84499998888889,lng:122.067047806671},
{lat:-2.84502561099808,lng:122.067222211111},
{lat:-2.8450736357716,lng:122.067499988889},
{lat:-2.8451262417123,lng:122.067777766667},
{lat:-2.84527776666667,lng:122.067954073839},
{lat:-2.84538991393389,lng:122.068055544444},
{lat:-2.84542530736599,lng:122.068333322222},
{lat:-2.84528395643906,lng:122.0686111},
{lat:-2.84527776666667,lng:122.068621349813},
{lat:-2.84506234753361,lng:122.068888877778},
{lat:-2.84499998888889,lng:122.069035880329},
{lat:-2.8448905021329,lng:122.069166655556},
{lat:-2.84493563305114,lng:122.069444433333},
{lat:-2.84499998888889,lng:122.069528423426},
{lat:-2.84510084451124,lng:122.069722211111},
{lat:-2.84499998888889,lng:122.069839914396},
{lat:-2.84485548229992,lng:122.069999988889},
{lat:-2.84472221111111,lng:122.070107872299},
{lat:-2.84462424882903,lng:122.070277766667},
{lat:-2.84444443333333,lng:122.070554622635},
{lat:-2.84444408244864,lng:122.070555544444},
{lat:-2.84439657231402,lng:122.070833322222},
{lat:-2.84417111564414,lng:122.0711111},
{lat:-2.84416665555556,lng:122.071128558101},
{lat:-2.84410262813977,lng:122.071388877778},
{lat:-2.84391189845721,lng:122.071666655556},
{lat:-2.84388887777778,lng:122.071795601709},
{lat:-2.84385782724243,lng:122.071944433333},
{lat:-2.84380191876988,lng:122.072222211111},
{lat:-2.84372901398798,lng:122.072499988889},
{lat:-2.84365690960639,lng:122.072777766667},
{lat:-2.8436111,lng:122.073052612794},
{lat:-2.84360972239074,lng:122.073055544444},
{lat:-2.8436111,lng:122.073082923845},
{lat:-2.84361828776172,lng:122.073333322222},
{lat:-2.8436453502288,lng:122.0736111},
{lat:-2.8436111,lng:122.073820161622},
{lat:-2.843594604052,lng:122.073888877778},
{lat:-2.84333332222222,lng:122.073987376301},
{lat:-2.84323581291442,lng:122.073888877778},
{lat:-2.84305554444445,lng:122.073619663259},
{lat:-2.84290147920493,lng:122.073888877778},
{lat:-2.84277776666667,lng:122.074001493457},
{lat:-2.84263549171371,lng:122.074166655556},
{lat:-2.84249998888889,lng:122.07434114452},
{lat:-2.8424472243959,lng:122.074444433333},
{lat:-2.84237460560766,lng:122.074722211111},
{lat:-2.84249998888889,lng:122.074910123464},
{lat:-2.84254032217389,lng:122.074999988889},
{lat:-2.84265009963118,lng:122.075277766667},
{lat:-2.84273053903385,lng:122.075555544444},
{lat:-2.8427011809415,lng:122.075833322222},
{lat:-2.84277776666667,lng:122.076019599313},
{lat:-2.8428666395511,lng:122.0761111},
{lat:-2.84305554444445,lng:122.076315011588},
{lat:-2.84333332222222,lng:122.076139186843},
{lat:-2.84349439066947,lng:122.076388877778},
{lat:-2.8436111,lng:122.076518123724},
{lat:-2.84388887777778,lng:122.076645104504},
{lat:-2.84402383221009,lng:122.076666655556},
{lat:-2.84416665555556,lng:122.076680129068},
{lat:-2.84425258742276,lng:122.076666655556},
{lat:-2.84444443333333,lng:122.076636381934},
{lat:-2.84472221111111,lng:122.076480689539},
{lat:-2.84499998888889,lng:122.076503530766},
{lat:-2.84511561587929,lng:122.076666655556},
{lat:-2.84527776666667,lng:122.076858782119},
{lat:-2.84542780363082,lng:122.076666655556},
{lat:-2.84555554444445,lng:122.07658467409},
{lat:-2.84583332222222,lng:122.076657331277},
{lat:-2.84587586698586,lng:122.076666655556},
{lat:-2.84583332222222,lng:122.07668406269},
{lat:-2.84574882613473,lng:122.076944433333},
{lat:-2.84583332222222,lng:122.077062748863},
{lat:-2.8461111,lng:122.076986229314},
{lat:-2.84616349653006,lng:122.076944433333},
{lat:-2.84619362577777,lng:122.076666655556},
{lat:-2.8461111,lng:122.076410482119},
{lat:-2.84610667283872,lng:122.076388877778},
{lat:-2.84598642365809,lng:122.0761111},
{lat:-2.84596087106778,lng:122.075833322222},
{lat:-2.8461111,lng:122.075660990208},
{lat:-2.84638887777778,lng:122.07576522151},
{lat:-2.84666665555556,lng:122.07579107957},
{lat:-2.84678405045489,lng:122.075833322222},
{lat:-2.84694443333333,lng:122.075878787997},
{lat:-2.84722221111111,lng:122.075930945122},
{lat:-2.84749998888889,lng:122.076007348004},
{lat:-2.84777776666667,lng:122.076034802696},
{lat:-2.84805554444445,lng:122.075938535991},
{lat:-2.84816736132163,lng:122.0761111},
{lat:-2.84815494675495,lng:122.076388877778},
{lat:-2.84816205110977,lng:122.076666655556},
{lat:-2.84818618353846,lng:122.076944433333},
{lat:-2.84827757449716,lng:122.077222211111},
{lat:-2.84805554444445,lng:122.077414271712},
{lat:-2.84783069261265,lng:122.077222211111},
{lat:-2.84777776666667,lng:122.077201622856},
{lat:-2.84755766273044,lng:122.076944433333},
{lat:-2.84749998888889,lng:122.076907005915},
{lat:-2.84732856281598,lng:122.076944433333},
{lat:-2.84722221111111,lng:122.076998775786},
{lat:-2.84709456249188,lng:122.077222211111},
{lat:-2.84720385649271,lng:122.077499988889},
{lat:-2.84722221111111,lng:122.077536795077},
{lat:-2.84749998888889,lng:122.077678301534},
{lat:-2.84756755300246,lng:122.077777766667},
{lat:-2.84767936469856,lng:122.078055544444},
{lat:-2.84777776666667,lng:122.078138775651},
{lat:-2.84798654685197,lng:122.078333322222},
{lat:-2.84805554444445,lng:122.078496663422},
{lat:-2.84808407055062,lng:122.0786111},
{lat:-2.84811912683446,lng:122.078888877778},
{lat:-2.84818453608074,lng:122.079166655556},
{lat:-2.84816486957843,lng:122.079444433333},
{lat:-2.84816764708482,lng:122.079722211111},
{lat:-2.84833332222222,lng:122.079860408873},
{lat:-2.8486111,lng:122.079957946955},
{lat:-2.84865332663454,lng:122.079999988889},
{lat:-2.8488628535227,lng:122.080277766667},
{lat:-2.84888887777778,lng:122.080302814147},
{lat:-2.84916665555556,lng:122.080314272972},
{lat:-2.84931563154393,lng:122.080555544444},
{lat:-2.84944443333333,lng:122.08069699996},
{lat:-2.84971484174039,lng:122.080833322222},
{lat:-2.84972221111111,lng:122.08083758116},
{lat:-2.84973218992875,lng:122.080833322222},
{lat:-2.84999998888889,lng:122.080737224826},
{lat:-2.85027776666667,lng:122.080716314369},
{lat:-2.85055554444445,lng:122.080665911555},
{lat:-2.85082619119153,lng:122.080555544444},
{lat:-2.85083332222222,lng:122.080530914175},
{lat:-2.85085564389416,lng:122.080277766667},
{lat:-2.8511111,lng:122.080133170362},
{lat:-2.85123993781048,lng:122.079999988889},
{lat:-2.85138887777778,lng:122.079883239491},
{lat:-2.85166665555556,lng:122.079818473673},
{lat:-2.85178791301711,lng:122.079722211111},
{lat:-2.85194443333334,lng:122.079643761116},
{lat:-2.85222221111111,lng:122.079518126818},
{lat:-2.85243540583272,lng:122.079722211111},
{lat:-2.85249998888889,lng:122.079849655106},
{lat:-2.85257379740656,lng:122.079999988889},
{lat:-2.85277776666667,lng:122.080211189641},
{lat:-2.85305554444445,lng:122.080201994663},
{lat:-2.8531889671289,lng:122.079999988889},
{lat:-2.85327282991688,lng:122.079722211111},
{lat:-2.85333332222222,lng:122.079585103512},
{lat:-2.85339277889683,lng:122.079444433333},
{lat:-2.85354491291852,lng:122.079166655556},
{lat:-2.8536111,lng:122.079076443168},
{lat:-2.85381796242021,lng:122.078888877778},
{lat:-2.85388887777778,lng:122.078801732759},
{lat:-2.85402776666667,lng:122.078801732759},]},
{ID:137,coord:[{lat:-2.85402776666667,lng:122.084381884549},
{lat:-2.85388887777778,lng:122.084381884549},
{lat:-2.85379598999874,lng:122.084166655556},
{lat:-2.85368803883228,lng:122.083888877778},
{lat:-2.8536111,lng:122.083720092007},
{lat:-2.85354145774285,lng:122.0836111},
{lat:-2.85333332222222,lng:122.083334986149},
{lat:-2.85333094593214,lng:122.083333322222},
{lat:-2.85305554444445,lng:122.083175374144},
{lat:-2.85297833687338,lng:122.083333322222},
{lat:-2.85295836556562,lng:122.0836111},
{lat:-2.85297077890011,lng:122.083888877778},
{lat:-2.85302261619033,lng:122.084166655556},
{lat:-2.85305554444445,lng:122.08427768659},
{lat:-2.85312590864368,lng:122.084444433333},
{lat:-2.85325570367385,lng:122.084722211111},
{lat:-2.85332949359108,lng:122.084999988889},
{lat:-2.85333332222222,lng:122.085011477497},
{lat:-2.8534496116174,lng:122.085277766667},
{lat:-2.85353089920171,lng:122.085555544444},
{lat:-2.8536111,lng:122.085733632744},
{lat:-2.85368758427472,lng:122.085833322222},
{lat:-2.85385761530726,lng:122.0861111},
{lat:-2.85388887777778,lng:122.086138396311},
{lat:-2.85402776666667,lng:122.086138396311},]},
{ID:138,coord:[{lat:-2.84546473250471,lng:122.063472211111},
{lat:-2.84546473250471,lng:122.0636111},
{lat:-2.84539841963207,lng:122.063888877778},
{lat:-2.84541103815264,lng:122.064166655556},
{lat:-2.84555554444445,lng:122.064234681116},
{lat:-2.84583332222222,lng:122.064323156411},
{lat:-2.8461111,lng:122.064305905942},
{lat:-2.84633821085108,lng:122.064444433333},
{lat:-2.84638887777778,lng:122.06447658425},
{lat:-2.84666665555556,lng:122.064596713541},
{lat:-2.84694443333333,lng:122.064703457831},
{lat:-2.84698225691017,lng:122.064722211111},
{lat:-2.84694443333333,lng:122.064757703264},
{lat:-2.84680662492073,lng:122.064999988889},
{lat:-2.84666665555556,lng:122.06512790716},
{lat:-2.84645783872538,lng:122.065277766667},
{lat:-2.84638887777778,lng:122.065316461499},
{lat:-2.8461111,lng:122.065367764108},
{lat:-2.84583332222222,lng:122.065498777328},
{lat:-2.84563490632747,lng:122.065555544444},
{lat:-2.84555554444445,lng:122.065600280317},
{lat:-2.84540046599739,lng:122.065833322222},
{lat:-2.8453545466086,lng:122.0661111},
{lat:-2.84531337768033,lng:122.066388877778},
{lat:-2.84527776666667,lng:122.066555691929},
{lat:-2.84524753225661,lng:122.066666655556},
{lat:-2.8451987740312,lng:122.066944433333},
{lat:-2.84520661071217,lng:122.067222211111},
{lat:-2.8452389038531,lng:122.067499988889},
{lat:-2.84527776666667,lng:122.067617134391},
{lat:-2.84540445910877,lng:122.067777766667},
{lat:-2.84555554444445,lng:122.067909862627},
{lat:-2.84568239586725,lng:122.068055544444},
{lat:-2.84563722028691,lng:122.068333322222},
{lat:-2.84555554444445,lng:122.068550304347},
{lat:-2.84553019470765,lng:122.0686111},
{lat:-2.8453712309668,lng:122.068888877778},
{lat:-2.84527776666667,lng:122.069139299562},
{lat:-2.84526575280828,lng:122.069166655556},
{lat:-2.84527776666667,lng:122.069204466211},
{lat:-2.84536789810171,lng:122.069444433333},
{lat:-2.84555554444445,lng:122.069690095},
{lat:-2.84558192538704,lng:122.069722211111},
{lat:-2.84555554444445,lng:122.069880609252},
{lat:-2.84553644275998,lng:122.069999988889},
{lat:-2.84534307155097,lng:122.070277766667},
{lat:-2.84527776666667,lng:122.070333845651},
{lat:-2.84501261415424,lng:122.070555544444},
{lat:-2.84499998888889,lng:122.07056616895},
{lat:-2.84478649741324,lng:122.070833322222},
{lat:-2.84473670660309,lng:122.0711111},
{lat:-2.844755585224,lng:122.071388877778},
{lat:-2.84472221111111,lng:122.071496732941},
{lat:-2.84444443333333,lng:122.071647187411},
{lat:-2.84442273190597,lng:122.071666655556},
{lat:-2.84420673140701,lng:122.071944433333},
{lat:-2.84416665555556,lng:122.072061277781},
{lat:-2.84413913443717,lng:122.072222211111},
{lat:-2.84416665555556,lng:122.072358745455},
{lat:-2.84424576942776,lng:122.072499988889},
{lat:-2.84416895978838,lng:122.072777766667},
{lat:-2.84416665555556,lng:122.072781741651},
{lat:-2.84405437273583,lng:122.073055544444},
{lat:-2.84409264805083,lng:122.073333322222},
{lat:-2.8441062386096,lng:122.0736111},
{lat:-2.84407143023899,lng:122.073888877778},
{lat:-2.84396234321257,lng:122.074166655556},
{lat:-2.84388887777778,lng:122.074348814956},
{lat:-2.8438586966757,lng:122.074444433333},
{lat:-2.84378856789702,lng:122.074722211111},
{lat:-2.84388887777778,lng:122.074868136778},
{lat:-2.84408341383939,lng:122.074999988889},
{lat:-2.84416665555556,lng:122.075134727711},
{lat:-2.8441821332258,lng:122.075277766667},
{lat:-2.84424510251276,lng:122.075555544444},
{lat:-2.84444443333333,lng:122.075787525598},
{lat:-2.84458023145783,lng:122.075833322222},
{lat:-2.84472221111111,lng:122.075938857176},
{lat:-2.84499998888889,lng:122.075981045194},
{lat:-2.84504850881021,lng:122.0761111},
{lat:-2.84515391113731,lng:122.076388877778},
{lat:-2.84527776666667,lng:122.076535609339},
{lat:-2.84551544466501,lng:122.076388877778},
{lat:-2.84555554444445,lng:122.076334897383},
{lat:-2.84568013657212,lng:122.0761111},
{lat:-2.84561980511364,lng:122.075833322222},
{lat:-2.84555554444445,lng:122.075648717087},
{lat:-2.84547072282169,lng:122.075555544444},
{lat:-2.84555554444445,lng:122.075501128545},
{lat:-2.84583332222222,lng:122.075309020395},
{lat:-2.8461111,lng:122.075301421422},
{lat:-2.84638887777778,lng:122.075382336103},
{lat:-2.84666665555556,lng:122.075419438557},
{lat:-2.84676132324571,lng:122.075555544444},
{lat:-2.84694443333333,lng:122.075680398409},
{lat:-2.84722221111111,lng:122.07563237889},
{lat:-2.84748473219115,lng:122.075833322222},
{lat:-2.84749998888889,lng:122.075839050441},
{lat:-2.84777776666667,lng:122.075879750379},
{lat:-2.84788247102643,lng:122.075833322222},
{lat:-2.84805554444445,lng:122.075739120768},
{lat:-2.84829549918404,lng:122.075833322222},
{lat:-2.84830235164804,lng:122.0761111},
{lat:-2.84828269670439,lng:122.076388877778},
{lat:-2.84833332222222,lng:122.076664028114},
{lat:-2.84833592307883,lng:122.076666655556},
{lat:-2.84847157275115,lng:122.076944433333},
{lat:-2.84855400281917,lng:122.077222211111},
{lat:-2.8486111,lng:122.077352340925},
{lat:-2.84865465940849,lng:122.077499988889},
{lat:-2.84876893531758,lng:122.077777766667},
{lat:-2.84888887777778,lng:122.078023973658},
{lat:-2.84891376730291,lng:122.078055544444},
{lat:-2.84904877476179,lng:122.078333322222},
{lat:-2.84902736125,lng:122.0786111},
{lat:-2.84916665555556,lng:122.078857323405},
{lat:-2.84919695599719,lng:122.078888877778},
{lat:-2.84944443333333,lng:122.079088939722},
{lat:-2.84959699731476,lng:122.079166655556},
{lat:-2.84972221111111,lng:122.079249387429},
{lat:-2.84987878716748,lng:122.079444433333},
{lat:-2.84999998888889,lng:122.079528811486},
{lat:-2.85027776666667,lng:122.079594494418},
{lat:-2.8504484887755,lng:122.079722211111},
{lat:-2.85055554444445,lng:122.079795982846},
{lat:-2.85079317630847,lng:122.079999988889},
{lat:-2.85083332222222,lng:122.080029330085},
{lat:-2.85091529137675,lng:122.079999988889},
{lat:-2.8511111,lng:122.079948464417},
{lat:-2.85138887777778,lng:122.07974632279},
{lat:-2.85144872062213,lng:122.079722211111},
{lat:-2.85166665555556,lng:122.079604792034},
{lat:-2.85194443333334,lng:122.079474310575},
{lat:-2.85200011204972,lng:122.079444433333},
{lat:-2.85222221111111,lng:122.079209465025},
{lat:-2.85249998888889,lng:122.07917144547},
{lat:-2.85256651960125,lng:122.079444433333},
{lat:-2.85264597998816,lng:122.079722211111},
{lat:-2.85277776666667,lng:122.079939521914},
{lat:-2.85305554444445,lng:122.079874784721},
{lat:-2.85312017594219,lng:122.079722211111},
{lat:-2.85323374648524,lng:122.079444433333},
{lat:-2.85333332222222,lng:122.079282898731},
{lat:-2.85339664096909,lng:122.079166655556},
{lat:-2.85359737728552,lng:122.078888877778},
{lat:-2.8536111,lng:122.078874053532},
{lat:-2.8538354040122,lng:122.0786111},
{lat:-2.85388887777778,lng:122.078555234529},
{lat:-2.85402776666667,lng:122.078555234529},]},
{ID:139,coord:[{lat:-2.84568909215557,lng:122.063472211111},
{lat:-2.84568909215557,lng:122.0636111},
{lat:-2.84565441613899,lng:122.063888877778},
{lat:-2.84583332222222,lng:122.06408174875},
{lat:-2.8461111,lng:122.06401826513},
{lat:-2.84638887777778,lng:122.063949143141},
{lat:-2.84654748310745,lng:122.064166655556},
{lat:-2.84666665555556,lng:122.0642958313},
{lat:-2.84694443333333,lng:122.064366973781},
{lat:-2.84722221111111,lng:122.064368017608},
{lat:-2.84740486070812,lng:122.064444433333},
{lat:-2.84749998888889,lng:122.064575337087},
{lat:-2.84761206706877,lng:122.064722211111},
{lat:-2.84749998888889,lng:122.064772514577},
{lat:-2.84722221111111,lng:122.064964016284},
{lat:-2.84718506123232,lng:122.064999988889},
{lat:-2.84694443333333,lng:122.065236205703},
{lat:-2.84689894543632,lng:122.065277766667},
{lat:-2.84666665555556,lng:122.065431063021},
{lat:-2.8464569017738,lng:122.065555544444},
{lat:-2.84638887777778,lng:122.065593146068},
{lat:-2.8461111,lng:122.065603591732},
{lat:-2.84583332222222,lng:122.065696064855},
{lat:-2.84564692327538,lng:122.065833322222},
{lat:-2.84555554444445,lng:122.066014415578},
{lat:-2.84552595450729,lng:122.0661111},
{lat:-2.84548250612381,lng:122.066388877778},
{lat:-2.84545002183796,lng:122.066666655556},
{lat:-2.84543615057967,lng:122.066944433333},
{lat:-2.8454652332006,lng:122.067222211111},
{lat:-2.84555554444445,lng:122.067476223607},
{lat:-2.84556590507723,lng:122.067499988889},
{lat:-2.84580638822302,lng:122.067777766667},
{lat:-2.84583332222222,lng:122.067810458038},
{lat:-2.84596004445881,lng:122.068055544444},
{lat:-2.84588525749624,lng:122.068333322222},
{lat:-2.84583332222222,lng:122.068386157397},
{lat:-2.84573370143905,lng:122.0686111},
{lat:-2.8456267427351,lng:122.068888877778},
{lat:-2.84565476356374,lng:122.069166655556},
{lat:-2.84583332222222,lng:122.06940262127},
{lat:-2.84586675359589,lng:122.069444433333},
{lat:-2.84594908533578,lng:122.069722211111},
{lat:-2.84583332222222,lng:122.069849046829},
{lat:-2.84574355125399,lng:122.069999988889},
{lat:-2.84564072176799,lng:122.070277766667},
{lat:-2.84555554444445,lng:122.070401361107},
{lat:-2.84538127550191,lng:122.070555544444},
{lat:-2.84527776666667,lng:122.070636013324},
{lat:-2.84505535338472,lng:122.070833322222},
{lat:-2.84499998888889,lng:122.070957776204},
{lat:-2.8449468691096,lng:122.0711111},
{lat:-2.84497378649123,lng:122.071388877778},
{lat:-2.8449541260206,lng:122.071666655556},
{lat:-2.84486508036983,lng:122.071944433333},
{lat:-2.84487413869291,lng:122.072222211111},
{lat:-2.84485396832735,lng:122.072499988889},
{lat:-2.84472221111111,lng:122.072703297523},
{lat:-2.84467244718746,lng:122.072777766667},
{lat:-2.84447945051395,lng:122.073055544444},
{lat:-2.84444443333333,lng:122.073263877778},
{lat:-2.84443737488861,lng:122.073333322222},
{lat:-2.84444443333333,lng:122.073394681788},
{lat:-2.84449483947802,lng:122.0736111},
{lat:-2.84472221111111,lng:122.073875575056},
{lat:-2.84473112767809,lng:122.073888877778},
{lat:-2.84472221111111,lng:122.073927425941},
{lat:-2.84467164919336,lng:122.074166655556},
{lat:-2.84463667981539,lng:122.074444433333},
{lat:-2.84462968481025,lng:122.074722211111},
{lat:-2.84459814427531,lng:122.074999988889},
{lat:-2.8446494217367,lng:122.075277766667},
{lat:-2.84472221111111,lng:122.075329641235},
{lat:-2.84499998888889,lng:122.07536808587},
{lat:-2.84520318588478,lng:122.075277766667},
{lat:-2.84527776666667,lng:122.075235572166},
{lat:-2.84555554444445,lng:122.075048912649},
{lat:-2.84581133289598,lng:122.074999988889},
{lat:-2.84583332222222,lng:122.074992775219},
{lat:-2.8461111,lng:122.074877948542},
{lat:-2.84638887777778,lng:122.074986894412},
{lat:-2.8464207986537,lng:122.074999988889},
{lat:-2.84666665555556,lng:122.075085722231},
{lat:-2.84685766310937,lng:122.075277766667},
{lat:-2.84694443333333,lng:122.075410556517},
{lat:-2.84722221111111,lng:122.075314695292},
{lat:-2.84749998888889,lng:122.075391540974},
{lat:-2.84763728038414,lng:122.075555544444},
{lat:-2.84777776666667,lng:122.075655925233},
{lat:-2.84803274700599,lng:122.075555544444},
{lat:-2.84805554444445,lng:122.075545122405},
{lat:-2.84827787845568,lng:122.075277766667},
{lat:-2.84833332222222,lng:122.075233427258},
{lat:-2.8486111,lng:122.075206881531},
{lat:-2.84877989456633,lng:122.075277766667},
{lat:-2.84880150027799,lng:122.075555544444},
{lat:-2.84863070608807,lng:122.075833322222},
{lat:-2.8486111,lng:122.075877498923},
{lat:-2.84853915319075,lng:122.0761111},
{lat:-2.84856637940944,lng:122.076388877778},
{lat:-2.8486111,lng:122.076433853975},
{lat:-2.84872652181346,lng:122.076666655556},
{lat:-2.84869354218979,lng:122.076944433333},
{lat:-2.84873241988077,lng:122.077222211111},
{lat:-2.84880868661257,lng:122.077499988889},
{lat:-2.84888887777778,lng:122.077647351563},
{lat:-2.84899061835158,lng:122.077777766667},
{lat:-2.84916665555556,lng:122.077980067203},
{lat:-2.8493242242527,lng:122.078055544444},
{lat:-2.84941523591874,lng:122.078333322222},
{lat:-2.84944443333333,lng:122.078411251741},
{lat:-2.8495867034124,lng:122.0786111},
{lat:-2.84972221111111,lng:122.078692835915},
{lat:-2.84999998888889,lng:122.078752598368},
{lat:-2.85014479531583,lng:122.078888877778},
{lat:-2.85027776666667,lng:122.07909558418},
{lat:-2.85033874096579,lng:122.079166655556},
{lat:-2.85055554444445,lng:122.079364529002},
{lat:-2.85058894545862,lng:122.079444433333},
{lat:-2.85074329701975,lng:122.079722211111},
{lat:-2.85083332222222,lng:122.07980326411},
{lat:-2.8511111,lng:122.079752093962},
{lat:-2.8511544073793,lng:122.079722211111},
{lat:-2.85138887777778,lng:122.079543753331},
{lat:-2.85160747821587,lng:122.079444433333},
{lat:-2.85166665555556,lng:122.079410655419},
{lat:-2.85194443333334,lng:122.079232166356},
{lat:-2.85200714129591,lng:122.079166655556},
{lat:-2.85203634412383,lng:122.078888877778},
{lat:-2.85215682675015,lng:122.0786111},
{lat:-2.85222221111111,lng:122.078560742539},
{lat:-2.85249998888889,lng:122.078492628856},
{lat:-2.85263222009068,lng:122.0786111},
{lat:-2.8527020258202,lng:122.078888877778},
{lat:-2.85269371505998,lng:122.079166655556},
{lat:-2.85275239038157,lng:122.079444433333},
{lat:-2.85277776666667,lng:122.079527147287},
{lat:-2.85305554444445,lng:122.079471407243},
{lat:-2.85306595028716,lng:122.079444433333},
{lat:-2.85318759751529,lng:122.079166655556},
{lat:-2.85333332222222,lng:122.079000214952},
{lat:-2.85340648498285,lng:122.078888877778},
{lat:-2.8536111,lng:122.078667838172},
{lat:-2.85365949866439,lng:122.0786111},
{lat:-2.85388887777778,lng:122.078371461516},
{lat:-2.85402776666667,lng:122.078371461516},]},
{ID:140,coord:[{lat:-2.846669660477,lng:122.063472211111},
{lat:-2.846669660477,lng:122.0636111},
{lat:-2.84681890427506,lng:122.063888877778},
{lat:-2.84694443333333,lng:122.06405772191},
{lat:-2.84722221111111,lng:122.064142657507},
{lat:-2.84727456505286,lng:122.064166655556},
{lat:-2.84749998888889,lng:122.064256396143},
{lat:-2.84777776666667,lng:122.064389342984},
{lat:-2.84786160608871,lng:122.064444433333},
{lat:-2.84805554444445,lng:122.064670918837},
{lat:-2.84813405898166,lng:122.064722211111},
{lat:-2.84805554444445,lng:122.064797223622},
{lat:-2.84777776666667,lng:122.064894980963},
{lat:-2.84754764838211,lng:122.064999988889},
{lat:-2.84749998888889,lng:122.065021879359},
{lat:-2.84722221111111,lng:122.065222469892},
{lat:-2.84716621384607,lng:122.065277766667},
{lat:-2.84694443333333,lng:122.065525620307},
{lat:-2.84689741233243,lng:122.065555544444},
{lat:-2.84666665555556,lng:122.065690507119},
{lat:-2.84639626587999,lng:122.065833322222},
{lat:-2.84638887777778,lng:122.065836939973},
{lat:-2.84637141278157,lng:122.065833322222},
{lat:-2.8461111,lng:122.065790963917},
{lat:-2.84600025135702,lng:122.065833322222},
{lat:-2.84583332222222,lng:122.065960590784},
{lat:-2.84574239820679,lng:122.0661111},
{lat:-2.84570893567675,lng:122.066388877778},
{lat:-2.84569994351856,lng:122.066666655556},
{lat:-2.8457197742545,lng:122.066944433333},
{lat:-2.84576557496898,lng:122.067222211111},
{lat:-2.84583332222222,lng:122.067343616745},
{lat:-2.84590138803319,lng:122.067499988889},
{lat:-2.84609508421208,lng:122.067777766667},
{lat:-2.8461111,lng:122.067811786872},
{lat:-2.84619907978391,lng:122.068055544444},
{lat:-2.84624344241334,lng:122.068333322222},
{lat:-2.84618409490614,lng:122.0686111},
{lat:-2.8461111,lng:122.068824507054},
{lat:-2.84598628044842,lng:122.068888877778},
{lat:-2.8461111,lng:122.068963593186},
{lat:-2.84617097723717,lng:122.069166655556},
{lat:-2.84624191677097,lng:122.069444433333},
{lat:-2.84627280992771,lng:122.069722211111},
{lat:-2.8461111,lng:122.069896841639},
{lat:-2.84601799496959,lng:122.069999988889},
{lat:-2.84583332222222,lng:122.070265245964},
{lat:-2.84582848433361,lng:122.070277766667},
{lat:-2.84568832232117,lng:122.070555544444},
{lat:-2.84555554444445,lng:122.07068986425},
{lat:-2.84535414047515,lng:122.070833322222},
{lat:-2.84527776666667,lng:122.070929328451},
{lat:-2.84519216309758,lng:122.0711111},
{lat:-2.84523816071234,lng:122.071388877778},
{lat:-2.84527776666667,lng:122.071628076972},
{lat:-2.84528511059699,lng:122.071666655556},
{lat:-2.84532256238707,lng:122.071944433333},
{lat:-2.84536016174759,lng:122.072222211111},
{lat:-2.84541012759143,lng:122.072499988889},
{lat:-2.84546217197124,lng:122.072777766667},
{lat:-2.84549016500096,lng:122.073055544444},
{lat:-2.84551453747285,lng:122.073333322222},
{lat:-2.84549195542833,lng:122.0736111},
{lat:-2.84541063669985,lng:122.073888877778},
{lat:-2.84555554444445,lng:122.074139561812},
{lat:-2.84583332222222,lng:122.074128370626},
{lat:-2.8461111,lng:122.074108978411},
{lat:-2.84614309807944,lng:122.074166655556},
{lat:-2.84626174375759,lng:122.074444433333},
{lat:-2.84638887777778,lng:122.07460461289},
{lat:-2.84645830179316,lng:122.074722211111},
{lat:-2.84666665555556,lng:122.0748672988},
{lat:-2.84694443333333,lng:122.074876997627},
{lat:-2.84722221111111,lng:122.07476897896},
{lat:-2.84749998888889,lng:122.074837568005},
{lat:-2.84760818897367,lng:122.074999988889},
{lat:-2.84777776666667,lng:122.075142911496},
{lat:-2.84805554444445,lng:122.075249208081},
{lat:-2.84833332222222,lng:122.075037869793},
{lat:-2.84843636329255,lng:122.074999988889},
{lat:-2.8486111,lng:122.074868808331},
{lat:-2.84872594984897,lng:122.074722211111},
{lat:-2.84883693902977,lng:122.074444433333},
{lat:-2.84888887777778,lng:122.074237513834},
{lat:-2.84916665555556,lng:122.074283583539},
{lat:-2.84932723193066,lng:122.074444433333},
{lat:-2.84937952748149,lng:122.074722211111},
{lat:-2.84930973959639,lng:122.074999988889},
{lat:-2.84918315473858,lng:122.075277766667},
{lat:-2.84916665555556,lng:122.075384091679},
{lat:-2.84913535436227,lng:122.075555544444},
{lat:-2.84912104209126,lng:122.075833322222},
{lat:-2.84888887777778,lng:122.076055784445},
{lat:-2.8488608880005,lng:122.0761111},
{lat:-2.84883758041678,lng:122.076388877778},
{lat:-2.84888887777778,lng:122.076544781057},
{lat:-2.84904188866017,lng:122.076666655556},
{lat:-2.84890927299968,lng:122.076944433333},
{lat:-2.84890021207327,lng:122.077222211111},
{lat:-2.84899809162166,lng:122.077499988889},
{lat:-2.84916665555556,lng:122.077718012898},
{lat:-2.84944443333333,lng:122.077665440173},
{lat:-2.84965412879938,lng:122.077777766667},
{lat:-2.84972221111111,lng:122.077824536116},
{lat:-2.84996351666859,lng:122.078055544444},
{lat:-2.84999998888889,lng:122.07809667921},
{lat:-2.85025046875374,lng:122.078333322222},
{lat:-2.85027776666667,lng:122.078367705883},
{lat:-2.85048932855501,lng:122.0786111},
{lat:-2.85055554444445,lng:122.078695565909},
{lat:-2.85083332222222,lng:122.078779604495},
{lat:-2.8511111,lng:122.078841064456},
{lat:-2.85113306831705,lng:122.078888877778},
{lat:-2.8511111,lng:122.078924972837},
{lat:-2.85083332222222,lng:122.079008633898},
{lat:-2.85081998879675,lng:122.079166655556},
{lat:-2.85081287172915,lng:122.079444433333},
{lat:-2.85083332222222,lng:122.079479445265},
{lat:-2.8511111,lng:122.079494636337},
{lat:-2.85118241529866,lng:122.079444433333},
{lat:-2.85138887777778,lng:122.079303430115},
{lat:-2.85162099223575,lng:122.079166655556},
{lat:-2.85159831035536,lng:122.078888877778},
{lat:-2.85165846633185,lng:122.0786111},
{lat:-2.85166665555556,lng:122.078606438236},
{lat:-2.85194443333334,lng:122.078386657596},
{lat:-2.85202929266779,lng:122.078333322222},
{lat:-2.85222221111111,lng:122.078195326411},
{lat:-2.85249998888889,lng:122.07807539685},
{lat:-2.85253594428495,lng:122.078055544444},
{lat:-2.85277776666667,lng:122.077903274542},
{lat:-2.85286888396513,lng:122.078055544444},
{lat:-2.85293632647943,lng:122.078333322222},
{lat:-2.85300000128162,lng:122.0786111},
{lat:-2.85305037709056,lng:122.078888877778},
{lat:-2.85305554444445,lng:122.07889624445},
{lat:-2.8530613211907,lng:122.078888877778},
{lat:-2.85333332222222,lng:122.078666371272},
{lat:-2.85338178721428,lng:122.0786111},
{lat:-2.8536111,lng:122.078452499381},
{lat:-2.85372425160709,lng:122.078333322222},
{lat:-2.85388887777778,lng:122.078194969057},
{lat:-2.85402776666667,lng:122.078194969057},]},
{ID:141,coord:[{lat:-2.85402776666667,lng:122.069755027887},
{lat:-2.85388887777778,lng:122.069755027887},
{lat:-2.85388591018747,lng:122.069722211111},
{lat:-2.85383818554253,lng:122.069444433333},
{lat:-2.85381297345551,lng:122.069166655556},
{lat:-2.85381354390061,lng:122.068888877778},
{lat:-2.85387678743781,lng:122.0686111},
{lat:-2.85388887777778,lng:122.068593087862},
{lat:-2.85402776666667,lng:122.068593087862},]},
{ID:142,coord:[{lat:-2.85402776666667,lng:122.070304656984},
{lat:-2.85388887777778,lng:122.070304656984},
{lat:-2.85388512533223,lng:122.070277766667},
{lat:-2.85388887777778,lng:122.070236449993},
{lat:-2.85402776666667,lng:122.070236449993},]},
{ID:143,coord:[{lat:-2.84704945284204,lng:122.063472211111},
{lat:-2.84704945284204,lng:122.0636111},
{lat:-2.84722221111111,lng:122.063872022505},
{lat:-2.84724066892342,lng:122.063888877778},
{lat:-2.84749998888889,lng:122.064019555679},
{lat:-2.84776982559623,lng:122.064166655556},
{lat:-2.84777776666667,lng:122.064170573222},
{lat:-2.84805554444445,lng:122.064332584263},
{lat:-2.84824032936114,lng:122.064444433333},
{lat:-2.84833332222222,lng:122.064511109607},
{lat:-2.8486111,lng:122.064670059956},
{lat:-2.8486629816013,lng:122.064722211111},
{lat:-2.8486111,lng:122.064842454018},
{lat:-2.84850896751147,lng:122.064999988889},
{lat:-2.84833332222222,lng:122.065078193773},
{lat:-2.84805554444445,lng:122.065117324907},
{lat:-2.84777776666667,lng:122.065110850636},
{lat:-2.84749998888889,lng:122.065269619547},
{lat:-2.84748870206531,lng:122.065277766667},
{lat:-2.84722221111111,lng:122.065511147661},
{lat:-2.84718279013144,lng:122.065555544444},
{lat:-2.84694443333333,lng:122.065753353936},
{lat:-2.84681999451809,lng:122.065833322222},
{lat:-2.84666665555556,lng:122.065951170818},
{lat:-2.84638887777778,lng:122.066075464059},
{lat:-2.8461111,lng:122.066093128886},
{lat:-2.84608825496337,lng:122.0661111},
{lat:-2.84606253101141,lng:122.066388877778},
{lat:-2.84606683537388,lng:122.066666655556},
{lat:-2.84606634730862,lng:122.066944433333},
{lat:-2.84604266882341,lng:122.067222211111},
{lat:-2.8461111,lng:122.067424470877},
{lat:-2.84613563210635,lng:122.067499988889},
{lat:-2.84629572465899,lng:122.067777766667},
{lat:-2.84638887777778,lng:122.068000803},
{lat:-2.84642067471882,lng:122.068055544444},
{lat:-2.8465244346811,lng:122.068333322222},
{lat:-2.84656482860599,lng:122.0686111},
{lat:-2.84652741572836,lng:122.068888877778},
{lat:-2.84650912974088,lng:122.069166655556},
{lat:-2.84656016212235,lng:122.069444433333},
{lat:-2.84666665555556,lng:122.069656852583},
{lat:-2.84672238565455,lng:122.069722211111},
{lat:-2.84666665555556,lng:122.06981551249},
{lat:-2.8465333009876,lng:122.069999988889},
{lat:-2.84638887777778,lng:122.070077774637},
{lat:-2.84612121858572,lng:122.070277766667},
{lat:-2.8461111,lng:122.070293128032},
{lat:-2.84599185172716,lng:122.070555544444},
{lat:-2.84583332222222,lng:122.070744878912},
{lat:-2.84574605782893,lng:122.070833322222},
{lat:-2.84555554444445,lng:122.071007256064},
{lat:-2.8454654522196,lng:122.0711111},
{lat:-2.84545665267786,lng:122.071388877778},
{lat:-2.84555554444445,lng:122.071606309918},
{lat:-2.84558439395405,lng:122.071666655556},
{lat:-2.84572519257219,lng:122.071944433333},
{lat:-2.84583332222222,lng:122.072178192678},
{lat:-2.84584485664751,lng:122.072222211111},
{lat:-2.8459072990097,lng:122.072499988889},
{lat:-2.84595432409673,lng:122.072777766667},
{lat:-2.84595866327143,lng:122.073055544444},
{lat:-2.84605919961458,lng:122.073333322222},
{lat:-2.8461111,lng:122.073376619243},
{lat:-2.84616364350504,lng:122.0736111},
{lat:-2.84628211387515,lng:122.073888877778},
{lat:-2.84638887777778,lng:122.07406028548},
{lat:-2.84644455580317,lng:122.074166655556},
{lat:-2.84653130396112,lng:122.074444433333},
{lat:-2.84666665555556,lng:122.074650162853},
{lat:-2.84687415947192,lng:122.074444433333},
{lat:-2.84694443333333,lng:122.074357111337},
{lat:-2.84722221111111,lng:122.074277354142},
{lat:-2.84749555458413,lng:122.074444433333},
{lat:-2.84749998888889,lng:122.074447049828},
{lat:-2.84777776666667,lng:122.074618121135},
{lat:-2.84789455254273,lng:122.074722211111},
{lat:-2.84805554444445,lng:122.074914151276},
{lat:-2.84828428661864,lng:122.074722211111},
{lat:-2.84833332222222,lng:122.074672243964},
{lat:-2.84850447827318,lng:122.074444433333},
{lat:-2.8486111,lng:122.074231728889},
{lat:-2.84863443921258,lng:122.074166655556},
{lat:-2.84870967784933,lng:122.073888877778},
{lat:-2.84877795362405,lng:122.0736111},
{lat:-2.84888887777778,lng:122.073427403531},
{lat:-2.84916665555556,lng:122.073493107845},
{lat:-2.84930876272268,lng:122.0736111},
{lat:-2.84944443333333,lng:122.073751846253},
{lat:-2.84957474471463,lng:122.073888877778},
{lat:-2.84972221111111,lng:122.074109827543},
{lat:-2.84974387722751,lng:122.074166655556},
{lat:-2.84976761457355,lng:122.074444433333},
{lat:-2.84975732039382,lng:122.074722211111},
{lat:-2.84972221111111,lng:122.074841938577},
{lat:-2.84966584188132,lng:122.074999988889},
{lat:-2.84950330781144,lng:122.075277766667},
{lat:-2.84944443333333,lng:122.075466767408},
{lat:-2.84942507269229,lng:122.075555544444},
{lat:-2.84944443333333,lng:122.075640614553},
{lat:-2.84949078851625,lng:122.075833322222},
{lat:-2.84953443887592,lng:122.0761111},
{lat:-2.84950891121079,lng:122.076388877778},
{lat:-2.84944443333333,lng:122.076579854196},
{lat:-2.84939920016801,lng:122.076666655556},
{lat:-2.84930355845705,lng:122.076944433333},
{lat:-2.84944443333333,lng:122.077141178088},
{lat:-2.8495340374969,lng:122.077222211111},
{lat:-2.84972221111111,lng:122.077372961641},
{lat:-2.84989850062021,lng:122.077499988889},
{lat:-2.84999998888889,lng:122.077594139943},
{lat:-2.850209010759,lng:122.077777766667},
{lat:-2.85027776666667,lng:122.077835999836},
{lat:-2.85055554444445,lng:122.077960977023},
{lat:-2.85083332222222,lng:122.077914887968},
{lat:-2.8511111,lng:122.077856028613},
{lat:-2.85138887777778,lng:122.077809511423},
{lat:-2.85143566431273,lng:122.077777766667},
{lat:-2.85166665555556,lng:122.077636895992},
{lat:-2.85186775795811,lng:122.077499988889},
{lat:-2.85194443333334,lng:122.077439324205},
{lat:-2.85222221111111,lng:122.077270325363},
{lat:-2.85230921765132,lng:122.077222211111},
{lat:-2.85249998888889,lng:122.077163667936},
{lat:-2.85277776666667,lng:122.077219795098},
{lat:-2.85277997040314,lng:122.077222211111},
{lat:-2.8528751141965,lng:122.077499988889},
{lat:-2.85305554444445,lng:122.077752637736},
{lat:-2.85307391883509,lng:122.077777766667},
{lat:-2.85320133644249,lng:122.078055544444},
{lat:-2.85333332222222,lng:122.0781914731},
{lat:-2.8536111,lng:122.078177867151},
{lat:-2.85378891994564,lng:122.078055544444},
{lat:-2.85388887777778,lng:122.077993538354},
{lat:-2.85402776666667,lng:122.077993538354},]},
{ID:144,coord:[{lat:-2.85402776666667,lng:122.071519999135},
{lat:-2.85388887777778,lng:122.071519999135},
{lat:-2.85381632096184,lng:122.071388877778},
{lat:-2.85377986004015,lng:122.0711111},
{lat:-2.85376816639061,lng:122.070833322222},
{lat:-2.85372433569048,lng:122.070555544444},
{lat:-2.85369170054713,lng:122.070277766667},
{lat:-2.85368682894835,lng:122.069999988889},
{lat:-2.85368639849429,lng:122.069722211111},
{lat:-2.85366086526492,lng:122.069444433333},
{lat:-2.85363238455758,lng:122.069166655556},
{lat:-2.85363433560662,lng:122.068888877778},
{lat:-2.85363462590969,lng:122.0686111},
{lat:-2.85380058982554,lng:122.068333322222},
{lat:-2.85384471807244,lng:122.068055544444},
{lat:-2.85388812947131,lng:122.067777766667},
{lat:-2.85388887777778,lng:122.06777336835},
{lat:-2.85402776666667,lng:122.06777336835},]},
{ID:145,coord:[{lat:-2.84742852962133,lng:122.063472211111},
{lat:-2.84742852962133,lng:122.0636111},
{lat:-2.84749998888889,lng:122.06367599683},
{lat:-2.84777776666667,lng:122.063854789991},
{lat:-2.84783895294815,lng:122.063888877778},
{lat:-2.84805554444445,lng:122.063999804557},
{lat:-2.84832468684488,lng:122.064166655556},
{lat:-2.84833332222222,lng:122.064170885062},
{lat:-2.8486111,lng:122.064325405187},
{lat:-2.84888887777778,lng:122.064431406819},
{lat:-2.84889949766074,lng:122.064444433333},
{lat:-2.848983317412,lng:122.064722211111},
{lat:-2.84894915605592,lng:122.064999988889},
{lat:-2.84888887777778,lng:122.065131516587},
{lat:-2.8486111,lng:122.065249296576},
{lat:-2.84851200595414,lng:122.065277766667},
{lat:-2.84833332222222,lng:122.065317265161},
{lat:-2.84805554444445,lng:122.065308395895},
{lat:-2.84777776666667,lng:122.065321294829},
{lat:-2.847515351517,lng:122.065555544444},
{lat:-2.84749998888889,lng:122.065573812504},
{lat:-2.84722221111111,lng:122.065793988392},
{lat:-2.84717211217468,lng:122.065833322222},
{lat:-2.84694443333333,lng:122.066016145948},
{lat:-2.84681782448193,lng:122.0661111},
{lat:-2.84666665555556,lng:122.066295620541},
{lat:-2.84657384892837,lng:122.066388877778},
{lat:-2.84645703339628,lng:122.066666655556},
{lat:-2.84641056402382,lng:122.066944433333},
{lat:-2.84638887777778,lng:122.067144993201},
{lat:-2.84637938762468,lng:122.067222211111},
{lat:-2.84638887777778,lng:122.067357944324},
{lat:-2.84639603299342,lng:122.067499988889},
{lat:-2.84653303772042,lng:122.067777766667},
{lat:-2.84666665555556,lng:122.068009424427},
{lat:-2.84670235237146,lng:122.068055544444},
{lat:-2.84685032625595,lng:122.068333322222},
{lat:-2.84694443333333,lng:122.068543898971},
{lat:-2.84698299024373,lng:122.0686111},
{lat:-2.84696284577752,lng:122.068888877778},
{lat:-2.84694854316731,lng:122.069166655556},
{lat:-2.84706701951778,lng:122.069444433333},
{lat:-2.84722221111111,lng:122.069542631515},
{lat:-2.84749998888889,lng:122.06954570357},
{lat:-2.84770581100212,lng:122.069722211111},
{lat:-2.84777776666667,lng:122.069859993084},
{lat:-2.84781948590399,lng:122.069999988889},
{lat:-2.84791018849462,lng:122.070277766667},
{lat:-2.84780075423601,lng:122.070555544444},
{lat:-2.84777776666667,lng:122.070585118335},
{lat:-2.84776126563781,lng:122.070555544444},
{lat:-2.84766322608182,lng:122.070277766667},
{lat:-2.84749998888889,lng:122.070131371217},
{lat:-2.84722221111111,lng:122.07000109018},
{lat:-2.84694443333333,lng:122.070127229473},
{lat:-2.84666665555556,lng:122.070256272107},
{lat:-2.84662652315383,lng:122.070277766667},
{lat:-2.84641171863179,lng:122.070555544444},
{lat:-2.84638887777778,lng:122.070585575841},
{lat:-2.84615520802004,lng:122.070833322222},
{lat:-2.8461111,lng:122.070874465346},
{lat:-2.84583868042927,lng:122.0711111},
{lat:-2.84583332222222,lng:122.071120145121},
{lat:-2.84573395164482,lng:122.071388877778},
{lat:-2.84580465554695,lng:122.071666655556},
{lat:-2.84583332222222,lng:122.071707388732},
{lat:-2.84599713573955,lng:122.071944433333},
{lat:-2.84609733496942,lng:122.072222211111},
{lat:-2.8461111,lng:122.07224649519},
{lat:-2.84625583657659,lng:122.072499988889},
{lat:-2.84626200176491,lng:122.072777766667},
{lat:-2.84628408047461,lng:122.073055544444},
{lat:-2.8463250867751,lng:122.073333322222},
{lat:-2.84637459261897,lng:122.0736111},
{lat:-2.84638887777778,lng:122.073638815577},
{lat:-2.8466324081804,lng:122.073888877778},
{lat:-2.84666665555556,lng:122.074083015381},
{lat:-2.84694443333333,lng:122.073893885258},
{lat:-2.84694996768422,lng:122.073888877778},
{lat:-2.84722221111111,lng:122.073634386149},
{lat:-2.84744332234757,lng:122.073888877778},
{lat:-2.84746780225893,lng:122.074166655556},
{lat:-2.84749998888889,lng:122.074189970946},
{lat:-2.84777776666667,lng:122.074264683489},
{lat:-2.84805554444445,lng:122.074423956992},
{lat:-2.8483053402965,lng:122.074166655556},
{lat:-2.84833332222222,lng:122.07395643468},
{lat:-2.84833960676518,lng:122.073888877778},
{lat:-2.84833332222222,lng:122.073799459623},
{lat:-2.84832228014578,lng:122.0736111},
{lat:-2.84833332222222,lng:122.073425221859},
{lat:-2.84834236321753,lng:122.073333322222},
{lat:-2.8484607593626,lng:122.073055544444},
{lat:-2.8486111,lng:122.072845153247},
{lat:-2.84888887777778,lng:122.072781114047},
{lat:-2.84916665555556,lng:122.072988296551},
{lat:-2.84918427509356,lng:122.073055544444},
{lat:-2.84942652138628,lng:122.073333322222},
{lat:-2.84944443333333,lng:122.073348456881},
{lat:-2.84965764199872,lng:122.0736111},
{lat:-2.84972221111111,lng:122.073677708295},
{lat:-2.84996380686953,lng:122.073888877778},
{lat:-2.84999998888889,lng:122.074068027959},
{lat:-2.85005764128909,lng:122.074166655556},
{lat:-2.85019359456415,lng:122.074444433333},
{lat:-2.85009021171213,lng:122.074722211111},
{lat:-2.84999998888889,lng:122.074945149762},
{lat:-2.84997838095052,lng:122.074999988889},
{lat:-2.84984152996971,lng:122.075277766667},
{lat:-2.84976170632986,lng:122.075555544444},
{lat:-2.84976926428386,lng:122.075833322222},
{lat:-2.84974671551387,lng:122.0761111},
{lat:-2.84972221111111,lng:122.076196453994},
{lat:-2.84968162103468,lng:122.076388877778},
{lat:-2.84964037645698,lng:122.076666655556},
{lat:-2.849721155211,lng:122.076944433333},
{lat:-2.84972221111111,lng:122.076945394594},
{lat:-2.84999998888889,lng:122.077143095136},
{lat:-2.85013127767758,lng:122.077222211111},
{lat:-2.85027776666667,lng:122.077320273711},
{lat:-2.85055554444445,lng:122.077344231343},
{lat:-2.85083332222222,lng:122.077268117833},
{lat:-2.8511111,lng:122.077279244898},
{lat:-2.85138887777778,lng:122.077268105512},
{lat:-2.851547797207,lng:122.077222211111},
{lat:-2.85166665555556,lng:122.077183896387},
{lat:-2.85190427321324,lng:122.076944433333},
{lat:-2.85194443333334,lng:122.076919586689},
{lat:-2.85222221111111,lng:122.076755736789},
{lat:-2.85249998888889,lng:122.07679318854},
{lat:-2.85266640992236,lng:122.076944433333},
{lat:-2.85277776666667,lng:122.077024704141},
{lat:-2.85295792022224,lng:122.077222211111},
{lat:-2.85305554444445,lng:122.07740990385},
{lat:-2.8531357078838,lng:122.077499988889},
{lat:-2.85332468602904,lng:122.077777766667},
{lat:-2.85333332222222,lng:122.077791700438},
{lat:-2.8536111,lng:122.077812181039},
{lat:-2.85367574893126,lng:122.077777766667},
{lat:-2.85388887777778,lng:122.077643467838},
{lat:-2.85402776666667,lng:122.077643467838},]},
{ID:146,coord:[{lat:-2.85402776666667,lng:122.072313224887},
{lat:-2.85388887777778,lng:122.072313224887},
{lat:-2.85386847090829,lng:122.072222211111},
{lat:-2.85381753564715,lng:122.071944433333},
{lat:-2.85366749790224,lng:122.071666655556},
{lat:-2.8536111,lng:122.071518646619},
{lat:-2.85359108229484,lng:122.071388877778},
{lat:-2.853588444254,lng:122.0711111},
{lat:-2.85357633952859,lng:122.070833322222},
{lat:-2.85355418901154,lng:122.070555544444},
{lat:-2.85352813315583,lng:122.070277766667},
{lat:-2.85350474017923,lng:122.069999988889},
{lat:-2.85350833737904,lng:122.069722211111},
{lat:-2.85348780988694,lng:122.069444433333},
{lat:-2.85343603559066,lng:122.069166655556},
{lat:-2.8533897356216,lng:122.068888877778},
{lat:-2.85341362731081,lng:122.0686111},
{lat:-2.85352663669164,lng:122.068333322222},
{lat:-2.8536111,lng:122.068133924354},
{lat:-2.85364278033789,lng:122.068055544444},
{lat:-2.85373124843183,lng:122.067777766667},
{lat:-2.85379279095732,lng:122.067499988889},
{lat:-2.85385607285504,lng:122.067222211111},
{lat:-2.85388887777778,lng:122.067141188036},
{lat:-2.85402776666667,lng:122.067141188036},]},
{ID:147,coord:[{lat:-2.84813100541135,lng:122.063472211111},
{lat:-2.84813100541135,lng:122.0636111},
{lat:-2.84833332222222,lng:122.063712198314},
{lat:-2.84851832431107,lng:122.063888877778},
{lat:-2.8486111,lng:122.063973359419},
{lat:-2.84888887777778,lng:122.064055463074},
{lat:-2.84901412743916,lng:122.064166655556},
{lat:-2.84916665555556,lng:122.064366666962},
{lat:-2.84919745976313,lng:122.064444433333},
{lat:-2.84923133369797,lng:122.064722211111},
{lat:-2.84919125851684,lng:122.064999988889},
{lat:-2.84916665555556,lng:122.065136341385},
{lat:-2.84913046200959,lng:122.065277766667},
{lat:-2.84906949627274,lng:122.065555544444},
{lat:-2.84888887777778,lng:122.065826007813},
{lat:-2.8486111,lng:122.065593912153},
{lat:-2.84835079026564,lng:122.065555544444},
{lat:-2.84833332222222,lng:122.065554032136},
{lat:-2.84828440435189,lng:122.065555544444},
{lat:-2.84805554444445,lng:122.065570135936},
{lat:-2.84777776666667,lng:122.065715258594},
{lat:-2.84768477732965,lng:122.065833322222},
{lat:-2.84749998888889,lng:122.066005530576},
{lat:-2.84736216441816,lng:122.0661111},
{lat:-2.84722221111111,lng:122.066257203333},
{lat:-2.84709063808528,lng:122.066388877778},
{lat:-2.84694443333333,lng:122.066583823919},
{lat:-2.84688726880009,lng:122.066666655556},
{lat:-2.8467578005139,lng:122.066944433333},
{lat:-2.84666665555556,lng:122.067213813796},
{lat:-2.84666498685148,lng:122.067222211111},
{lat:-2.84665453586671,lng:122.067499988889},
{lat:-2.84666665555556,lng:122.067523205671},
{lat:-2.84691555238796,lng:122.067777766667},
{lat:-2.84694443333333,lng:122.06781882324},
{lat:-2.84703513984561,lng:122.068055544444},
{lat:-2.84717376991165,lng:122.068333322222},
{lat:-2.84722221111111,lng:122.06840141137},
{lat:-2.84735391725542,lng:122.0686111},
{lat:-2.8474295627478,lng:122.068888877778},
{lat:-2.84749998888889,lng:122.068967432242},
{lat:-2.84769738581848,lng:122.069166655556},
{lat:-2.84777776666667,lng:122.069271045507},
{lat:-2.84787413540341,lng:122.069444433333},
{lat:-2.84797725449306,lng:122.069722211111},
{lat:-2.84805554444445,lng:122.069877165547},
{lat:-2.84813375161491,lng:122.069999988889},
{lat:-2.84833332222222,lng:122.070251586105},
{lat:-2.84835730617637,lng:122.070277766667},
{lat:-2.8486111,lng:122.070477923923},
{lat:-2.84867893666219,lng:122.070555544444},
{lat:-2.84866697586305,lng:122.070833322222},
{lat:-2.84874625265757,lng:122.0711111},
{lat:-2.84875890883265,lng:122.071388877778},
{lat:-2.84879027876252,lng:122.071666655556},
{lat:-2.84887122705214,lng:122.071944433333},
{lat:-2.84888887777778,lng:122.072009460334},
{lat:-2.84916665555556,lng:122.072148634333},
{lat:-2.84923448198548,lng:122.072222211111},
{lat:-2.84938007678261,lng:122.072499988889},
{lat:-2.84944443333333,lng:122.072628907733},
{lat:-2.84949503845581,lng:122.072777766667},
{lat:-2.8495563973595,lng:122.073055544444},
{lat:-2.84968581758303,lng:122.073333322222},
{lat:-2.84972221111111,lng:122.073376632777},
{lat:-2.84999998888889,lng:122.073534297888},
{lat:-2.85006762790068,lng:122.0736111},
{lat:-2.85027776666667,lng:122.073826689991},
{lat:-2.8503650074608,lng:122.073888877778},
{lat:-2.85050083708257,lng:122.074166655556},
{lat:-2.85045086799085,lng:122.074444433333},
{lat:-2.85036293748757,lng:122.074722211111},
{lat:-2.85027776666667,lng:122.074902032633},
{lat:-2.85022674516158,lng:122.074999988889},
{lat:-2.85011749776994,lng:122.075277766667},
{lat:-2.8500054910335,lng:122.075555544444},
{lat:-2.84999998888889,lng:122.075586772446},
{lat:-2.84997706761343,lng:122.075833322222},
{lat:-2.8499817741408,lng:122.0761111},
{lat:-2.84999998888889,lng:122.076301710024},
{lat:-2.8500467869383,lng:122.076388877778},
{lat:-2.85027776666667,lng:122.076525171024},
{lat:-2.85055554444445,lng:122.076641627566},
{lat:-2.85070765888705,lng:122.076666655556},
{lat:-2.85083332222222,lng:122.076689030589},
{lat:-2.8511111,lng:122.076742614044},
{lat:-2.85138887777778,lng:122.0767166667},
{lat:-2.8516539814072,lng:122.076666655556},
{lat:-2.85166665555556,lng:122.076664659556},
{lat:-2.85194443333334,lng:122.07657356609},
{lat:-2.85222221111111,lng:122.07646528345},
{lat:-2.85249998888889,lng:122.076545790659},
{lat:-2.85260439868494,lng:122.076666655556},
{lat:-2.85277776666667,lng:122.076827222558},
{lat:-2.85291634370244,lng:122.076944433333},
{lat:-2.85305554444445,lng:122.077101945845},
{lat:-2.85320594855769,lng:122.077222211111},
{lat:-2.85333332222222,lng:122.07736704233},
{lat:-2.8536111,lng:122.077345383432},
{lat:-2.853828789941,lng:122.077222211111},
{lat:-2.85388887777778,lng:122.077164091661},
{lat:-2.85402776666667,lng:122.077164091661},]},
{ID:148,coord:[{lat:-2.85402776666667,lng:122.073213687398},
{lat:-2.85388887777778,lng:122.073213687398},
{lat:-2.85380388689032,lng:122.073055544444},
{lat:-2.85374044756027,lng:122.072777766667},
{lat:-2.85367361237903,lng:122.072499988889},
{lat:-2.8536111,lng:122.072290623917},
{lat:-2.85359918513208,lng:122.072222211111},
{lat:-2.85355988276429,lng:122.071944433333},
{lat:-2.85349455841559,lng:122.071666655556},
{lat:-2.85345593442984,lng:122.071388877778},
{lat:-2.853459484349,lng:122.0711111},
{lat:-2.85344860965731,lng:122.070833322222},
{lat:-2.85342377197632,lng:122.070555544444},
{lat:-2.85338589559862,lng:122.070277766667},
{lat:-2.85334523452215,lng:122.069999988889},
{lat:-2.85334327968239,lng:122.069722211111},
{lat:-2.85333332222222,lng:122.069616787176},
{lat:-2.85331231304314,lng:122.069444433333},
{lat:-2.85324002119403,lng:122.069166655556},
{lat:-2.85320314116948,lng:122.068888877778},
{lat:-2.85322393746703,lng:122.0686111},
{lat:-2.8533144701932,lng:122.068333322222},
{lat:-2.85333332222222,lng:122.068295080348},
{lat:-2.85344203312008,lng:122.068055544444},
{lat:-2.85356570230092,lng:122.067777766667},
{lat:-2.8536111,lng:122.06766253806},
{lat:-2.85365621180168,lng:122.067499988889},
{lat:-2.85371097381768,lng:122.067222211111},
{lat:-2.85381329006717,lng:122.066944433333},
{lat:-2.85388887777778,lng:122.066852968047},
{lat:-2.85402776666667,lng:122.066852968047},]},
{ID:149,coord:[{lat:-2.84861101632314,lng:122.063472211111},
{lat:-2.84861101632314,lng:122.0636111},
{lat:-2.8486111,lng:122.063611180357},
{lat:-2.84888887777778,lng:122.063675439518},
{lat:-2.84916665555556,lng:122.063864431573},
{lat:-2.84919412553024,lng:122.063888877778},
{lat:-2.84933339168981,lng:122.064166655556},
{lat:-2.84940658221974,lng:122.064444433333},
{lat:-2.84944443333333,lng:122.064648031994},
{lat:-2.84945861425377,lng:122.064722211111},
{lat:-2.84944443333333,lng:122.064851042241},
{lat:-2.84942649817586,lng:122.064999988889},
{lat:-2.84939173310235,lng:122.065277766667},
{lat:-2.84938733020602,lng:122.065555544444},
{lat:-2.84943333502726,lng:122.065833322222},
{lat:-2.84931930577262,lng:122.0661111},
{lat:-2.84916665555556,lng:122.066363166602},
{lat:-2.84914154667872,lng:122.066388877778},
{lat:-2.84888887777778,lng:122.066659315907},
{lat:-2.8487269041007,lng:122.066388877778},
{lat:-2.8486111,lng:122.066170997591},
{lat:-2.84855221150843,lng:122.0661111},
{lat:-2.84833332222222,lng:122.065935115571},
{lat:-2.84818258240597,lng:122.0661111},
{lat:-2.84805554444445,lng:122.066269346576},
{lat:-2.84783569298772,lng:122.066388877778},
{lat:-2.84777776666667,lng:122.066459452688},
{lat:-2.84765984244126,lng:122.066666655556},
{lat:-2.84749998888889,lng:122.066824932962},
{lat:-2.84739298773276,lng:122.066944433333},
{lat:-2.84727793704049,lng:122.067222211111},
{lat:-2.84726244263743,lng:122.067499988889},
{lat:-2.847260330895,lng:122.067777766667},
{lat:-2.84731621791115,lng:122.068055544444},
{lat:-2.84748202569428,lng:122.068333322222},
{lat:-2.84749998888889,lng:122.068364114337},
{lat:-2.84765514168447,lng:122.0686111},
{lat:-2.84777776666667,lng:122.068817460535},
{lat:-2.84795518424862,lng:122.068888877778},
{lat:-2.84805554444445,lng:122.069018983862},
{lat:-2.848104802034,lng:122.069166655556},
{lat:-2.84819882388435,lng:122.069444433333},
{lat:-2.84829434232554,lng:122.069722211111},
{lat:-2.84833332222222,lng:122.069794189733},
{lat:-2.84843725752977,lng:122.069999988889},
{lat:-2.8486111,lng:122.070190085395},
{lat:-2.84869620227813,lng:122.070277766667},
{lat:-2.84888887777778,lng:122.070517949894},
{lat:-2.84891522919781,lng:122.070555544444},
{lat:-2.84903438172226,lng:122.070833322222},
{lat:-2.84916665555556,lng:122.070940463217},
{lat:-2.84926135818291,lng:122.0711111},
{lat:-2.84932533885194,lng:122.071388877778},
{lat:-2.84942722291638,lng:122.071666655556},
{lat:-2.84944443333333,lng:122.071717872352},
{lat:-2.8495173205976,lng:122.071944433333},
{lat:-2.84972221111111,lng:122.072218401681},
{lat:-2.84972351469859,lng:122.072222211111},
{lat:-2.84973556251195,lng:122.072499988889},
{lat:-2.84978946153129,lng:122.072777766667},
{lat:-2.84987528411215,lng:122.073055544444},
{lat:-2.84999998888889,lng:122.07319549991},
{lat:-2.85011069178765,lng:122.073333322222},
{lat:-2.85027776666667,lng:122.073521448746},
{lat:-2.8504176421193,lng:122.0736111},
{lat:-2.85055554444445,lng:122.073705474755},
{lat:-2.85068546742364,lng:122.073888877778},
{lat:-2.85076922176883,lng:122.074166655556},
{lat:-2.85075390207436,lng:122.074444433333},
{lat:-2.85061369231741,lng:122.074722211111},
{lat:-2.85055554444445,lng:122.074914176442},
{lat:-2.85053598218035,lng:122.074999988889},
{lat:-2.85055554444445,lng:122.075052750983},
{lat:-2.85070247154641,lng:122.075277766667},
{lat:-2.85064957710678,lng:122.075555544444},
{lat:-2.8505699744337,lng:122.075833322222},
{lat:-2.85060621657382,lng:122.0761111},
{lat:-2.85083332222222,lng:122.076351651314},
{lat:-2.85093813646785,lng:122.076388877778},
{lat:-2.8511111,lng:122.076418461651},
{lat:-2.85138887777778,lng:122.076402418606},
{lat:-2.85144600921197,lng:122.076388877778},
{lat:-2.85166665555556,lng:122.076295909932},
{lat:-2.85194443333334,lng:122.076218378281},
{lat:-2.85202904530734,lng:122.0761111},
{lat:-2.85222221111111,lng:122.075965140319},
{lat:-2.85242654324892,lng:122.0761111},
{lat:-2.85249998888889,lng:122.076187531903},
{lat:-2.85262572706264,lng:122.076388877778},
{lat:-2.85277776666667,lng:122.076582653119},
{lat:-2.85299189174475,lng:122.076666655556},
{lat:-2.85305554444445,lng:122.076700958376},
{lat:-2.85333332222222,lng:122.076857150247},
{lat:-2.8536111,lng:122.076882255192},
{lat:-2.85372518483401,lng:122.076666655556},
{lat:-2.85388887777778,lng:122.076456752451},
{lat:-2.85402776666667,lng:122.076456752451},]},
{ID:150,coord:[{lat:-2.85402776666667,lng:122.074762602355},
{lat:-2.85388887777778,lng:122.074762602355},
{lat:-2.85386546846744,lng:122.074722211111},
{lat:-2.85377061944905,lng:122.074444433333},
{lat:-2.8536111,lng:122.074173137812},
{lat:-2.85360869313033,lng:122.074166655556},
{lat:-2.8536111,lng:122.074105338799},
{lat:-2.85363462866098,lng:122.073888877778},
{lat:-2.85368093793323,lng:122.0736111},
{lat:-2.8536111,lng:122.073449363966},
{lat:-2.85358484679279,lng:122.073333322222},
{lat:-2.85355082104546,lng:122.073055544444},
{lat:-2.85353420080106,lng:122.072777766667},
{lat:-2.85349508649114,lng:122.072499988889},
{lat:-2.85345153683494,lng:122.072222211111},
{lat:-2.85340892379152,lng:122.071944433333},
{lat:-2.85335432660858,lng:122.071666655556},
{lat:-2.85333332222222,lng:122.071495109647},
{lat:-2.85331804919308,lng:122.071388877778},
{lat:-2.85332974142124,lng:122.0711111},
{lat:-2.85331684280755,lng:122.070833322222},
{lat:-2.85328657483835,lng:122.070555544444},
{lat:-2.85323861086161,lng:122.070277766667},
{lat:-2.85317199249168,lng:122.069999988889},
{lat:-2.85314183051581,lng:122.069722211111},
{lat:-2.85309929590712,lng:122.069444433333},
{lat:-2.85305554444445,lng:122.069217593559},
{lat:-2.85304843231459,lng:122.069166655556},
{lat:-2.8530368760274,lng:122.068888877778},
{lat:-2.85305083273331,lng:122.0686111},
{lat:-2.85305554444445,lng:122.068577734736},
{lat:-2.85309665584006,lng:122.068333322222},
{lat:-2.85318904287178,lng:122.068055544444},
{lat:-2.85333332222222,lng:122.067861779183},
{lat:-2.85337181358244,lng:122.067777766667},
{lat:-2.85346447347643,lng:122.067499988889},
{lat:-2.85353806505639,lng:122.067222211111},
{lat:-2.8536111,lng:122.067055071499},
{lat:-2.85364568111163,lng:122.066944433333},
{lat:-2.85386829695777,lng:122.066666655556},
{lat:-2.85388887777778,lng:122.066649061269},
{lat:-2.85402776666667,lng:122.066649061269},]},
{ID:151,coord:[{lat:-2.85402776666667,lng:122.063919703471},
{lat:-2.85388887777778,lng:122.063919703471},
{lat:-2.85388798784002,lng:122.063888877778},
{lat:-2.85385823290731,lng:122.0636111},
{lat:-2.85385823290731,lng:122.063472211111},]},
{ID:152,coord:[{lat:-2.84963436602753,lng:122.063472211111},
{lat:-2.84963436602753,lng:122.0636111},
{lat:-2.84965141166092,lng:122.063888877778},
{lat:-2.84959882753718,lng:122.064166655556},
{lat:-2.84962843376336,lng:122.064444433333},
{lat:-2.84967365446881,lng:122.064722211111},
{lat:-2.84966294238087,lng:122.064999988889},
{lat:-2.8496510065095,lng:122.065277766667},
{lat:-2.84970479750722,lng:122.065555544444},
{lat:-2.84972221111111,lng:122.065658981669},
{lat:-2.84976655944443,lng:122.065833322222},
{lat:-2.84972221111111,lng:122.065887666483},
{lat:-2.84962543418375,lng:122.0661111},
{lat:-2.8494835780882,lng:122.066388877778},
{lat:-2.84944443333333,lng:122.066476437766},
{lat:-2.8493499168502,lng:122.066666655556},
{lat:-2.84930345404238,lng:122.066944433333},
{lat:-2.84916665555556,lng:122.067214315164},
{lat:-2.84916222897237,lng:122.067222211111},
{lat:-2.84904708893083,lng:122.067499988889},
{lat:-2.84888887777778,lng:122.067609742436},
{lat:-2.84876657897253,lng:122.067499988889},
{lat:-2.8486111,lng:122.067245793271},
{lat:-2.84858963028329,lng:122.067222211111},
{lat:-2.84833332222222,lng:122.066969159165},
{lat:-2.84805554444445,lng:122.067130064428},
{lat:-2.84794697418482,lng:122.067222211111},
{lat:-2.84782485447677,lng:122.067499988889},
{lat:-2.84783638405347,lng:122.067777766667},
{lat:-2.84777776666667,lng:122.068054070658},
{lat:-2.84777743914016,lng:122.068055544444},
{lat:-2.84777776666667,lng:122.068056388984},
{lat:-2.84799412949846,lng:122.068333322222},
{lat:-2.84805554444445,lng:122.068353976604},
{lat:-2.84833332222222,lng:122.068422442035},
{lat:-2.84842955055586,lng:122.0686111},
{lat:-2.84849122301706,lng:122.068888877778},
{lat:-2.84851063692185,lng:122.069166655556},
{lat:-2.84851445701497,lng:122.069444433333},
{lat:-2.84857927356468,lng:122.069722211111},
{lat:-2.8486111,lng:122.069775475023},
{lat:-2.84876816415104,lng:122.069999988889},
{lat:-2.84888887777778,lng:122.070123973679},
{lat:-2.84900795369045,lng:122.070277766667},
{lat:-2.84916665555556,lng:122.070487731474},
{lat:-2.8492195010407,lng:122.070555544444},
{lat:-2.84944443333333,lng:122.070780979131},
{lat:-2.84948466567106,lng:122.070833322222},
{lat:-2.84954534058677,lng:122.0711111},
{lat:-2.84960534805231,lng:122.071388877778},
{lat:-2.84972221111111,lng:122.07164496551},
{lat:-2.84973520751793,lng:122.071666655556},
{lat:-2.84987700705257,lng:122.071944433333},
{lat:-2.84994257087451,lng:122.072222211111},
{lat:-2.84999057457926,lng:122.072499988889},
{lat:-2.84999998888889,lng:122.072538769678},
{lat:-2.85006273236904,lng:122.072777766667},
{lat:-2.85019399498036,lng:122.073055544444},
{lat:-2.85027776666667,lng:122.073164672264},
{lat:-2.85055554444445,lng:122.073317892742},
{lat:-2.85057455067594,lng:122.073333322222},
{lat:-2.85079411622149,lng:122.0736111},
{lat:-2.85083332222222,lng:122.073677785337},
{lat:-2.85100816890597,lng:122.073888877778},
{lat:-2.85107631758026,lng:122.074166655556},
{lat:-2.85103418156691,lng:122.074444433333},
{lat:-2.85097127548333,lng:122.074722211111},
{lat:-2.85103147138734,lng:122.074999988889},
{lat:-2.8511111,lng:122.075029748379},
{lat:-2.85114989490035,lng:122.074999988889},
{lat:-2.85138887777778,lng:122.074870069424},
{lat:-2.85145286781342,lng:122.074999988889},
{lat:-2.85151842062306,lng:122.075277766667},
{lat:-2.85166665555556,lng:122.075472226784},
{lat:-2.8517598479174,lng:122.075555544444},
{lat:-2.85194443333334,lng:122.075706811665},
{lat:-2.85219747049444,lng:122.075555544444},
{lat:-2.85222221111111,lng:122.075543397301},
{lat:-2.85228661239784,lng:122.075555544444},
{lat:-2.85249998888889,lng:122.075633797858},
{lat:-2.85260116456109,lng:122.075833322222},
{lat:-2.85277776666667,lng:122.076089652054},
{lat:-2.85280900112616,lng:122.0761111},
{lat:-2.85305554444445,lng:122.076258185816},
{lat:-2.85333332222222,lng:122.076259295951},
{lat:-2.8536111,lng:122.076153294158},
{lat:-2.85367219392423,lng:122.0761111},
{lat:-2.85388887777778,lng:122.075890592121},
{lat:-2.85402776666667,lng:122.075890592121},]},
{ID:153,coord:[{lat:-2.85402776666667,lng:122.07515187064},
{lat:-2.85388887777778,lng:122.07515187064},
{lat:-2.85380499798775,lng:122.074999988889},
{lat:-2.85365661519986,lng:122.074722211111},
{lat:-2.8536111,lng:122.074638021987},
{lat:-2.85352548336166,lng:122.074444433333},
{lat:-2.85340677734587,lng:122.074166655556},
{lat:-2.85336919178811,lng:122.073888877778},
{lat:-2.85336333457349,lng:122.0736111},
{lat:-2.85336207252986,lng:122.073333322222},
{lat:-2.85336884684305,lng:122.073055544444},
{lat:-2.85338435345621,lng:122.072777766667},
{lat:-2.85334102617818,lng:122.072499988889},
{lat:-2.85333332222222,lng:122.072444283071},
{lat:-2.85330935720866,lng:122.072222211111},
{lat:-2.85327272256738,lng:122.071944433333},
{lat:-2.85321401374112,lng:122.071666655556},
{lat:-2.85315338951722,lng:122.071388877778},
{lat:-2.85316468911203,lng:122.0711111},
{lat:-2.85314767067098,lng:122.070833322222},
{lat:-2.8531340336837,lng:122.070555544444},
{lat:-2.85308836677917,lng:122.070277766667},
{lat:-2.85305554444445,lng:122.070167546433},
{lat:-2.85300086972147,lng:122.069999988889},
{lat:-2.85295661262637,lng:122.069722211111},
{lat:-2.85292407936551,lng:122.069444433333},
{lat:-2.85289422714604,lng:122.069166655556},
{lat:-2.85287825789494,lng:122.068888877778},
{lat:-2.85286796722272,lng:122.0686111},
{lat:-2.85285208254908,lng:122.068333322222},
{lat:-2.85286519335082,lng:122.068055544444},
{lat:-2.85300747010953,lng:122.067777766667},
{lat:-2.85305554444445,lng:122.067627468377},
{lat:-2.85311255806199,lng:122.067499988889},
{lat:-2.85326670341995,lng:122.067222211111},
{lat:-2.85333332222222,lng:122.06713556266},
{lat:-2.85340546604083,lng:122.066944433333},
{lat:-2.8536111,lng:122.066672447233},
{lat:-2.85361537454925,lng:122.066666655556},
{lat:-2.85388887777778,lng:122.066432841058},
{lat:-2.85402776666667,lng:122.066432841058},]},
{ID:154,coord:[{lat:-2.85402776666667,lng:122.064874391115},
{lat:-2.85388887777778,lng:122.064874391115},
{lat:-2.85384726324446,lng:122.064722211111},
{lat:-2.8537862576653,lng:122.064444433333},
{lat:-2.85373400513473,lng:122.064166655556},
{lat:-2.853731877131,lng:122.063888877778},
{lat:-2.85370611112224,lng:122.0636111},
{lat:-2.85370611112224,lng:122.063472211111},]},
            ],
            deedsData :[
            {
                owner: 'Mukh Efendi, SKom',
                cert_no:'593.83.1498.BHDP.VII.2022',
                isVerified: true, 
                isValid: true,
                NEcoord:
                [
                    {N:9685589.373,E:396826.189},
                    {N:9685589.373,E:396926.189},
                    {N:9685489.373,E:396926.189},
                    {N:9685489.373,E:396826.189}
                ],
                coord:[],
            },
            {owner: 'Arpin',cert_no:'593.83.1499.BHDP.VII.2022',isVerified: true, isValid: true,
                NEcoord:[
                    {N:9685689.373,E:396626.189},
                    {N:9685689.373,E:396726.189},
                    {N:9685589.373,E:396726.189},
                    {N:9685589.373,E:396626.189}
                ],
                coord:[],
            },
            {owner: ' Carto',cert_no:'593.83.1500.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:396926.189},
            {N:9685189.373,E:397026.189},
            {N:9685089.373,E:397026.189},
            {N:9685089.373,E:396926.189}],coord:[],},
            {owner: ' Atman',cert_no:'593.83.1501.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:396726.189},
            {N:9685189.373,E:396826.189},
            {N:9685089.373,E:396826.189},
            {N:9685089.373,E:396726.189}],coord:[],},
            {owner: ' Abd Azis',cert_no:'593.83.1502.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396626.189},
{N:9685289.373,E:396726.189},
{N:9685189.373,E:396726.189},
{N:9685189.373,E:396626.189}],coord:[],},
{owner: ' Habri Barri',cert_no:'593.83.1503.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396626.189},
{N:9685389.373,E:396726.189},
{N:9685289.373,E:396726.189},
{N:9685289.373,E:396626.189}],coord:[],},
{owner: ' Darwin',cert_no:'593.83.1504.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:396826.189},
{N:9685689.373,E:396926.189},
{N:9685589.373,E:396926.189},
{N:9685589.373,E:396826.189}],coord:[],},
{owner: ' Edi Aksan',cert_no:'593.83.1505.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[
  {N:9685489.373,E:396626.189},
{N:9685489.373,E:396726.189}],coord:[],},
{owner: ' Muhammad Ihwan',cert_no:'593.83.1506.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396826.189},
{N:9685389.373,E:396926.189},
{N:9685289.373,E:396926.189},
{N:9685289.373,E:396826.189}],coord:[],},
{owner: ' Hasmudin',cert_no:'593.83.1507.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:396526.189},
{N:9685689.373,E:396626.189},
{N:9685589.373,E:396626.189},
{N:9685589.373,E:396526.189}],coord:[],},
{owner: ' Fandi Risaldi',cert_no:'593.83.1508.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:396826.189},
{N:9685489.373,E:396926.189},
{N:9685389.373,E:396926.189},
{N:9685389.373,E:396826.189}],coord:[],},
{owner: ' Erasmus Kukung',cert_no:'593.83.1509.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:396526.189},
{N:9685489.373,E:396626.189},
{N:9685389.373,E:396626.189},
{N:9685389.373,E:396526.189}],coord:[],},
{owner: ' Fazlurrahiman Nugraha, SP.',cert_no:'593.83.1510.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:396926.189},
{N:9685689.373,E:397026.189},
{N:9685589.373,E:397026.189},
{N:9685589.373,E:396926.189}],coord:[],},
{owner: ' Antan',cert_no:'593.83.1511.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:396826.189},
{N:9685189.373,E:396926.189},
{N:9685089.373,E:396926.189},
{N:9685089.373,E:396826.189}],coord:[],},
{owner: ' Hamdan',cert_no:'593.83.1512.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:396526.189},
{N:9685589.373,E:396626.189},
{N:9685489.373,E:396626.189},
{N:9685489.373,E:396526.189}],coord:[],},
{owner: ' Habil',cert_no:'593.83.1513.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396726.189},
{N:9685289.373,E:396826.189},
{N:9685189.373,E:396826.189},
{N:9685189.373,E:396726.189}],coord:[],},
{owner: ' Kardianto',cert_no:'593.83.1514.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:396626.189},
{N:9685189.373,E:396726.189},
{N:9685089.373,E:396726.189},
{N:9685089.373,E:396626.189}],coord:[],},
{owner: ' Muh. Ridwan',cert_no:'593.83.1515.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396826.189},
{N:9685289.373,E:396926.189},
{N:9685189.373,E:396926.189},
{N:9685189.373,E:396826.189}],coord:[],},
{owner: ' Faldi',cert_no:'593.83.1516.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396726.189},
{N:9685389.373,E:396826.189},
{N:9685289.373,E:396826.189},
{N:9685289.373,E:396726.189}],coord:[],},
{owner: ' Muhrin',cert_no:'593.83.1517.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396526.189},
{N:9685389.373,E:396626.189},
{N:9685289.373,E:396626.189},
{N:9685289.373,E:396526.189}],coord:[],},
{owner: ' Sukardi',cert_no:'593.83.1518.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:396626.189},
{N:9685589.373,E:396726.189},
{N:9685489.373,E:396726.189},
{N:9685489.373,E:396626.189}],coord:[],},
{owner: ' Yusran Afandi',cert_no:'593.83.1519.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396926.189},
{N:9685289.373,E:397026.189},
{N:9685189.373,E:397026.189},
{N:9685189.373,E:396926.189}],coord:[],},
{owner: ' Sahabudin',cert_no:'593.83.1521.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:396526.189},
{N:9685189.373,E:396626.189},
{N:9685089.373,E:396626.189},
{N:9685089.373,E:396526.189}],coord:[],},
{owner: ' Mursalim',cert_no:'593.83.1522.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:396926.189},
{N:9685589.373,E:397026.189},
{N:9685489.373,E:397026.189},
{N:9685489.373,E:396926.189}],coord:[],},
{owner: ' Murhadi Asmin',cert_no:'593.83.1523.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[
{N:9685489.373,E:397026.189},
{N:9685489.373,E:396926.189},
{N:9685389.373,E:396926.189},
{N:9685389.373,E:397026.189}],coord:[],},
{owner: ' Parman Sitanjak',cert_no:'593.83.1524.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:396726.189},
{N:9685589.373,E:396826.189},
{N:9685489.373,E:396826.189},
{N:9685489.373,E:396726.189}],coord:[],},
{owner: ' Nur Afni',cert_no:'593.83.1525.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396526.189},
{N:9685289.373,E:396626.189},
{N:9685189.373,E:396626.189},
{N:9685189.373,E:396526.189}],coord:[],},
{owner: ' Rizal Onami',cert_no:'593.83.1526.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396926.189},
{N:9685389.373,E:397026.189},
{N:9685289.373,E:397026.189},
{N:9685289.373,E:396926.189}],coord:[],},
{owner: ' Yunus M',cert_no:'593.83.1527.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397426.189},
{N:9685189.373,E:397526.189},
{N:9685089.373,E:397526.189},
{N:9685089.373,E:397426.189}],coord:[],},
{owner: ' Rajid D',cert_no:'593.83.1528.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397326.189},
{N:9685289.373,E:397426.189},
{N:9685189.373,E:397426.189},
{N:9685189.373,E:397326.189}],coord:[],},
{owner: ' Sumardi',cert_no:'593.83.1529.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397426.189},
{N:9685089.373,E:397526.189},
{N:9684989.373,E:397526.189},
{N:9684989.373,E:397426.189}],coord:[],},
{owner: ' Ramli',cert_no:'593.83.1530.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397126.189},
{N:9685489.373,E:397226.189},
{N:9685389.373,E:397226.189},
{N:9685389.373,E:397126.189}],coord:[],},
{owner: ' Sukri',cert_no:'593.83.1531.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397326.189},
{N:9685189.373,E:397426.189},
{N:9685089.373,E:397426.189},
{N:9685089.373,E:397326.189}],coord:[],},
{owner: ' Rahmat',cert_no:'593.83.1532.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397326.189},
{N:9685589.373,E:397426.189},
{N:9685489.373,E:397426.189},
{N:9685489.373,E:397326.189}],coord:[],},
{owner: ' Tasrim',cert_no:'593.83.1533.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397026.189},
{N:9685489.373,E:397126.189},
{N:9685389.373,E:397126.189},
{N:9685389.373,E:397026.189}],coord:[],},
{owner: ' Rudi T',cert_no:'593.83.1534.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397126.189},
{N:9685589.373,E:397226.189},
{N:9685489.373,E:397226.189},
{N:9685489.373,E:397126.189}],coord:[],},
{owner: ' Alimudin',cert_no:'593.83.1535.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397326.189},
{N:9685489.373,E:397426.189},
{N:9685389.373,E:397426.189},
{N:9685389.373,E:397326.189}],coord:[],},
{owner: ' Arman Amir Injil',cert_no:'593.83.1536.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397126.189},
{N:9685289.373,E:397226.189},
{N:9685189.373,E:397226.189},
{N:9685189.373,E:397126.189}],coord:[],},
{owner: ' Arman Nasir',cert_no:'593.83.1537.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397326.189},
{N:9685389.373,E:397426.189},
{N:9685289.373,E:397426.189},
{N:9685289.373,E:397326.189}],coord:[],},
{owner: ' Asnawi L',cert_no:'593.83.1538.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397326.189},
{N:9685089.373,E:397426.189},
{N:9684989.373,E:397426.189},
{N:9684989.373,E:397326.189}],coord:[],},
{owner: ' Awit',cert_no:'593.83.1539.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397226.189},
{N:9685289.373,E:397326.189},
{N:9685189.373,E:397326.189},
{N:9685189.373,E:397226.189}],coord:[],},
{owner: ' Burhan Lamido',cert_no:'593.83.1540.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397026.189},
{N:9685689.373,E:397126.189},
{N:9685589.373,E:397126.189},
{N:9685589.373,E:397026.189}],coord:[],},
{owner: ' Daeria',cert_no:'593.83.1541.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397226.189},
{N:9685689.373,E:397326.189},
{N:9685589.373,E:397326.189},
{N:9685589.373,E:397226.189}],coord:[],},
{owner: ' Dafrin',cert_no:'593.83.1542.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397426.189},
{N:9685489.373,E:397526.189},
{N:9685389.373,E:397526.189},
{N:9685389.373,E:397426.189}],coord:[],},
{owner: ' Hastin',cert_no:'593.83.1543.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397226.189},
{N:9685389.373,E:397326.189},
{N:9685289.373,E:397326.189},
{N:9685289.373,E:397226.189}],coord:[],},
{owner: ' Jafudin',cert_no:'593.83.1544.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397226.189},
{N:9685489.373,E:397326.189},
{N:9685389.373,E:397326.189},
{N:9685389.373,E:397226.189}],coord:[],},
{owner: ' Mukmin',cert_no:'593.83.1545.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397426.189},
{N:9685389.373,E:397526.189},
{N:9685289.373,E:397526.189},
{N:9685289.373,E:397426.189}],coord:[],},
{owner: ' Rocky Mandiri Putra',cert_no:'593.83.1546.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397126.189},
{N:9685189.373,E:397226.189},
{N:9685089.373,E:397226.189},
{N:9685089.373,E:397126.189}],coord:[],},
{owner: ' Gusrif Risal',cert_no:'593.83.1547.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397226.189},
{N:9685589.373,E:397326.189},
{N:9685489.373,E:397326.189},
{N:9685489.373,E:397226.189}],coord:[],},
{owner: ' Kenarti',cert_no:'593.83.1548.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397026.189},
{N:9685389.373,E:397126.189},
{N:9685289.373,E:397126.189},
{N:9685289.373,E:397026.189}],coord:[],},
{owner: ' Junaedin',cert_no:'593.83.1549.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397026.189},
{N:9685589.373,E:397126.189},
{N:9685489.373,E:397126.189},
{N:9685489.373,E:397026.189}],coord:[],},
{owner: ' Jupri M',cert_no:'593.83.1550.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397326.189},
{N:9685689.373,E:397426.189},
{N:9685589.373,E:397426.189},
{N:9685589.373,E:397326.189}],coord:[],},
{owner: ' Junaeda',cert_no:'593.83.1551.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397026.189},
{N:9685289.373,E:397126.189},
{N:9685189.373,E:397126.189},
{N:9685189.373,E:397026.189}],coord:[],},
{owner: ' Ertiany Rara',cert_no:'593.83.1552.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397226.189},
{N:9685189.373,E:397326.189},
{N:9685089.373,E:397326.189},
{N:9685089.373,E:397226.189}],coord:[],},
{owner: ' Jafar Fajeku',cert_no:'593.83.1553.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397126.189},
{N:9685389.373,E:397226.189},
{N:9685289.373,E:397226.189},
{N:9685289.373,E:397126.189}],coord:[],},
{owner: ' Mariana Udin',cert_no:'593.83.1554.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397126.189},
{N:9685689.373,E:397226.189},
{N:9685589.373,E:397226.189},
{N:9685589.373,E:397126.189}],coord:[],},
{owner: ' Abd. Hamid',cert_no:'593.83.1555.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397426.189},
{N:9685289.373,E:397526.189},
{N:9685189.373,E:397526.189},
{N:9685189.373,E:397426.189}],coord:[],},
{owner: ' Adaria',cert_no:'593.83.1556.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397026.189},
{N:9685189.373,E:397126.189},
{N:9685089.373,E:397126.189},
{N:9685089.373,E:397026.189}],coord:[],},
{owner: ' Misba',cert_no:'593.83.1557.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:398226.189},
{N:9685589.373,E:398326.189},
{N:9685489.373,E:398326.189},
{N:9685489.373,E:398226.189}],coord:[],},
{owner: ' Rama Dheo G. Y',cert_no:'593.83.1558.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:398426.189},
{N:9685589.373,E:398526.189},
{N:9685489.373,E:398526.189},
{N:9685489.373,E:398426.189}],coord:[],},
{owner: ' Mukrim Yusup',cert_no:'593.83.1559.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:398326.189},
{N:9685489.373,E:398426.189},
{N:9685389.373,E:398426.189},
{N:9685389.373,E:398326.189}],coord:[],},
{owner: ' Patarudin',cert_no:'593.83.1560.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685759.047,E:398426.189},
{N:9685761.455,E:398486.32},
{N:9685714.399,E:398501.526},
{N:9685677.196,E:398519.444},
{N:9685652.839,E:398520.582},
{N:9685637.225,E:398526.613},
{N:9685639.373,E:398426.189}],coord:[],},
{owner: ' Ramadan Tandi Sasong',cert_no:'593.83.1561.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:398326.189},
{N:9685789.373,E:398426.189},
{N:9685689.373,E:398426.189},
{N:9685689.373,E:398326.189}],coord:[],},
{owner: ' Irfan Mahmud, SH',cert_no:'593.83.1562.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397926.189},
{N:9685789.373,E:398026.189},
{N:9685689.373,E:398026.189},
{N:9685689.373,E:397926.189}],coord:[],},
{owner: ' Nuripa',cert_no:'593.83.1563.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:398026.189},
{N:9685789.373,E:398126.189},
{N:9685689.373,E:398126.189},
{N:9685689.373,E:398026.189}],coord:[],},
{owner: ' Sandi Pratama',cert_no:'593.83.1564.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:398126.189},
{N:9685789.373,E:398226.189},
{N:9685689.373,E:398226.189},
{N:9685689.373,E:398126.189}],coord:[],},
{owner: ' Supriadi',cert_no:'593.83.1565.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[
{N:9685489.373,E:398526.189},
{N:9685495.397,E:398609.201},
{N:9685467.78,E:398642.595},
{N:9685440.959,E:398654.915},
{N:9685440.959,E:398607.449},
{N:9685389.373,E:398608.51},
{N:9685389.373,E:398526.189}],coord:[],},
{owner: ' Suaela',cert_no:'593.83.1566.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397926.189},
{N:9685889.373,E:398026.189},
{N:9685789.189,E:398026.189},
{N:9685789.189,E:397926.189}],coord:[],},
{owner: ' Syarifuddin',cert_no:'593.83.1567.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:398226.189},
{N:9685789.373,E:398326.189},
{N:9685689.373,E:398326.189},
{N:9685689.373,E:398226.189}],coord:[],},
{owner: ' Sanuddin',cert_no:'593.83.1568.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:398226.189},
{N:9685689.373,E:398326.189},
{N:9685589.373,E:398326.189},
{N:9685589.373,E:398226.189}],coord:[],},
{owner: ' Suradi',cert_no:'593.83.1569.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:398326.189},
{N:9685589.373,E:398426.189},
{N:9685489.373,E:398426.189},
{N:9685489.373,E:398326.189}],coord:[],},
{owner: ' Syarifudin Udin',cert_no:'593.83.1570.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:398026.189},
{N:9685989.373,E:398126.189},
{N:9685889.373,E:398126.189},
{N:9685889.373,E:398026.189}],coord:[],},
{owner: ' Rustam Efendi',cert_no:'593.83.1571.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685857.013,E:398326.189},
{N:9685855.605,E:398432.834},
{N:9685761.455,E:398486.32},
{N:9685759.047,E:398426.189},
{N:9685789.373,E:398426.189},
{N:9685789.373,E:398326.189}],coord:[],},
{owner: ' Rahmayanti',cert_no:'593.83.1572.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:398226.189},
{N:9685489.373,E:398326.189},
{N:9685389.373,E:398326.189},
{N:9685389.373,E:398226.189}],coord:[],},
{owner: ' Julfikar Bualo',cert_no:'593.83.1573.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:398126.189},
{N:9685989.373,E:398226.189},
{N:9685889.373,E:398226.189},
{N:9685889.373,E:398126.189}],coord:[],},
{owner: ' Arni Hube',cert_no:'593.83.1576.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685639.373,E:398426.189},
{N:9685637.225,E:398526.613},
{N:9685582.132,E:398552.845},
{N:9685555.114,E:398556.547},
{N:9685495.397,E:398609.201},
{N:9685489.373,E:398526.189},
{N:9685589.373,E:398526.189},
{N:9685589.373,E:398426.189}],coord:[],},
{owner: ' Arminzal',cert_no:'593.83.1577.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:398126.189},
{N:9685889.373,E:398226.189},
{N:9685789.373,E:398226.189},
{N:9685789.373,E:398126.189}],coord:[],},
{owner: ' Asnaldin, S. Sos',cert_no:'593.83.1578.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:398126.189},
{N:9685689.373,E:398226.189},
{N:9685589.373,E:398226.189},
{N:9685589.373,E:398126.189}],coord:[],},
{owner: ' Arwin Samrun',cert_no:'593.83.1579.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:398426.189},
{N:9685489.373,E:398526.189},
{N:9685389.373,E:398526.189},
{N:9685389.373,E:398426.189}],coord:[],},
{owner: 'Herwin Paallo',cert_no:'593.83.1580.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:398326.189},
{N:9685689.373,E:398426.189},
{N:9685589.373,E:398426.189},
{N:9685589.373,E:398326.189}],coord:[],},
{owner: 'Asrul H. Mahmud',cert_no:'593.83.1581.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397826.189},
{N:9685989.373,E:397926.189},
{N:9685889.373,E:397926.189},
{N:9685889.373,E:397826.189}],coord:[],},
{owner: 'Armin U',cert_no:'593.83.1582.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685978.027,E:398326.189},
{N:9685988.004,E:398364.48},
{N:9685943.428,E:398395.455},
{N:9685855.605,E:398432.834},
{N:9685857.013,E:398326.189}],coord:[],},
{owner: ' Abdul Wahid Labaso',cert_no:'593.83.1583.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397926.189},
{N:9685989.373,E:398026.189},
{N:9685889.373,E:398026.189},
{N:9685889.373,E:397926.189}],coord:[],},
{owner: ' Arsin B',cert_no:'593.83.1584.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:398226.189},
{N:9685889.373,E:398326.189},
{N:9685789.373,E:398326.189},
{N:9685789.373,E:398226.189}],coord:[],},
{owner: ' Arafik',cert_no:'593.83.1585.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397826.189},
{N:9685789.373,E:397926.189},
{N:9685689.373,E:397926.189},
{N:9685689.373,E:397826.189}],coord:[],},
{owner: ' Udin Syamsudin',cert_no:'593.83.1586.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:398226.189},
{N:9685989.373,E:398326.189},
{N:9685889.373,E:398326.189},
{N:9685889.373,E:398226.189}],coord:[],},
{owner: ' Dian Saputra',cert_no:'593.83.1587.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397326.189},
{N:9684889.373,E:397426.189},
{N:9684789.373,E:397426.189},
{N:9684789.373,E:397326.189}],coord:[],},
{owner: ' Isa',cert_no:'593.83.1588.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:396897.886},
{N:9684889.373,E:396926.189},
{N:9684789.373,E:396926.189},
{N:9684789.373,E:397066.128},
{N:9684705.552,E:397066.768},
{N:9684747.551,E:396970.574},
{N:9684795.768,E:396899.499}],coord:[],},
{owner: ' Jusman',cert_no:'593.83.1589.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397326.189},
{N:9684989.373,E:397426.189},
{N:9684889.373,E:397426.189},
{N:9684889.373,E:397326.189}],coord:[],},
{owner: ' Haryono',cert_no:'593.83.1590.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397126.189},
{N:9685089.373,E:397226.189},
{N:9684989.373,E:397226.189},
{N:9684989.373,E:397126.189}],coord:[],},
{owner: ' Harifin',cert_no:'593.83.1591.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397226.189},
{N:9684789.373,E:397326.189},
{N:9684689.373,E:397326.189},
{N:9684689.373,E:397226.189}],coord:[],},
{owner: ' Irma',cert_no:'593.83.1592.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397226.189},
{N:9684989.373,E:397326.189},
{N:9684889.373,E:397326.189},
{N:9684889.373,E:397226.189}],coord:[],},
{owner: ' Kamarudin',cert_no:'593.83.1593.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397326.189},
{N:9684789.373,E:397426.189},
{N:9684689.373,E:397426.189},
{N:9684689.373,E:397326.189}],coord:[],},
{owner: ' Iskandar',cert_no:'593.83.1594.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397026.189},
{N:9684989.373,E:397126.189},
{N:9684889.373,E:397126.189},
{N:9684889.373,E:397026.189}],coord:[],},
{owner: ' Kasmat',cert_no:'593.83.1595.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397026.189},
{N:9685089.373,E:397126.189},
{N:9684989.373,E:397126.189},
{N:9684989.373,E:397026.189}],coord:[],},
{owner: ' Alwan Jaya',cert_no:'593.83.1596.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397126.189},
{N:9684989.373,E:397226.189},
{N:9684889.373,E:397226.189},
{N:9684889.373,E:397126.189}],coord:[],},
{owner: ' Muhammad Fidyan Arfin',cert_no:'593.83.1597.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:396926.189},
{N:9685089.373,E:397026.189},
{N:9684989.373,E:397026.189},
{N:9684989.373,E:396926.189}],coord:[],},
{owner: ' Mas_adu',cert_no:'593.83.1598.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:396726.189},
{N:9685089.373,E:396826.189},
{N:9684989.373,E:396826.189},
{N:9684989.373,E:396726.189}],coord:[],},
{owner: ' Nurhayat Moh Alwi Arsad',cert_no:'593.83.1599.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397026.189},
{N:9684889.373,E:397126.189},
{N:9684789.373,E:397126.189},
{N:9684789.373,E:397026.189}],coord:[],},
{owner: ' Rahman',cert_no:'593.83.1600.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:396926.189},
{N:9684889.373,E:397026.189},
{N:9684789.373,E:397026.189},
{N:9684789.373,E:396926.189}],coord:[],},
{owner: ' Samsu Daris',cert_no:'593.83.1601.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397126.189},
{N:9684889.373,E:397226.189},
{N:9684789.373,E:397226.189},
{N:9684789.373,E:397126.189}],coord:[],},
{owner: ' Yasma',cert_no:'593.83.1602.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:396926.189},
{N:9684989.373,E:397026.189},
{N:9684889.373,E:397026.189},
{N:9684889.373,E:396926.189}],coord:[],},
{owner: ' Johanis',cert_no:'593.83.1603.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:396326.189},
{N:9685089.373,E:396481.052},
{N:9684986.103,E:396481.001},
{N:9685016.527,E:396448.112},
{N:9685029.172,E:396349.215}],coord:[],},
{owner: ' Fadli Salam',cert_no:'593.83.1604.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397226.189},
{N:9684889.373,E:397326.189},
{N:9684789.373,E:397326.189},
{N:9684789.373,E:397226.189}],coord:[],},
{owner: ' Arifin Alwi',cert_no:'593.83.1605.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:396826.189},
{N:9684989.373,E:396926.189},
{N:9684889.373,E:396926.189},
{N:9684889.373,E:396826.189}],coord:[],},
{owner: ' Almin',cert_no:'593.83.1606.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397066.128},
{N:9684789.373,E:397126.189},
{N:9684689.373,E:397126.189},
{N:9684689.373,E:397226.189},
{N:9684631.814,E:397222.158},
{N:9684658.522,E:397147.585},
{N:9684705.552,E:397066.768}],coord:[],},
{owner: ' Amirudin',cert_no:'593.83.1607.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397226.189},
{N:9685089.373,E:397326.189},
{N:9684989.373,E:397326.189},
{N:9684989.373,E:397226.189}],coord:[],},
{owner: ' Aksar',cert_no:'593.83.1608.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397126.189},
{N:9684789.373,E:397226.189},
{N:9684689.373,E:397226.189},
{N:9684689.373,E:397126.189}],coord:[],},
{owner: 'Ahdin Roma',cert_no:'593.83.1609.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397426.189},
{N:9684989.373,E:397526.189},
{N:9684889.373,E:397526.189},
{N:9684889.373,E:397426.189}],coord:[],},
{owner: 'Ahdin Roma',cert_no:'593.83.1610.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:397226.189},
{N:9684689.373,E:397326.189},
{N:9684607.207,E:397326.189},
{N:9684631.814,E:397222.158}],coord:[],},
{owner: 'Ahdin Roma',cert_no:'593.83.1611.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:396692.36},
{N:9684989.373,E:396826.189},
{N:9684912.525,E:396826.189},
{N:9684916.223,E:396720.104},
{N:9684948.499,E:396678.808}],coord:[],},
{owner: 'Albert',cert_no:'593.83.1610.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:396481.052},
{N:9685089.373,E:396526.189},
{N:9684989.373,E:396526.189},
{N:9684989.373,E:396692.36},
{N:9684948.499,E:396678.808},
{N:9684958.006,E:396668.893},
{N:9684961.5,E:396598.273},
{N:9684971.675,E:396495.116},
{N:9684986.103,E:396481.001}],coord:[],},
{owner: 'Ahdin Roma',cert_no:'593.83.1613.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:396526.189},
{N:9685089.373,E:396626.189},
{N:9684989.373,E:396626.189},
{N:9684989.373,E:396526.189}],coord:[],},
{owner: 'Oscar Mandala',cert_no:'593.83.1614.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:396626.189},
{N:9685089.373,E:396726.189},
{N:9684989.373,E:396726.189},
{N:9684989.373,E:396626.189}],coord:[],},
{owner: 'Asis Turangan',cert_no:'593.83.1615.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9687061.562,E:397823.279},
{N:9686989.373,E:397891.807},
{N:9686989.373,E:397826.189},
{N:9686889.373,E:397826.189},
{N:9686889.373,E:397753.904},
{N:9686925.372,E:397740.073},
{N:9686962.554,E:397765.144},
{N:9687004.337,E:397805.868}],coord:[],},
{owner: 'Ajmain Yusdin',cert_no:'593.83.1616.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686789.373,E:398226.189},
{N:9686789.373,E:398326.189},
{N:9686689.373,E:398326.189},
{N:9686689.373,E:398226.189}],coord:[],},
{owner: 'Rianto Rantong',cert_no:'593.83.1617.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686989.373,E:397826.189},
{N:9686989.373,E:397926.189},
{N:9686889.373,E:397926.189},
{N:9686889.373,E:397826.189}],coord:[],},
{owner: 'Ratmin',cert_no:'593.83.1618.BHDP.VII.2022',isVerified: false, isValid: false,NEcoord:[{N:9686989.373,E:398126.189},
{N:9686989.373,E:398126.189},
{N:9686889.373,E:398126.189},
{N:9686889.373,E:398026.189}],coord:[],},
{owner: 'Nasrudin Namlu',cert_no:'593.83.1619.BHDP.VII.2022',isVerified: false, isValid: false,NEcoord:[{N:9686989.373,E:398126.189},
{N:9686889.373,E:398126.189},
{N:9686789.373,E:398126.189},
{N:9686789.373,E:398026.189}],coord:[],},
{owner: 'Ramadhan',cert_no:'593.83.1620.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686989.373,E:397926.189},
{N:9686989.373,E:398026.189},
{N:9686889.373,E:398026.189},
{N:9686889.373,E:397926.189}],coord:[],},
{owner: 'Jon Sabara',cert_no:'593.83.1621.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686789.373,E:398026.189},
{N:9686789.373,E:398126.189},
{N:9686689.373,E:398126.189},
{N:9686689.373,E:398026.189}],coord:[],},
{owner: 'Sahar',cert_no:'593.83.1622.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686889.373,E:398126.189},
{N:9686889.373,E:398226.189},
{N:9686789.373,E:398226.189},
{N:9686789.373,E:398126.189}],coord:[],},
{owner: 'Widyawati Janudin',cert_no:'593.83.1623.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686789.373,E:398126.189},
{N:9686789.373,E:398226.189},
{N:9686689.373,E:398226.189},
{N:9686689.373,E:398126.189}],coord:[],},
{owner: 'Erwin H. Genda',cert_no:'593.83.1624.BHDP.VII.2022',isVerified: false, isValid: false,NEcoord:[{N:9686989.373,E:398126.189},
{N:9686989.373,E:398126.189},
{N:9686889.373,E:398226.189},
{N:9686889.373,E:398126.189}],coord:[],},
{owner: 'Ilham',cert_no:'593.83.1625.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397826.189},
{N:9684889.373,E:397926.189},
{N:9684789.373,E:397926.189},
{N:9684789.373,E:397826.189}],coord:[],},
{owner: 'Wahyudin',cert_no:'593.83.1626.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397626.189},
{N:9684789.373,E:397726.189},
{N:9684689.373,E:397726.189},
{N:9684689.373,E:397626.189}],coord:[],},
{owner: 'Siti Asrini Junaedo',cert_no:'593.83.1627.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397626.189},
{N:9684989.373,E:397726.189},
{N:9684889.373,E:397726.189},
{N:9684889.373,E:397626.189}],coord:[],},
{owner: 'Desmin',cert_no:'593.83.1628.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397726.189},
{N:9684789.373,E:397826.189},
{N:9684689.373,E:397826.189},
{N:9684689.373,E:397726.189}],coord:[],},
{owner: 'Ibrahim',cert_no:'593.83.1629.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:397526.189},
{N:9684689.373,E:397626.189},
{N:9684589.373,E:397626.189},
{N:9684589.373,E:397526.189}],coord:[],},
{owner: 'Efendi',cert_no:'593.83.1630.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397826.189},
{N:9684789.373,E:397926.189},
{N:9684689.373,E:397926.189},
{N:9684689.373,E:397826.189}],coord:[],},
{owner: 'Ihwan Moh. Thaiyeb',cert_no:'593.83.1631.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:397426.189},
{N:9684689.373,E:397526.189},
{N:9684589.373,E:397526.189},
{N:9684589.373,E:397426.189}],coord:[],},
{owner: 'Sirajudin Aga',cert_no:'593.83.1632.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397826.189},
{N:9685089.373,E:397926.189},
{N:9684989.373,E:397926.189},
{N:9684989.373,E:397826.189}],coord:[],},
{owner: 'Kobra',cert_no:'593.83.1633.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397626.189},
{N:9684889.373,E:397726.189},
{N:9684789.373,E:397726.189},
{N:9684789.373,E:397626.189}],coord:[],},
{owner: 'Ahyudin Masempo',cert_no:'593.83.1634.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397726.189},
{N:9685089.373,E:397826.189},
{N:9684989.373,E:397826.189},
{N:9684989.373,E:397726.189}],coord:[],},
{owner: 'Hasnawi',cert_no:'593.83.1635.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684589.373,E:397497.144},
{N:9684589.373,E:397626.189},
{N:9684495.989,E:397644.437},
{N:9684519.95,E:397539.264},
{N:9684542.358,E:397495.653}],coord:[],},
{owner: 'Afidin',cert_no:'593.83.1636.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397626.189},
{N:9685089.373,E:397726.189},
{N:9684989.373,E:397726.189},
{N:9684989.373,E:397626.189}],coord:[],},
{owner: 'Abd. Malik',cert_no:'593.83.1637.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684589.373,E:397826.189},
{N:9684589.373,E:397926.189},
{N:9684499.258,E:397940.38},
{N:9684495.336,E:397844.364},
{N:9684475.276,E:397813.745}],coord:[],},
{owner: 'Darwis Musa',cert_no:'593.83.1638.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:397626.189},
{N:9684689.373,E:397726.189},
{N:9684589.373,E:397726.189},
{N:9684589.373,E:397626.189}],coord:[],},
{owner: 'Harlin',cert_no:'593.83.1639.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:397826.189},
{N:9684689.373,E:397926.189},
{N:9684589.373,E:397926.189},
{N:9684589.373,E:397826.189}],coord:[],},
{owner: 'Jamaludin',cert_no:'593.83.1640.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397926.189},
{N:9685089.373,E:398026.189},
{N:9684989.373,E:398026.189},
{N:9684989.373,E:397926.189}],coord:[],},
{owner: 'Misra',cert_no:'593.83.1641.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397726.189},
{N:9684989.373,E:397826.189},
{N:9684889.373,E:397826.189},
{N:9684889.373,E:397726.189}],coord:[],},
{owner: 'Lubis',cert_no:'593.83.1642.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397526.189},
{N:9684889.373,E:397626.189},
{N:9684789.373,E:397626.189},
{N:9684789.373,E:397526.189}],coord:[],},
{owner: 'Iswanto',cert_no:'593.83.1643.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397926.189},
{N:9684989.373,E:398026.189},
{N:9684889.373,E:398026.189},
{N:9684889.373,E:397926.189}],coord:[],},
{owner: 'Muh. Tamrin',cert_no:'593.83.1644.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684589.373,E:397626.189},
{N:9684589.373,E:397726.189},
{N:9684477.801,E:397741.61},
{N:9684485.044,E:397716.32},
{N:9684495.989,E:397644.437}],coord:[],},
{owner: 'Nur Afni N',cert_no:'593.83.1645.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:397726.189},
{N:9684689.373,E:397826.189},
{N:9684589.373,E:397826.189},
{N:9684589.373,E:397726.189}],coord:[],},
{owner: 'Nikma',cert_no:'593.83.1646.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397426.189},
{N:9684889.373,E:397526.189},
{N:9684789.373,E:397526.189},
{N:9684789.373,E:397426.189}],coord:[],},
{owner: 'Naima',cert_no:'593.83.1647.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397426.189},
{N:9684789.373,E:397526.189},
{N:9684689.373,E:397526.189},
{N:9684689.373,E:397426.189}],coord:[],},
{owner: 'Nadima',cert_no:'593.83.1648.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684589.373,E:397726.189},
{N:9684589.373,E:397826.189},
{N:9684475.276,E:397813.745},
{N:9684468.458,E:397784.48},
{N:9684477.801,E:397741.61}],coord:[],},
{owner: 'Rawina',cert_no:'593.83.1649.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:397526.189},
{N:9685089.373,E:397626.189},
{N:9684989.373,E:397626.189},
{N:9684989.373,E:397526.189}],coord:[],},
{owner: 'Robin',cert_no:'593.83.1650.BHDP.VII.2022 ',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397726.189},
{N:9684889.373,E:397826.189},
{N:9684789.373,E:397826.189},
{N:9684789.373,E:397726.189}],coord:[],},
{owner: 'Rusno',cert_no:'593.83.1651.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:397362.929},
{N:9684689.373,E:397426.189},
{N:9684589.373,E:397426.189},
{N:9684589.373,E:397497.144},
{N:9684542.358,E:397495.653},
{N:9684568.764,E:397399.578},
{N:9684607.207,E:397359.815}],coord:[],},
{owner: 'Ruiyana Udin',cert_no:'593.83.1652.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397526.189},
{N:9684989.373,E:397626.189},
{N:9684889.373,E:397626.189},
{N:9684889.373,E:397526.189}],coord:[],},
{owner: 'Saenudin',cert_no:'593.83.1653.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:397826.189},
{N:9684989.373,E:397926.189},
{N:9684889.373,E:397926.189},
{N:9684889.373,E:397826.189}],coord:[],},
{owner: 'Sakir AR',cert_no:'593.83.1654.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397526.189},
{N:9684789.373,E:397626.189},
{N:9684689.373,E:397626.189},
{N:9684689.373,E:397526.189}],coord:[],},
{owner: 'Adrianus Gintu',cert_no:'593.83.1655.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:398226.189},
{N:9685089.373,E:398326.189},
{N:9684989.373,E:398326.189},
{N:9684989.373,E:398226.189}],coord:[],},
{owner: 'Agus Salim',cert_no:'593.83.1656.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:398226.189},
{N:9684889.373,E:398326.189},
{N:9684789.373,E:398326.189},
{N:9684789.373,E:398226.189}],coord:[],},
{owner: 'Amirudin',cert_no:'593.83.1657.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:398026.189},
{N:9684689.373,E:398126.189},
{N:9684589.373,E:398126.189},
{N:9684589.373,E:398026.189}],coord:[],},
{owner: 'Martinus Kiding',cert_no:'593.83.1658.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:398126.189},
{N:9684789.373,E:398226.189},
{N:9684689.373,E:398226.189},
{N:9684689.373,E:398126.189}],coord:[],},
{owner: 'Nurlin',cert_no:'593.83.1659.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:398526.189},
{N:9684989.373,E:398589.503},
{N:9684910.144,E:398608.313},
{N:9684875.503,E:398594.266},
{N:9684854.568,E:398566.116},
{N:9684822.65,E:398533.699},
{N:9684889.373,E:398526.189}],coord:[],},
{owner: 'Samarudin Samendeo',cert_no:'593.83.1660.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:398026.189},
{N:9685089.373,E:398126.189},
{N:9684989.373,E:398126.189},
{N:9684989.373,E:398026.189}],coord:[],},
{owner: 'Yulianis',cert_no:'593.83.1661.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:398126.189},
{N:9684889.373,E:398226.189},
{N:9684789.373,E:398226.189},
{N:9684789.373,E:398126.189}],coord:[],},
{owner: 'Sultan',cert_no:'593.83.1662.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:398126.189},
{N:9685089.373,E:398226.189},
{N:9684989.373,E:398226.189},
{N:9684989.373,E:398126.189}],coord:[],},
{owner: 'Marhadi',cert_no:'593.83.1663.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684589.373,E:397926.189},
{N:9684589.373,E:398026.189},
{N:9684502.586,E:398048.892},
{N:9684489.709,E:398020.54},
{N:9684491.895,E:397982.03},
{N:9684499.256,E:397940.38}],coord:[],},
{owner: 'Salihuddin',cert_no:'593.83.1664.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684722.157,E:398226.189},
{N:9684721.525,E:398334.903},
{N:9684694.187,E:398327.812},
{N:9684632.608,E:398294.536},
{N:9684575.503,E:398226.189}],coord:[],},
{owner: 'Arifuddin',cert_no:'593.83.1665.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684689.373,E:397926.189},
{N:9684689.373,E:398026.189},
{N:9684589.373,E:398026.189},
{N:9684589.373,E:397926.189}],coord:[],},
{owner: 'Ferdy Johan Ruaw',cert_no:'593.83.1666.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:398126.189},
{N:9684989.373,E:398226.189},
{N:9684889.373,E:398226.189},
{N:9684889.373,E:398126.189}],coord:[],},
{owner: 'Ekatusalam',cert_no:'593.83.1667.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:397926.189},
{N:9684789.373,E:398026.189},
{N:9684689.373,E:398026.189},
{N:9684689.373,E:397926.189}],coord:[],},
{owner: 'Haryanto',cert_no:'593.83.1668.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:398326.189},
{N:9684989.373,E:398426.189},
{N:9684889.373,E:398426.189},
{N:9684889.373,E:398326.189}],coord:[],},
{owner: 'Darto',cert_no:'593.83.1669.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:398626.189},
{N:9685089.373,E:398693.581},
{N:9684981.954,E:398688.929},
{N:9684981.605,E:398668.39},
{N:9684960.775,E:398653.707},
{N:9684939.055,E:398628.731},
{N:9684910.144,E:398608.313},
{N:9684989.373,E:398589.503},
{N:9684989.373,E:398626.189}],coord:[],},
{owner: 'Rahmawati',cert_no:'593.83.1670.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:398026.189},
{N:9684789.373,E:398126.189},
{N:9684689.373,E:398126.189},
{N:9684689.373,E:398026.189}],coord:[],},
{owner: 'Dominggus',cert_no:'593.83.1671.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:398426.189},
{N:9684889.373,E:398526.189},
{N:9684822.65,E:398533.699},
{N:9684796.199,E:398505.81},
{N:9684785.787,E:398480.884},
{N:9684789.373,E:398426.189}],coord:[],},
{owner: 'Junaedi',cert_no:'593.83.1672.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:398226.189},
{N:9684989.373,E:398326.189},
{N:9684889.373,E:398326.189},
{N:9684889.373,E:398226.189}],coord:[],},
{owner: 'Nasir Nuhun',cert_no:'593.83.1673.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:397926.189},
{N:9684889.373,E:398026.189},
{N:9684789.373,E:398026.189},
{N:9684789.373,E:397926.189}],coord:[],},
{owner: 'Ridwan',cert_no:'593.83.1674.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684789.373,E:398226.189},
{N:9684785.452,E:398480.081},
{N:9684776.443,E:398406.566},
{N:9684753.068,E:398343.45},
{N:9684721.525,E:398334.903},
{N:9684722.157,E:398226.189}],coord:[],},
{owner: 'Samrin Hasan',cert_no:'593.83.1675.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:398426.189},
{N:9685089.373,E:398526.189},
{N:9684989.373,E:398526.189},
{N:9684989.373,E:398426.189}],coord:[],},
{owner: 'Anton Nurdin',cert_no:'593.83.1676.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:398026.189},
{N:9684989.373,E:398126.189},
{N:9684889.373,E:398126.189},
{N:9684889.373,E:398026.189}],coord:[],},
{owner: 'Jhony Balalembang',cert_no:'593.83.1677.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684889.373,E:398326.189},
{N:9684889.373,E:398426.189},
{N:9684789.373,E:398426.189},
{N:9684789.373,E:398326.189}],coord:[],},
{owner: 'Jumadi Somat',cert_no:'593.83.1678.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684989.373,E:398426.189},
{N:9684989.373,E:398526.189},
{N:9684889.373,E:398526.189},
{N:9684889.373,E:398426.189}],coord:[],},
{owner: 'Ahiri',cert_no:'593.83.1709.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397126.189},
{N:9686089.373,E:397226.189},
{N:9685989.373,E:397226.189},
{N:9685989.373,E:397126.189}],coord:[],},
{owner: 'Amrullah',cert_no:'593.83.1710.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:396926.189},
{N:9685989.373,E:397026.189},
{N:9685889.373,E:397026.189},
{N:9685889.373,E:396926.189}],coord:[],},
{owner: 'Armin Mohamad Ali',cert_no:'593.83.1711.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:396526.189},
{N:9685789.373,E:396626.189},
{N:9685689.373,E:396626.189},
{N:9685689.373,E:396526.189}],coord:[],},
{owner: 'Hapsia',cert_no:'593.83.1712.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686131.558,E:396988.479},
{N:9686179.394,E:397043.855},
{N:9686231.541,E:397124.746},
{N:9686277.434,E:397165.727},
{N:9686189.373,E:397175.87},
{N:9686189.373,E:397126.189},
{N:9686135.451,E:397126.189}],coord:[],},
{owner: 'Harjon',cert_no:'593.83.1713.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397026.189},
{N:9686089.373,E:397126.189},
{N:9685989.373,E:397126.189},
{N:9685989.373,E:397026.189}],coord:[],},
{owner: 'Hasrin',cert_no:'593.83.1714.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:396926.189},
{N:9685789.373,E:397026.189},
{N:9685689.373,E:397026.189},
{N:9685689.373,E:396926.189}],coord:[],},
{owner: 'Haswan',cert_no:'593.83.1715.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397126.189},
{N:9685989.373,E:397226.189},
{N:9685889.373,E:397226.189},
{N:9685889.373,E:397126.189}],coord:[],},
{owner: 'H Amrin Arif',cert_no:'593.83.1716.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:396826.189},
{N:9685889.373,E:396926.189},
{N:9685789.373,E:396926.189},
{N:9685789.373,E:396826.189}],coord:[],},
{owner: 'Nihaya',cert_no:'593.83.1717.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686277.434,E:397165.727},
{N:9686289.317,E:397223.243},
{N:9686305.698,E:397268.817},
{N:9686189.373,E:397269.898},
{N:9686189.373,E:397175.87}],coord:[],},
{owner: 'Jabir P. Beluano',cert_no:'593.83.1718.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685701.02,E:396285.248},
{N:9685735.052,E:396363.182},
{N:9685735.528,E:396526.189},
{N:9685689.373,E:396526.189},
{N:9685689.373,E:396326.189},
{N:9685659.369,E:396326.189}],coord:[],},
{owner: 'Jasrin',cert_no:'593.83.1719.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:396726.189},
{N:9685789.373,E:396826.189},
{N:9685689.373,E:396826.189},
{N:9685689.373,E:396726.189}],coord:[],},
{owner: 'Laiwan',cert_no:'593.83.1720.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397226.189},
{N:9685789.373,E:397326.189},
{N:9685689.373,E:397326.189},
{N:9685689.373,E:397226.189}],coord:[],},
{owner: 'Lias',cert_no:'593.83.1721.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685847.732,E:396647.546},
{N:9685893.519,E:396782.243},
{N:9685789.373,E:396777.344},
{N:9685789.373,E:396646.856}],coord:[],},
{owner: 'Mardi',cert_no:'593.83.1722.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685735.052,E:396363.182},
{N:9685783.669,E:396469.07},
{N:9685799.897,E:396531.74},
{N:9685825.098,E:396581.553},
{N:9685847.732,E:396647.546},
{N:9685789.373,E:396646.856},
{N:9685789.373,E:396526.189},
{N:9685735.528,E:396526.189}],coord:[],},
{owner: 'Muh. Aswad Nunu',cert_no:'593.83.1723.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397125.87},
{N:9686189.373,E:397226.189},
{N:9686089.373,E:397226.189},
{N:9686089.373,E:397125.87}],coord:[],},
{owner: 'Muhrim Ahmad Hadi Supyin',cert_no:'593.83.1724.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397226.189},
{N:9685889.373,E:397326.189},
{N:9685789.373,E:397326.189},
{N:9685789.373,E:397226.189}],coord:[],},
{owner: 'Mustamin',cert_no:'593.83.1725.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397226.189},
{N:9686189.373,E:397326.189},
{N:9686089.373,E:397326.189},
{N:9686089.373,E:397226.189}],coord:[],},
{owner: 'Nahran',cert_no:'593.83.1726.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397126.189},
{N:9685789.373,E:397226.189},
{N:9685689.373,E:397226.189},
{N:9685689.373,E:397126.189}],coord:[],},
{owner: 'Nurdin',cert_no:'593.83.1727.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397226.189},
{N:9685989.373,E:397326.189},
{N:9685889.373,E:397326.189},
{N:9685889.373,E:397226.189}],coord:[],},
{owner: 'Nurhaena',cert_no:'593.83.1728.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:396926.189},
{N:9685889.373,E:397026.189},
{N:9685789.373,E:397026.189},
{N:9685789.373,E:396926.189}],coord:[],},
{owner: 'Nuriyati',cert_no:'593.83.1729.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397026.189},
{N:9685889.373,E:397126.189},
{N:9685789.373,E:397126.189},
{N:9685789.373,E:397026.189}],coord:[],},
{owner: 'Rahman',cert_no:'593.83.1730.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:396626.189},
{N:9685789.373,E:396726.189},
{N:9685689.373,E:396726.189},
{N:9685689.373,E:396626.189}],coord:[],},
{owner: 'Ridwan',cert_no:'593.83.1731.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397026.189},
{N:9685989.373,E:397126.189},
{N:9685889.373,E:397126.189},
{N:9685889.373,E:397026.189}],coord:[],},
{owner: 'Rosmin',cert_no:'593.83.1732.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397126.189},
{N:9685889.373,E:397226.189},
{N:9685789.373,E:397226.189},
{N:9685789.373,E:397126.189}],coord:[],},
{owner: 'Sabri',cert_no:'593.83.1733.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685946.954,E:396871.352},
{N:9685945.478,E:396926.189},
{N:9685889.373,E:396926.189},
{N:9685889.373,E:396826.189},
{N:9685789.373,E:396826.189},
{N:9685789.373,E:396777.344},
{N:9685893.579,E:396782.243}],coord:[],},
{owner: 'Subaeda',cert_no:'593.83.1734.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686057.601,E:396924.206},
{N:9686057.571,E:397026.189},
{N:9685989.373,E:397026.189},
{N:9685989.373,E:396926.189},
{N:9685945.478,E:396926.189},
{N:9685946.954,E:396871.352},
{N:9685971.426,E:396889.848},
{N:9686036.847,E:396907.494}],coord:[],},
{owner: 'Sudirman',cert_no:'593.83.1735.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397226.189},
{N:9686089.373,E:397326.189},
{N:9685989.373,E:397326.189},
{N:9685989.373,E:397226.189}],coord:[],},
{owner: 'Suhadin M',cert_no:'593.83.1736.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:396826.189},
{N:9685789.373,E:396926.189},
{N:9685689.373,E:396926.189},
{N:9685689.373,E:396826.189}],coord:[],},
{owner: 'Suhardin',cert_no:'593.83.1737.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397026.189},
{N:9685789.373,E:397126.189},
{N:9685689.373,E:397126.189},
{N:9685689.373,E:397026.189}],coord:[],},
{owner: 'Abd. Salam',cert_no:'593.83.1738.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686131.558,E:396988.479},
{N:9686135.451,E:397126.189},
{N:9686089.373,E:397126.189},
{N:9686089.373,E:397026.189},
{N:9686057.571,E:397026.189},
{N:9686057.601,E:396924.206},
{N:9686096.945,E:396949.654}],coord:[],},
{owner: 'Abd. Asis Katemba',cert_no:'593.83.1739.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686137.752,E:398226.189},
{N:9686137.723,E:398272.215},
{N:9686019.238,E:398299.097},
{N:9685991.279,E:398356.533},
{N:9685978.027,E:398326.189},
{N:9685989.373,E:398326.189},
{N:9685989.373,E:398226.189}],coord:[],},
{owner: 'Abd. Majid Syamsudin',cert_no:'593.83.1740.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397826.189},
{N:9686089.373,E:397926.189},
{N:9685989.373,E:397926.189},
{N:9685989.373,E:397826.189}],coord:[],},
{owner: 'Aras',cert_no:'593.83.1741.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:398126.189},
{N:9686189.373,E:398226.189},
{N:9686089.373,E:398226.189},
{N:9686089.373,E:398126.189}],coord:[],},
{owner: 'Asnar',cert_no:'593.83.1742.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686760.652,E:397629.133},
{N:9686759.019,E:397726.189},
{N:9686689.373,E:397726.189},
{N:9686689.373,E:397626.189},
{N:9686642.06,E:397626.189},
{N:9686630.513,E:397589.55},
{N:9686681.071,E:397587.632},
{N:9686725.62,E:397611.691}],coord:[],},
{owner: 'Burhanudin',cert_no:'593.83.1743.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397926.189},
{N:9686089.373,E:398026.189},
{N:9685989.373,E:398026.189},
{N:9685989.373,E:397926.189}],coord:[],},
{owner: 'Edward',cert_no:'593.83.1744.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686389.373,E:397926.189},
{N:9686389.373,E:398026.189},
{N:9686289.373,E:398026.189},
{N:9686289.373,E:397926.189}],coord:[],},
{owner: 'Feniati',cert_no:'593.83.1745.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686630.513,E:397589.55},
{N:9686642.06,E:397626.189},
{N:9686389.373,E:397626.189},
{N:9686389.373,E:397577.169},
{N:9686494.57,E:397577.109},
{N:9686525.565,E:397597.965},
{N:9686584.565,E:397589.487}],coord:[],},
{owner: ' Hatir Satta Minggu',cert_no:'593.83.1749.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:398126.189},
{N:9686289.373,E:398226.189},
{N:9686189.373,E:398226.189},
{N:9686189.373,E:398126.189}],coord:[],},
{owner: ' Hendrik',cert_no:'593.83.1750.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686389.373,E:397626.189},
{N:9686389.373,E:397726.189},
{N:9686289.373,E:397726.189},
{N:9686289.373,E:397626.189}],coord:[],},
{owner: ' Ida Bagus Kade Darmaya',cert_no:'593.83.1751.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:397826.189},
{N:9686289.373,E:397926.189},
{N:9686189.373,E:397926.189},
{N:9686189.373,E:397826.189}],coord:[],},
{owner: ' Moh. Rifal',cert_no:'593.83.1752.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686389.373,E:397526.189},
{N:9686389.373,E:397626.189},
{N:9686289.373,E:397626.189},
{N:9686289.373,E:397526.189}],coord:[],},
{owner: ' Muh. Yamin Baddu',cert_no:'593.83.1753.BHDP.VII.2022',isVerified: true, isValid: true,
NEcoord:[
    {N:9686689.373,E:397626.189},
    {N:9686689.373,E:397726.189},
    {N:9686589.373,E:397726.189},
    {N:9686589.373,E:397626.189}],coord:[],},
{owner: ' Risnawati',cert_no:'593.83.1754.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:397926.189},
{N:9686289.373,E:398026.189},
{N:9686189.373,E:398026.189},
{N:9686189.373,E:397926.189}],coord:[],},
{owner: ' Roman Arief',cert_no:'593.83.1755.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397826.189},
{N:9686189.373,E:397926.189},
{N:9686089.373,E:397926.189},
{N:9686089.373,E:397826.189}],coord:[],},
{owner: ' Rusdianto',cert_no:'593.83.1756.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686339.373,E:398226.189},
{N:9686329.742,E:398285.44},
{N:9686297.381,E:398287.778},
{N:9686288.398,E:398272.318},
{N:9686238.726,E:398271.839},
{N:9686192.437,E:398279.281},
{N:9686137.723,E:398272.215},
{N:9686137.752,E:398226.189}],coord:[],},
{owner: ' Safarudin',cert_no:'593.83.1757.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:397626.189},
{N:9686289.373,E:397726.189},
{N:9686189.373,E:397726.189},
{N:9686189.373,E:397626.189}],coord:[],},
{owner: ' SahruI',cert_no:'593.83.1758.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:398026.189},
{N:9686089.373,E:398126.189},
{N:9685989.373,E:398126.189},
{N:9685989.373,E:398026.189}],coord:[],},
{owner: ' Sapriyadi',cert_no:'593.83.1759.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686392.486,E:397457.628},
{N:9686430.408,E:397512.744},
{N:9686494.57,E:397577.109},
{N:9686389.373,E:397577.169},
{N:9686389.373,E:397526.189},
{N:9686289.373,E:397526.189},
{N:9686289.373,E:397508.206}],coord:[],},
{owner: ' Sarmin',cert_no:'593.83.1760.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686389.373,E:397726.189},
{N:9686389.373,E:397826.189},
{N:9686289.373,E:397826.189},
{N:9686289.373,E:397726.189}],coord:[],},
{owner: ' Sitti Aminah',cert_no:'593.83.1761.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686389.373,E:398126.189},
{N:9686389.373,E:398226.189},
{N:9686289.373,E:398226.189},
{N:9686289.373,E:398126.189}],coord:[],},
{owner: ' Taufik',cert_no:'593.83.1762.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686489.373,E:397626.189},
{N:9686489.373,E:397726.189},
{N:9686389.373,E:397726.189},
{N:9686389.373,E:397626.189}],coord:[],},
{owner: ' Yusri',cert_no:'593.83.1763.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:398026.189},
{N:9686189.373,E:398126.189},
{N:9686089.373,E:398126.189},
{N:9686089.373,E:398026.189}],coord:[],},
{owner: ' Yusuf Ibrahim',cert_no:'593.83.1764.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:398026.189},
{N:9686289.373,E:398126.189},
{N:9686189.373,E:398126.189},
{N:9686189.373,E:398026.189}],coord:[],},
{owner: ' Andi Mustaring',cert_no:'593.83.1765.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:397726.189},
{N:9686289.373,E:397826.189},
{N:9686189.373,E:397826.189},
{N:9686189.373,E:397726.189}],coord:[],},
{owner: ' Andre Anggara',cert_no:'593.83.1766.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[
{N:9686399.373,E:398226.189},
{N:9686389.373,E:398376.851},
{N:9686332.983,E:398382.319},
{N:9686314.518,E:398317.271},
{N:9686297.381,E:398287.778},
{N:9686329.742,E:398285.44},
{N:9686339.373,E:398226.189}],coord:[],},
{owner: ' Irman',cert_no:'593.83.1767.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686589.373,E:397626.189},
{N:9686589.373,E:397726.189},
{N:9686489.373,E:397726.189},
{N:9686489.373,E:397626.189}],coord:[],},
{owner: ' Muh. Risal',cert_no:'593.83.1768.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686389.373,E:397826.189},
{N:9686389.373,E:397926.189},
{N:9686289.373,E:397926.189},
{N:9686289.373,E:397826.189}],coord:[],},
{owner: ' Samra',cert_no:'593.83.1803.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686489.373,E:398226.189},
{N:9686489.373,E:398326.189},
{N:9686389.373,E:398326.189},
{N:9686389.373,E:398226.189}],coord:[],},
{owner: ' Samir Salindo',cert_no:'593.83.1804.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686589.373,E:397826.189},
{N:9686589.373,E:397926.189},
{N:9686489.373,E:397926.189},
{N:9686489.373,E:397826.189}],coord:[],},
{owner: ' Udin Kondo',cert_no:'593.83.1805.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686889.373,E:397826.189},
{N:9686889.373,E:397926.189},
{N:9686789.373,E:397926.189},
{N:9686789.373,E:397826.189}],coord:[],},
{owner: ' Titin',cert_no:'593.83.1806.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686789.373,E:397926.189},
{N:9686789.373,E:398026.189},
{N:9686689.373,E:398026.189},
{N:9686689.373,E:397926.189}],coord:[],},
{owner: ' Tima Lajumu',cert_no:'593.83.1807.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686689.373,E:397826.189},
{N:9686689.373,E:397926.189},
{N:9686589.373,E:397926.189},
{N:9686589.373,E:397826.189}],coord:[],},
{owner: ' Suprianto D.',cert_no:'593.83.1808.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686789.373,E:397826.189},
{N:9686789.373,E:397926.189},
{N:9686689.373,E:397926.189},
{N:9686689.373,E:397826.189}],coord:[],},
{owner: ' Sarifudin M.',cert_no:'593.83.1809.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686489.373,E:397726.189},
{N:9686489.373,E:397826.189},
{N:9686389.373,E:397826.189},
{N:9686389.373,E:397726.189}],coord:[],},
{owner: ' Sidik Danudin',cert_no:'593.83.1810.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686589.373,E:397926.189},
{N:9686589.373,E:398026.189},
{N:9686489.373,E:398026.189},
{N:9686489.373,E:397926.189}],coord:[],},
{owner: ' Kamaludin',cert_no:'593.83.1811.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686489.373,E:398026.189},
{N:9686489.373,E:398126.189},
{N:9686389.373,E:398126.189},
{N:9686389.373,E:398026.189}],coord:[],},
{owner: ' Jabar',cert_no:'593.83.1812.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686689.373,E:397726.189},
{N:9686689.373,E:397826.189},
{N:9686589.373,E:397826.189},
{N:9686589.373,E:397726.189}],coord:[],},
{owner: ' Illang Musa, S.Pd',cert_no:'593.83.1813.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686589.373,E:398326.189},
{N:9686589.373,E:398426.189},
{N:9686489.373,E:398426.189},
{N:9686489.373,E:398326.189}],coord:[],},
{owner: ' Hendrik',cert_no:'593.83.1814.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686689.373,E:398226.189},
{N:9686689.373,E:398326.189},
{N:9686589.373,E:398326.189},
{N:9686589.373,E:398226.189}],coord:[],},
{owner: ' Hendrik Januddin',cert_no:'593.83.1815.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686889.373,E:397726.189},
{N:9686889.373,E:397826.189},
{N:9686789.373,E:397826.189},
{N:9686789.373,E:397726.189}],coord:[],},
{owner: ' Purwandi Umarwan, ST',cert_no:'593.83.1816.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686489.373,E:398126.189},
{N:9686489.373,E:398226.189},
{N:9686389.373,E:398226.189},
{N:9686389.373,E:398126.189}],coord:[],},
{owner: ' Masber Musa, SH',cert_no:'593.83.1817.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686889.373,E:397926.189},
{N:9686889.373,E:398026.189},
{N:9686789.373,E:398026.189},
{N:9686789.373,E:397926.189}],coord:[],},
{owner: ' Muh Nur',cert_no:'593.83.1818.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686489.373,E:397926.189},
{N:9686489.373,E:398026.189},
{N:9686389.373,E:398026.189},
{N:9686389.373,E:397926.189}],coord:[],},
{owner: ' Najomuddin',cert_no:'593.83.1819.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686689.373,E:398126.189},
{N:9686689.373,E:398226.189},
{N:9686589.373,E:398226.189},
{N:9686589.373,E:398126.189}],coord:[],},
{owner: ' Nasrudin',cert_no:'593.83.1820.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686646.409,E:398426.189},
{N:9686645.528,E:398496.343},
{N:9686609.505,E:398526.704},
{N:9686579.171,E:398497.022},
{N:9686539.069,E:398489.315},
{N:9686509.307,E:398491.678},
{N:9686518.582,E:398426.189}],coord:[],},
{owner: ' Nursang',cert_no:'593.83.1821.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686807.684,E:397633.438},
{N:9686876.017,E:397696.472},
{N:9686906.578,E:397715.058},
{N:9686925.372,E:397740.073},
{N:9686889.373,E:397753.904},
{N:9686889.373,E:397726.189},
{N:9686759.019,E:397726.189},
{N:9686760.652,E:397629.133}],coord:[],},
{owner: ' Erni',cert_no:'593.83.1822.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686589.373,E:397726.189},
{N:9686589.373,E:397826.189},
{N:9686489.373,E:397826.189},
{N:9686489.373,E:397726.189}],coord:[],},
{owner: ' Ebit',cert_no:'593.83.1823.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686589.373,E:398026.189},
{N:9686589.373,E:398126.189},
{N:9686489.373,E:398126.189},
{N:9686489.373,E:398026.189}],coord:[],},
{owner: ' Bahtiar',cert_no:'593.83.1824.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686489.373,E:398326.189},
{N:9686489.373,E:398426.189},
{N:9686389.373,E:398426.189},
{N:9686389.373,E:398326.189}],coord:[],},
{owner: ' Arif DG. Sittuju',cert_no:'593.83.1825.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686689.373,E:398026.189},
{N:9686689.373,E:398126.189},
{N:9686589.373,E:398126.189},
{N:9686589.373,E:398026.189}],coord:[],},
{owner: ' Asri',cert_no:'593.83.1826.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686789.373,E:397726.189},
{N:9686789.373,E:397826.189},
{N:9686689.373,E:397826.189},
{N:9686689.373,E:397726.189}],coord:[],},
{owner: ' Arfan',cert_no:'593.83.1827.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686689.373,E:398326.189},
{N:9686689.373,E:398426.189},
{N:9686589.373,E:398426.189},
{N:9686589.373,E:398326.189}],coord:[],},
{owner: ' Ahmadu',cert_no:'593.83.1828.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686689.373,E:397926.189},
{N:9686689.373,E:398026.189},
{N:9686589.373,E:398026.189},
{N:9686589.373,E:397926.189}],coord:[],},
{owner: ' Azdhin Y.',cert_no:'593.83.1829.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686589.373,E:398126.189},
{N:9686589.373,E:398226.189},
{N:9686489.373,E:398226.189},
{N:9686489.373,E:398126.189}],coord:[],},
{owner: ' Darmanto Bidolong',cert_no:'593.83.1830.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686589.373,E:398226.189},
{N:9686589.373,E:398326.189},
{N:9686489.373,E:398326.189},
{N:9686489.373,E:398226.189}],coord:[],},
{owner: ' Ferdi',cert_no:'593.83.1831.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686518.582,E:398426.189},
{N:9686509.307,E:398491.678},
{N:9686442.552,E:398476.942},
{N:9686381.196,E:398474.656},
{N:9686332.983,E:398382.317},
{N:9686389.373,E:398376.851},
{N:9686389.373,E:398426.189}],coord:[],},
{owner: ' Rahim',cert_no:'593.83.1875.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686489.373,E:397826.189},
{N:9686489.373,E:397926.189},
{N:9686389.373,E:397926.189},
{N:9686389.373,E:397826.189}],coord:[],},
{owner: ' Ikrawati',cert_no:'593.83.1876.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:398026.189},
{N:9685889.373,E:398126.189},
{N:9685789.373,E:398126.189},
{N:9685789.373,E:398026.189}],coord:[],},
{owner: ' Darsin Muksin',cert_no:'593.83.1877.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397826.189},
{N:9685889.373,E:397926.189},
{N:9685789.373,E:397926.189},
{N:9685789.373,E:397826.189}],coord:[],},
{owner: ' Fidel Rawton ',cert_no:'593.83.1878.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685496.239,E:395933.595},
{N:9685489.373,E:396026.189},
{N:9685389.373,E:396026.189},
{N:9685398.502,E:395915.414}],coord:[],},
{owner: ' Rusdin',cert_no:'593.83.1879.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:396126.189},
{N:9685489.373,E:396226.189},
{N:9685389.373,E:396226.189},
{N:9685389.373,E:396126.189}],coord:[],},
{owner: ' Ruslan',cert_no:'593.83.1880.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685296.449,E:395944.964},
{N:9685289.373,E:396026.189},
{N:9685289.373,E:396126.189},
{N:9685216.797,E:396126.189},
{N:9685210.787,E:396053.229},
{N:9685249.915,E:396005.746}],coord:[],},
{owner: ' Syawaludin R.',cert_no:'593.83.1881.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:396426.189},
{N:9685589.373,E:396526.189},
{N:9685489.373,E:396526.189},
{N:9685489.373,E:396426.189}],coord:[],},
{owner: ' Suardi Azis',cert_no:'593.83.1882.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:396226.189},
{N:9685589.373,E:396326.189},
{N:9685489.373,E:396326.189},
{N:9685489.373,E:396226.189}],coord:[],},
{owner: ' Samad',cert_no:'593.83.1883.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:396426.189},
{N:9685489.373,E:396526.189},
{N:9685389.373,E:396526.189},
{N:9685389.373,E:396426.189}],coord:[],},
{owner: ' Suryanti',cert_no:'593.83.1884.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:396426.189},
{N:9685689.373,E:396526.189},
{N:9685589.373,E:396526.189},
{N:9685589.373,E:396426.189}],coord:[],},
{owner: ' Siti Maria Ulfa Musa',cert_no:'593.83.1885.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396326.189},
{N:9685289.373,E:396426.189},
{N:9685189.373,E:396426.189},
{N:9685189.373,E:396326.189}],coord:[],},
{owner: ' Udin Kudusia',cert_no:'593.83.1886.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396126.189},
{N:9685389.373,E:396226.189},
{N:9685289.373,E:396226.189},
{N:9685289.373,E:396126.189}],coord:[],},
{owner: ' Zubedi',cert_no:'593.83.1887.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396326.189},
{N:9685389.373,E:396426.189},
{N:9685289.373,E:396426.189},
{N:9685289.373,E:396326.189}],coord:[],},
{owner: ' Irwan',cert_no:'593.83.1888.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:396026.189},
{N:9685489.373,E:396126.189},
{N:9685389.373,E:396126.189},
{N:9685389.373,E:396026.189}],coord:[],},
{owner: ' Moh Basar Salim',cert_no:'593.83.1889.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:396326.189},
{N:9685189.373,E:396426.189},
{N:9685089.373,E:396426.189},
{N:9685089.373,E:396326.189}],coord:[],},
{owner: ' Isdar Roman',cert_no:'593.83.1890.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:396426.189},
{N:9685189.373,E:396526.189},
{N:9685089.373,E:396526.189},
{N:9685089.373,E:396426.189}],coord:[],},
{owner: ' Jufri',cert_no:'593.83.1891.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685124.363,E:396242.678},
{N:9685189.373,E:396245.462},
{N:9685189.373,E:396326.189},
{N:9685089.373,E:396326.189},
{N:9685029.172,E:396349.215},
{N:9685033.391,E:396322.335},
{N:9685079.914,E:396277.864},
{N:9685107.221,E:396266.491}],coord:[],},
{owner: ' Indra Jaya',cert_no:'593.83.1892.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396426.189},
{N:9685289.373,E:396526.189},
{N:9685189.373,E:396526.189},
{N:9685189.373,E:396426.189}],coord:[],},
{owner: ' Husnia',cert_no:'593.83.1893.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685559.03,E:396148.841},
{N:9685617.314,E:396168.484},
{N:9685656.785,E:396185.511},
{N:9685680.494,E:396219.359},
{N:9685589.373,E:396226.189},
{N:9685489.373,E:396226.189},
{N:9685489.373,E:396185.314}],coord:[],},
{owner: ' Hasmun',cert_no:'593.83.1894.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396226.189},
{N:9685289.373,E:396326.189},
{N:9685189.373,E:396326.189},
{N:9685189.373,E:396226.189}],coord:[],},
{owner: ' H. Nawir',cert_no:'593.83.1895.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685210.715,E:396035.208},
{N:9685216.797,E:396126.189},
{N:9685189.373,E:396126.189},
{N:9685189.373,E:396245.462},
{N:9685124.363,E:396242.678},
{N:9685142.191,E:396198.072},
{N:9685161.982,E:396073.658}],coord:[],},
{owner: ' Rosa Sulistati',cert_no:'593.83.1896.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:396326.189},
{N:9685489.373,E:396426.189},
{N:9685389.373,E:396426.189},
{N:9685389.373,E:396326.189}],coord:[],},
{owner: ' Daeng Raja',cert_no:'593.83.1897.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:396226.189},
{N:9685489.373,E:396326.189},
{N:9685389.373,E:396326.189},
{N:9685389.373,E:396226.189}],coord:[],},
{owner: ' Asnawi',cert_no:'593.83.1898.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396426.189},
{N:9685389.373,E:396526.189},
{N:9685289.373,E:396526.189},
{N:9685289.373,E:396426.189}],coord:[],},
{owner: ' Asri',cert_no:'593.83.1899.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685496.239,E:395933.595},
{N:9685527.478,E:395947.424},
{N:9685590.914,E:396011.343},
{N:9685584.317,E:396064.749},
{N:9685489.373,E:396064.822},
{N:9685489.373,E:396026.189}],coord:[],},
{owner: ' Alfandi Tampeindo',cert_no:'593.83.1900.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396026.189},
{N:9685389.373,E:396126.189},
{N:9685289.373,E:396126.189},
{N:9685289.373,E:396026.189}],coord:[],},
{owner: ' Badawi',cert_no:'593.83.1901.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685398.429,E:395915.394},
{N:9685389.373,E:396026.189},
{N:9685289.373,E:396026.189},
{N:9685299.093,E:395942.075},
{N:9685374.847,E:395916.757}],coord:[],},
{owner: ' Asmaun',cert_no:'593.83.1902.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:396226.189},
{N:9685389.373,E:396326.189},
{N:9685289.373,E:396326.189},
{N:9685289.373,E:396226.189}],coord:[],},
{owner: ' Boine J',cert_no:'593.83.1903.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:396326.189},
{N:9685689.373,E:396426.189},
{N:9685589.373,E:396426.189},
{N:9685589.373,E:396326.189}],coord:[],},
{owner: ' Amaludin Udin',cert_no:'593.83.1904.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685584.317,E:396064.749},
{N:9685590.03,E:396148.841},
{N:9685489.373,E:396185.314},
{N:9685489.373,E:396064.822}],coord:[],},
{owner: ' Aris',cert_no:'593.83.1905.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685680.494,E:396219.359},
{N:9685701.02,E:396285.248},
{N:9685659.369,E:396326.189},
{N:9685589.373,E:396326.189},
{N:9685589.373,E:396226.189}],coord:[],},
{owner: ' Alton Tampeindo',cert_no:'593.83.1906.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:396126.189},
{N:9685289.373,E:396226.189},
{N:9685189.373,E:396226.189},
{N:9685189.373,E:396126.189}],coord:[],},
{owner: ' Baba Alimundin',cert_no:'593.83.1907.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:396326.189},
{N:9685589.373,E:396426.189},
{N:9685489.373,E:396426.189},
{N:9685489.373,E:396326.189}],coord:[],},
{owner: ' Rony Mustafa',cert_no:'593.83.1908.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397526.189},
{N:9685489.373,E:397626.189},
{N:9685389.373,E:397626.189},
{N:9685389.373,E:397526.189}],coord:[],},
{owner: ' Ronald',cert_no:'593.83.1909.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397626.189},
{N:9685489.373,E:397726.189},
{N:9685389.373,E:397726.189},
{N:9685389.373,E:397626.189}],coord:[],},
{owner: ' Risman',cert_no:'593.83.1910.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397826.189},
{N:9685689.373,E:397926.189},
{N:9685589.373,E:397926.189},
{N:9685589.373,E:397826.189}],coord:[],},
{owner: ' Arwanto',cert_no:'593.83.1911.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397426.189},
{N:9685589.373,E:397526.189},
{N:9685489.373,E:397526.189},
{N:9685489.373,E:397426.189}],coord:[],},
{owner: ' Ruslin',cert_no:'593.83.1912.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397926.189},
{N:9685289.373,E:398026.189},
{N:9685189.373,E:398026.189},
{N:9685189.373,E:397926.189}],coord:[],},
{owner: ' Suaro',cert_no:'593.83.1913.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397526.189},
{N:9685389.373,E:397626.189},
{N:9685289.373,E:397626.189},
{N:9685289.373,E:397526.189}],coord:[],},
{owner: ' Syahrir',cert_no:'593.83.1914.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397526.189},
{N:9685689.373,E:397626.189},
{N:9685589.373,E:397626.189},
{N:9685589.373,E:397526.189}],coord:[],},
{owner: ' Tahir M.',cert_no:'593.83.1915.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397826.189},
{N:9685289.373,E:397926.189},
{N:9685189.373,E:397926.189},
{N:9685189.373,E:397826.189}],coord:[],},
{owner: ' Yohanis Y Tumuntuan',cert_no:'593.83.1916.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397526.189},
{N:9685589.373,E:397626.189},
{N:9685489.373,E:397626.189},
{N:9685489.373,E:397526.189}],coord:[],},
{owner: ' Rahman',cert_no:'593.83.1917.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397626.189},
{N:9685389.373,E:397726.189},
{N:9685289.373,E:397726.189},
{N:9685289.373,E:397626.189}],coord:[],},
{owner: ' Abd. Haris',cert_no:'593.83.1918.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397426.189},
{N:9685689.373,E:397526.189},
{N:9685589.373,E:397526.189},
{N:9685589.373,E:397426.189}],coord:[],},
{owner: ' Abdul Malik',cert_no:'593.83.1919.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397726.189},
{N:9685289.373,E:397826.189},
{N:9685189.373,E:397826.189},
{N:9685189.373,E:397726.189}],coord:[],},
{owner: ' Abas',cert_no:'593.83.1920.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397926.189},
{N:9685389.373,E:398026.189},
{N:9685289.373,E:398026.189},
{N:9685289.373,E:397926.189}],coord:[],},
{owner: ' Afdal',cert_no:'593.83.1921.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397726.189},
{N:9685689.373,E:397826.189},
{N:9685589.373,E:397826.189},
{N:9685589.373,E:397726.189}],coord:[],},
{owner: ' Aswan A.',cert_no:'593.83.1922.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397726.189},
{N:9685589.373,E:397826.189},
{N:9685489.373,E:397826.189},
{N:9685489.373,E:397726.189}],coord:[],},
{owner: ' Andi Muh. Arifin Baso',cert_no:'593.83.1923.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397626.189},
{N:9685689.373,E:397726.189},
{N:9685589.373,E:397726.189},
{N:9685589.373,E:397626.189}],coord:[],},
{owner: ' Rahman S.',cert_no:'593.83.1924.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397626.189},
{N:9685289.373,E:397726.189},
{N:9685189.373,E:397726.189},
{N:9685189.373,E:397626.189}],coord:[],},
{owner: ' Farid Pratama Putra',cert_no:'593.83.1925.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397826.189},
{N:9685189.373,E:397926.189},
{N:9685089.373,E:397926.189},
{N:9685089.373,E:397826.189}],coord:[],},
{owner: ' Dahyar',cert_no:'593.83.1926.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397826.189},
{N:9685489.373,E:397926.189},
{N:9685389.373,E:397926.189},
{N:9685389.373,E:397826.189}],coord:[],},
{owner: ' Haerudin Hamidu',cert_no:'593.83.1927.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:397526.189},
{N:9685289.373,E:397626.189},
{N:9685189.373,E:397626.189},
{N:9685189.373,E:397526.189}],coord:[],},
{owner: ' Hatta Alimudin',cert_no:'593.83.1928.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397726.189},
{N:9685389.373,E:397826.189},
{N:9685289.373,E:397826.189},
{N:9685289.373,E:397726.189}],coord:[],},
{owner: ' Julaela',cert_no:'593.83.1929.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397726.189},
{N:9685189.373,E:397826.189},
{N:9685089.373,E:397826.189},
{N:9685089.373,E:397726.189}],coord:[],},
{owner: ' Mu_min',cert_no:'593.83.1930.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397926.189},
{N:9685489.373,E:398026.189},
{N:9685389.373,E:398026.189},
{N:9685389.373,E:397926.189}],coord:[],},
{owner: ' Masdir',cert_no:'593.83.1931.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397826.189},
{N:9685589.373,E:397926.189},
{N:9685489.373,E:397926.189},
{N:9685489.373,E:397826.189}],coord:[],},
{owner: ' Nurdin',cert_no:'593.83.1932.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:397726.189},
{N:9685489.373,E:397826.189},
{N:9685389.373,E:397826.189},
{N:9685389.373,E:397726.189}],coord:[],},
{owner: ' Mince Astriani',cert_no:'593.83.1933.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397526.189},
{N:9685189.373,E:397626.189},
{N:9685089.373,E:397626.189},
{N:9685089.373,E:397526.189}],coord:[],},
{owner: ' Kadir',cert_no:'593.83.1934.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397926.189},
{N:9685189.373,E:398026.189},
{N:9685089.373,E:398026.189},
{N:9685089.373,E:397926.189}],coord:[],},
{owner: ' Hanija',cert_no:'593.83.1935.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:397826.189},
{N:9685389.373,E:397926.189},
{N:9685289.373,E:397926.189},
{N:9685289.373,E:397826.189}],coord:[],},
{owner: ' Hasmin',cert_no:'593.83.1936.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:397626.189},
{N:9685189.373,E:397726.189},
{N:9685089.373,E:397726.189},
{N:9685089.373,E:397626.189}],coord:[],},
{owner: ' Rahidin',cert_no:'593.83.1937.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397626.189},
{N:9685589.373,E:397726.189},
{N:9685489.373,E:397726.189},
{N:9685489.373,E:397626.189}],coord:[],},
{owner: ' Ahmad Hadi Supyin',cert_no:'593.83.1940.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:396726.189},
{N:9685489.373,E:396826.189},
{N:9685389.373,E:396826.189},
{N:9685389.373,E:396726.189}],coord:[],},
{owner: ' Fitra',cert_no:'593.83.1941.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685089.373,E:396826.189},
{N:9685089.373,E:396926.189},
{N:9684989.373,E:396926.189},
{N:9684989.373,E:396826.189}],coord:[],},
{owner: ' Dedi Purwanto',cert_no:'593.83.1942.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9684916.223,E:396720.104},
{N:9684912.525,E:396826.189},
{N:9684889.373,E:396826.189},
{N:9684889.373,E:396897.886},
{N:9684795.768,E:396899.499},
{N:9684841.512,E:396824.217},
{N:9684894.574,E:396733.721}],coord:[],},
{owner: ' Arjun',cert_no:'593.83.1952.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:398026.189},
{N:9685689.373,E:398126.189},
{N:9685589.373,E:398126.189},
{N:9685589.373,E:398026.189}],coord:[],},
{owner: ' Amin',cert_no:'593.83.1953.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:398126.189},
{N:9685489.373,E:398226.189},
{N:9685389.373,E:398226.189},
{N:9685389.373,E:398126.189}],coord:[],},
{owner: ' Boine M.',cert_no:'593.83.1954.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:398326.189},
{N:9685389.373,E:398426.189},
{N:9685289.373,E:398426.189},
{N:9685289.373,E:398326.189}],coord:[],},
{owner: ' Ilham',cert_no:'593.83.1955.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:398526.189},
{N:9685289.373,E:398626.189},
{N:9685189.373,E:398626.189},
{N:9685189.373,E:398526.189}],coord:[],},
{owner: ' Amelia',cert_no:'593.83.1956.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:398426.189},
{N:9685389.373,E:398526.189},
{N:9685289.373,E:398526.189},
{N:9685289.373,E:398426.189}],coord:[],},
{owner: ' Harpia',cert_no:'593.83.1957.BHDP.VII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:398126.189},
{N:9685189.373,E:398226.189},
{N:9685089.373,E:398226.189},
{N:9685089.373,E:398126.189}],coord:[],},
{owner: ' Andi Moh. Darma',cert_no:'593.83.1958.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:398226.189},
{N:9685289.373,E:398326.189},
{N:9685189.373,E:398326.189},
{N:9685189.373,E:398226.189}],coord:[],},
{owner: ' Hasnia',cert_no:'593.83.1959.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685489.373,E:398026.189},
{N:9685489.373,E:398126.189},
{N:9685389.373,E:398126.189},
{N:9685389.373,E:398026.189}],coord:[],},
{owner: ' Asram',cert_no:'593.83.1960.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:398526.189},
{N:9685389.373,E:398626.189},
{N:9685289.373,E:398626.189},
{N:9685289.373,E:398526.189}],coord:[],},
{owner: ' Anton Elia',cert_no:'593.83.1961.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:398026.189},
{N:9685289.373,E:398126.189},
{N:9685189.373,E:398126.189},
{N:9685189.373,E:398026.189}],coord:[],},
{owner: ' Nurlija',cert_no:'593.83.1962.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:398626.189},
{N:9685189.373,E:398726.189},
{N:9685089.373,E:398726.189},
{N:9685089.373,E:398626.189}],coord:[],},
{owner: ' Sarifudin N.',cert_no:'593.83.1963.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685689.373,E:397926.189},
{N:9685689.373,E:398026.189},
{N:9685589.373,E:398026.189},
{N:9685589.373,E:397926.189}],coord:[],},
{owner: ' Syukur',cert_no:'593.83.1964.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:398426.189},
{N:9685289.373,E:398526.189},
{N:9685189.373,E:398526.189},
{N:9685189.373,E:398426.189}],coord:[],},
{owner: ' Zulkifli Udin',cert_no:'593.83.1965.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:398026.189},
{N:9685589.373,E:398126.189},
{N:9685489.373,E:398126.189},
{N:9685489.373,E:398026.189}],coord:[],},
{owner: ' Wijayanti',cert_no:'593.83.1966.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:398626.189},
{N:9685289.373,E:398726.189},
{N:9685189.373,E:398726.189},
{N:9685189.373,E:398626.189}],coord:[],},
{owner: ' Rosmaida',cert_no:'593.83.1967.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:398526.189},
{N:9685189.373,E:398626.189},
{N:9685089.373,E:398626.189},
{N:9685089.373,E:398526.189}],coord:[],},
{owner: ' Yefta Mangende',cert_no:'593.83.1968.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:398126.189},
{N:9685389.373,E:398226.189},
{N:9685289.373,E:398226.189},
{N:9685289.373,E:398126.189}],coord:[],},
{owner: ' Sandi',cert_no:'593.83.1969.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:398026.189},
{N:9685389.373,E:398126.189},
{N:9685289.373,E:398126.189},
{N:9685289.373,E:398026.189}],coord:[],},
{owner: ' Wahnilda',cert_no:'593.83.1970.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:398326.189},
{N:9685289.373,E:398426.189},
{N:9685189.373,E:398426.189},
{N:9685189.373,E:398326.189}],coord:[],},
{owner: ' Novianti',cert_no:'593.83.1971.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685289.373,E:398126.189},
{N:9685289.373,E:398226.189},
{N:9685189.373,E:398226.189},
{N:9685189.373,E:398126.189}],coord:[],},
{owner: ' Murni Arsad',cert_no:'593.83.1972.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:398026.189},
{N:9685189.373,E:398126.189},
{N:9685089.373,E:398126.189},
{N:9685089.373,E:398026.189}],coord:[],},
{owner: ' Abd Muluk Saputra',cert_no:'593.83.1973.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:398326.189},
{N:9685189.373,E:398426.189},
{N:9685089.373,E:398426.189},
{N:9685089.373,E:398326.189}],coord:[],},
{owner: ' Maman Suryana',cert_no:'593.83.1974.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:397926.189},
{N:9685589.373,E:398026.189},
{N:9685489.373,E:398026.189},
{N:9685489.373,E:397926.189}],coord:[],},
{owner: ' Mujiono',cert_no:'593.83.1975.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685440.959,E:398607.449},
{N:9685440.959,E:398654.915},
{N:9685412.069,E:398661.38},
{N:9685347.882,E:398704.182},
{N:9685289.373,E:398704.246},
{N:9685289.373,E:398626.189},
{N:9685389.373,E:398626.189},
{N:9685389.373,E:398608.51}],coord:[],},
{owner: ' Muh Rafli S.',cert_no:'593.83.1976.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685347.882,E:398704.182},
{N:9685272.171,E:398762.164},
{N:9685148.434,E:398825.983},
{N:9685154.907,E:398726.189},
{N:9685289.373,E:398726.189},
{N:9685289.373,E:398704.246}],coord:[],},
{owner: ' Nasrudin',cert_no:'593.83.1977.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:398226.189},
{N:9685189.373,E:398326.189},
{N:9685089.373,E:398326.189},
{N:9685089.373,E:398226.189}],coord:[],},
{owner: ' Nasrah Nuhun',cert_no:'593.83.1979.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685189.373,E:398426.189},
{N:9685189.373,E:398526.189},
{N:9685089.373,E:398526.189},
{N:9685089.373,E:398426.189}],coord:[],},
{owner: ' Yerna',cert_no:'593.83.1980.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685389.373,E:398226.189},
{N:9685389.373,E:398326.189},
{N:9685289.373,E:398326.189},
{N:9685289.373,E:398226.189}],coord:[],},
{owner: ' Maunia',cert_no:'593.83.1981.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685589.373,E:398126.189},
{N:9685589.373,E:398226.189},
{N:9685489.373,E:398226.189},
{N:9685489.373,E:398126.189}],coord:[],},
{owner: ' Yusrisal',cert_no:'593.83.1982.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:397326.189},
{N:9686289.373,E:397426.189},
{N:9686189.373,E:397426.189},
{N:9686189.373,E:397326.189}],coord:[],},
{owner: ' Mustakim',cert_no:'593.83.1983.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397726.189},
{N:9686189.373,E:397826.189},
{N:9686089.373,E:397826.189},
{N:9686089.373,E:397726.189}],coord:[],},
{owner: ' Malik Baco Laida',cert_no:'593.83.1984.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397326.189},
{N:9685789.373,E:397426.189},
{N:9685689.373,E:397426.189},
{N:9685689.373,E:397326.189}],coord:[],},
{owner: ' Nani Rosita',cert_no:'593.83.1985.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397426.189},
{N:9686189.373,E:397526.189},
{N:9686089.373,E:397526.189},
{N:9686089.373,E:397426.189}],coord:[],},
{owner: ' Nawir',cert_no:'593.83.1986.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397726.189},
{N:9685889.373,E:397826.189},
{N:9685789.373,E:397826.189},
{N:9685789.373,E:397726.189}],coord:[],},
{owner: ' Safia Abu',cert_no:'593.83.1987.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397726.189},
{N:9685789.373,E:397826.189},
{N:9685689.373,E:397826.189},
{N:9685689.373,E:397726.189}],coord:[],},
{owner: ' Rahida',cert_no:'593.83.1988.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397426.189},
{N:9686089.373,E:397526.189},
{N:9685989.373,E:397526.189},
{N:9685989.373,E:397426.189}],coord:[],},
{owner: ' Hasyim Halilu',cert_no:'593.83.1989.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397626.189},
{N:9685889.373,E:397726.189},
{N:9685789.373,E:397726.189},
{N:9685789.373,E:397626.189}],coord:[],},
{owner: ' Syamsiar',cert_no:'593.83.1990.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686359.262,E:397356.489},
{N:9686375.884,E:397420.349},
{N:9686392.486,E:397457.628},
{N:9686289.373,E:397508.206},
{N:9686289.373,E:397361.596}],coord:[],},
{owner: ' Syahbuddin, S.Pd.',cert_no:'593.83.1991.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397526.189},
{N:9685989.373,E:397626.189},
{N:9685889.373,E:397626.189},
{N:9685889.373,E:397526.189}],coord:[],},
{owner: ' Syukur Jun Agus',cert_no:'593.83.1992.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397426.189},
{N:9685989.373,E:397526.189},
{N:9685889.373,E:397526.189},
{N:9685889.373,E:397426.189}],coord:[],},
{owner: ' Tati',cert_no:'593.83.1993.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397726.189},
{N:9685989.373,E:397826.189},
{N:9685889.373,E:397826.189},
{N:9685889.373,E:397726.189}],coord:[],},
{owner: ' Usman Jaelani',cert_no:'593.83.1994.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397526.189},
{N:9685789.373,E:397626.189},
{N:9685689.373,E:397626.189},
{N:9685689.373,E:397526.189}],coord:[],},
{owner: ' Ucok Nainggolan',cert_no:'593.83.1995.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:397426.189},
{N:9686289.373,E:397526.189},
{N:9686189.373,E:397526.189},
{N:9686189.373,E:397426.189}],coord:[],},
{owner: ' Yusran',cert_no:'593.83.1996.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397526.189},
{N:9685889.373,E:397626.189},
{N:9685789.373,E:397626.189},
{N:9685789.373,E:397526.189}],coord:[],},
{owner: ' As_adi',cert_no:'593.83.1997.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397526.189},
{N:9686189.373,E:397626.189},
{N:9686089.373,E:397626.189},
{N:9686089.373,E:397526.189}],coord:[],},
{owner: ' Zaenab Aminudin, SE.',cert_no:'593.83.1998.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686305.698,E:397268.817},
{N:9686354.898,E:397327.531},
{N:9686359.262,E:397356.489},
{N:9686289.373,E:397361.596},
{N:9686289.373,E:397326.189},
{N:9686189.373,E:397326.189},
{N:9686189.373,E:397269.898}],coord:[],},
{owner: ' Asnadi',cert_no:'593.83.1999.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397626.189},
{N:9686189.373,E:397726.189},
{N:9686089.373,E:397726.189},
{N:9686089.373,E:397626.189}],coord:[],},
{owner: ' Bakri',cert_no:'593.83.2000.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397526.189},
{N:9686089.373,E:397626.189},
{N:9685989.373,E:397626.189},
{N:9685989.373,E:397526.189}],coord:[],},
{owner: ' Faisal',cert_no:'593.83.2001.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397426.189},
{N:9685789.373,E:397526.189},
{N:9685689.373,E:397526.189},
{N:9685689.373,E:397426.189}],coord:[],},
{owner: ' Hanisa',cert_no:'593.83.2002.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397326.189},
{N:9686189.373,E:397426.189},
{N:9686089.373,E:397426.189},
{N:9686089.373,E:397326.189}],coord:[],},
{owner: ' Ilham Nuhun Samir',cert_no:'593.83.2003.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397326.189},
{N:9685989.373,E:397426.189},
{N:9685889.373,E:397426.189},
{N:9685889.373,E:397326.189}],coord:[],},
{owner: ' Lutfi',cert_no:'593.83.2004.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397726.189},
{N:9686089.373,E:397826.189},
{N:9685989.373,E:397826.189},
{N:9685989.373,E:397726.189}],coord:[],},
{owner: ' Jefri Tolekende',cert_no:'593.83.2005.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397326.189},
{N:9685889.373,E:397426.189},
{N:9685789.373,E:397426.189},
{N:9685789.373,E:397326.189}],coord:[],},
{owner: ' Abd Muh Arfa',cert_no:'593.83.2006.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686289.373,E:397526.189},
{N:9686289.373,E:397626.189},
{N:9686189.373,E:397626.189},
{N:9686189.373,E:397526.189}],coord:[],},
{owner: ' Arisno Kalalo',cert_no:'593.83.2007.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685889.373,E:397426.189},
{N:9685889.373,E:397526.189},
{N:9685789.373,E:397526.189},
{N:9685789.373,E:397426.189}],coord:[],},
{owner: ' Abd Malik',cert_no:'593.83.2008.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685789.373,E:397626.189},
{N:9685789.373,E:397726.189},
{N:9685689.373,E:397726.189},
{N:9685689.373,E:397626.189}],coord:[],},
{owner: ' Alderit Hargans',cert_no:'593.83.2009.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397626.189},
{N:9686089.373,E:397726.189},
{N:9685989.373,E:397726.189},
{N:9685989.373,E:397626.189}],coord:[],},
{owner: ' Akbar',cert_no:'593.83.2010.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:397326.189},
{N:9686089.373,E:397426.189},
{N:9685989.373,E:397426.189},
{N:9685989.373,E:397326.189}],coord:[],},
{owner: ' Alimin',cert_no:'593.83.2011.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9685989.373,E:397626.189},
{N:9685989.373,E:397726.189},
{N:9685889.373,E:397726.189},
{N:9685889.373,E:397626.189}],coord:[],},
{owner: '  Hamasia',cert_no:'593.83.1746.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686389.373,E:398026.189},
{N:9686389.373,E:398126.189},
{N:9686289.373,E:398126.189},
{N:9686289.373,E:398026.189}],coord:[],},
{owner: '  Hamid S',cert_no:'593.83.1747.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686089.373,E:398126.189},
{N:9686089.373,E:398226.189},
{N:9685989.373,E:398226.189},
{N:9685989.373,E:398126.189}],coord:[],},
{owner: '  Hamrun',cert_no:'593.83.1748.BHDP.VIII.2022',isVerified: true, isValid: true,NEcoord:[{N:9686189.373,E:397926.189},
{N:9686189.373,E:398026.189},
{N:9686089.373,E:398026.189},
{N:9686089.373,E:397926.189}],coord:[],},
            ],
            deedsPoligon:[],
            contourPoligon:[],
            solarlayoutPoligon:[],
            substationMark:[],
            TLRoute:[],
            ownerLabel:[],
            counter_lines:[],
            inProgressIcon:'/img/theme/general/pin-progress.png',
            completedIcon:'/img/theme/general/pin-complete.png',
            utm51M:"+proj=utm +zone=51 +south +datum=WGS84 +units=m +no_defs",
            wgs84:"+proj=longlat +datum=WGS84 +no_defs",
            searchValue: '',
            proposeLayoutData:[
            {
                desc:'proposeSolarLayout',
                coord:[
                {lat:-2.822552739890628,lng:122.0867432121615},
{lat:-2.822362310835779,lng:122.0856041221343},
{lat:-2.822773248515726,lng:122.0850140875967},
{lat:-2.82370337298395,lng:122.0842489309904},
{lat:-2.82486666268451,lng:122.0838161174511},
{lat:-2.826883952952243,lng:122.0839036744312},
{lat:-2.827030664484873,lng:122.0833241214557},
{lat:-2.827457490472653,lng:122.0821247810059},
{lat:-2.828018014104696,lng:122.0810863980957},
{lat:-2.829021834936924,lng:122.0803122233434},
{lat:-2.83169858333332,lng:122.079932388852},
{lat:-2.832304471375188,lng:122.0790465512444},
{lat:-2.833039677904773,lng:122.0782910154967},
{lat:-2.833961056971653,lng:122.0780190337071},
{lat:-2.834429262228067,lng:122.0777879048788},
{lat:-2.835690250301321,lng:122.078030851966},
{lat:-2.836189171066139,lng:122.0775067974488},
{lat:-2.836777130587225,lng:122.0772319015545},
{lat:-2.837015462818346,lng:122.0759401558097},
{lat:-2.837687107685348,lng:122.0751820645203},
{lat:-2.837744092875642,lng:122.0738393728193},
{lat:-2.838802741216331,lng:122.0733694139123},
{lat:-2.83916067019346,lng:122.0725773068909},
{lat:-2.839362715143741,lng:122.0718111276066},
{lat:-2.839890350353913,lng:122.0716251012124},
{lat:-2.840220874090511,lng:122.0713161709522},
{lat:-2.840809492479471,lng:122.0711623170773},
{lat:-2.841602213699736,lng:122.0699993883306},
{lat:-2.841951559982776,lng:122.0684637452693},
{lat:-2.843454895995181,lng:122.0651404403449},
{lat:-2.843578302394707,lng:122.0645197809518},
{lat:-2.844045127796437,lng:122.0643443894422},
{lat:-2.84425476450724,lng:122.0630759825917},
{lat:-2.844275590619958,lng:122.062526735623},
{lat:-2.846440161608713,lng:122.0616977780876},
{lat:-2.84672974399836,lng:122.0617239045561},
{lat:-2.847761430628802,lng:122.0626229159163},
{lat:-2.848761953368314,lng:122.0635937799706},
{lat:-2.848787290180395,lng:122.0647047422773},
{lat:-2.849650377373347,lng:122.0656306376299},
{lat:-2.849656519617781,lng:122.0667699200202},
{lat:-2.850249874702149,lng:122.0672701063642},
{lat:-2.850281545540704,lng:122.0690692935104},
{lat:-2.850930701180622,lng:122.0695212244965},
{lat:-2.853301772190886,lng:122.0745490428367},
{lat:-2.853165141948463,lng:122.0758422433894},
{lat:-2.853747488507495,lng:122.0765499627611},
{lat:-2.853735482254087,lng:122.0775114590696},
{lat:-2.854016039074785,lng:122.0781064310987},
{lat:-2.853954386570239,lng:122.0799309655474},
{lat:-2.853976401755975,lng:122.0807535181676},
{lat:-2.853725348735324,lng:122.0810839568444},
{lat:-2.853959114333985,lng:122.082157726404},
{lat:-2.853642768538651,lng:122.0832958541562},
{lat:-2.853131514612898,lng:122.0847648024285},
{lat:-2.852517311762683,lng:122.0854203645601},
{lat:-2.85171729846999,lng:122.0855148759133},
{lat:-2.851531444760711,lng:122.0867639348477},
{lat:-2.850360295616996,lng:122.0879442152579},
{lat:-2.849861656516748,lng:122.0880614767852},
{lat:-2.849593886307759,lng:122.0885456625859},
{lat:-2.849521689607446,lng:122.0895182197122},
{lat:-2.84911363101449,lng:122.0900946968528},
{lat:-2.847355882305481,lng:122.0897477486648},
{lat:-2.845119096615571,lng:122.0883713725987},
{lat:-2.844597583444269,lng:122.0873612277861},
{lat:-2.843613440272033,lng:122.0872899181286},
{lat:-2.843417264164284,lng:122.0869682268559},
{lat:-2.840662807632896,lng:122.0856512739832},
{lat:-2.839807778474288,lng:122.0846343783112},
{lat:-2.837885449835757,lng:122.0845975122958},
{lat:-2.837118173863952,lng:122.0864924822511},
{lat:-2.835491885061776,lng:122.0866080677919},
{lat:-2.83694037218395,lng:122.0878388176123},
{lat:-2.836436470455279,lng:122.090365826559},
{lat:-2.835826349368906,lng:122.0904748842308},
{lat:-2.835167106077869,lng:122.0904387164515},
{lat:-2.834881939060247,lng:122.1008550895526},
{lat:-2.835918207822919,lng:122.1005490715188},
{lat:-2.836502775652971,lng:122.0995726673614},
{lat:-2.83646578677036,lng:122.1008424413261},
{lat:-2.833917231737411,lng:122.1023699869988},
{lat:-2.83424368069451,lng:122.0878838625616},
{lat:-2.822775917503137,lng:122.0875337474112},
{lat:-2.822552739890628,lng:122.0867432121615},
  
                ],
            },
            ],
            solarLayoutData:[
            {
                desc:'latestSolarLayout',
                coord:[                   
                {lat:-2.848169488720637,lng:122.0654772530268},
{lat:-2.848215187220286,lng:122.0660529979641},
{lat:-2.848532291167152,lng:122.0666645108885},
{lat:-2.848785656395743,lng:122.0667632694392},
{lat:-2.84920209646118,lng:122.0671677811494},
{lat:-2.849256657239397,lng:122.0675186047232},
{lat:-2.849501664227875,lng:122.0684630511906},
{lat:-2.849855418444923,lng:122.0696503194908},
{lat:-2.850118515765421,lng:122.0705947529152},
{lat:-2.850788502634911,lng:122.0713229418596},
{lat:-2.851594680510669,lng:122.0726717902309},
{lat:-2.852401024726885,lng:122.0742275634955},
{lat:-2.852763471573962,lng:122.0749829920535},
{lat:-2.853063206670876,lng:122.0764941902569},
{lat:-2.853597626173464,lng:122.077375436494},
{lat:-2.853896902322587,lng:122.0783198476932},
{lat:-2.854251025003768,lng:122.0799749530765},
{lat:-2.853971555792523,lng:122.08116273612},
{lat:-2.854126303767583,lng:122.0823681679177},
{lat:-2.853593646831204,lng:122.0836821050657},
{lat:-2.852843813824907,lng:122.0849062463587},
{lat:-2.851695505646283,lng:122.0855639107567},
{lat:-2.851361796779514,lng:122.0868057142696},
{lat:-2.850331298655814,lng:122.0877331828773},
{lat:-2.849680635580319,lng:122.0885433944743},
{lat:-2.849256413086953,lng:122.089722290293},
{lat:-2.847908432346259,lng:122.0895524125612},
{lat:-2.846587110924229,lng:122.0887797391459},
{lat:-2.846406070696119,lng:122.0886269385785},
{lat:-2.846405706160875,lng:122.0881681105535},
{lat:-2.847572631934618,lng:122.0881671931004},
{lat:-2.847173809334724,lng:122.0871598832827},
{lat:-2.846504252754229,lng:122.0869624843698},
{lat:-2.846503923354207,lng:122.0865486396397},
{lat:-2.847544205548563,lng:122.0865478202926},
{lat:-2.847543969061398,lng:122.0862509314453},
{lat:-2.848050369141779,lng:122.0860346129799},
{lat:-2.848294322371536,lng:122.0856745549781},
{lat:-2.848737572925593,lng:122.0856742053477},
{lat:-2.849153326798314,lng:122.085224044982},
{lat:-2.849153032377526,lng:122.0848551827094},
{lat:-2.849035435319963,lng:122.0848552755705},
{lat:-2.848583246649556,lng:122.0849905822337},
{lat:-2.848266639171162,lng:122.0849908321485},
{lat:-2.847217067474229,lng:122.0846857749791},
{lat:-2.846041096903966,lng:122.0846867029564},
{lat:-2.846040759557638,lng:122.0842638620409},
{lat:-2.846592130105543,lng:122.0837236293553},
{lat:-2.846863126922402,lng:122.0832465941619},
{lat:-2.846863026265459,lng:122.0831206415052},
{lat:-2.84620267384993,lng:122.0831211634735},
{lat:-2.846200225648684,lng:122.0800623163553},
{lat:-2.844951889260131,lng:122.0800633060475},
{lat:-2.843801102374707,lng:122.0776261423093},
{lat:-2.840571178166797,lng:122.0769629612707},
{lat:-2.840567441948882,lng:122.072320742574},
{lat:-2.840721185897047,lng:122.0722756371276},
{lat:-2.841552441946784,lng:122.0710784319175},
{lat:-2.842039646446621,lng:122.0695036454646},
{lat:-2.842418910508429,lng:122.0686846552897},
{lat:-2.843159176501017,lng:122.0668397677128},
{lat:-2.844236111557603,lng:122.0674236787939},
{lat:-2.846778004789836,lng:122.0674216359132},
{lat:-2.847463873585872,lng:122.0654328388458},
{lat:-2.848169452059829,lng:122.0654322701108},
{lat:-2.848169474056525,lng:122.0654592598603},
                ],
            }
            ],
            proposeTLRoute:[
            {
                desc:'proposedTLRoute',
                coord:[
                {lat:-2.844615288030678,lng:122.0808162215605},
                {lat:-2.843626065557394,lng:122.0808581604368},
                {lat:-2.842561916832862,lng:122.0835809703182},
                {lat:-2.841683869208673,lng:122.0870592412189},
                {lat:-2.842930810426423,lng:122.0954786352646},
                {lat:-2.837476413586116,lng:122.1013152926669},
                {lat:-2.828609591574998,lng:122.108910416868},
                {lat:-2.827088504528595,lng:122.1202308164921},
                {lat:-2.829230982549955,lng:122.1328643341851},
                {lat:-2.829537642082105,lng:122.1429807751129},
                {lat:-2.838550870239782,lng:122.1429912846695},
                {lat:-2.842895570101015,lng:122.1519798356979},                  
                ]
            },
            ],
            filterOption: [
                {
                label: 5,
                value: 5
                },
                {
                label: 10,
                value: 10
                },
                {
                label: 25,
                value: 25
                },
                {
                label: 50,
                value: 50
                },
            ],
            all_data: [],
            selectedFilter: 5,
            file_uploads: false,
            rows: 15,
            perPage: 5,
            currentPage: 1,
            model: {
                project_id: null,
                developer_id: "x",
                project_type_id: "x",
                project_manager: "x",
                project_manager_name: "x",
                project_engineer: "x",
                project_engineer_name: "x",
                project_business_development: "x",
                project_business_development_name: "x",
                project_safety: "x",
                project_safety_name: "x",
                project_name: null,
                project_ppa_approved_capacity: null,
                project_ppa_start_date: null,
                project_watt_class: null,
                roofed_handover_date: null,
                project_tag: null,
                site_id: null,
                developers: [],
                project_type: [],
                project_managers: [],
                project_engineers: [],
                project_business_development: [],
                project_safety: [],
                site_status: [],
                isSolarNovaProject: false,
                items: [],
            },
            siteListingData: null,
            title: 'Document Tracking',
            pid: null,
            sid: null,
            selectedDocID: null,
            setting: {},
            categories1: [],
            categories2: [],
            categories3: [],
            selectedCategory1: null,
            selectedCategory2: null,
            selectedCategory3: null,
            documents: [],
            access: {},
            document: {},
            revisions: [],
            revision: null,
            comment: null,
            files: [],
            uploadedFiles: [],
            revision_comment: null,
            review: null,
            uploadedFiles: [],
            review_uploadedFiles:[],
            revision_comment: null,
            review_comment: null,
            review_detail:null,

            confirmDialogVisible: false,
            confirmDialogMessage: '',
            confirmDialogKind: '',
            intCounter :0,
        };
    },
    methods: {
        handleClick(event,SKPTNo) {
          event.preventDefault();
          //alert(SKPTNo);
          this.searchValue = SKPTNo;
          //this.$refs.myTextBox.value = SKPTNo;
          this.$refs.myTextBox.search(SKPTNo);
        },

        updateSearchValue(newValue) {
          this.searchValue = newValue;
        },

        checkAndUpdateDataDate() {
            const currentDate = getcurrentDate();
            if (this.model.data_date !== currentDate) {
                this.model.data_date = currentDate;
                this.changeAllChart();
            }
        },
        loadGoogleMap() {
            GoogleMapsLoader.load(google => {
                this.initMap(google);
            });
        },

        initMap(google) {
            

            let map, lat = -2.842371, lng = 122.080706, color = "#5e72e4", zoomLevel = 12;
            if (this.model.developer_id == 1) {
                lat = 1.350037;
                lng = 103.809228;
                zoomLevel = 12;
            } else if (this.model.developer_id == 2) {
                lat = -2.381462;
                lng = 107.231926;
                zoomLevel = 5;
            }
            
            
            map = document.getElementById('map-custom');


            let myLatlng = new google.maps.LatLng(-2.842371, 122.080706);
            let mapOptions = {
                zoom: 15,
                center: myLatlng,
                mapTypeId: google.maps.MapTypeId.SATELLITE,
                mapTypeControl: true,
                rotateControl: true,
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#242f3e"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#746855"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#242f3e"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#d59563"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#d59563"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#263c3f"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#6b9a76"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#38414e"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#212a37"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9ca5b3"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#746855"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#1f2835"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#f3d19c"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#2f3948"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#d59563"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#17263c"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#515c6d"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#17263c"
                            }
                        ]
                    }
                ],
                disableDefaultUI: true,
                scrollwheel: true,
                zoomControl: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
                // mapId: "SEMBCORP_MAP_DASHBOARD",
            }

            map = new google.maps.Map(map, mapOptions);
            this.sesnamap = map;

            this.drawSKPT();

            this.drawSolarLayout();

            this.drawSubStation();

            this.drawProposedTLRoute();

            this.drawContourLine();

        },

        

        drawProposedTLRoute()
        {
                const TLPolyline = new google.maps.Polyline({
                    path:this.proposeTLRoute[0].coord,
                    strokeColor:'#FFDE59',
                    strokeOpacity:0.8,
                    strokeWeight:5,
                    zIndex:1012,
                });
                TLPolyline.setMap(this.sesnamap);
                this.TLRoute.push(TLPolyline);
        },

        drawSubStation()
        {
            const cpletedIcon = 'img/theme/general/pin-progress.png';
            const position = { lat: -2.8446539, lng: 122.0805891 };
            let markerIcon = cpletedIcon ;
            
            const marker = new google.maps.Marker({
                    position: position,
                    title: "PV Substation",
                    icon: markerIcon,
                    label:{
                        text: 'PV Substation',
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        className: 'custom-marker-label',
                        zIndex:1010,
                    },
                });

  
            marker.setMap(this.sesnamap);
            this.substationMark.push(marker);

            const IMIPposition = { lat: -2.8430658, lng: 122.1523283 };
            let IMIPmarkerIcon = cpletedIcon ;
            const IMIPmarker = new google.maps.Marker({
                    position: IMIPposition,
                    title: "IMIP Substation",
                    icon: IMIPmarkerIcon,
                    label:{
                        text: 'IMIP Substation',
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        className: 'custom-marker-label',
                        zIndex:1011,
                    }
                });
            IMIPmarker.setMap(this.sesnamap);
            this.substationMark.push(IMIPmarker);
        },

        removeContourLine()
        {
            for (let i = 0; i < this.contourPoligon.length; i++) 
            {
                this.contourPoligon[i].setMap(null);  // Removes the polygon from the map
             }
             this.contourPoligon = [];  // Clear the polygons array
        },

        drawSKPT()
        {
            let j = 500;
            const infowindow = new google.maps.InfoWindow({
                maxWidth: 500,
            });

            //_ somehow if using this.sesnamap, infoWIndow not working, don't know why
            let map = this.sesnamap;

            this.deedsData.forEach(item => {
                this.infoWindow = new google.maps.InfoWindow();
                for (let i = 0; i < item.NEcoord.length; i++) {
                    //console.log("NE Coordinate");            
                    //console.log(item.NEcoord[i].N);
                    //console.log(item.NEcoord[i].E);   
                 
                    const [longitude, latitude] = proj4(this.utm51M, this.wgs84, [item.NEcoord[i].E, item.NEcoord[i].N]);

                    item.coord.push({lat:latitude,lng:longitude});

                    //console.log("Coordinate");
                    //console.log(item.coord[i].lat + "," + item.coord[i].lng);
                };
                
                const deedCoords = item.coord;

                let areaColor = (item.isValid) ? '#04d627' : '#FF0000';
                let areaVerified = areaColor;
                //let areaVerified = (item.isVerified) ? '#002fff' : areaColor;

                
                j = j++;
                const deedArea = new google.maps.Polygon({
                    paths: deedCoords,
                    strokeColor: areaVerified,
                    strokeOpacity: 0.5,
                    strokeWeight: 3,
                    fillColor: areaColor,
                    fillOpacity: 0.5,
                    zIndex:j,
                });

                const tempBounds = new google.maps.LatLngBounds();

                for (let j = 0; j < deedCoords.length; j++) {
                    const x = {
                        lat: deedCoords[j].lat,
                        lng: deedCoords[j].lng,
                    };
                    const BoundLatLng = new google.maps.LatLng({
                        lat: parseFloat(x.lat),
                        lng: parseFloat(x.lng),
                    });
                    tempBounds.extend(BoundLatLng);
                }
                const centroid = tempBounds.getCenter();
                let contentString = "<b> name: " + item.owner + "</b>" +
                        "<br> cert: " + item.cert_no +
                        `<div>
                            <a href="#" onclick="window.vm.handleClick(event,'`+ item.cert_no +`')">SKPT Document</a>
                        </div>
                        `;
                      
                let label = new google.maps.InfoWindow({
                    content: contentString,
                    position: this.getPolygonCenter(deedCoords),
                  });

                if(this.display_owner==true)
                {
                  label.open(this.sesnamap);
                }
                else
                {
                  label.close();
                }

                var area = google.maps.geometry.spherical.computeArea(deedArea.getPath());

                //_ so it's simple to debug and track which is which
                google.maps.event.addListener(deedArea, 'click', function (event) {
                    // alert(item.cert_no + "-" + item.owner);
                    // console.log(item.owner);

                    if (this.lastInfoWindow != null) this.lastInfoWindow.close();

                    console.log(item.cert_no + "-" + item.owner + ', area = ' + area);
                    // console.log(item.cert_no + "-" + item.owner);

                    var latitude = event.latLng.lat();
                    var longitude = event.latLng.lng();
                    console.log('coordinate clicked: ' + latitude + ', ' + longitude);

                    // infowindow.open(this.sesnamap);

                    let contentString = "<b> name: " + item.owner + "</b>" +
                        "<br> cert: " + item.cert_no +
                        "<br> area: " + area + 
                        `<div>
                            <a href="#" onclick="window.vm.handleClick(event,'`+ item.cert_no +`')">SKPT Document</a>
                        </div>
                        `;
                    infowindow.setContent(contentString);
                    infowindow.setPosition(event.latLng);
                    infowindow.open(map);


                    this.lastInfoWindow = infowindow;
                });

                //this.polygon_info = item.cert_no + "-" + item.owner;
                //deedArea.addListener('mouseover', this.showInfoWindow);
                //deedArea.addListener('mouseout', this.hideInfoWindow);

                //_  mouse hovers over the polygon.
                google.maps.event.addListener(deedArea, 'mouseover', function (event) {
                    // Within the event listener, "this" refers to the polygon which
                    // received the event.
                    this.setOptions({
                        strokeColor: '#f7f7f7',
                        fillColor: '#f7f7f7'
                    });
                });

                //_ mouse stops hovering over the polygon.
                google.maps.event.addListener(deedArea, 'mouseout', function (event) {
                    this.setOptions({
                        strokeColor: areaVerified,
                        fillColor: areaColor
                    });
                });

                // markerLabel.setMap(map);
                deedArea.setMap(this.sesnamap);
                this.deedsPoligon.push({deed:deedArea,owner:label});
            });
        },

      getPolygonCenter(path) {
        let bounds = new google.maps.LatLngBounds();
          path.forEach(point => {
            bounds.extend(point);
          });
        return bounds.getCenter();
        },

        drawContourLine()
        {
            let k = 0;
            this.contourData.forEach(item => {
                const contourCoords = item.coord;

                
                k = k++;

                const contourArea = new google.maps.Polyline({
                    path: contourCoords,
                    strokeColor: '#bcbcbc',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    zIndex: k,
                });
                contourArea.setMap(this.sesnamap);
                this.contourPoligon.push(contourArea);
            });
        },

        toggleSKPT(newvalue)
        {   
            
            if(newvalue == true) 
            {
                console.log('Turn on SKPT');
                this.drawSKPT();
                
            }
            else
            {
                console.log('Turn off SKPT');
                this.removeSKPT();
            }

        },

        toggleOwner(newvalue)
        {   
            
            if(newvalue == true) 
            {
                if (this.display_SKPT==true)
                {
                  this.showOwner();
                  console.log('Turn on owner');
                }
                else this.display_owner = false;
            }
            else
            {
              if (this.display_SKPT==true)
                {
                  this.removeOwner();
                  console.log('Turn off owner');
                }
            }

        },


        toggleContour(newvalue)
        {   
            
            if(newvalue == true) 
            {
                console.log('Turn on Contour');
                this.drawContourLine();
                
            }
            else
            {
                console.log('Turn off Contour');
                this.removeContourLine();
            }

        },

        togglesolarLayout(newvalue)
        {   
            
            if(newvalue == true) 
            {
                console.log('Turn on solar layout');
                this.drawSolarLayout();
                
            }
            else
            {
                console.log('Turn off solar layout');
                this.removeSolarLayout();
            }

        },

        toggleSubStation(newvalue)
        {
            if(newvalue == true) 
            {
                console.log('Turn on sub station');
                this.drawSubStation();
                
            }
            else
            {
                console.log('Turn off sub station');
                this.removeSubStation();
            }
        },

        toggleTLRoute(newvalue)
        {
            if(newvalue == true) 
            {
                console.log('Turn on TL Route');
                this.drawProposedTLRoute();
                
            }
            else
            {
                console.log('Turn off TL Route');
                this.removeTLRoute();
            }
        },

        removeSKPT()
        {
            for (let i = 0; i < this.deedsPoligon.length; i++) 
            {
                this.deedsPoligon[i].deed.setMap(null);
                this.deedsPoligon[i].owner.close();
                  // Removes the polygon from the map
             }
             this.deedsPoligon = [];  // Clear the polygons array
        },

        removeOwner()
        {
          for (let i = 0; i < this.deedsPoligon.length; i++) 
            {
                this.deedsPoligon[i].owner.close();
            }
        },

        showOwner()
        {
          for (let i = 0; i < this.deedsPoligon.length; i++) 
            {
                this.deedsPoligon[i].owner.open(this.sesnamap);
            }
        },

        removeTLRoute()
        {
            for (let i = 0; i < this.TLRoute.length; i++) 
            {
                this.TLRoute[i].setMap(null);  // Removes the polygon from the map
             }
             this.TLRoute = [];  // Clear the polygons array
        },

        removeSolarLayout()
        {
            for (let i = 0; i < this.solarlayoutPoligon.length; i++) 
            {
                this.solarlayoutPoligon[i].setMap(null);  // Removes the polygon from the map
             }
             this.solarlayoutPoligon = [];  // Clear the polygons array
        },

        removeSubStation()
        {
            for (let i = 0; i < this.substationMark.length; i++) 
            {
                this.substationMark[i].setMap(null); 
             }
             this.substationMark = [];

        },

        drawSolarLayout()
        {
            const solarLayoutCoords = this.solarLayoutData[0].coord;

            let solarLayoutareaColor = '#38b6ff';
            let solarLayoutareaVerified = '#004aad';

                const solarLayoutArea = new google.maps.Polygon({
                    paths: solarLayoutCoords,
                    strokeColor: solarLayoutareaVerified,
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                    fillColor: solarLayoutareaColor,
                    fillOpacity: 0.5,
                    zIndex:3000,
                });

            solarLayoutArea.setMap(this.sesnamap);
            this.solarlayoutPoligon.push(solarLayoutArea);
        },

        showInfoWindow(event) {
            this.infoWindow.setPosition(event.latLng);
            this.infoWindow.setContent(`<div id="infoWindowContent" style="color:white">${this.polygon_info}</div>`);
            this.infoWindow.open(this.sesnamap);
            this.hideInfoWindowCloseButton();
        },
    
        hideInfoWindow() {
            this.infoWindow.close();
        },    

        reloadMap() {
            this.loadGoogleMap();
        },

        hideInfoWindowCloseButton() {
      // Wait for the InfoWindow to be attached to the DOM
        this.$nextTick(() => {
        const closeBtn = document.querySelector('.gm-ui-hover-effect');
        if (closeBtn) {
          closeBtn.style.display = 'none';
        }
            });
        },
        removeFile(index) {
      this.files.splice(index, 1)
    },
    loadSetting() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/setting`)
        .then(response => {
          this.setting = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadCategories() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/category`)
        .then(response => {
          const categories = response.data.data
          this.categories1 = categories.filter(x => x.level == 1)
          this.categories2 = categories.filter(x => x.level == 2)
          this.categories3 = categories.filter(x => x.level == 3)

          //_ add filter -- ALL --
          this.categories1 = this.setupAllInsideCategories(this.categories1);
          this.categories2 = this.setupAllInsideCategories(this.categories2);
          this.categories3 = this.setupAllInsideCategories(this.categories3);
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadDocuments() {
      const options = {
        params: {
          category1_id: this.selectedCategory1,
          category2_id: this.selectedCategory2,
          category3_id: this.selectedCategory3,
        },
      }

      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document`, options)
        .then(response => {
          this.documents = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    loadDocument() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}`)
        .then(response => {
          this.document = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    showCompleteModal(docID, docStatus) {
      this.$refs['complete-pasif-modal'].show();
      this.id = docID;
      this.loadDocument();
      this.loadRevisions();
      console.log ("CURRENT REVISION");
      console.log(this.revision);
      //this.review = this.revision.reviews[0];
      console.log ("CURRENT REVIEW");
      console.log(this.review);
      console.log ("REVIEW DETAIL");
      console.log(this.review_detail);
      this.files = [];
    },

    confirmDialog(message, kind, callback) {
      console.log("1");
      this.confirmDialogMessage = message
      console.log("2");
      this.confirmDialogKind = kind
      console.log("3");
      this.confirmDialogCallback = callback
      console.log("4");
      this.confirmDialogVisible = true
      console.log("5");
    },

    hideConfirmDialog() {
      this.confirmDialogVisible = false;
    },

    confirmDialogAccept() {
      if (typeof this.confirmDialogCallback === 'function') {
        this.confirmDialogCallback(() => this.hideConfirmDialog())
      }
    },

    loadRevisions() {
      axios.get(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`)
        .then(response => {

          const revisions = response.data.data;
          //console.log("BEFORE");
          //console.log(revisions);
          // Group reviews into revisions.
          const groups = revisions
            .filter(x => x.version == 0)
            .sort((a, b) => a.revision - b.revision)

          for (const group of groups) {
            group.isNew = group.status == 'NEW_REVISION'
            group.isEditing = false

            // Reviews.
            group.reviews = revisions
              .filter(x => x.revision == group.revision && x.version > 0)
              .map(review => {
                review.isNew = review.status == 'NEW_REVIEW'
                review.isEditing = false

                return review
              })
              .sort((a, b) => a.version - b.version)
          }

          this.revisions = groups;
          //console.log("AFTER");
          //console.log(this.revisions);
          this.revision = this.revisions.at(-1);
          console.log("REVISION");
          console.log(this.revision);
          console.log("UPLOADED FILES");
          this.uploadedFiles = this.revision.uploads;
          console.log(this.uploadFiles);
          this.revision_comment = this.revision.comment;
          this.revision_comment = this.revision.comment;
          if (this.revision.reviews != null)
          {
            this.review = this.revision.reviews[0];
            this.review_uploadedFiles = this.review.uploads;
            this.review_comment = this.review.comment;
            this.review_detail = this.revision.status   + " by " + this.review.created_by.name + " on " + this.review.created_at;
          }
          else this.review = null;
          console.log("current review");
          console.log(this.review);

        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    formatSize(size) {
      return formatBytesInUnits(size)
    },


    showFileDialog() {
      //document.getElementById("file_upload").click();
      this.$refs.file_upload.click();
    },

    currentFileChanged(event) {
      const input = event.target
      this.files.push(...input.files)
      input.value = ''
    },

    showModal(docID, docStatus) {
      this.$refs['contractor-modal'].show()
      this.id = docID;
      this.loadDocument();
      if (docStatus == "EMPTY") {
        this.createRevision();
      }
      else this.loadRevisions();
      this.files = [];

    },

    showModalAfterComplete() {
      this.hideCompleteModal();
      this.$refs['contractor-modal'].show()
      this.loadDocument();
      this.createRevision();
      this.loadRevisions();
      this.files = [];
    },

    showPasifModal(docID, docStatus) {
      this.$refs['contractor-pasif-modal'].show()
      this.id = docID;
      this.loadDocument();
      this.loadRevisions();
    },

    hideModal() {
      this.$refs['contractor-modal'].hide()
      this.reload();
    },

    hidePasifModal() {
      this.$refs['contractor-pasif-modal'].hide()
      this.reload();
    },

    hideCompleteModal() {
      this.$refs['complete-pasif-modal'].hide()
      this.reload();
    },

    reload() {
      this.loadDocuments();
      //this.loadRevisions();
    },

    generateData() {
      this.all_data = [];
      var status = ["Approve", "Pending", "New", "Rejected", "Being Reviewed", "Empty"];
      for (let index = 1; index <= this.rows; index++) {
        var status_num = 0;
        if (index % 2 == 0) {
          status_num = 1;
        }
        var name = "Test " + index.toString()
        this.all_data.push({
          id: index,
          cat_a: "Eng",
          cat_b: "DR",
          cat_c: "DR",
          number: "XXX-00-ENG-DR-0001",
          title: name,
          alt_title: "UI/UX",
          status: status[Math.floor(Math.random() * 5)],
          created_at: "24 Juni 2024"
        })
      }
    },
    changePerpage(perPage = 5) {
      this.perPage = perPage;
      console.log(this.currentPage, this.perPage)

    },
    getPagination() {
      this.items = [];

      let from = 0
      if (this.currentPage == 1) {
        from = (this.currentPage - 1)
      } else {
        from = (this.currentPage - 1) * this.perPage;
      }
      this.items = this.all_data.slice(from, this.perPage * this.currentPage)
      // console.log(this.currentPage, this.perPage)
    },
    getDashboardData() {
      this.sid = 7377;
      axios
        .get(apiDomain + "project/project_site/" + this.sid, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {

            this.siteListingData = response.data.data[0];
            //console.log(this.siteListingData);
            this.access = getAccessByModule('DOC_REPO_REVIEW');

            this.pid = 708;
            this.loadSetting();
            this.loadCategories();
            this.loadDocuments();

          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        });
    },

    createRevision() {
      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision`, {})
        .then(response => {
          this.loadRevisions();
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    updateReview() {

      const body = {
        comment: this.revision_comment,
      }

      axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}`, body)
        .then(response => {
          //this.loadRevisions()

          this.$notify({
            message: 'Review Updated',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });

          this.submitForReview();
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })

        .finally(() => {
          // Optionally, execute code that should run regardless of success or failure
          console.log('Request completed.');
        });

    },

    submitForReview() {

      console.log(this.revision_comment);
      const body = {
        comment: "Test comment here",
      }

      axios.put(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}/submit`, body)
        .then(response => {
          this.reload()

          this.$notify({
            message: 'Revision Submitted',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
      this.hideModal();
    },


    downloadFile(id) {
      axios.post(`doc_repo/token?value=${id}`)
        .then(response => {
          const token = response.data
          const url = `${apiDomain}doc_repo/download/${id}?token=${token}`

          window.open(url, '_blank')
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },

    uploadFiles() {
      const data = new FormData();
      console.log("List of files");
      console.log(this.files);
      this.files.forEach(file => data.append('files[]', file))
      console.log("List of data");
      console.log(data);
      axios.post(`doc_repo/p/${this.pid}/s/${this.sid}/document/${this.id}/revision/${this.revision.id}/upload`, data)
        .then(response => {
          this.files.splice(0)
          this.loadRevisions()

          this.$notify({
            message: 'Files Uploaded',
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: 'default',
          });

          this.updateReview();

        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })

        .finally(() => {
          // Optionally, execute code that should run regardless of success or failure
          console.log('Request completed.');
        });
    },

    //_ custom methods
    //  > de-structure the array and insert at the beginning an -- ALL -- option
    setupAllInsideCategories(theCategory) {
      return [
        {
          id: null,
          code: "-- ALL --",
          name: "-- ALL --"
        },
        ...theCategory
      ];
    },
    customFormatNumber(theData) {
      let retVal = parseFloat(theData.replace(/,/g, ""));

      return retVal;
    },
    customFormatDate(theData) {
      let checkDate = theData !== null ? theData : "N/A";
      if (checkDate !== "N/A") {
        let convertCheckDate = new Date(checkDate);
        const year = convertCheckDate.getFullYear();
        const month = convertCheckDate.toLocaleString(
          "en-US",
          { month: "short" }
        );
        const day = convertCheckDate.toLocaleString("en-US", {
          day: "2-digit",
        });

        return `${day}-${month}-${year}`;
      }
    },


        //_ method that we use to calculate how many data based on the observation type that is given.
    },
    mounted() {
        this.loadGoogleMap();
    },

    watch: {
        display_SKPT(value){
            this.toggleSKPT(value);
        },

        display_solarlayout(value){
            this.togglesolarLayout(value);
        },

        display_substation(value){
            this.toggleSubStation(value);
        },

        display_TLRoute(value){
            this.toggleTLRoute(value);
        },

        display_contour(value){
            this.toggleContour(value);
        },

        display_owner(value){
          this.toggleOwner(value);
        },

        searchValue(value){
          this.updateSearchValue(value);
        },

    }
};
</script>


<style scoped>
.card {
    background-color: #082a2f;
}
</style>

<style scoped>

.custom-marker-label {
  transform: translateY(22px); /* Move label below the marker */
}

.icon-dashboard-cs {
    position: relative;
    height: 18px;
    width: 18px;
    /* margin: 4px; */
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;
    background-image: url('data:image/svg+xml;utf8,<svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 9C0 9.5523 0.44772 10 1 10H7C7.5523 10 8 9.5523 8 9V1C8 0.44772 7.5523 0 7 0H1C0.44772 0 0 0.44772 0 1V9ZM0 17C0 17.5523 0.44772 18 1 18H7C7.5523 18 8 17.5523 8 17V13C8 12.4477 7.5523 12 7 12H1C0.44772 12 0 12.4477 0 13V17ZM10 17C10 17.5523 10.4477 18 11 18H17C17.5523 18 18 17.5523 18 17V9C18 8.4477 17.5523 8 17 8H11C10.4477 8 10 8.4477 10 9V17ZM11 0C10.4477 0 10 0.44772 10 1V5C10 5.55228 10.4477 6 11 6H17C17.5523 6 18 5.55228 18 5V1C18 0.44772 17.5523 0 17 0H11Z" fill="white"/></svg>');
}

.small-button-cs {
    font-size: small;
    font-weight: 600;
    top: -4px;
    left: 7px;
    position: relative;
}

.btn-map {
    position: relative;
    text-transform: none;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    will-change: transform;
}

.rounded-pill-map {
    border-radius: 50rem !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
    background: #2E575D;
    opacity: 0.9;
}

.gm-style .gm-style-iw-tc::after {
    background: #2E575D;
    opacity: 0.9;
}

#__BVID__39___BV_modal_content_ {
    background-color: #306b74;
}
</style>

<style scoped>
/* Class For Modal */
/* .modal-content {
  background-color: #306b74;

} */

.table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #3a656b;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.th-table-new {
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 20px;
}




.tr-td-table-new {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
}

.el-table th.el-table__cell {

    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
    /* border-bottom: 20px solid #082A2F; */
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
}

div.el-table .el-table__row {
    background: #3a656b;
    border-bottom: 0 solid #082A2F;


}

div.el-table .el-table__row:hover {
    background: #3a656b;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
}

.form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;

    &:focus {
        background-color: #2C545A;
        border-color: #2C545A;
        /* color: white; */
    }

    &::placeholder {
        color: white;
    }
}

.form-control {
    &:focus {
        &::placeholder {
            color: white;
        }
    }
}

.input-group-text {
    background-color: #2C545A;
    ;
    color: white;

    &:focus {
        background-color: #2C545A;
        ;
        border-color: #1D4147;
        color: white;
    }
}

.focused {
    .input-group-text {
        background-color: #2C545A;
    }
}

.navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
}

.navbar-search .input-group {
    border-radius: 7px;
}

select {
    background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
}
</style>
