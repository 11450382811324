<template>
  <div>
    <!-- Header -->
    <div class="header  py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-3">
          <h1 class="text-white text-weight-bold">MOMENS 2.0</h1>

        </div>
      </b-container>

    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-black-transparant border-0 mb-0">

            <b-card-body class="px-lg-5 py-lg-5">

              <div class="text-center text-muted mb-4">
                <!-- <small></small> -->
                <img src="img/theme/user-login.png">
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">


                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="model.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <router-link to="/forgot-password" class="text-primary "><em>Forgot password?</em></router-link>



                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <!-- <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script src="{{ asset('js/app.js?version='.date("ymdhis").'') }}"></script>
<script>
import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
import axios from 'axios'
import loginFunctionMix from '@/assets/js/loginFunctionMix.js'

export default {
    mixins: [loginFunctionMix],
    data() {
        return {
            model: {
                email: '',
                password: '',
                rememberMe: false,
                status: false,
            }
        };
    },
    methods: {
        onSubmit() {
            const authUser = {}
            const loginUser = {}
            const defaultDashboard = {}

            const postData = {
                grant_type : 'password',
                client_id : clientId,
                client_secret : clientSecret,
                username : this.model.email,
                password : this.model.password,
                scope : '*'
            }

            axios.post(LoginURL, postData )
                .then ( response => {
                    if (response.status === 200)
                    {
                        authUser.access_token = response.data.access_token
                        authUser.refresh_token = response.data.refresh_token
                        window.localStorage.setItem('authUser', JSON.stringify(authUser))

                        // defaultDashboard.project_id = 1
                        // defaultDashboard.group_id = 1
                        // defaultDashboard.site_id = 1
                        // window.localStorage.setItem('defaultDashboard', JSON.stringify(defaultDashboard))

                        axios.get(apiDomain + 'api/me', { headers: getHeader() })
                            .then(response => {
                                // console.log(response)
                                loginUser.id = response.data.id
                                loginUser.role_id = response.data.role_id
                                loginUser.name = response.data.name
                                loginUser.email = response.data.email
                                window.localStorage.setItem('loginUser', JSON.stringify(loginUser))
                                //this.getLoginUserRoleAccessNew(response.data.role_id)
                                this.getLoginUserRoleAccess()
                                let redirectUrl = window.localStorage.getItem('my_current_url')
                                window.localStorage.removeItem('my_current_url')
                                console.log(redirectUrl);
                                if (redirectUrl) {
                                    console.log('if (redirectUrl) {');
                                    this.$router.push({path: redirectUrl})
                                } else {
                                    if ( response.data.role_id == '12')
                                    {
                                        if ( response.data.contractor_id == '35')
                                        {
                                            this.$router.push('mapSesmoforExternal');
                                        }
                                        else
                                        {
                                            this.$router.push('contractorLandingPage');
                                        }
                                    }
                                    else
                                    {
                                        this.$router.push('mainLandingPage');
                                    }
                                }
                            })
                    }
                })
                .catch ( error => {
                    console.log(error.response.status)
                    this.model.status = "Invalid Email or Password !!!"
                } );

        }
    },
    beforeDestroy() {
        document.body.classList.remove('bg-default-moment');
    }
};
</script>
<style scoped>
  .bg-black-transparant {
    background-color:#3E3E3E99;
  }
  .bg-card-dashboard {
    background-color: #23454A;
  }
  .bg-default-dashboard {
    background-color: #082A2F;
    /* background-size: cover; */
    /* position:fixed; */
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
  .form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  &:focus {
     background-color: #2C545A;
     border-color: #2C545A;
     color: white;
   }
  &::placeholder {
     color: white;
   }
  }
  .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }
  .input-group-text {
    background-color: #2C545A;;
    color: white;
  &:focus {
     background-color: #2C545A;;
     border-color: #1D4147;
     color: white;
   }
  }
  .focused {
  .input-group-text {
    background-color: #2C545A;
  }
  }
  .navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search .input-group {
    border-radius: 7px;
  }
  .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
    background-color: #23454A;
  }
  .line-custom {
    background-color: #356168; margin-top: 15px; margin-bottom: 15px;
  }

  /* .el-table th.el-table__cell {

   overflow: hidden;
   -moz-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background-color: #23454A;
   color: white;
   padding: 20px;
   border-top: 0px solid #082A2F;
   border-bottom: 0px solid #082A2F;
 }

 .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
   padding-top: 1.5rem;
   padding-bottom: 1.5rem;
   border-bottom: 20px solid #082A2F;
   border-top: 0px solid #082A2F;
 }

 div.el-table .el-table__row {
   background: #3a656b;;
   border-bottom: 0 solid #082A2F;

 }
 div.el-table .el-table__row:hover {
   background: #0d2f33;
 }

 .el-table--border::after, .el-table--group::after, .el-table::before {
   content: '';
   position: absolute;
   background-color: #ebeef500;
   z-index: 1;
 } */

</style>
