<!-- TODO: -->
<!-- [done] 1. safety inspection bar chart -->
<!-- [done] 2. observation items, and also need to be change into pie chart -->
<!-- [done] 3. gant chart color condition based on end date vs current date (check: NOTES_01)  -->
<!-- [done] 4. sites images that slides, get files from Adrian, functionality done, just need to put in the actual/real photo images -->
<!-- [done] 5. getSafetyInspectionList, this.model.site_id = 7333 still hardcoded to ikn site, need to change it dynamically -->
<!-- [done] 6. added more information of the site -->

<!-- NOTES_01:
Jadi kalo dia udah lewat deadline tspu belum kelar dimerahin
Kalo dia belum lewat deadline tapi udah 7 hari mendekati deadline, jadih orange
Kalo belum lewat deadline tapi masi lebih dari 7 hari deadline nya, jadih ijo
-->

<template>
  <div class="bg-default-dashboard">
    <div class="py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative">
        <!-- <div class="header-body "  > -->
        <!-- Project title | Key Contract -->
        <b-row class="mb-2">
          <b-col md="9">
            <card class="bg-card-dashboard">
              <b-row class="mb-6">
                <b-col md="8">
                  <span class="text-white display-2 mr-3">{{
                      model.project_name
                    }}</span>
                  <a class="btn btn-sm btn-warning rounded-pill text-default" style="position: relative; top: -10px">
                    <i class="fa fa-xs fa-circle mr-1"></i>In Progress</a>
                </b-col>
                <b-col md="4" class="text-right">
                  <span class="text-white" style="position: relative; top: 20px">
                    PPA Signed Date :
                    <strong>{{ model.project_ppa_start_date }}</strong></span>
                </b-col>
              </b-row>
              <b-row class="mb-6">
                <b-col md="4">
                  <div class="text-left">
                    <span>
                      <img src="/img/theme/general/capacity.png" class="mr-2" alt="" />
                      <span class="text-white lead text-muted">PPA Signed Capacity</span>
                    </span>
                    <br />
                    <span class="text-white display-4 ml-3">{{ model.project_ppa_approved_capacity }} kWp</span>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="text-center">
                    <span>
                      <img src="/img/theme/general/developer.png" class="mr-2" alt="" />
                      <!-- <span class="text-white lead text-muted">Developer</span> -->
                      <span class="text-white lead text-muted">Joint Venture With</span>
                    </span>
                    <br />
                    <!-- <span class="text-white display-4 ml-3">{{ model.developer_name }}</span> -->
                    <span class="text-white display-4 ml-3">PLN Nusantara Renewables</span>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="text-right">
                    <span>
                      <img src="/img/theme/general/watt.png" class="mr-2" alt="" />
                      <!-- <span class="text-white lead text-muted">Watt Class</span> -->
                      <span class="text-white lead text-muted">Contractor</span>
                    </span>
                    <br />
                    <!-- <span class="text-white display-4 mr-2">{{ model.project_watt_class }} Wp</span> -->
                    <span class="text-white display-4 mr-2">SEPEC</span>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
          <b-col>
            <card class="bg-card-dashboard">
              <b-row class="mb-2">
                <b-col class="text-white">
                  <div class="display-4 pb-3">Key Contact</div>
                  <div class="pb-2">
                    <span class="text-muted">Project Engineer</span> <br />
                    <span class="lead"><strong>{{ model.project_manager_name }}</strong></span>
                  </div>
                  <div class="pb-2">
                    <span class="text-muted">Engineer</span> <br />
                    <span class="lead"><strong>{{ model.project_engineer_name }}</strong></span>
                  </div>
                  <div class="pb-2">
                    <span class="text-muted">Bussiness Development</span> <br />
                    <span class="lead"><strong>{{
                        model.project_business_development_name
                      }}</strong></span>
                  </div>
                  <div class="pb-0">
                    <span class="text-muted">Safety Officer</span> <br />
                    <span class="lead"><strong>{{ model.project_safety_name }}</strong></span>
                  </div>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
        <!-- site selection button -->
        <b-row class="mb-4">
          <b-col md="3" class="mb-3">
            <div class="btn-group" role="group" aria-label="Basic example">
              <base-button v-for="site in siteListingData" :key="site.id"
                           :type="site.id == 1 ? 'success' : 'secondary'">
                {{ site.site_name }}
              </base-button>
            </div>
          </b-col>
        </b-row>
        <!-- project technical information | slider -->
        <b-row class="mb-3">
          <b-col md="7">
            <card class="bg-card-dashboard">
              <div class="mb-3">
                <span class="text-white lead text-muted">Site Code :
                  <template v-if="siteListingData[0]">
                    <strong class="h1 text-white">{{
                        siteListingData[0].site_code
                      }}</strong>
                  </template>
                </span>
              </div>
              <div class="mb-3">
                <span class="text-white lead text-muted">Site Name :
                  <template v-if="siteListingData[0]">
                    <strong class="h1 text-white">{{
                        siteListingData[0].site_name
                      }}</strong>
                  </template>
                </span>
              </div>
              <div class="mb-3">
                <span>
                  <img src="/img/theme/general/map.png" alt="" class="mr-3" />
                  <span class="text-white lead">
                    <template v-if="siteListingData[0]">
                      <strong>{{ siteListingData[0].site_address }}</strong>
                    </template>
                  </span>
                </span>
              </div>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col class="text-white lead">Postal Code</b-col>
                    <b-col class="text-white lead">
                      <template v-if="siteListingData[0]">
                        <strong>: {{ siteListingData[0].site_postal_code }}</strong>
                      </template>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="text-white lead">As Built Capacity </b-col>
                    <b-col class="text-white lead">
                      <template v-if="siteListingData[0]">
                        <strong>:
                          {{ this.customFormatNumber(siteListingData[0].site_as_built_capacity) }}
                          kWp</strong>
                      </template>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col class="text-white lead">Site Capacity </b-col>
                    <b-col class="text-white lead">
                      <template v-if="siteListingData[0]">
                        <strong>:
                          {{ this.customFormatNumber(siteListingData[0].site_total_capacity) }}
                          kWp</strong>
                      </template>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="text-white lead">Actual Turn On Date </b-col>
                    <b-col class="text-white lead">
                      <template v-if="siteListingData[0]">
                        <strong>: {{ this.customFormatDate(siteListingData[0].site_actual_turn_on_date) }}</strong>
                      </template>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col class="text-white lead">Planned Turn On </b-col>
                    <b-col class="text-white lead" style="padding-bottom: 25px;">
                      <template v-if="siteListingData[0]">
                        <strong>: {{ this.customFormatDate(siteListingData[0].site_target_turn_on_date) }}</strong>
                      </template>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="text-white lead">&nbsp; </b-col>
                    <b-col class="text-white lead"><strong>&nbsp;</strong>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- <b-col>
                  <b-row>
                    <b-col class="text-white lead">Contractor </b-col>
                    <b-col class="text-white lead"><strong>: SEPEC</strong>
                    </b-col>
                  </b-row>
                </b-col> -->
              </b-row>
              <!-- <b-row>
                <b-col>
                  <b-row>
                    <b-col class="text-white lead">Inverter Brand / Type</b-col>
                    <b-col class="text-white lead"><strong>:
                        Huawei / SUN2000-330KTL </strong>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col class="text-white lead">Module Brand/Type</b-col>
                    <b-col class="text-white lead"><strong>: Jinko N Type Bifacial 625Wp</strong>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row> -->
              <div class="mb-3">
                <span class="text-white lead text-muted">Inverter Brand / Type :
                  <strong class="text-white">Huawei / SUN2000-330KTL, Huawei / SUN2000-215KTL-H0</strong>
                </span>
              </div>
              <div class="mb-3">
                <span class="text-white lead text-muted">Module Brand / Type :
                  <strong class="text-white">Jinko N Type Bifacial 625Wp, Quantity: 92820 (40MW); 21600 (10MW) </strong>
                </span>
              </div>
              <div class="mb-3">
                <span class="text-white lead text-muted">Mounting Type :
                  <strong class="text-white">mix screw mounting and concrete foundation (ground mounting)</strong>
                </span>
              </div>
              <b-row>
                <b-col class="text-right">
                  <template v-if="siteListingData[0]">
                    <b-link :to="`/siteUtilitiesEdit/${siteListingData[0].site_id}`" class="btn btn-primary"
                            target="_blank">
                      Edit
                    </b-link>
                  </template>
                  <!--<b-button variant="outline-info">Edit</b-button>-->
                </b-col>
              </b-row>
            </card>
          </b-col>
          <b-col md="5">
            <card class="bg-card-dashboard">
              <div id="slider">
                <div class="slider">
                  <ul class="slides" :style="{ left: -width * current + 'px' }">
                    <li v-for="(slide, i) in slides">
                      <img :src="slide" alt="" />
                    </li>
                  </ul>
                  <ul class="bullets" style="display: none">
                    <li v-for="(slide, i) in slides" @click="selectSlide(i)"
                        v-html="i == current ? '&#9679;' : '&omicron;'"></li>
                  </ul>
                  <a class="prev" href="#" @click.prevent="prevSlide" style="display: none">&#x25C0;</a>
                  <a class="next" href="#" @click.prevent="nextSlide" style="display: none">&#x25B6;</a>
                </div>
              </div>
            </card>
          </b-col>
        </b-row>
        <!-- historical project update -->
        <b-row class="mb-3">
          <b-col>
            <card class="bg-card-dashboard">
              <!-- <div id="chart"> -->
              <apexchart type="rangeBar" height="490" width="100%" :options="chartOptions" :series="series"></apexchart>
              <!-- </div> -->
              <div class="text-right">
                <template v-if="siteListingData[0]">
                  <b-link :to="`/siteUtilitiesHistory/${siteListingData[0].site_id}`" class="btn btn-primary">
                    Historical Project Update
                  </b-link>
                </template>
              </div>
            </card>
          </b-col>
        </b-row>
        <!-- chart: safety inspection | observation item -->
        <b-row>
          <!--SAFETY INSPECTION BAR CHART-->
          <b-col>
            <card class="bg-card-dashboard">
              <div class="h2 text-white text-center">Safety Inspection</div>
              <apexchart :key="obj_inspection.chartKey" type="bar" height="350" :options="obj_inspection.chartOptions"
                         :series="obj_inspection.series">
              </apexchart>
            </card>
          </b-col>
          <!--OPENED SAFETY INSPECTION-->
          <b-col>
            <card class="bg-card-dashboard">
              <div class="h2 text-white text-center">Observation Items</div>
              <vue-highcharts :options="obj_observation_items.chartOptions"
                              ref="chartObservationItems"></vue-highcharts>
              <!--
              <table style="width: 100%;">
                <thead
                  style="background-color: #4E99A4; color: white; font-size: small; border-bottom: 10px solid #23454A; ">
                  <th class="th-table-new ">Observation Category</th>
                  <th class="th-table-new ">Open Item(s)</th>
                  <th class="th-table-new ">Closed Item(s)</th>
                </thead>
                <tbody>
                  <template v-if="obj_inspection.items_cek.length > 0">
                    <tr v-for="item in obj_inspection.items_cek" :key="item.id"
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  10px solid #23454A;">
                      <td class="tr-td-table-new">{{ item.observation_category }}</td>
                      <td class="tr-td-table-new" v-if="item.items_open > 0" style="color:red;font-weight: bold;font">{{
                        item.items_open }}</td>
                      <td class="tr-td-table-new" v-else>{{ item.items_open }}</td>
                      <td class="tr-td-table-new">{{ item.items_close }}</td>
                    </tr>
                  </template>
<template v-else>
                    <tr
                      style="background-color: #3a656b; color: white; font-size: smaller; border-bottom:  10px solid #23454A;">
                      <td class="tr-td-table-new text-muted text-center" colspan="3">No Data</td>
                    </tr>
                  </template>
</tbody>
</table>
-->
            </card>
          </b-col>
        </b-row>
        <!-- milestone monitoring -->
        <b-row>
          <b-col md="12">
            <card class="bg-card-dashboard">
              <b-table :items="items" :fields="fields" responsive="sm">
                <template #thead-top="" dark>
                  <b-tr>
                    <b-th colspan="1"><span class="sr-only" style="text-align: midle">Name and ID</span></b-th>
                    <b-th colspan="3" class="text-center">Current </b-th>
                    <b-th colspan="1" v-for="day in daymonth" :key="day.id">{{
                        day.label
                      }}</b-th>
                  </b-tr>
                </template>
                <template #cell(diff)="data">
                  <span class="h4" style="color: #28af28" v-if="data.item.diff_status == '+'">
                    <strong>{{ data.value }}%</strong>
                  </span>
                  <span class="text-danger h4" v-if="data.item.diff_status == '-'">
                    <strong>{{ data.value }}%</strong>
                  </span>
                </template>
              </b-table>
            </card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
    import { Table, TableColumn } from "element-ui";
    import { VueEllipseProgress } from "vue-ellipse-progress";
    import VueHighcharts from "vue2-highcharts";

    import {
        LoginURL,
        clientId,
        clientSecret,
        apiDomain,
        getHeader,
        getcurrentDate,
    } from "@/assets/js/config.js";
    import axios from "axios";
    import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
    import jsonFormData from "json-form-data";
    import VueApexCharts from "vue-apexcharts";
    import moment from "moment";
    import ProjectSiteTemplateViewForm from "@/views/SemCorp/Template/Project/ProjectSiteTemplateViewForm.vue";
    import { Select, Option } from "element-ui";

    export default {
        name: "Detail",
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            // VueEllipseProgress,
            VueHighcharts,
        },
        created() {
            const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
            this.myUserID = LoginUser;
            this.getDeveloperListing();
            this.getProjectTypeListing();
            this.getProjectManagerListing();
            this.getProjectManagerDetails();
            this.getProjectEngineerListing();
            this.getBusinessDevelopmentListing();
            this.getSafetyOfficerListing();
            this.getProjectSiteStatusListing();
            this.getDashboardData();
            // this.getGanttChartData();
        },
        // el: '#slider',
        data() {
            return {
                obj_inspection: {
                    intMonthInc: 0,
                    siteTaskYear: 0,
                    chartKey: 0,
                    chartOptions: {
                        chart: {
                            type: "bar",
                            height: 350,
                            toolbar: {
                                show: false,
                            },
                        },
                        colors: ["#BA5A5A", "#5ABAB9"],
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: "55%",
                                endingShape: "rounded",
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ["transparent"],
                        },
                        xaxis: {
                            categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
                            labels: {
                                // colors:"#fff",
                                style: {
                                    colors: ["#fff", "#fff", "#fff", "#fff", "#fff", "#fff"],
                                },
                            },
                        },
                        yaxis: {
                            labels: {
                                color: "#fff",
                                style: {
                                    colors: ["#fff"],
                                },
                            },
                        },
                        fill: {
                            opacity: 1,
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: true,
                            // position: "bottom",
                            // horizontalAlign: "left",
                            // verticalAlign: "left",
                            // floating: false,
                            // fontSize: "20px",
                            offsetX: 0,
                            offsetY: 10,

                            labels: {
                                colors: "#fff",
                            },
                            // colors:["#fff"]
                        },
                    },
                    series: [
                        {
                            name: "Negative Observation",
                            data: [0, 0, 0, 0, 0, 0],
                        },
                        {
                            name: "Positive Observation",
                            data: [0, 0, 0, 0, 0, 0],
                        },
                    ],
                    items_cek: [],
                    items: [
                        {
                            id: 0,
                            task_name: "PLTS IKN",
                            planned_start: "10/07/2024",
                            planned_end: "30/07/2024",
                            status: "finish",
                        },
                        {
                            id: 0,
                            task_name: "PLTS IKN",
                            planned_start: "10/07/2024",
                            planned_end: "30/07/2024",
                            status: "finish",
                        },
                    ],
                    si_site_all: [],
                    so_site_all: [],
                },
                obj_observation_items: {
                    chartOptions: null,
                    // series: [{data: null}],
                },
                obj_gant_chart: {
                    chartOptions: null,
                },
                slides: [
                    "/img/ikn/01.png",
                    "/img/ikn/02.png",
                    "/img/ikn/03.png",
                    "/img/ikn/04.png",
                    "/img/ikn/05.png",
                    "/img/ikn/06.png",
                    "/img/ikn/07.png",
                    "/img/ikn/08.png",
                    "/img/ikn/09.png",
                ],
                current: 0,
                width: 800,
                timer: 0,
                model: {
                    project_id: null,
                    developer_id: "x",
                    project_type_id: "x",
                    project_manager: "x",
                    project_manager_name: "x",
                    project_engineer: "x",
                    project_engineer_name: "x",
                    project_business_development: "x",
                    project_business_development_name: "x",
                    project_safety: "x",
                    project_safety_name: "x",
                    project_name: null,
                    project_ppa_approved_capacity: null,
                    project_ppa_start_date: null,
                    project_watt_class: null,
                    roofed_handover_date: null,
                    project_tag: null,
                    site_id: null,
                },
                developers: [],
                project_type: [],
                project_managers: [],
                project_engineers: [],
                project_business_development: [],
                project_safety: [],
                site_status: [],
                siteListingData: [],
                siteTaskProgressData: [],
                siteTaskMonth: "00",
                siteTaskYear: 0,
                siteTaskBase01: "",
                siteTaskBase02: "",
                siteTaskBase03: "",
                siteTaskBase04: "",
                siteTaskBase05: "",
                siteTaskBase06: "",
                siteInc: 0,
                taskDiff: 0,
                isSolarNovaProject: false,
                items: [],
                fields: [
                    { key: "key_mil", label: "Key Milestone" },
                    { key: "base", label: "Base" },
                    { key: "actual", label: "Actual" },
                    { key: "diff", label: "Diff" },
                    { key: "b1", label: "Base" },
                    { key: "b2", label: "Base" },
                    { key: "b3", label: "Base" },
                    { key: "b4", label: "Base" },
                    { key: "b5", label: "Base" },
                    { key: "b6", label: "Base" },
                ],
                daymonth: [
                    { id: 0, label: "30/4", labelData: "30/4" },
                    { id: 1, label: "31/5", labelData: "30/4" },
                    { id: 2, label: "30/6", labelData: "30/4" },
                    { id: 3, label: "31/7", labelData: "30/4" },
                    { id: 4, label: "31/8", labelData: "30/4" },
                    { id: 5, label: "30/9", labelData: "30/4" },
                ],
                series_pie: [44, 55, 41, 17, 15, 44, 12, 11],
                chartOptions_pie: {
                    chart: {
                        type: "donut",
                    },
                    labels: [
                        "Pending Submission",
                        "Submit",
                        "In Review",
                        "RI",
                        "AC",
                        "Return",
                        "A",
                        "TR",
                    ],
                    colors: [
                        "#5090DC",
                        "#63FFF9",
                        "#34BFE1",
                        "#E1CC00",
                        "#00FFB5",
                        "#459D55",
                        "#8BD1CF",
                        "#FFA03A",
                    ],
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: false,
                        width: 0,
                    },
                    legend: {
                        show: true,
                        showForSingleSeries: true,
                        // position: "bottom",
                        // horizontalAlign: "left",
                        // verticalAlign: "left",
                        // floating: false,
                        // fontSize: "20px",
                        offsetX: 0,
                        offsetY: 10,

                        labels: {
                            colors: "#fff",
                        },
                        // colors:["#fff"]
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },

                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
                ms_upload_task: [],
                series: [],
                /*
                series: [
                {
                      name: 'Base',
                      data: [
                        {
                          x: 'Sub Stasion',
                          y: [
                            new Date('2019-03-02').getTime(),
                            new Date('2019-03-05').getTime()
                          ]
                        },
                        {
                          x: 'Pilling',
                          y: [
                            new Date('2019-03-08').getTime(),
                            new Date('2019-03-11').getTime()
                          ]
                        },
                        {
                          x: 'Mounting',
                          y: [
                            new Date('2019-03-11').getTime(),
                            new Date('2019-03-16').getTime()
                          ]
                        }
                      ]
                    },
                    {
                      name: 'Actual',
                      data: [
                        {
                          x: 'Sub Stasion',
                          y: [
                            new Date('2019-03-03').getTime(),
                            new Date('2019-03-08').getTime()
                          ]
                        },
                        {
                          x: 'Pilling',
                          y: [
                            new Date('2019-03-06').getTime(),
                            new Date('2019-03-09').getTime()
                          ]
                        },
                        {
                          x: 'Mounting',
                          y: [
                            new Date('2019-03-10').getTime(),
                            new Date('2019-03-19').getTime()
                          ]
                        }
                      ]
                    }
                  ],
                */
                chartOptions: {
                    chart: {
                        height: 390,
                        type: "rangeBar",

                        zoom: {
                            enabled: false,
                        },
                        toolbar: {
                            show: false,
                        },
                    },
                    // colors: ['#00B9E2', '#D05252'],
                    plotOptions: {
                        bar: {
                            borderRadius: 20,
                            borderRadiusWhenStacked: "all", // 'all', 'last'
                            horizontal: true,
                            columnWidth: "50%",
                            barHeight: "50%",
                        },
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shade: "light",
                            type: "vertical",
                            shadeIntensity: 0.25,
                            gradientToColors: undefined,
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [50, 0, 100, 100],
                        },
                    },
                    legend: {
                        show: false,
                        showForSingleSeries: true,
                        position: "bottom",
                        horizontalAlign: "center",
                        customLegendItems: ["Base", "Actual"],
                        labels: {
                            colors: "#fff",
                        },
                        // colors:["#fff"]
                    },
                    xaxis: {
                        type: "datetime",
                        labels: {
                            color: "#fff",
                            style: {
                                colors: ["#fff"],
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            color: "#fff",
                            style: {
                                colors: ["#fff"],
                                fontFamily: 'Open Sans, sans-serif',
                                fontSize: '1.25rem',
                                fontWeight: '300',
                            },
                        },
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                        yaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                },
            };
        },
        methods: {
            getDeveloperListing() {
                axios
                    .get(apiDomain + "setup/developer", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.developers = response.data.data;
                            // default to sembcorp solar || developer_id = 1
                            // this.model.developer_id = 1;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Developer Information");
                    });
            },
            getProjectTypeListing() {
                axios
                    .get(apiDomain + "setup/project_type", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.project_type = response.data.data;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Project Type Information");
                    });
            },
            getProjectManagerListing() {
                axios
                    .get(apiDomain + "lookup/user_list/Project_Manager", {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.project_managers = response.data.data;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Project Manager");
                    });
            },
            getProjectManagerDetails() {
                axios
                    .get(apiDomain + "lookup/user_list/Project_Manager", {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.project_managers = response.data.data;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Project Manager");
                    });
            },
            getProjectEngineerListing() {
                axios
                    .get(apiDomain + "lookup/user_list/Engineer", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.project_engineers = response.data.data;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Engineer Information");
                    });
            },
            getBusinessDevelopmentListing() {
                axios
                    .get(apiDomain + "lookup/user_list/Business_Development", {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.project_business_development = response.data.data;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Safety Officer Information");
                    });
            },
            getSafetyOfficerListing() {
                axios
                    .get(apiDomain + "lookup/user_list/Safety", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            this.project_safety = response.data.data;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Safety Officer Information");
                    });
            },
            getProjectSiteStatusListing() {
                // axios.get(apiDomain + 'lookup/site_status', { headers: getHeader() })
                axios
                    .get(apiDomain + "lookup/site_status.singapore_v2", {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.site_status = response.data.data;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        this.errorFunction(error, "Site Status Information");
                    });
            },
            getDashboardData() {
                this.model.project_id = this.$route.params.id;
                axios
                    .get(apiDomain + "project/project/" + this.model.project_id, {
                        headers: getHeader(),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.model.project_name = response.data.data[0].project_name;
                            document.title = "MOMENS-" + response.data.data[0].project_name;
                            this.model.developer_id = response.data.data[0].developer.developer_id;
                            this.model.developer_name = response.data.data[0].developer.developer_name;
                            this.model.project_type_id = response.data.data[0].project_type.project_type_id;
                            if (
                                this.model.project_type_id != null &&
                                [2, 3, 4, 9, 10, 11, 12, 13, 14].includes(
                                    this.model.project_type_id
                                )
                            ) {
                                this.isSolarNovaProject = true;
                            } else {
                                this.isSolarNovaProject = false;
                                this.getSiteListing();
                            }
                            this.model.project_type_name = response.data.data[0].project_type.project_type;
                            this.model.project_ppa_counter_party = response.data.data[0].project_ppa_counter_party;
                            let this_project_ppa_start_date =
                                response.data.data[0].project_ppa_start_date !== null
                                    ? response.data.data[0].project_ppa_start_date
                                    : "N/A";
                            if (this_project_ppa_start_date !== "N/A") {
                                let convertProjectStartDateDate = new Date(this_project_ppa_start_date);
                                const year = convertProjectStartDateDate.getFullYear();
                                const month = convertProjectStartDateDate.toLocaleString(
                                    "en-US",
                                    { month: "short" }
                                );
                                const day = convertProjectStartDateDate.toLocaleString("en-US", {
                                    day: "2-digit",
                                });
                                this_project_ppa_start_date = `${day}-${month}-${year}`;
                            }

                            this.model.project_ppa_start_date = this_project_ppa_start_date;
                            // console.log(response.data.data[0].project_ppa_approved_capacity);
                            this.model.project_ppa_approved_capacity = parseFloat(
                                response.data.data[0].project_ppa_approved_capacity.replace(
                                    /,/g,
                                    ""
                                )
                            );
                            if (response.data.data[0].project_watt_class !== null) {
                                this.model.project_watt_class = parseFloat(
                                    response.data.data[0].project_watt_class.replace(/,/g, "")
                                );
                            }
                            this.model.project_manager = response.data.data[0].project_manager.id;
                            this.model.project_manager_name = response.data.data[0].project_manager.name;
                            this.model.project_engineer = response.data.data[0].project_engineer.id;
                            this.model.project_engineer_name = response.data.data[0].project_engineer.name;
                            this.model.project_business_development = response.data.data[0].project_business_development.id;
                            this.model.project_business_development_name = response.data.data[0].project_business_development.name;
                            this.model.project_safety = response.data.data[0].project_safety.id;
                            this.model.project_safety_name = response.data.data[0].project_safety.name;
                            this.model.project_tag = response.data.data[0].project_tag;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                        if (error.response.status == 401) {
                            this.$router.push("/login");
                        }
                    });
            },
            customFormatNumber(theData) {
                let retVal = parseFloat(theData.replace(/,/g, ""));

                return retVal;
            },
            customFormatDate(theData) {
                let checkDate = theData !== null ? theData : "N/A";
                if (checkDate !== "N/A") {
                    let convertCheckDate = new Date(checkDate);
                    const year = convertCheckDate.getFullYear();
                    const month = convertCheckDate.toLocaleString(
                        "en-US",
                        { month: "short" }
                    );
                    const day = convertCheckDate.toLocaleString("en-US", {
                        day: "2-digit",
                    });

                    return `${day}-${month}-${year}`;
                }
            },
            getSiteListing() {
                axios
                    .get(
                        apiDomain +
                        "project/project_site.show_by_project/" +
                        this.model.project_id,
                        { headers: getHeader() }
                    )
                    .then((response) => {
                        // console.log("getSiteListing()");
                        // console.log(response.data);
                        if (response.status === 200) {
                            let data = response.data.data;
                            data.forEach((item) => {
                                item.visible = false;
                                //                    this.getContractorFromSite(item.site_id);
                            });

                            this.siteListingData = data;

                            axios
                                .get(
                                    apiDomain +
                                    "project/ms_task_main_level/" +
                                    this.siteListingData[0].site_id,
                                    { headers: getHeader() }
                                )
                                .then((response) => {
                                    if (response.status === 200) {
                                        this.ms_upload_task = response.data.data;
                                        this.updateGanttChartSeries();
                                        this.getSiteTaskProgressData();
                                        this.getSafetyInspectionList();
                                        // this.updateChartObservationItems();
                                    }
                                })
                                .catch((error) => {
                                    if (typeof error.response !== "undefined") {
                                        this.errorFunction(error, "Site Status Information");
                                    }
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.status);
                    });
            },
            getSiteTaskProgressData() {
                axios
                    .get(
                        apiDomain +
                        "project/get_dashboard_utilities_data_by_project_site/" +
                        this.siteListingData[0].site_id,
                        { headers: getHeader() }
                    )
                    .then((response) => {
                        // console.log("getSiteTaskProgressData()");
                        // console.log(response.data);
                        if (response.status === 200) {
                            this.siteTaskProgressData = response.data.data;
                            //alert(this.siteTaskProgressData[0].task_name);
                            const today = new Date();
                            this.siteTaskYear = today.getFullYear();
                            this.siteInc = 1;
                            for (let key in this.daymonth) {
                                this.siteTaskMonth = String(today.getMonth() + this.siteInc).padStart(2, "0");
                                this.daymonth[key].label = this.getLabelLastDayOfMonth(this.siteTaskYear, this.siteTaskMonth);
                                this.daymonth[key].labelData = this.getLastDayOfMonth(this.siteTaskYear, this.siteTaskMonth);
                                this.siteInc = this.siteInc + 1;
                            }

                            for (let task in this.siteTaskProgressData) {
                                let taskColor = "-";
                                if (this.siteTaskProgressData[task].diff_in_progress >= 0) {
                                    taskColor = "+";
                                }
                                this.taskDiff = this.siteTaskProgressData[task].diff_in_progress;

                                this.items.push({
                                    key_mil: this.siteTaskProgressData[task].task_name,
                                    base: this.siteTaskProgressData[task].today_progress + "%",
                                    actual:
                                    this.siteTaskProgressData[task].percent_completion + "%",
                                    diff: this.taskDiff.toFixed(2),
                                    diff_status: taskColor,
                                    b1: this.siteTaskProgressData[task].monthly_progress.base0 + "%",
                                    b2: this.siteTaskProgressData[task].monthly_progress.base1 + "%",
                                    b3: this.siteTaskProgressData[task].monthly_progress.base2 + "%",
                                    b4: this.siteTaskProgressData[task].monthly_progress.base3 + "%",
                                    b5: this.siteTaskProgressData[task].monthly_progress.base4 + "%",
                                    b6: this.siteTaskProgressData[task].monthly_progress.base5,
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information");
                        }
                    });
            },
            getLastDayOfMonth(year, month) {
                let date = new Date(year, month, 1);
                date.setDate(date.getDate() - 1);

                let yyyy = date.getFullYear();
                let mm = String(date.getMonth() + 1).padStart(2, "0");
                let dd = String(date.getDate()).padStart(2, "0");

                return `${yyyy}-${mm}-${dd}`;
            },
            getLabelLastDayOfMonth(year, month) {
                let date = new Date(year, month, 1);
                date.setDate(date.getDate() - 1);

                let yyyy = date.getFullYear();
                let mm = String(date.getMonth() + 1).padStart(2, "0");
                let dd = String(date.getDate()).padStart(2, "0");

                return `${dd}/${mm}`;
            },
            updateGanttChartSeries() {
                /*
                  Jadi kalo dia udah lewat deadline tspu belum kelar dimerahin
                  Kalo dia belum lewat deadline tapi udah 7 hari mendekati deadline, jadih orange
                  Kalo belum lewat deadline tapi masi lebih dari 7 hari deadline nya, jadih ijo
                */
                // console.log("updateGanttChartSeries");
                // console.log(this.ms_upload_task);

                var tmp_series = [
                    {
                        name: "Planned",
                        data: [],
                    },
                    {
                        name: "Actual",
                        data: [],
                    },
                    // {
                    //   name: "Actual",
                    //   data: [{
                    //     fillColor: "#2bff00",
                    //     x: "Mobilization (construction comencement) & Overall Construction",
                    //     y: null
                    //   },
                    //   {
                    //     fillColor: "#fcad03",
                    //     x: "Engineering",
                    //     y: [1707984000000, 1714449033000]
                    //   },
                    //   {
                    //     fillColor: "#fcad03",
                    //     x: "Procurement",
                    //     y: [1710403200000, 1720151433000]
                    //   },
                    //   {
                    //     fillColor: "#fcad03",
                    //     x: "Construction",
                    //     y: [1721116800000, 1722311433000]
                    //   },
                    //   {
                    //     fillColor: "#05bdfa",
                    //     x: "Commissioning",
                    //     y: null     //[1729872000000, 1732294800000]
                    //   }
                    //   ],
                    // },
                ];

                // console.log(tmp_series);

                let keys = Object.keys(this.ms_upload_task);
                keys.forEach((key) => {
                    let item = this.ms_upload_task[key];
                    // console.log(item);

                    let planned_color =
                        item.mspt_percent_completion == 100
                            ? "#27bd09" //"#003cff"
                            : this.getGantChartColor(item.mspt_planned_end_date);
                    let actual_color = "#003cff";
                    // console.log(planned_color);

                    // console.log(item.observation_category);
                    // obj_obs_items.chartOptions.series[0].data.push({
                    //   name: item.observation_category,
                    //   y: item.items_open,
                    //   // color: "#3F92D3"
                    // });

                    tmp_series[0].data.push({
                        fillColor: planned_color,
                        x: item.mspt_task_name,
                        y: [
                            item.mspt_planned_start_date
                                ? new Date(item.mspt_planned_start_date).getTime()
                                : null,
                            item.mspt_planned_end_date
                                ? new Date(item.mspt_planned_end_date).getTime()
                                : null,
                        ],
                    });

                    tmp_series[1].data.push({
                        fillColor: actual_color,
                        x: item.mspt_task_name,
                        y: [
                            item.mspt_actual_start_date
                                ? new Date(item.mspt_actual_start_date).getTime()
                                : null,
                            item.mspt_actual_end_date
                                ? new Date(item.mspt_actual_end_date).getTime()
                                : null,
                        ],
                    });
                });
                // console.log(tmp_series);

                /*
                const temp_series = [
                  {
                    name: "Planned",
                    data: this.ms_upload_task.map((item) => ({
                      x: item.mspt_task_name,
                      y: [
                        item.mspt_planned_start_date
                          ? new Date(item.mspt_planned_start_date).getTime()
                          : null,
                        item.mspt_planned_end_date
                          ? new Date(item.mspt_planned_end_date).getTime()
                          : null,
                      ],
                      fillColor: "#FF4560",
                    })),
                  },
                  {
                    name: "Actual",
                    data: this.ms_upload_task.map((item) => ({
                      x: item.mspt_task_name,
                      y: [
                        item.mspt_actual_start_date
                          ? new Date(item.mspt_actual_start_date).getTime()
                          : null,
                        item.mspt_actual_end_date
                          ? new Date(item.mspt_actual_end_date).getTime()
                          : null,
                      ],
                      fillColor: "#00E396",
                    })),
                  },
                ];

                console.log("temp_series");
                console.log(temp_series);
                */

                this.series = [];
                this.series = tmp_series;

                // this.chartOptions.colors = [];
                // this.chartOptions.colors = ['#2cd158', '#e4f21d', '#990342', '#a813e8'];
            },
            getGantChartColor(mspt_planned_end_date) {
                // mspt_planned_end_date = "2024-06-15T16:00:00.000000Z";
                // mspt_planned_end_date = "2024-08-17T16:00:00.000000Z";
                // mspt_planned_end_date = "2024-07-28T16:00:00.000000Z";
                // mspt_planned_end_date = "2024-07-29T16:00:00.000000Z";
                // mspt_planned_end_date = "2024-07-30T16:00:00.000000Z";
                // mspt_planned_end_date = "2024-07-31T16:00:00.000000Z";
                // mspt_planned_end_date = "2024-07-27T16:00:00.000000Z";
                // mspt_planned_end_date = "2024-07-26T16:00:00.000000Z";

                let target_end_date = new Date(mspt_planned_end_date).getTime();
                // console.log(mspt_planned_end_date);
                // console.log(new Date(mspt_planned_end_date));
                // console.log(target_end_date);
                // console.log("============================");

                let date_7d_before = new Date(mspt_planned_end_date);
                date_7d_before.setDate(date_7d_before.getDate() - 7);
                // console.log("date_7d_before");
                // // let date_7d_before = new Date();
                // console.log(date_7d_before);
                // console.log(date_7d_before.getTime());
                // console.log("----------------------------");

                let date_today = new Date();
                // console.log("today");
                // console.log(date_today);
                // console.log(date_today.getTime());
                // console.log("----------------------------");
                // todayDate.setDate(todayDate.getDate() + 1);

                var chart_color = "#ff0004"; //"red";
                if (date_today < date_7d_before.getTime()) {
                    chart_color = "#2bff00"; //"green";
                } else {
                    if (target_end_date > date_7d_before.getTime()) {
                        if (target_end_date > date_today) {
                            chart_color = "#ffb700"; //"orange";
                        }
                    }
                }

                chart_color = '#807c7c';
                // console.log(chart_color);
                return chart_color;
            },
            addMonth(date, intDiff) {
                const newDate = new Date(date);
                const month = newDate.getMonth();
                const year = newDate.getFullYear();

                newDate.setMonth(month + intDiff);

                if (newDate.getMonth() !== (month + intDiff) % 12) {
                    newDate.setDate(0); // Set to the last day of the previous month
                }

                return newDate;
            },
            formatMonthYear(date) {
                const MYmonth = date.toLocaleString("default", { month: "short" });
                const MYyear = date.getFullYear();
                return `${MYmonth}-${MYyear}`;
            },
            getSafetyInspectionList() {
                var oInspection = this.obj_inspection;
                var oChartOption = oInspection.chartOptions;
                var oSeries = oInspection.series;

                const today = new Date();
                oInspection.siteTaskYear = today.getFullYear();
                oInspection.intMonthInc = 0;
                const Diff = 5;
                let firstDay = new Date(today.setDate(1));
                for (let key in oChartOption.xaxis.categories) {
                    let newDay = this.addMonth(firstDay, oInspection.intMonthInc - Diff);
                    oChartOption.xaxis.categories[oInspection.intMonthInc] =
                        this.formatMonthYear(newDay);
                    oInspection.intMonthInc = oInspection.intMonthInc + 1;
                }

                // console.log("getSafetyInspectionList");
                // console.log(this.siteListingData);
                // console.log(this.siteListingData[0].site_id);
                this.model.site_id = this.siteListingData[0].site_id;
                axios
                    .get(
                        apiDomain +
                        "health_safety/get_safety_observation_by_site_id/" +
                        this.model.site_id,
                        { headers: getHeader() }
                    )
                    .then((response) => {
                        // console.log('getSafetyInspectionList()');
                        // console.log(response.data);
                        if (response.status === 200) {
                            oInspection.si_site_all = response.data.data;
                            for (let i = 0; i < oInspection.si_site_all.length; i++) {
                                let create_date = new Date(
                                    oInspection.si_site_all[i].safety_observation_create_date
                                );
                                let matchIndex = -1;
                                matchIndex = oChartOption.xaxis.categories.indexOf(
                                    this.formatMonthYear(create_date)
                                );
                                if (matchIndex != -1) {
                                    oInspection.so_site_all =
                                        oInspection.si_site_all[i].safety_observation_items;
                                    for (let k = 0; k < oInspection.so_site_all.length; k++) {
                                        //alert(this.series[0].name + ':' + this.series[0].data[matchIndex] + ' - ' + this.series[1].name + ':' + this.series[1].data[matchIndex]);
                                        if (
                                            oInspection.so_site_all[k].safety_observation_type_id > 3
                                        ) {
                                            oSeries[0].data[matchIndex] =
                                                oSeries[0].data[matchIndex] + 1;
                                            if (oInspection.items_cek.length == 0) {
                                                if (
                                                    oInspection.so_site_all[k]
                                                        .safety_observation_status_id == 3
                                                ) {
                                                    oInspection.items_cek.push({
                                                        observation_category:
                                                        oInspection.so_site_all[k]
                                                            .safety_observation_category
                                                            .safety_observation_category,
                                                        items_open: 0,
                                                        items_close: 1,
                                                    });
                                                } else
                                                    oInspection.items_cek.push({
                                                        observation_category:
                                                        oInspection.so_site_all[k]
                                                            .safety_observation_category
                                                            .safety_observation_category,
                                                        items_open: 1,
                                                        items_close: 0,
                                                    });
                                            } else {
                                                let matchCategory = -1;
                                                matchCategory = oInspection.items_cek.findIndex(
                                                    (item) =>
                                                        item.observation_category ===
                                                        oInspection.so_site_all[k].safety_observation_category
                                                            .safety_observation_category
                                                );
                                                if (matchCategory == -1) {
                                                    if (
                                                        oInspection.so_site_all[k]
                                                            .safety_observation_status_id == 3
                                                    ) {
                                                        oInspection.items_cek.push({
                                                            observation_category:
                                                            oInspection.so_site_all[k]
                                                                .safety_observation_category
                                                                .safety_observation_category,
                                                            items_open: 0,
                                                            items_close: 1,
                                                        });
                                                    } else
                                                        oInspection.items_cek.push({
                                                            observation_category:
                                                            oInspection.so_site_all[k]
                                                                .safety_observation_category
                                                                .safety_observation_category,
                                                            items_open: 1,
                                                            items_close: 0,
                                                        });
                                                } else {
                                                    if (
                                                        oInspection.so_site_all[k]
                                                            .safety_observation_status_id == 3
                                                    ) {
                                                        oInspection.items_cek[matchCategory].items_close =
                                                            oInspection.items_cek[matchCategory].items_close +
                                                            1;
                                                    } else {
                                                        oInspection.items_cek[matchCategory].items_open =
                                                            oInspection.items_cek[matchCategory].items_open + 1;
                                                    }
                                                }
                                            }
                                        } else
                                            oSeries[1].data[matchIndex] =
                                                oSeries[1].data[matchIndex] + 1;
                                    }
                                }
                            }

                            let arrObsItems = oInspection.items_cek;
                            this.updateChartObservationItems(arrObsItems);
                            oInspection.chartKey += 1;
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Safety Inspection");
                    });
            },
            //_ method is trigger in getSafetyInspectionList(), it populate and draw the pie chart
            updateChartObservationItems(obsItems) {
                // console.log('updateChartObservationItems');

                var obj_obs_items = this.obj_observation_items;

                let chart_template = {
                    credits: {
                        enabled: false,
                    },
                    series: [
                        {
                            name: "Total",
                            colorByPoint: true,
                            dataLabels: {
                                enabled: true,
                                style: {
                                    textOutline: 'none',
                                    fontSize: '14px',
                                    fontFamily: "Open Sans, sans-serif",
                                    fontWeight: "bold",
                                    color: '#ffffff'
                                }
                            },
                            data: [],
                        },
                    ],
                    chart: {
                        type: "pie",
                        backgroundColor: "#23454A",
                    },
                    title: {
                        text: "",
                        style: {
                            color: "white",
                            fontWeight: "bold",
                        },
                    },
                };
                obj_obs_items.chartOptions = chart_template;

                obj_obs_items.chartOptions.series[0].data = [];

                let keys = Object.keys(this.obj_inspection.items_cek);
                keys.forEach((key) => {
                    let item = this.obj_inspection.items_cek[key];
                    // console.log(item.observation_category);
                    obj_obs_items.chartOptions.series[0].data.push({
                        name: item.observation_category,
                        y: item.items_open,
                        // color: "#3F92D3"
                    });
                });

                // console.log(this.obj_observation_items);
            },
            //_ method for image slides
            nextSlide: function () {
                this.current++;
                if (this.current >= this.slides.length) this.current = 0;
                this.resetPlay();
            },
            prevSlide: function () {
                this.current--;
                if (this.current < 0) this.current = this.slides.length - 1;
                this.resetPlay();
            },
            selectSlide: function (i) {
                this.current = i;
                this.resetPlay();
            },
            resetPlay: function () {
                clearInterval(this.timer);
                this.play();
            },
            play: function () {
                let app = this;
                this.timer = setInterval(function () {
                    app.nextSlide();
                }, 5000);
            },
        },
        createData() {
            axios
                .put(apiDomain + "project/project/" + this.model.project_id, this.model, {
                    headers: getHeader(),
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.$notify({
                            message:
                            "<b>Update Project : " +
                            this.model.project_name +
                            "</b> - Successfully Updated.",
                            timeout: 10000,
                            icon: "ni ni-bell-55",
                            type: "default",
                        });
                        //   this.$router.back()
                        this.getDashboardData();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errorFunction(error, "Project Information");
                });
        },
        mounted() {
            this.play();
        },
    };
</script>

<style scoped>
  /*
  .table thead th {
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: 0px solid #e9ecef;
      border-top: 0px solid #e9ecef;
      color: white;
  }
  .table td {
    color: white;
  }*/

  .apexcharts-legend-marker {
    width: 12px !important;
    height: 12px !important;
    left: 0px !important;
    top: 0px !important;
    border-radius: 2px !important;
  }

  /* .apexcharts-series {
    bottom:  30px !important;
   } */
  .apexcharts-rangebar-area {
    margin-bottom: 30px !important;
    padding-bottom: 30px !important;
    top: -80px;
  }

  /* .apexcharts-rangebar-series {
    padding: 30px !important;

  }
  .apexcharts-plot-series {
    padding: 30px !important;

  } */
  select {
    background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
  }

  .bg-card-dashboard {
    background-color: #23454a;
  }

  .bg-default-dashboard {
    background-color: #082a2f;
    /* background-size: cover; */
    /* position:fixed; */
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .form-control {
    background-color: #2c545a;
    border-color: #2c545a;
    color: white;

  &:focus {
     background-color: #2c545a;
     border-color: #2c545a;
     color: white;
   }

  &::placeholder {
     color: white;
   }
  }

  .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }

  .input-group-text {
    background-color: #2c545a;
    color: white;

  &:focus {
     background-color: #2c545a;
     border-color: #1d4147;
     color: white;
   }
  }

  .focused {
  .input-group-text {
    background-color: #2c545a;
  }
  }

  .navbar-search-dark .input-group {
    background-color: #23454a;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
  }

  .navbar-search .input-group {
    border-radius: 7px;
  }

  .el-input__inner,
  .el-select-dropdown__item.is-disabled:hover {
    background-color: #23454a;
  }

  .line-custom {
    background-color: #356168;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  /* .el-table th.el-table__cell {

    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #23454A;
    color: white;
    padding: 20px;
    border-top: 0px solid #082A2F;
    border-bottom: 0px solid #082A2F;
  }
   */

  /* .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 20px solid #082A2F;
    border-top: 0px solid #082A2F;
  } */

  /* div.el-table .el-table__row {
    background: #3a656b;;
    border-bottom: 0 solid #082A2F;

  }
  div.el-table .el-table__row:hover {
    background: #0d2f33;
  }

  .el-table--border::after, .el-table--group::after, .el-table::before {
    content: '';
    position: absolute;
    background-color: #ebeef500;
    z-index: 1;
  } */
</style>

<!-- CSS for image slides -->
<style scoped>
  #slider {
    width: 100%;
  }

  .slider {
    margin: 0 auto;
    padding: 0;
    /* width: 800px; */
    height: 450px;
    position: relative;
    overflow: hidden;

  ul.slides {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: table;
    position: absolute;
    top: 0;
    transition: left 800ms;

  li {
    list-style-type: none;
    display: table-cell;

  img {
    width: 800px;
  }
  }
  }

  ul.bullets {
    width: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: center;
    z-index: 1;

  li {
    list-style-type: none;
    display: inline;
    color: #fff;
    cursor: pointer;
    padding: 0 5px;
    font-size: 20px;
    font-family: sans-serif;
  }
  }

  .prev,
  .next {
    text-decoration: none;
    color: #fff;
    position: absolute;
    z-index: 1;
    font-size: 42px;
    top: 43%;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .prev {
    left: 20px;
  }

  .next {
    right: 20px;
  }
  }
</style>
