<template>
  <card>
    <h3 slot="header" class="mb-0">New Milestone Template</h3>
    <div class="text-right">
    </div>
    <form class="needs-validation"
          @submit.prevent="submit">

      <div class="form-row">
        <b-col md="12">
          <base-input label="Template Name"
                      name="Template Name"
                      placeholder="Template Name"
                      v-model="model.milestone_template_name">
          </base-input>
        </b-col>
      </div>

      <div class="form-row">
        <b-col md="12">
          <b-form-checkbox
                  id="allowed_task"
                  v-model="model.milestone_template_allowed_task"
                  name="Need Import Task?"
                  value="1"
                  unchecked-value="0">
            <b>Set Milestone Task?</b>
          </b-form-checkbox>
        </b-col>
      </div>

      <div>
        <br>
      </div>

      <base-button type="primary" @click="createData()">Create / Save</base-button>
      <base-button type="primary" @click="$router.back()">Cancel</base-button>
    </form>
  </card>
</template>
<script>
  import BaseSlider from '@/components/BaseSlider'
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'

  export default {
    mixins: [ErrorFunctionMixin],
    components: {
      BaseSlider
    },
    created()
    {

    },
    data() {
      return {
        model: {
          milestone_template_name: null,
        },
      }
    },
    methods: {

      createData(){
        if ( !this.model.milestone_template_name )
        {
          this.$notify({
            message:
              '<b>Invalid Input : All Column are Mandatory in Milestone Template</b> - These field are required.',
            timeout: 10000,
            icon: 'ni ni-bulb-61',
            type: 'danger',
          });
        }
        else
        {
          axios.post(apiDomain + 'template/milestone_template', this.model, { headers: getHeader() })
          .then ( response => {
            if (response.status === 200)
            {
                this.$notify({
                message:
                  '<b>New Milestone Template : ' + this.model.milestone_template_name + '</b> - Successfully Created.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
                });
                this.$router.back()
            }
          })
          .catch ( error => {
              console.log(error.response.data.errors)
              this.errorFunction(error, 'Create New Milestone Template')
          });
        }
      },
    }
  }
</script>
<style>
</style>
