<template>
  <div>
    <div class=" py-7 py-lg-8 pt-lg-5">
      <b-container>
        <div class="header-body " style="margin-top: 20%;" >
          <b-row  >
            <b-col md="4" xs="12">
              <card class="bg-card-dashboard" >
                <!-- Card body -->
                <b-link to="CNISiteList" target="_blank">
                  <b-img src="img/theme/project-mgmt/c&i.png"
                         class="img-center " style="width: 97px; " />
                  <div class="pt-4 text-center">
                    <h5 class="h3 title">
                      <span class="d-block text-cyan mb-1">C&I</span>
                    </h5>

                  </div>
                </b-link>

              </card>
            </b-col>
            <b-col md="4" xs="12">
              <card class="bg-card-dashboard" >
                <!-- Card body -->
                <b-link to="projectListing" target="_blank">
                  <b-img src="img/theme/project-mgmt/solarnova.png" class="img-center " style="width: 97px;" />
                  <div class="pt-4 text-center">
                    <h5 class="h3 title">
                      <span class="d-block text-cyan mb-1">Solarnova</span>
                    </h5>
                  </div>
                </b-link>

              </card>
            </b-col>
            <b-col md="4" xs="12">
              <card class="bg-card-dashboard" >
                <!-- Card body -->
                <b-link to="projectUtilitiesLandingPage" target="_blank">
                  <b-img src="img/theme/project-mgmt/utilities.png"
                         class="img-center " style="width: 97px; " />

                  <div class="pt-4 text-center">
                    <h5 class="h3 title">
                      <span class="d-block text-cyan mb-1">Utility Scale</span>
                    </h5>
                  </div>
                </b-link>
              </card>
            </b-col>
          </b-row>

        </div>
      </b-container>

    </div>


  </div>
</template>
<script>

    export default {
        name :"Project Management",
        components: {

        },
        data() {
            return {
                tets:""
            };
        },
        created() {
            document.title = 'MOMENS-Project Management';
        },
        methods: {

        },
        mounted() {
        }
    };
</script>
<style scoped>
  .bg-card-dashboard {
    background-color: #23454A;
  }
  .bg-default-dashboard {
    background-color: #082A2F;
    /* background-size: cover; */
    /* position:fixed; */
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
  .form-control {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  &:focus {
     background-color: #2C545A;
     border-color: #2C545A;
     color: white;
   }
  &::placeholder {
     color: white;
   }
  }
  .form-control {
  &:focus {
  &::placeholder {
     color: white;
   }
  }
  }
  .input-group-text {
    background-color: #2C545A;;
    color: white;
  &:focus {
     background-color: #2C545A;;
     border-color: #1D4147;
     color: white;
   }
  }
  .focused {
  .input-group-text {
    background-color: #2C545A;
  }
  }
  .navbar-search-dark .input-group {
    background-color: #23454A;
    color: black;
    border-color: rgba(255, 255, 255, 0.6);
  }
  .navbar-search .input-group {
    border-radius: 7px;
  }
  .el-input__inner, .el-select-dropdown__item.is-disabled:hover {
    background-color: #23454A;
  }
  .line-custom {
    background-color: #356168; margin-top: 15px; margin-bottom: 15px;
  }
</style>
